.usersList
    height: 30vh
    padding: 0 19px
    overflow-x: hidden
    overflow-y: scroll
    scrollbar-width: thin
    scrollbar-color: #4086F1 #F1F1F1
    &::-webkit-scrollbar-track
        background: #F1F1F1
    &::-webkit-scrollbar-track
        -webkit-box-shadow: none
        background-color: #F1F1F1
    &::-webkit-scrollbar
        width: 3px
        background-color: #F1F1F1
    &::-webkit-scrollbar-thumb
        background-color: #4086F1
    .noUsersMsg
        height: 100%
        display: flex
        align-items: center
        justify-content: center
        color: grey
