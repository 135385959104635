
.container
    width: 100%
    height: 100%
    overflow: scroll
    display: flex

.control
    display: flex
    gap: 8px
