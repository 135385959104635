.container
    border-radius: 4px
    background: #FFF
    height: 32px
    padding: 0 12px
    display: flex
    align-items: center
    justify-content: space-between
    transition: all 0.3s ease-in-out
    position: relative
    overflow: hidden
    &:hover
        background: var(--Blue-B50, #E3F2FD)
        .buttons
            right: 12px

.name
    width: 250px
    color: var(--DarkGreen-D800, #274A42)
    font-size: 12px
    font-weight: 400
    line-height: 16px

.user
    display: flex
    align-items: center
    justify-content: flex-start
    flex-grow: 1
    gap: 8px
    color: var(--DarkGreen-D800, #274A42)
    font-size: 12px
    font-weight: 400
    line-height: 16px

.settings
    display: flex
    align-items: center
    justify-content: space-between
    width: 150px

.buttons
    display: flex
    align-items: center
    gap: 2px
    position: absolute
    transition: all 0.3s ease-in-out
    right: -100%
