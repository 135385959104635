
.taskMessage
  display: flex
  flex-direction: column
  width: 85%
  height: max-content
  min-height: max-content
  border: 1px solid var(--color-blue-smooth)
  border-radius: 6px
  margin: 15px 5% 55px 10%
  position: relative

  .projectHeader
    display: flex
    gap: 15px
    border-bottom: 1px solid var(--color-gallery)
    padding: 5px
    font-size: 13px
    width: 100%
    justify-content: space-between
    .projectTitle
      display: flex
      gap: 10px
    .projectIcon
      width: 31px
      min-width: 31px
      height: 31px
      min-height: 31px
      img
        width: 100%
        height: 100%
        object-fit: cover
    .name
      display: flex
      justify-content: center
      align-items: center
    .categoryButton
      display: flex
      justify-content: center
      align-items: center
      color: var(--color-white)
      background: var(--color-grey)
      border-radius: 6px
      padding: 0 10px
      cursor: pointer
      gap: 10px
      .menuTriangle
        transform: rotateZ(-90deg)
        height: 10px
        width: 10px
        & path
          fill: var(--color-text-dark)
  .taskParts
    display: flex
  .taskLeftPart
    width: 80%
    padding: 10px
    border-right: 1px solid var(--color-gallery)
    .taskDetails
      padding: 20px 25px
      font-size: 10px
      h1
        font-family: "SF Pro Bold", sans-serif
        font-weight: bold
        font-size: 20px
      h2
        font-family: "SF Pro Bold", sans-serif
        font-weight: bold
        font-size: 15px
  .taskRightPart
    display: flex
    flex-direction: column
    gap: 5px
    width: 20%
    padding: 10px
    font-size: 12px

    .user
      display: flex
      align-items: center
      gap: 10px
    .userIcon
      box-shadow: 0 2px 4px rgba(140, 140, 140, 0.5)
      margin: 8px 0
      border-radius: 50%
      width: 24px
      height: 24px
      & path
        fill: var(--color-blue-smooth)

    .sprint
      display: flex
      justify-content: center
      align-items: center
      border: 1px solid var(--color-gallery)
      border-radius: 6px
      width: 100%
      padding: 5px 0
      .dateSprintWrap
        display: flex
        font-size: 8px
        color: var(--color-text-dark)
  .buttons
    display: flex
    width: 100%
    justify-content: flex-end
    align-items: center
    gap: 15px
    font-size: 11px
    position: absolute
    bottom: -45px
    z-index: 2
    .openTaskButton
      display: flex
      justify-content: center
      align-items: center
      width: 35%
      background: var(--color-blue-smooth)
      color: var(--color-white)
      height: 35px
      border-radius: 30px
      max-width: 194px
      cursor: pointer
    .editTaskButton
      display: flex
      justify-content: center
      align-items: center
      width: 35%
      background: var(--color-background-orange-medium)
      color: var(--color-white)
      height: 35px
      border-radius: 30px
      max-width: 194px
      cursor: pointer
.itemMenu
  display: flex
  padding-left: 20px
  align-items: center
  position: relative
  .circle
    left: 5px
    position: absolute
    width: 10px
    height: 10px
    border-radius: 50%

.deletedTaskWrap
  display: flex
  width: 100%
  padding: 15px 40px
  justify-content: flex-end
  .deletedTask
    display: flex
    gap: 30px
    background: var(--color-blue-lightest)
    width: 100px
    min-width: max-content
    flex-wrap: nowrap
    padding: 10px
    border-radius: 6px 6px 0 6px
    align-items: center
    color: var(--color-text-dark)
    font-style: italic
    span
      margin: 0 30px 0 0
    .trashIcon
      display: flex
      justify-content: center
      align-items: center
      width: 30px
      height: 30px
      border: 1px solid var(--color-blue-deleted)
      border-radius: 50%
      & path
        fill: var(--color-blue-deleted)
