@-webkit-keyframes hide-show
    0%
        opacity: 1
    50%
        opacity: 0
    100%
        opacity: 1

@keyframes hide-show
    0%
        opacity: 1
    50%
        opacity: 0
    100%
        opacity: 1

