.content
  display: flex
  justify-content: space-between
  gap: 15px
  background-color: var(--color-d50)
  border-radius: 4px
  padding: 12px 16px
  margin-bottom: -20px

  &__text
    max-width: 520px
    font-size: 14px
    color: var(--color-d800)

  &__date
    display: flex
    align-items: center
    flex-shrink: 0
    gap: 4px
    font-size: 12px