
.container
    display: flex
    align-items: flex-start
    padding: 3px 13px 3px 5px
    background: #E7EBEA
    border: 3px solid #E7EBEA
    border-radius: 4px
    cursor: pointer
    margin-bottom: 8px
    transition: all 0.3s ease-in-out
    &:hover
        border: 3px solid #a4cbed
    &.active
        border: 3px solid #1E88E5
    img
        border-radius: 4px
        margin-right: 6px
    h6
        font-family: 'Roboto'
        font-style: normal
        font-weight: 700
        font-size: 16px
        line-height: 20px
        color: #274A42
        margin-bottom: 0
    span
        font-family: 'Roboto'
        font-style: normal
        font-weight: 400
        font-size: 12px
        line-height: 16px
        color: #56716B

.info
    display: flex
    flex-direction: column
    justify-content: space-evenly
    height: 100%
