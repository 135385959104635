
@keyframes appear
  0%
    opacity: 0
.background
  position: fixed
  top: 0
  left: 0
  width: 100vw
  height: 100vh
  z-index: 10000
.contextMenuWrap
  overflow: hidden
  box-shadow: 0px 2px 4px 0px #00000033
  border-radius: 8px
  display: flex
  flex-direction: column
  width: max-content
  position: fixed
  z-index: 10001
  animation: appear 0.2s ease-in-out
  // box-shadow: 0 2px 5px var(--color-shadow)
  box-sizing: border-box
  // background: inherit
.wrap
  width: 100%
  border-radius: 8px
  height: 100%
  position: relative
  background: var(--color-white)
  .span
    content: ''
    top: -20px
    position: absolute
    border: 10px solid transparent
