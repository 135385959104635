@import "src/theme/scrollbar"
@import "src/theme/global"
.container
  display: flex
  gap: 5px
  margin-bottom: 10px
  max-width: 100%
  overflow-x: auto
  @include scrollbar(0, 0, "#fff", "#fff")
  &:last-child
    margin-bottom: 0
.additions
  display: flex
  gap: 5px
.taskBox
  display: flex
  align-items: center
  gap: 10px
  padding: 8px 10px
  position: relative
  border: 1px solid var(--color-border-dark)
  border-radius: 6px
  color: var(--color-text-dark)
  min-width: 100%
  &:last-child
    margin-bottom: 0
  .num
    width: 18px
    height: 18px
    border: 1px solid var(--color-border-dark)
    border-radius: 50%
    line-height: 16px
    text-align: center
    background: var(--color-background-light)
    font-size: 10px
    flex-shrink: 0
  .name
    flex: 1 1 auto
    font-size: 12px
    @include textEllipsis
  .through
    text-decoration: line-through

  .tag
    max-width: 110px
    min-width: max-content
    padding: 0 10px
    background: var(--color-blue-light)
    text-align: center
    font-size: 10px
    line-height: 18px
    border-radius: 9px
    @include textEllipsis

  .color
    width: 14px
    height: 14px
    border-radius: 50%
    flex-shrink: 0
  .emo
    font-size: 14px
  .commentBox
    position: relative
    width: 16px
    height: 16px
    display: flex
    flex-shrink: 0
    svg
      width: 100%
      height: 100%
  .isComment:before
    content: ""
    position: absolute
    top: 0
    right: 0
    width: 5px
    height: 5px
    border-radius: 50%
    background: var(--color-red-dark)
  .iconStatus
    position: absolute
    top: 0
    left: 1px
    width: 8px
    height: 8px
    display: flex
.dayView
  margin-bottom: 10px
  .taskIcon
    flex-shrink: 0
    path
      fill: var(--color-blue-smooth)
  .bellIcon
    flex-shrink: 0
    path
      fill: var(--color-aqua)
  .block
    font-size: 12px
    line-height: 18px
    border-radius: 9px
    padding: 0 8px
    background: var(--color-background-light)
    font-family: "SF Pro Text", sans-serif
