.container
    display: flex
    align-items: flex-start
    padding: 10px 0 0 16px
    flex-direction: column
    border-radius: 8px
    height: 100%
    .list
        margin-top: 12px
        width: 100%
        display: flex
        flex-wrap: wrap
        gap: 12px
        overflow: auto
        padding-top: 3px
        margin-bottom: 5px
    &_long
        padding-right: 5px
        .list
            flex-wrap: nowrap
            padding-bottom: 16px
            margin-bottom: 0

.emptyWrapper
    padding-right: 16px
    width: 100%
    height: 100%
    display: flex
    align-items: center
    justify-content: center

.title
    color: var(--light-blue-b-700, #0288D1)
    font-family: Roboto
    font-size: 16px
    font-weight: 700
    line-height: 24px
