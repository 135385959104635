
.wrapper
    width: 100%
    background: #E7EBEA
    height: 100%
    overflow: scroll
    padding: 20px

.content
    margin-top: 20px
