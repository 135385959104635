.wrapper
    width: 100%
    height: 100%
    font-family: "SF Pro Light", sans-serif
    font-size: 18px
    // color: #49494B
    position: relative
    .avatar
        width: 36px
        height: 36px
        border-radius: 50%
        object-fit: cover
    .header
        display: flex
        justify-content: space-between
        align-items: center
        border-bottom: 1px solid #DFDFDF
        border-color: inherit
        padding: 0 20px
        height: 68px
        .button
            color: #4086F1
            cursor: pointer
            display: flex
            align-items: center
            &.disable
                opacity: 0
                cursor: default
            .arrow
                transform: rotateZ(135deg)
                border-bottom: 1px solid grey
                border-right: 1px solid grey
                width: 9px
                height: 9px
                margin-right: 10px
            &:hover
                color: #3b6dcb
                .arrow
                    border-color: #3b6dcb

    .main
        flex-grow: 1
        .contactsList
            padding-bottom: 10px
            overflow-y: scroll
            scrollbar-width: auto
            scrollbar-color: #4086F1 #F1F1F1
            &::-webkit-scrollbar-track
                background: #F1F1F1
            &::-webkit-scrollbar-track
                -webkit-box-shadow: none
                background-color: #F1F1F1
            &::-webkit-scrollbar
                width: 12px
                background-color: #F1F1F1
            &::-webkit-scrollbar-thumb
                background-color: #4086F1
        .inputAreaWrap
            padding: 10px 20px
            border-bottom: 1px solid #DFDFDF
            .inputArea
                background: #F7F7F7
                height: max-content
                border-radius: 9px
                padding: 1px
                height: max-content
                .scrollContainer
                    max-height: 165px
                    margin: 5px
                    display: flex
                    align-items: flex-start
                    flex-wrap: wrap
                    overflow-x: hidden
                    overflow-y: scroll
                    scrollbar-width: thin
                    &::-webkit-scrollbar-track
                        background: #0A3AAB
                    &::-webkit-scrollbar-track
                        -webkit-box-shadow: none
                        background-color: #F7F7F7
                    &::-webkit-scrollbar
                        width: 3px
                        background-color: #FFFFFF
                    &::-webkit-scrollbar-thumb
                        background-color: #4086F1
                    .contact_wrap
                        display: flex
                        align-items: center
                        font-size: 13px
                        background: #ECF3FE
                        padding: 2px 10px
                        border-radius: 20px
                        margin: 3px 0 0 10px
                        .avatar
                            height: 26px
                            width: 26px
                            border-radius: 50%
                            margin-right: 4px
                            object-fit: cover
                        .name
                            text-overflow: ellipsis
                            white-space: nowrap
                            overflow: hidden
                    .input
                        margin: 3px 0
                        border: none
                        outline: none
                        background: none
                        font-family: inherit
                        font-size: 13px
                        color: inherit
                        height: 100%
                        width: 210px
                        background: #fff
                        border-radius: 20px
                        height: 30px
                        padding: 0 10px
                        align-self: flex-start
    .groupSettings
        display: flex
        align-items: center
        padding: 0 20px
        height: 66px
        border-bottom: 1px solid #DFDFDF
        .name
            border: none
            outline: none
            background: none
            font-family: inherit
            font-size: inherit
            color: inherit
            height: 100%
        .avatar
            width: 36px
            height: 36px
            margin-right: 10px
            object-fit: cover

.wrapper.darkTheme
    .header
        border-color: #4E4E4E
    .main
        .contactsList
            scrollbar-color: #272727 #323232
            &::-webkit-scrollbar-track
                background: #272727
            &::-webkit-scrollbar-track
                background-color: #272727
            &::-webkit-scrollbar
                background-color: #323232
            &::-webkit-scrollbar-thumb
                background-color: #323232
        .inputAreaWrap
            border-color: #4E4E4E
            .inputArea
                background: #292929
                .scrollContainer
                    scrollbar-color: #272727 #323232
                    &::-webkit-scrollbar-track
                        background: #272727
                    &::-webkit-scrollbar-track
                        background-color: #272727
                    &::-webkit-scrollbar
                        background-color: #323232
                    &::-webkit-scrollbar-thumb
                        background-color: #323232
                    .contact_wrap
                        background: #4E4E4E
                    .input
                        background: #292929
    .groupSettings
        border-color: #4E4E4E

@media (max-width: 1440px)
    .wrapper
        font-size: 16px
