@import "src/theme/cutsStr"
.card
  width: 128px
  height: 128px
  font: 12px/1.33 "Roboto", sans-serif
  padding: 8px

  &__head
    height: 78px
    background-color: var(--color-d50)
    border-radius: 4px
    overflow: hidden
    margin-bottom: 8px

    > img
      width: 112px
      object-fit: contain

  &__name
    color: var(--color-d700)
    @include cutsStr(2)
