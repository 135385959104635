.input
    width: 400px

.content
    border-radius: 4px
    background: #FFF
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.20), 0px 2px 5px 0px rgba(0, 0, 0, 0.12), 0px 2px 3px 0px rgba(0, 0, 0, 0.14)

.filter
    background: var(--DarkGreen-D40, #F1F3F2)
    display: flex
    flex-wrap: wrap
    gap: 4px
    padding: 8px 12px
    .button
        display: flex
        justify-content: center
        align-items: center
        padding: 4px 8px
        background: #CFD7D5
        border-radius: 4px
        font-weight: 500
        font-size: 12px
        line-height: 16px
        color: #274A42
        cursor: pointer
        gap: 6px
        border: 2px solid #1565c000
        transition: all 0.3s ease-in-out
        svg
            max-width: 16px
            max-height: 16px
        &.active
            border: 2px solid #1565C0

.results
    max-height: 300px
    height: 200px
    overflow: auto
    > div
        height: 36px
        > svg, img
            max-height: 28px
    .empty
        display: flex
        align-items: center
        justify-content: center
        height: 200px

.moreButton
    width: 100%
    height: 28px

.title
    color: var(--DarkGreen-D600, #56716B)
    font-size: 12px
    font-weight: 400
    line-height: 16px
    gap: 6px
    display: flex
    align-items: center
    padding: 8px 12px 0

.list
    padding: 8px 12px
    display: flex
    gap: 6px
