@import "src/theme/scrollbar"
.day
  flex: 1
  text-align: center
  height: 40px
  padding: 5px
  border: .5px solid var(--color-border-dark)
  overflow: hidden
  display: flex
  gap: 5px
  justify-content: center
  align-items: center
  background: var(--color-background-light)
  &:last-child
    border-radius: 0 6px 6px 0
  &:first-child
    border-radius: 6px 0 0 6px
.scroll
  overflow-x: auto
  width: 100%
  justify-content: flex-start
  @include scrollbar(0, 0, "#fff", "#fff")
.more
  width: 24px
  height: 24px
  line-height: 22px
  padding: 0
  text-align: center
  background: var(--color-white)
  border: 1px solid var(--color-border-dark)
  border-radius: 6px
  flex-shrink: 0
  &:hover
    border-color: var(--color-blue-smooth)
    color: var(--color-primary)
    & *
      fill: var(--color-blue-smooth)
  & *
    fill: var(--color-icon)
.day.today
  border-color: var(--color-blue-smooth)
  background: var(--color-blue-light)
  border-radius: 6px
