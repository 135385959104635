
.button
  flex: 1 1 82px
  width: 80px
  height: 24px
  border: 1px solid var(--color-border-dark)
  border-radius: 6px
  display: flex
  flex-direction: column
  align-items: center
  justify-content: center
  &:hover:not(:disabled)
    border-color: var(--color-blue-smooth)
    .default
      *
        fill: var(--color-blue-smooth)

.wrap
  width: 270px
  padding: 10px
  margin: 0
  position: relative
  display: flex
  flex-wrap: wrap
  gap: 3px
  padding-right: 30px

.closeBtn
  display: flex
  align-items: center
  justify-content: center
  position: absolute
  top: 12px
  right: 15px
  width: 22px
  height: 22px
  border-radius: 50%
  background: var(--color-blue-items)
  *
    width: 10px
    height: 10px
    fill: var(--color-icon)
  &:hover
    *
      fill: var(--color-blue-smooth)
.emoji
  cursor: pointer
  border: 2px solid #ffffff00
  width: 24px
  height: 26px
  display: flex
  align-items: center
  justify-content: center
  position: relative
  span
    position: relative
    z-index: 1
  .wrapper
    position: relative
    line-height: 1.5

  &.active .wrapper::before
    border-radius: 50%
    content: ''
    position: absolute
    width: 28px
    z-index: 0
    height: 28px
    left: 50%
    top: 50%
    background: #E1F5FE
    border: 2px solid var(--color-blue-smooth, #4086F1)
    transform: translate(-50%, -50%)

.selected
  background: var(--color-blue-light)
  border-color: var(--color-blue-smooth)
