.container
    padding: 16px
    width: 320px

.title
    color: var(--dark-green-d-700, #3E5D56)
    font-size: 12px
    font-weight: 400
    line-height: 10px
    margin: 12px 5px

.colors
    display: flex
    flex-wrap: wrap
    justify-content: space-between
    gap: 6px

.color
    border-radius: 50%
    cursor: pointer
    border: 3px solid #ffffff00
    span
        display: block
        border-radius: 50%
        width: 30px
        height: 30px
        border: 3px solid var(--color-white, #fff)

    &.active
        border: 3px solid var(--color-blue-smooth, #4086F1)

.buttons
    display: flex
    align-items: center
    justify-content: flex-end
    gap: 8px
    margin-top: 40px
