@import src/theme/global

.options
  flex: 0 0
  display: flex
  justify-content: flex-end
  gap: 2px

.next
  position: relative
  &:hover
    .folderList
      display: block
    .toFolder
      background: var(--color-d200)
.toFolder
  border-radius: 4px
  width: 32px
  height: 32px
  padding: 6px
  display: flex
  align-items: center
  justify-content: center
  flex-direction: column
  cursor: pointer

.folderList
  display: none
  position: absolute
  top: 100%
  left: 50%
  width: 250px
  background: var(--color-white)
  transform: translateX(-50%)
  @include boxShadow
.folderItem
  width: 100%
  padding: 6px 0
  position: relative
  &:hover button
    color: var(--color-b800)
  button
    width: 100%
    text-align: start
    color: var(--color-d800)
    @include textEllipsis
