.container
    width: 100%
    display: flex
    height: 100%

.scrollWrapper
    width: 100%
    overflow-x: scroll
    overflow-y: hidden
    height: calc( 100% - 100px)
    &.scrollWrapperMyTasksPage
        height: calc( 100% - 48px)

.scrollTitlesWrapper
    width: 100%
    overflow-x: auto
    overflow-y: hidden

.grid
    width: 100%
    height: 100%
    margin-top: 5px
    min-height: 300px

.content
    width: 100%
    height: 100%
    padding-bottom: 80px
    overflow-y: scroll
    overflow-x: hidden

.itemTitle
    display: flex
    align-items: center
    justify-content: space-between
    padding: 4px 16px 4px 4px
    background: var(--dark-green-d-40, #F1F3F2)
    color: var(--dark-green-d-700, #3E5D56)
    font-size: 12px
    line-height: 16px
    margin-bottom: 4px
    position: relative
    z-index: 1

.activeItemTitle
    background: var(--Blue-B500, #2196F3)
    color: var(--DarkGreen-D20, #F5F7F7)

.inlineItemTitle
    cursor: pointer
    transition: all 0.3s ease-in-out

.item
    padding: 4px

.addButton
    position: absolute
    bottom: -32px
    z-index: 1

.title
    display: flex
    gap: 4px

.color
    border-radius: 50%
    width: 15px
    height: 15px

.count
    border-radius: 4px
    background: var(--dark-green-d-100, #CFD7D5)
    padding: 0 5px
    color: var(--dark-green-d-800, #274A42)
    font-size: 12px
    font-weight: 500
    line-height: 16px
