@import "src/theme/global"

.item
  padding: 10px
  height: 49px
  border: 1px solid transparent
  border-radius: 6px
  display: flex
  gap: 5px
  align-items: center
  cursor: pointer
  margin-bottom: 4px
  &:hover
    background: var(--color-blue-light)
  &.active
    background: var(--color-blue-light)
    border-color: var(--color-blue-smooth)
  &.isMediumSize,
  &.isBigSize
    height: 60px
.itemName
  flex-grow: 1
  @include textEllipsis
.itemDescriptionGroup
  display: flex
  align-items: center
  gap: 5px
.popoverWrapper
  margin-left: 5px
.itemIconWrapper
  display: flex
  align-items: center
  justify-content: center
  flex-shrink: 0
