.wrap
  width: 440px
  .body
    padding: 12px 16px
    & > button
      width: 100%

  .btns
    display: flex
    justify-content: flex-end
    gap: 8px
    padding-top: 16px
