
.buttonsWrapper
  margin-bottom: 16px
  display: flex
  gap: 10px
.list
  height: 292px
  overflow-y: auto
.account
  border-radius: 4px
  padding: 4px 12px
  background: var(--color-d40)
  display: flex
  align-items: center
  justify-content: space-between
  font-weight: 500
  font-size: 14px
  line-height: 20px
  margin-bottom: 8px
  &:last-child
    margin-bottom: 0
  span
    flex: 1 1 auto
  .btns
    display: flex
    gap: 4px
.delimiter
  width: 1px
  height: 32px
  background: var(--color-d100)
