
.inputMailWrapper
  position: relative
  .box
    display: flex
    gap: 6px
    align-items: stretch
    border-bottom: 1px solid var(--color-d100)

  .inputMailPlaceholder
    font-size: 14px
    font-weight: 400
    color: var(--color-d500)
  .inputMail
    flex: 1 0 auto
    border: none
    font-size: 14px
    color: var(--color-d800)
  .optionsWrapper
    position: absolute
    z-index: 1
    width: 100%
    background: var(--color-white)
    border-radius: 4px
    box-shadow: 0px 3px 14px 0px #00000024
    max-height: 126px
    overflow-y: scroll
    .option
      cursor: pointer
      padding: 6px
      display: flex
      align-items: center
      gap: 8px
      line-height: 1.2

      .optionRight
        .name
          font-weight: 700
          font-size: 12px
          color: var(--color-d800)
        .email
          font-weight: 400
          font-size: 12px
          color: var(--color-d800)
