
.wrap
  height: 100%
  width: 910px
  padding: 0 10px 20px
  margin-top: 10px
  font-family: "SF Prop Text", sans-serif
  color: var(--color-text-grey)
  .weekBox
    margin-left: 50px

  .timeRow
    display: flex
    gap: 10px
    font-size: 13px
    align-items: center
    margin-bottom: 10px
  .days
    display: flex
    border-radius: 10px
    width: 100%
    padding: 0 1px
  .time, .infin
    width: 40px
    flex-shrink: 0
  .infin
    background: var(--color-white)
    path
      fill: var(--color-icon)

  .scroll
    overflow-y: auto
    height: calc(100% - 100px)
