@import src/theme/global
.btn
    min-width: 135px
    font: 400 12px/1.66 'Roboto', sans-serif
    border-radius: 4px
    padding: 11px
    border: none
    display: flex
    align-items: center
    justify-content: center
    gap: 4px
    & .text
        @include textEllipsis

.accordion
    font-weight: 400
    color: var(--color-d800)
    background: var(--color-border-dark)
.special
    font-weight: 500
    color: var(--color-d600)
    background: transparent
    border: 1px solid var(--color-d200)
    .btnText
        svg
            width: 24px
            height: 24px
.ok
    background: var(--color-blue-smooth)
    color: var(--color-white)
    &:hover
        background: var(--color-secondary)
.okRed
    background: var(--color-valencia)
    color: var(--color-white)
    &:hover
        background: var(--color-red)
.add
    background: var(--color-green)
    color: var(--color-white)
    &:hover
        background: var(--color-green-dark)
.addGrey
    background: var(--color-background-light)
    color: var(--color-text-grey)
    border: 1px solid var(--color-border-dark)
    padding: 6px 10px
    line-height: 16px
    &:hover
        background: var(--color-blue-light)
.addGrey.active
    border-color: var(--color-blue-smooth)
    background: var(--color-blue-light)

.cancel
    background: var(--color-border-dark)
    color: var(--color-grey_dark)
    &:hover
        background: var(--color-background-orange)
        color: var(--color-white)
.ok-radius
    border-radius: 20px
    padding: 8px 10px
    background: var(--color-blue-smooth)
    color: var(--color-white)
    &:hover
        background: var(--color-secondary)
.cancel-radius
    border-radius: 20px
    padding: 8px 10px
    background: var(--color-red-dark)
    color: var(--color-white)
    &:hover
        background: var(--color-red-extradark)
.full
    width: 100%
.btn[disabled]
    background: var(--color-border-dark)
    color: var(--color-grey_dark)
    cursor: not-allowed
    &:hover
        background: var(--color-border-dark)
        color: var(--color-grey_dark)

.iconBox
    display: flex
    align-items: center
    justify-content: center
    flex-shrink: 0
    svg
        width: 100%
        height: 100%

// variants
.opacity
    background: rgba(0, 0, 0, 0)
    color: var(--color-d800)
    & path
        fill: var(--color-d800)
    &[disabled], &[disabled]:hover
        background: rgba(0, 0, 0, 0)
        color: var(--color-d500)
        & path
            fill: var(--color-d500)
    &:hover
        background: var(--color-d200)
    &:active, &.active
        background: var(--color-d300)
.extraLight
    background: rgba(39, 74, 66, 0.16)
    color: var(--color-d800)
    & path
        fill: var(--color-d800)
    &[disabled], &[disabled]:hover
        background: rgba(39, 74, 66, 0.10)
        color: var(--color-d400)
        & path
            fill: var(--color-d400)
    &:hover
        background: var(--color-d300)
    &:active, &.active
        background: var(--color-d400)

.light
    background: var(--color-d50)
    color: var(--color-d800)
    & path
        fill: var(--color-d800)
    &[disabled], &[disabled]:hover
        background: var(--color-d50)
        color: var(--color-d400)
        & path
            fill: var(--color-d400)
    &:hover
        background: var(--color-d100)
    &:active, &.active
        background: var(--color-d300)
.dark
    background: var(--color-d800)
    color: var(--color-d50)
    & path
        fill: var(--color-d50)
    &[disabled], &[disabled]:hover
        background: var(--color-d400)
        color: var(--color-n200)
        & path
            fill: var(--color-n200)
    &:hover
        background: var(--color-d600)
    &:active, &.active
        background: var(--color-d900)
.blue
    background: var(--color-b800)
    color: var(--color-b50)
    & path
        fill: var(--color-b50)
    &[disabled], &[disabled]:hover
        background: var(--color-b500)
        color: var(--color-b200)
        & path
            fill: var(--color-b200)
    &:hover
        background: var(--color-b600)
    &:active, &.active
        background: var(--color-b900)

.red
    background: var(--color-red, #F00)
    color: var(--color-white)
    &:hover
        background: var(--color-valencia)
.redReverse
    color: var(--color-red, #f00)
    background: var(--color-white)
    border: 1px solid var(--color-red, #f00)
    &:hover
        background: #fdf4f4

.darkGreen
    background: var(--color-d900, #0E352C)
    color: var(--color-white)
    &:hover
        background: var(--color-d700)

.green
    background: var(--green-g-900, #2FB292)
    color: var(--color-white)
    &:hover
        background: var(--color-g800)

// sizes
.extraSmall
    min-width: auto
    font-size: 12px
    padding: 2px 10px
    height: 24px

    &.square
        padding: 2px
        width: 24px
        height: 24px
    .iconBox
        width: 12.8px
        height: 12.8px

.small
    min-width: auto
    font-size: 12px
    padding: 4px 10px
    height: 28px
    &.square
        padding: 4px
        height: 28px
        width: 28px
    .iconBox
        width: 12.8px
        height: 12.8px

.medium
    min-width: auto
    font-size: 14px
    padding: 6px 8px
    height: 32px
    &.square
        padding: 6px
        width: 32px
        height: 32px
    .iconBox
        width: 16px
        height: 16px
.large
    min-width: auto
    font-size: 14px
    padding: 10px 16px
    height: 40px
    &.square
        padding: 10px
        width: 40px
        height: 40px
    .iconBox
        width: 16px
        height: 16px
