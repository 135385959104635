
.editorWrap
    flex: 1 1 auto
    width: 100%
    font-size: 12px !important
    line-height: 14px
.editorWrap > div:last-child
    height: 70px !important
    max-height: 70px !important
    overflow-y: auto !important
    padding: 10px
    &  p
        margin: 0 0 6px !important
    & ul
        list-style-type: disc
        padding-left: 20px
.editorWrapAddFile
    & > div:nth-child(2)
        padding-left: 70px !important
