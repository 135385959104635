
.itemsWrap
  display: flex
  flex-direction: column
  row-gap: 20px
.itemWrap
  display: flex
  flex-direction: column
  row-gap: 13px
  padding-bottom: 20px
  border-bottom: 1px solid var(--color-border-dark)
  position: relative
  &:last-child
    border: none
.itemsWrapBar
  flex-direction: row
  padding-top: 5px
  row-gap: 0
  .itemWrap
    display: block
    border-bottom: none
    border-right: 1px solid var(--color-border-dark)
    text-align: left
    padding: 10px 10px 0
    &:last-child
      border: none
    span
      position: sticky
      left: 0
