
.tabsWrapper
  width: max-content
  border-radius: 4px
  display: flex
  align-items: center
  background: var(--color-white)
  button
    height: 100%
    border-right: 1px solid
    flex-grow: 1
    padding-right: 12px
    padding-left: 12px
    position: relative
    &:last-child
      border: none
  &.main
    border: 1px solid var(--color-d50)
    button
      color: var(--color-d800)
      border-color: var(--color-d50)
    & .active
      background: var(--color-d50)
  &.secondary
    border: 1px solid var(--color-d300)
    button
      color: var(--color-d800)
      border-color: var(--color-d300)
    & .active
      background: var(--color-d300)
  &.dark
    border: 1px solid var(--color-d500)
    button
      color: var(--color-d200)
      border-color: var(--color-d500)
    & .active
      background: var(--color-d500)
  &.darkGreen
    border: 1px solid #274A42
    button
      color: #274A42
      border-color: #274A42
    & .active
      color: #E7EBEA
      background: #274A42
  &.full
    width: 100%

  &.small
    height: 24px
    font-size: 12px
    line-height: 16px
  &.medium
    height: 28px
    font-size: 12px
    line-height: 16px
  &.large
    height: 32px
    font-size: 14px
    line-height: 18px

.tip
  display: flex
  padding: 2px 6px
  flex-direction: column
  justify-content: center
  align-items: center
  position: absolute
  top: 0
  transform: translate(-50%, -75%)
  left: 50%
  border-radius: 4px
  background: var(--main-red, #F00)
  color: var(--main-white, #FFF)
  font-family: 'Roboto', sans-serif
  font-size: 12px
  line-height: 16px
