@import "src/theme/theme"

.groupStart
  width: 100%
  display: flex
  align-items: center
  gap: 10px

  &:not(:last-of-type)
    margin-right: 24px

  &_right
    display: flex
    justify-content: flex-end
    padding-right: 20px
  input
    font-size: 12px
    padding-top: 4px
    padding-bottom: 4px
    height: 28px
