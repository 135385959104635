
.passWrap
    width: 380px
    padding: 16px
    position: relative
    .btns
        display: flex
        gap: 10px
        justify-content: flex-end
        align-items: center
        margin-top: 30px
