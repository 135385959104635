
.tabs
    display: flex
    align-items: center
    gap: 10px
    padding: 9px 10px
    border-bottom: 1px solid var(--color-border-dark)
    .tab
        padding: 5px 10px
        background: var(--color-background-light)
        border: 1px solid var(--color-border-dark)
        border-radius: 6px
        font-family: "SF Pro Regular", sans-serif
        font-size: 13px
        line-height: 18px
        color: var(--color-text-grey)-light
        min-width: 120px
        text-align: center
        cursor: pointer
    .active
        border: 1px solid var(--color-blue-smooth)
        background: var(--color-blue-light)
