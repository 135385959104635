@import src/theme/global
.container
  padding: 8px 12px
  display: flex
  align-items: center
  gap: 8px
  color: var(--color-d800)
  font-size: 14px
  & > button:last-child
    margin-left: 8px

  .amount
    display: inline-block
    padding: 2px 6px
    border-radius: 4px
    background: var(--color-b800)
    margin-right: 24px
    color: var(--color-white)
    font-size: 12px
    line-height: 20px

  .active path
    fill: var(--color-b800)
  .folderBlock
    position: relative
    &:hover .folderList
      display: block

  .folderList
    display: none
    position: absolute
    bottom: 100%
    left: 50%
    min-width: 150px
    max-width: 250px
    background: var(--color-white)
    transform: translateX(-50%)
    @include boxShadow
  .folderItem
    width: 100%
    padding: 6px 0
    position: relative
    &:hover button
      color: var(--color-b800)
    button
      width: 100%
      text-align: start
      color: var(--color-d800)
      @include textEllipsis
