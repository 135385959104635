
.wrap
  width: 360px
  .body
    padding: 12px 16px
  .text
    color: var(--color-d700)
    font-size: 12px
    line-height: 16px
    margin: 0 0 8px
  .btns
    display: flex
    justify-content: flex-end
    gap: 8px
    padding: 0 16px 12px
