.wrapp
    width: 100%
    display: flex
    flex-direction: column
    gap: 10px

.tools
    width: 100%
    display: flex
    justify-content: space-between
    align-items: center
    gap: 8px
    
.buttons
    display: flex
    align-items: center
    gap: 8px
