.menuWrap
  display: flex
  width: 20px
  height: 20px
  justify-content: center
  align-items: center
  cursor: pointer
  .menu
    position: relative
    width: 3px
    height: 3px
    background: #c1c0c0
    transition: background 0.2s ease
  .menu::before, .menu::after
    content: ''
    position: absolute
    width: 3px
    height: 3px
    background: #c1c0c0
    transition: background 0.2s ease
  .menu::before
    top: 8px
    right: 0
  .menu::after
    top: -8px
    right: 0
.menuWrap:hover .menu, .menuWrap:hover .menu::before, .menuWrap:hover .menu::after
  background: #477DE1
  transition: background 0.2s ease
.horizontal
  transform: rotate(90deg)
