.box
    max-width: 440px
    width: 100%
    height: 100%
    margin: 0 auto
    display: flex
    flex-direction: column
    justify-content: space-between
    gap: 12px

.panel
    display: flex
    flex-direction: column
    align-items: flex-end
    gap: 12px
    background-color: var(--color-white)
    border-radius: 4px
    padding: 12px 16px

    &__title
        font-size: 14px
        font-weight: 400
        line-height: 1.42
        color: var(--color-d700)

    &__box
        width: 100%
        background-color: var(--color-d50)
        border-radius: 8px
        padding: 8px 16px 24px
