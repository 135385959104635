
@keyframes showPopup1420
  0%
    width: 10px
    height: 10px
    background: white
    opacity: 0
  99%
    width: 430px
    height: 500px
    opacity: 0

@keyframes showPopup
  0%
    width: 10px
    height: 10px
    background: white
    opacity: 0
  99%
    width: 480px
    height: 720px
    opacity: 0

@keyframes showTags
  0%
    display: none
    height: 0
    opacity: 0
    bottom: 0
  100%
    display: block
    height: 140px
    opacity: 1
    bottom: -137px

.createFolderWrap
  display: flex
  flex-direction: column
  justify-content: flex-start
  align-items: center
  position: relative
  width: 420px
  padding: 30px 30px 15px
  height: max-content
  animation: showPopup 0.2s ease-in-out
  cursor: default
.title
  width: 100%
  max-width: 300px
  font-family: 'SF Pro Text', sans-serif
  font-weight: 500
  font-size: 18px
  line-height: 21px
  color: var(--color-text-dark)
  text-align: center
  margin: 0 auto 25px
.inputFieldsWrap
  width: 100%
  display: flex
  flex-direction: column
  row-gap: 15px
  margin-bottom: 30px
.buttonsWrap
  width: 100%
  max-width: 285px
  font-family: "SF Pro Regular", sans-serif
  display: flex
  flex-wrap: wrap
  justify-content: space-between
  gap: 8px
  margin: 0 auto
