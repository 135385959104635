
.screenPaintingPanel
  display: flex
  align-items: center
  column-gap: 6px

.popup
  position: fixed
  top: 0
  left: 0
  width: 100%
  height: 100%
  background: rgba(0,0,0,0.5)
  z-index: 10
  display: flex
  align-items: center
  justify-content: center
  .container
    border-radius: 10px
    overflow: hidden
  &.opacity
    opacity: 0
  .canvas
    display: block
    margin: 0 auto
