
.inputBlock
  display: flex
  justify-content: space-between
  align-items: center
  gap: 20px
  padding: 10px
  border: 1px solid var(--color-border-dark)
  border-radius: 6px
  margin-bottom: 10px
  & > input
    border: none
    width: 100%
    color: var(--color-text-grey)

  & > button
    padding-top: 6px
    padding-bottom: 6px
