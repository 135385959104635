@import src/theme/global
.tools
  border-bottom: 0.75px solid #ECECEC
  height: 60px
  .button
    display: block
    height: 100%
    width: 48px
    display: flex
    align-items: center
    justify-content: center
    border-top: 0.75px solid #ECECEC
    border-right: 0.75px solid #ECECEC
    background: #FCFCFE
    &:hover
      background: #eaeaeb
.taskInfo
  height: calc(100% - 60px)
  padding: 16px 16px 0
