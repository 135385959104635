
.header
  color: var(--DarkGreen-D700, #3E5D56)
  font-family: Roboto
  font-size: 14px
  font-style: normal
  font-weight: 500
  line-height: 18px
  padding: 16px 0

.notes
  display: flex
  flex-wrap: wrap
  gap: 12px
  margin-bottom: 10px
