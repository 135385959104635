
.button
  flex: 0 0 40px
  width: 30px
  height: 24px
  display: flex
  align-items: center
  justify-content: center
  column-gap: 4px
  background-color: var(--color-background-light)
  border: 1px solid var(--color-gallery)
  border-radius: 6px
  padding: 0
  transition: background-color 0.2s ease-in-out, border-color 0.2s ease-in-out
  position: relative
  cursor: pointer
  .icon path
    fill: var(--color-d200)
.button:hover, .open
  background-color: var(--color-b50)
  border-color: var(--color-b700)
  svg
    path,
    rect
      fill: var(--color-blue-smooth)
.container
  padding: 0 10px
  button
    display: block
  .list
    display: flex
    flex-wrap: wrap
    align-items: center
    margin: 10px 0
    row-gap: 5px

    button
      padding: 0
  .title
    width: 54px
    height: 24px
    flex-shrink: 0
    border: 1px solid var(--color-border-dark)
    background: var(--color-background-light)
    border-radius: 6px
    display: flex
    justify-content: center
    align-items: center
    margin-right: 10px
  .color
    width: 20px
    height: 20px
    border-radius: 50%
    border: 1px solid
    padding: 0
    display: flex

  .btnItem
    display: flex
    justify-content: center
    align-items: center
    width: 32px
    height: 32px
    border-radius: 50%
    border: 1px solid transparent
    &.activeItem
      background: var(--color-blue-light)
      border-color: var(--color-blue-smooth)
.defaultFig
  *
    fill: var(--color-icon)
.defaultEmo
  width: 15px
  height: 15px
  & circle:first-child
    fill: var(--color-icon)
  & *
    fill: var(--color-white)
.btns
  display: flex
  gap: 5px
  justify-content: center
.popoverBody
  &:global(.popover-body)
    padding: 4px 0
    border: 1px solid var(--color-gallery)
    border-radius: 10px
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05)
.popoverHeader
  &:global(.popover-header)
    font-weight: 600
    font-size: 13px
    line-height: 15px
    letter-spacing: -0.06px
    color: var(--color-white)
    background-color: var(--color-b800)
    border-bottom: 1px solid var(--color-gallery)
    padding: 10px 10px 8px
