.container
    height: 50px
    width: 100%
    border-radius: 6px
    display: flex
    padding: 4px 8px 4px 16px
    flex-direction: column
    justify-content: space-between
    cursor: pointer
    transition: all 0.3s ease-in-out
    &:hover
        background: var(--color-b100)
    &_new
        background: var(--color-b40, #EDF9FE)
        position: relative
        &::before
            content: ""
            left: 4px
            top: 4px
            position: absolute
            width: 8px
            height: 8px
            border-radius: 50%
            background: var(--color-error)

.header
    display: flex
    justify-content: space-between
    span
        color: var(--dark-green-d-600, #56716B)
        font-size: 14px
        font-weight: 400
.title
    width: 100%
    white-space: nowrap
    overflow: hidden
    text-overflow: ellipsis
    color: var(--dark-green-d-900, #0E352C)
    font-weight: 500
    font-size: 14px

.info
    color: var(--dark-green-d-800, #274A42)
    font-size: 14px
    width: 100%
    white-space: nowrap
    overflow: hidden
    text-overflow: ellipsis
