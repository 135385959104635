@import "src/theme/global"
.box
  border: 1px solid var(--color-border-dark)
  background: var(--color-background-light)
  border-radius: 6px
  padding: 10px
  width: 263px
  font-family: "SF Pro Text", sans-serif
  margin-bottom: 10px
  &:last-child
    margin: 0
  .top
    display: flex
    align-items: center
    gap: 5px
    justify-content: space-between
    margin-bottom: 10px
  .num
    width: 18px
    line-height: 18px
    border-radius: 50%
    text-align: center
    background: var(--color-blue-smooth)
    color: var(--color-white)
    font-size: 10px
  .date
    font-size: 10px
    flex: 1
  .color
    width: 14px
    height: 14px
    border-radius: 50%
    flex-shrink: 0
  .commentBox
    position: relative
    width: 16px
    height: 16px
    display: flex
    flex-shrink: 0
    svg
      width: 100%
      height: 100%
    &:before
      content: ""
      position: absolute
      top: 0
      right: 0
      width: 5px
      height: 5px
      border-radius: 50%
      background: var(--color-red-dark)
  .medium
    display: flex
    gap: 10px
  .tag, .dep
    min-width: max-content
    padding: 0 10px
    text-align: center
    font-size: 10px
    line-height: 18px
    border-radius: 9px
    @include textEllipsis
  .tag
    background: var(--color-blue-light)
    max-width: 110px
    border: 1px solid var(--color-blue-smooth)
  .dep
    background: var(--color-white)
    max-width: 150px
    border: 1px solid var(--color-border-dark)
  .name
    font-weight: 600
    font-size: 11px
    margin: 10px 0
  .text
    font-size: 11px
    line-height: 13px
    p
      margin: 0 0 5px
