
.phoneBlock
    display: flex
    gap: 8px
    align-items: flex-start
    &:not(:last-child)
        margin-bottom: 8px
    button
        margin-top: 6px

.input
    height: 32px
    padding: 6px 8px 6px 12px
    width: 244px
