
.infoBlock
    padding: 25px
    text-align: center
    background: var(--color-blue-light)
    border-radius: 10px
    .infoIcon
        position: absolute
        right: 10px
        top: 10px
        width: 20px
        height: 20px
        border: 1px solid
        border-radius: 50%
        padding: 3px 4px
    .infoIcon path
        stroke: var(--color-text-dark)
        fill: var(--color-text-dark)
    .title
        margin: 0
        color: var(--color-yellow)
        font-weight: 700
        font-size: 20px
    .text
        font-family: 'SF Pro Text', sans-serif
        font-size: 12px
        line-height: 21px
        color: var(--color-text-grey)
