
.box
  display: flex
  padding: 12px
  align-items: flex-start
  gap: 4px
  align-self: stretch
  border-radius: 4px
  &.red
    background: #F99

  &.green
    background: var(--color-g500)
    .text
      font-size: 12px
  &.yellow
    background: var(--color-y300)
  .icon
    flex-shrink: 0
    display: flex
  .title
    line-height: 20px
    font-size: 16px
    font-weight: 700
    line-height: 20px
  .text
    color: var(--color-d900)
    font-size: 14px
    line-height: 20px
    margin: 0
    padding-top: 2px
