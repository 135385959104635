
.fileBox
    width: 25px
    height: 28px
    position: relative
    flex-shrink: 0
    &:hover .deleteBtn
        display: block
    .deleteBtn
        display: none
        position: absolute
        top: -2px
        left: -2px
        cursor: pointer
    .amount
        backdrop-filter: blur(1px)
        position: absolute
        width: 100%
        height: 100%
        display: flex
        align-items: center
        justify-content: center
        top: 0
        color: var(--color-white)
        font-size: 13px
        font-weight: 700
