
.wrap
    position: relative
    cursor: pointer

    .placeholder
        color: var(--color-grey)
        line-height: 16px
    .values
        border: 1px solid var(--color-border-dark)
        border-radius: 5px
        padding: 4px 20px 4px 10px
        width: 100%
        display: flex
        align-items: center
        gap: 10px
        min-height: 39px
        overflow: scroll
        &::-webkit-scrollbar
            height: 0

        .item
            display: flex
            align-items: center
            gap: 10px
            padding: 3px 20px 3px 10px
            background: var(--color-blue-light)
            border-radius: 30px
            white-space: nowrap

    .list
        border: 1px solid var(--color-border-dark)
        padding: 10px 0
        position: absolute
        top: 100%
        width: 100%
        background: var(--color-white)
        z-index: 3
    .listShow
        border-bottom: none
        border-radius: 5px 5px 0 0

    .itemBlock
        display: flex
        align-items: center
        gap: 10px
        cursor: pointer
        padding: 6px 10px
        &:hover
            background: var(--color-blue-light)
        .text
            flex-grow: 1
    .check, .noCheck
        width: 19px
        height: 19px
    .noCheck
        border-radius: 50%
        border: 1px solid var(--color-border-dark)
    .arrowIcon
        position: absolute
        top: 17px
        right: 10px
        z-index: -1
    .arrowIcon path
        fill: var(--color-border-dark)
    .arrowActive path
        fill: var(--color-blue-smooth)
    .arrowActive
        transform: scaleY(-1)
