.item
    padding: 12px
    border-radius: 4px
    transition: all 0.3s ease-in-out
    display: flex
    justify-content: space-between
    cursor: pointer
    transition: all 0.2s linear
    position: relative
    &:hover
        background: var(--blue-b-100, #BBDEFB)
        transition: all 0.2s linear
        .buttons
            width: 160px
            transition: all 0.2s linear

    &.active
        background: var(--blue-b-200, #90CAF9)
        .buttons
            background: var(--blue-b-200, #90CAF9)
.info
    align-items: center
    display: flex

.buttons
    width: 0
    right: 0
    top: 50%
    transform: translateY(-50%)
    height: 100%
    overflow: hidden
    display: flex
    align-items: center
    justify-content: center
    transition: all 0.2s linear
    position: absolute
    background: var(--blue-b-100, #BBDEFB)
    transition: all 0.2s linear

.name
    flex-grow: 1
    margin: 0 8px

.favorite
    fill: #1565C0
