
.wrap
  width: 420px
  .inputSection
    display: block
    width: 100%
    padding: 10px
    font-size: 14px
    border: 1px solid var(--color-border-dark)
    border-radius: 6px
    margin: 20px 0
  .btns
    display: flex
    gap: 10px
    justify-content: center
