
.item
    display: flex
    border-radius: 4px
    background: var(--dark-green-d-40, #F1F3F2)
    color: var(--dark-green-d-800, #274A42)
    font-size: 14px
    font-weight: 500
    line-height: 20px
    align-items: center
    min-height: 40px
    padding: 0 12px
    transition: all 0.3s ease-in-out
    justify-content: space-between
    position: relative
    .buttons
        display: none
        gap: 10px
        margin-left: 24px
        span
            width: 16px
            height: 16px

    &:hover
        background: var(--blue-b-100, #BBDEFB)
        .buttons
            display: flex
            gap: 10px

    &.active
        background: var(--blue-b-100, #BBDEFB)
        .buttons
            display: flex
            span
                width: 24px
                height: 24px

.input
    width: 100%
