.arrowOutlined
  display: flex
  align-items: center
  width: 100px
  position: absolute
  top: 50%
  left: 50%
  cursor: move
  border-radius: 50%
  z-index: 3
  .dotRight, .dotLeft
    width: 7px
    height: 7px
    background: blue
    position: absolute
    border-radius: 50%
    border: 1px solid black
    cursor: pointer
  .arrow
    position: absolute
    right: -9px