
.wrap
  padding: 10px 10px 0
  &:last-child
    margin-bottom: 10px
  .title
    font-size: 10px
    color: var(--color-text-grey)
    font-family: "SF Pro Medium", sans-serif
    font-weight: 700
    margin: 0 0 5px
