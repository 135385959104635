.wrap
  flex: 1
.header
  display: flex
  align-items: center
  padding: 4px 16px
  background-color: var(--color-d40)
  color: var(--color-d800)
  font-size: 12px
  line-height: 16px
  .name
    flex: 0 0 360px
  .email
    flex: 0 0 270px
  .tel
    flex: 1 0 auto
