
.container
    .head
        padding: 10px 0 0 5px
        display: flex
        align-items: flex-start
        gap: 6px
        .role
            color: var(--color-blue-smooth)
            border: 1px solid var(--color-blue-smooth)
            border-radius: 10px
            background: var(--color-white)
            padding: 1px 8px 0
            font-size: 10px
            line-height: 14px
            width: max-content
            max-width: 80px
            white-space: nowrap
            text-overflow: ellipsis
            overflow: hidden
            margin: 0
        .icons
            display: flex
            gap: 6px
            flex-wrap: wrap
            align-items: center
            .clip
                font-size: 10px
                line-height: 12px
        & >div:last-child
            display: flex
            margin-left: auto
    .name
        margin: 0
        padding: 8px 0
        margin: 0 5px
        font-weight: 500
        font-size: 12px
        line-height: 14px
        color: var(--color-text-dark)
        font-family: "SF Pro SemiBold", sans-serif
        word-break: break-word
        .icon
            margin-right: 4px
    .block
        padding: 5px 0
        margin: 0 5px
        color: var(--color-text-dark)
        font-family: "SF Pro Text", sans-serif
        border-top: 1px solid var(--color-border-dark)
        .subTitle, .text
            margin: 0
            font-size: 10px
            line-height: 14px
        .subTitle
            color: var(--color-blue-smooth)
            white-space: nowrap
        .text
            overflow: hidden
            width: 100%
            max-height: 14px
    .user > div
        padding: 0
    .blockFlex
        display: flex
        align-items: center

    .statusBox
        display: flex
        justify-content: space-between
        gap: 6px
        align-items: center
        flex-grow: 1
        &>div:last-child
            width: 10px
            margin-right: 10px

    .circle
        width: 12px
        height: 12px
        border-radius: 50%
        margin-left: 5px
        flex-shrink: 0
    .status
        font-size: 12px
        flex-grow: 1
        line-height: 12px
        max-width: 85px
        text-overflow: ellipsis
        white-space: nowrap
        overflow: hidden

    .arrowBox
        padding: 5px 0
        cursor: pointer
    .icon
        flex-shrink: 0
    .cursor
        cursor: pointer
