
.fielsdWrap
    border-top: 1px solid var(--color-grey-secondary)
    padding: 17px 0 12px
    display: flex
    align-items: center
    align-self: flex-end
    .messageBox
        display: flex
        flex: 1
        border-right: 1px solid var(--color-grey-secondary)
        padding: 10px 18px
        margin-right: 20px
        .textarea
            outline: 0
            border: none
            resize: none
            width: 100%
            font-size: 11px
            line-height: 16px
            font-family: "SF Pro Text", sans-serif
            letter-spacing: 0.023em
            padding: 0
            margin-right: 10px
        .sendIcon
            cursor: pointer
        .sendIcon path
            fill: var(--color-grey)
        .active path
            fill: var(--color-primary)

    .btn
        width: 34px
        height: 34px
        border-radius: 50%
        border: 1px solid var(--color-primary)
        display: flex
        justify-content: center
        align-items: center
        margin: 0 7px
        &:hover
            background: var(--color-primary)

        &:hover .icon path
            fill: var(--color-white)
        &:hover .playIcon path
            fill: var(--color-white)
        & .icon path
            fill: var(--color-grey)
        .playIcon
            transform: scaleX(-1)
