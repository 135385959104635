.item
  padding: 8px 12px
  color: var(--DarkGreen-D800, #274A42)
  font-size: 12px
  line-height: 20px
  cursor: pointer
  &:hover
    background: var(--color-b50)
.border
  border-bottom: 1px solid var(--color-d100)
