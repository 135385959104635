.container
    width: 100%
    display: flex
    align-items: center
    cursor: pointer
    position: relative
    border-radius: 6px
    transition: all 0.2s ease-in-out
    padding: 2px
    .info
        margin-left: 8px
        display: flex
        flex-direction: column
        width: 100%
    &:hover
        background: var(--color-b40, #EDF9FE)
    &_normal
        max-width: initial
        .info
            display: flex
            flex-direction: row
            gap: 10px
            width: 100%
    &_big
        max-width: initial
        .info
            margin-left: 10px
            display: flex
            flex-direction: row
            gap: 40px
            width: 100%

.name
    color: var(--dark-green-d-800, #274A42)
    font-size: 12px
    font-weight: 500
    white-space: nowrap
    margin-left: 20px
    width: 120px
    min-width: 120px
    white-space: nowrap
    overflow: hidden
    text-overflow: ellipsis

.title
    color: var(--light-blue-b-700, #0288D1)
    font-size: 12px
    font-weight: 500
    white-space: nowrap
    overflow: hidden
    text-overflow: ellipsis

.file
    color: var(--color-d800, #274A42)
    font-size: 12px
    font-weight: 400
    white-space: nowrap
    overflow: hidden
    text-overflow: ellipsis
    display: flex
    gap: 5px
    span
        white-space: nowrap
        overflow: hidden
        text-overflow: ellipsis
