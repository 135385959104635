
.rightSide
  display: flex
  align-items: center
  column-gap: 6px
  div
    &:first-child
      button
        margin-left: 0
  .icon
    path
      stroke: var(--color-white)
  .trashIcon
    path
      fill: var(--color-red-dark)
.popoverWrapper
  margin-left: 4px
