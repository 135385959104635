
@import src/theme/global
.atachment
  flex: 0 0 auto
  cursor: pointer !important
  align-items: center !important
  display: flex !important
  gap: 4px !important
  font-weight: 500 !important
  color: var(--color-d800) !important
  border-radius: 14px !important
  background: var(--color-d100) !important
  font-size: 14px !important
  padding: 6px 8px !important
  line-height: 1 !important
  max-width: 160px
  .fileName
    flex: 1 1
    max-width: 157px
    @include textEllipsis
.btn
  padding: 8px 12px
  display: flex
  gap: 4px
  align-items: center
  width: 100%
  text-align: start
  &:hover .name
    color: var(--color-b800)

  .icon
    width: 24px
    height: 24px
    display: flex
    align-items: center
    justify-content: center
    flex: 0 0 24px

  .name
    color: var(--color-d800)
    font-family: Roboto, "sans-serif"
    font-size: 14px
    line-height: 22px
    flex: 1
