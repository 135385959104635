.wrapper
  border: 1px solid var(--color-b600)
  border-radius: 4px
  display: flex
  flex-direction: column-reverse
  position: relative

.editor
  height: 200px
  overflow: auto
  & > div
    font-size: 14px
.toolbar
  border: none
  border-top: 1px solid var(--color-d100)
  background: #fff
  margin-top: 6px
  gap: 8px
  align-items: center
  & > div
    margin: 0
  & > button
    order: -1
    width: 24px
    height: 20px
    border-radius: 4px
    border: 1px solid #F1F1F1
    &:hover
      background: var(--color-d100)
    .clip
      display: flex
      align-items: center
      justify-content: center
      transform: rotate(45deg)
      svg
        height: 18px
  .sendBtns
    margin-left: auto
    display: flex
    gap: 8px
.item
  padding: 8px 12px
  color: var(--color-d800)
  font-size: 12px
  font-style: normal
  font-weight: 400
  line-height: 20px
  cursor: pointer
  &:hover
    color: var(--color-b800)
.hidden
  display: none
