
.wrap
    width: 410px
    padding-top: 10px
    text-align: left
    .subTitle
        display: inline-block
        font-family: "SF Pro Regular", sans-serif
        font-size: 13px
        line-height: 16px
        color: var(--color-text-grey)
        margin-bottom: 8px
    .input
        background: var(--color-background-light)
        border: 1px solid var(--color-border-dark)
        border-radius: 6px
        padding: 9px 20px
        width: 100%
        font-family: "SF Pro Regular", sans-serif
        font-size: 13px
        line-height: 16px
        color: var(--color-text-grey)
        margin-bottom: 20px
    .chosenRoles
        display: flex
        align-items: center
        flex-wrap: wrap
        gap: 10px
        margin-bottom: 16px
        min-height: 39px

    .rolesList
        height: 230px
        display: flex
        flex-direction: column
        gap: 8px
        overflow-y: auto
        padding-right: 6px
        margin-bottom: 30px

    .btns
        display: flex
        justify-content: center
        flex-wrap: wrap
        gap: 6px
