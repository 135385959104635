
.wrap
  display: flex
  gap: 10px
  justify-content: space-between
  align-items: center
  padding: 10px
  border-bottom: 1px solid var(--color-border-dark)
  font-family: "SF Pro Text", sans-serif
  .selectBtn
    border: 1px solid var(--color-border-dark)
    border-radius: 6px
    background: var(--color-background-light)
    cursor: pointer
    font-size: 12px
    line-height: 14px
    padding: 4px 10px
  .active, .selectBtn:hover
    border: 1px solid var(--color-blue-smooth)
    background: var(--color-blue-light)
  .rightGroup
    display: flex
    gap: 10px
    align-items: center

  .icons
    display: flex
    align-items: center
    gap: 6px
    border-left: 1px solid var(--color-border-dark)
    padding: 2px 0 2px 10px
    .icon path
      fill: var(--color-red-dark)
