@import "src/theme/cutsStr"

.item
  display: grid
  grid-template-columns: minmax(90px, 0.27fr) minmax(200px, 0.43fr) minmax(150px, 0.188fr) minmax(200px, 0.43fr) 32px
  justify-content: space-between
  align-items: center
  column-gap: 8px
  position: relative
  transition: 500ms background-color
  font-size: 12px
  line-height: 1.33
  padding: 4px 16px
  color: var(--color-d800)

  &:hover,
  &:active
    background-color: var( --color-sail)
  
  &__info
    display: flex
    align-items: center
    gap: 4px

  &__name
    @include cutsStr(1)
  
  &__date
    color: var(--color-d600)
  
  &__btnIcon
    transition: transform 500ms

    &.active
      transform: rotate(180deg)

