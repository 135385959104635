
.container
    height: 100%
    display: flex
    width: 100%
    max-width: 450px
    margin: 0 auto
    flex-direction: column
    justify-content: center
    h4
        font-family: "SF Pro Text", sans-serif
        font-style: normal
        font-weight: 590
        font-size: 36px
        line-height: 43px
        color: var(--color-white)
        margin-bottom: 0
    p
        font-family: "SF Pro Text", sans-serif
        font-style: normal
        font-weight: 400
        font-size: 16px
        line-height: 19px
        color: var(--color-white)
        margin: 15px 0

.buttons_wrapper
    display: flex
    flex-direction: column
    items: center
    gap: 15px
    width: 100%
    position: relative
    button
        width: 100%
        &:hover
            svg
                path
                    fill: var(--color-c400)
                    transition: fill 0.3s ease-in-out

.icon
    position: absolute
    left: 25px
    top: 50%
    transform: translateY(-50%)

.error
    position: absolute
    left: 0
    bottom: -25px
    font-family: "Roboto", sans-serif
    font-style: normal
    font-weight: 510
    font-size: 14px
    line-height: 100%
    color: var(--color-red)
