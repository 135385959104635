.container
  width: 100%
  min-height: 100vh
  height: 100%
  display: flex
  flex-direction: column
  justify-content: space-between

.wrapper
  display: flex
  width: 100%
  height: calc(100% - 98px)

.leftBar
  display: flex
  height: 100%
  padding: 12px 12px 0px 12px
  flex-direction: column
  align-items: flex-start
  background: #FFF
  box-shadow: 2px 0px 4px 0px rgba(0, 0, 0, 0.20), 1px 0px 10px 0px rgba(0, 0, 0, 0.12), 4px 0px 5px 0px rgba(0, 0, 0, 0.14)
  & > ul:first-child
    width: 320px
    overflow-y: auto
    ul
      padding-left: 10px
