.previewIcon
  &__box
    display: flex
    justify-content: center
    align-items: center
    width: 56px
    height: 56px
    background-color: var( --color-b200)
    border-radius: 4px
    overflow: hidden
