@import "src/theme/theme"
.girdBoardWrap
  width: 100%
  height: calc(100% - 145px - 50px )
  padding: 10px

//SCHEMAS
.grid-bar
  display: grid
  grid-template-columns: repeat(6, 1fr)
  grid-template-columns: repeat(6, minmax(150px, 1fr))
  grid-template-rows: repeat(5, 1fr)
  gap: 10px
  .medium-item
    grid-area: 1 / 1 / 4 / 3
  .small-item-left
    grid-area: 4 / 1 / 6 / 2
  .small-item-right
    grid-area: 4 / 2 / 6 / 3
  .big-item
    grid-area: 1 / 3 / 6 / 7

.hidden
  display: none

.expanded-meetings-board
  display: flex
  align-items: flex-start
  justify-content: center
  .expanded-board
    height: 100%
    width: 100%

.expanded-calls-board
  display: flex
  align-items: flex-start
  justify-content: center
  .expanded-board
    height: 100%
    width: 100%

.expanded-mail-board
  display: flex
  align-items: flex-start
  justify-content: center
  .expanded-board
    height: 100%
    width: 100%

.expanded-tasks-board
  display: flex
  align-items: flex-start
  justify-content: center
  .expanded-board
    height: 100%
    width: 100%
