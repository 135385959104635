@import src/theme/global
.wrapper
  display: flex
  justify-content: space-between
  align-items: center
  gap: 10px
  padding: 14px 12px
  cursor: pointer
  border-bottom: 1px solid var(--color-border-grey)
  background: var(--color-white)
  border-radius: 4px

.name
  font-weight: 700
  font-size: 14px
  flex: 1 1 auto
  @include textEllipsis

.buttonsWrap
  display: flex
  align-items: center
  margin-right: 6px

.mailMenu
  display: block
  position: relative
  min-height: 346px
  padding: 4px 0
  border-bottom: 1px solid var(--color-border-grey)
  &.linksMailMenuHide
    display: none

.mailMenuOpen  .buttonsWrap
  transform: scaleY(-1)
.link
  display: flex
  align-items: center
  padding: 8px 12px
  cursor: pointer
  justify-content: space-between
  column-gap: 10px
  border-radius: 4px
  margin-bottom: 2px
  background-color: transparency
  transition: background-color 0.3s

  &:hover
    background-color: var(--color-b100)
    transition: background-color 0.3s
    .contextBox button
      opacity: 1
      transition: opacity 0.3s

  &.active
    background-color: var(--color-b400)
    .iconBox path
      fill: var(--color-d50)
    .folderName
      color: var(--color-d50)
    .amount
      color: var(--color-d800)
      &_inbox
        background: var(--color-d50)

.iconBox
  flex-shrink: 0
  width: 24px
  height: 24px
  display: flex
  align-items: center
  justify-content: center
  & path
    fill: var(--color-d500)
.folderName
  flex-grow: 1
  color: var(--color-d800)
  font-size: 14px
  line-height: 20px
  @include textEllipsis
.contextBox button
  opacity: 0
  transition: opacity 0.3s
.addBtn
  width: 100%
  display: flex
  align-items: center
  padding: 8px 12px
  gap: 10px
  .folderName
    text-align: start
  &:hover
    .folderName
      color: var(--color-b400)
    *
      fill: var(--color-b400)
.amount
  display: inline-flex
  padding: 0px 6px
  flex-direction: column
  justify-content: center
  align-items: center
  border-radius: 4px
  color: var(--color-white)
  &_inbox
    background: var(--color-d700)
  &_unread
    background: var(--color-red)
