
.workAreaWrap
  height: 100%
  width: 100%
  display: flex
  flex-grow: 1
  background-color: var(--color-white)
  overflow: hidden
  position: relative
  &.column
    flex-direction: column
  &.row
    flex-direction: row
