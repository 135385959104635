.wrapper
  display: flex
  align-items: center
  gap: 4px

.tag
  border-radius: 4px
  background: #CFD7D5
  display: inline
  padding: 2px 6px
  color: var(--DarkGreen-D800, #274A42)
  font-size: 12px
  font-style: normal
  font-weight: 500
  line-height: 16px
  white-space: nowrap
  overflow: hidden
  text-overflow: ellipsis
  max-width: 70px

.tooltipTag
  background: var(--DarkGreen-D900, #0E352C)
  color: var(--DarkGreen-D50, #E7EBEA)
  max-width: 168px

.tooltip
  padding: 6px !important
  display: flex
  flex-wrap: wrap
  gap: 4px
