
.customSend
  font-family: 'Roboto', sans-serif !important
  background: var(--color-b800) !important
  color: var(--color-b50) !important
  font-size: 14px !important
  font-weight: 400 !important
  width: unset !important
  cursor: pointer !important
.customEmoji
  width: 32px !important
.customClip
  background-color: #274A4229 !important
  width: 32px !important
.customSchedule
  margin-right: 8px !important
  background-color: #274A4229 !important
  width: 32px !important
  position: relative
.isChedule
  position: relative !important
  overflow: visible !important
  &::before
    content: ""
    width: 10px
    height: 10px
    border-radius: 50%
    background: var(--color-red)
    position: absolute
    top: -3px
    left: -3px
.scheduleMessageModal
  width: 400px
  padding: 12px 20px 20px
  box-shadow: 0px 8px 10px 0px rgba(0, 0, 0, 0.20), 0px 6px 30px 0px rgba(0, 0, 0, 0.12), 0px 16px 24px 0px rgba(0, 0, 0, 0.14)
  border-radius: 8px
  position: absolute
  bottom: 44px
  right: 90px
  z-index: 2
  background: var(--color-white)
  .scheduleMessageHeader
    display: flex
    justify-content: space-between
    align-items: center
    gap: 12px
    margin-bottom: 6px
    .scheduleMessageTitle
      color: var(--color-d900)
      font-size: 24px
      font-style: normal
      font-weight: 500
      line-height: 28px
    .crossBtn
      padding: 6px
      display: flex
      &:hover *
        fill: var(--color-b800)
  .scheduleMessageContent
    width: 168px
    margin-bottom: 18px
  .scheduleActions
    display: flex
    gap: 8px
    justify-content: flex-end

.emojiBox
  position: absolute
  bottom: 44px
  right: 190px
  z-index: 2
