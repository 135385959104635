
.block
  padding: 10px
  & > div
    max-width: max-content
.block:first-child
  border-bottom: 1px solid var(--color-border-dark)
.role
  padding: 4px 10px
  background: var(--color-background-light)
  border: 1px solid var(--color-border-dark)
  border-radius: 6px
  font-family: "SF Pro Regular", sans-serif
  font-size: 13px
  line-height: 16px
  color: var(--color-text-grey-light)
