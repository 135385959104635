$base-item-padding: 14px

@mixin clickAreaEnlargement($width, $height)
  &::before
    content: ""
    position: absolute
    top: 50%
    transform: translateY(-50%)
    width: $width
    height: $height
    background-color: transparent

@mixin textEllipsis
  white-space: nowrap
  text-overflow: ellipsis
  overflow: hidden

@mixin boxShadow
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.20), 0px 1px 10px 0px rgba(0, 0, 0, 0.12), 0px 4px 5px 0px rgba(0, 0, 0, 0.14)
  border-radius: 8px

@mixin borderBottom
  box-shadow: 0px -1px 0px 0px var(--color-d100) inset

.flex-center
  display: flex
  justify-content: center
  align-items: center

a,
button
  transition-duration: 300ms
.rdw-suggestion-dropdown
  width: 200px
  @include boxShadow
  .rdw-suggestion-option-active
    background-color: var(--color-b50)
