.item
  font-size: 12px
  line-height: 1.33
  display: flex
  justify-content: space-between
  align-items: center

  &_noBtn
    padding: 8px 0

  &__btn
    width: 100%
    display: flex
    justify-content: space-between
    align-items: center
    border-radius: 4px
    padding: 10px 12px

    &:hover,
    &:active
      background-color: var(--color-lb50)

    &.active
      background-color: var(--color-lb200)
  
  &__info
    display: flex
    align-items: center

  &__color
    width: 15px
    height: 15px
    border-radius: 16px
    margin-right: 3px

  &__name
    color: var(--color-d600)
  
  &__size
    color: var(--color-d800)
    font-weight: 700
  


