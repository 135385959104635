$border: 1px solid #DFDFDF

.mutualEdit
  display: flex
  border-radius: 6px
  width: calc(100vw - 80px)
  height: calc(100vh - 80px)
  flex-direction: column
  .header
    display: flex
    height: 60px
    width: 100%
    border-bottom: $border
  .mainField
    display: flex
    height: calc(100% - 60px)
    width: 100%

  .rightPanelWrap
    display: flex
    flex-direction: column
    width: 100px
    .asideWrap
      height: calc(100% - 86px)
      border-bottom: $border
    .buttonsWrap
      display: flex
      justify-content: center
      align-items: center
      flex-direction: column
      height: 86px
      width: 100%
      border-left: $border
      .sendSavedButton
        display: flex
        justify-content: center
        align-items: center
        width: 80px
        height: 26px
        background: #FFFFFF
        box-shadow: 0 2px 4px #DEDEDE
        border-radius: 2px
        font-family: "SF Pro Regular", sans-serif
        font-size: 12px
        color: #3F3F3F
        cursor: pointer
        &:hover
          background: #4086F1
          color: #FFFFFF
        &:first-child
          margin: 0 0 5px 0
      .choosing
        background: #4086F1
        color: #FFFFFF
      .notActive
        cursor: default
        &:hover
          color: #3F3F3F
          background: #FFFFFF
