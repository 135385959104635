
.nopadding
    padding: 0 !important
.text
    font-family: 'Roboto', sans-serif
    font-weight: 400
    font-size: 12px
    line-height: 14px
    color: var(--color-scorpion)
.icon
    path
        transition: fill 0.2s ease-in-out
.list
    padding: 0 10px
    margin: 0
    border-bottom: 1px solid var(--color-border-dark)
    &:last-child
        border: none
.item
    padding: 10px 0
    display: flex
    gap: 10px
    align-items: center
    cursor: pointer
    font-family: 'Roboto', sans-serif
    font-weight: 400
    font-size: 14px
    line-height: 22px
    color: var(--color-d800)
    &:hover
        color: var(--color-blue-smooth)
