.wrap
  width: 560px
  .body
    padding: 12px 16px 0
    .users
      margin-top: 12px
      min-height: 200px
      max-height: 324px
      overflow-y: auto
      overflow-x: hidden
      position: relative
  .btns
    display: flex
    justify-content: flex-end
    gap: 10px
    padding: 24px 16px 12px
