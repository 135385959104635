@import "src/theme/global"
.link
  display: flex
  align-items: center
  gap: 6px
  padding: 7px 2px 7px 12px
  border-radius: 4px
  height: 46px
  color: var(--color-d800)
  cursor: pointer
  &.active
    background: var(--color-b50)
  &:hover
    background: var(--color-b50)
    .context button
      opacity: 1
      transition: opacity 0.3s
  &.collapsed .name, &.collapsed .context button
    opacity: 0
    transition: opacity 0.3s

  .name
    flex-grow: 1
    font-size: 13.5px
    font-weight: 400
    line-height: normal
    letter-spacing: -0.062px
    transition: opacity 0.2s ease-in-out,
    @include textEllipsis
  .context button
    opacity: 0
    transition: opacity 0.3s
.safeIconBlock
  position: relative
  .icons
    position: absolute
    top: -3px
    left: 14px
    display: flex
    gap: 2px
    width: max-content
  .iconBox
    width: 14px
    height: 14px
    border-radius: 4px
    display: flex
    flex-direction: column
    align-items: center
    justify-content: center
    &:hover .time
      display: flex

  .green
    background: var(--color-g500)
  .yellow
    background: var(--color-y600)
  .time
    position: absolute
    top: 110%
    left: 0
    display: none
    gap: 5px
    background: var(--color-white)
    border-radius: 4px
    padding: 4px 8px
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.20), 0px 1px 10px 0px rgba(0, 0, 0, 0.12), 0px 4px 5px 0px rgba(0, 0, 0, 0.14)
    & p
      color: var(--color-n800)
      font-size: 14px
      line-height: 20px
      font-weight: 400
      margin: 0
