.teams
  padding: 8px
  border-bottom: 1px solid var(--color-d100)
.participants
  max-height: 238px
  overflow-y: auto
.arrow
  transform: rotate(-90deg)
  flex-shrink: 0
.arrowOpen
  transform: rotate(90deg)
  flex-shrink: 0

.item
  padding: 8px 12px
  color: var(--color-d800)
  font-size: 12px
  font-style: normal
  font-weight: 400
  line-height: 20px
  cursor: pointer
  display: flex
  gap: 4px
  align-items: center
  &:hover
    color: var(--color-b800)

.label
  padding-left: 0 !important
  &:hover, &.active
    background: var(--color-b100)
