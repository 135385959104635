.react-datepicker-popper
  z-index: 10

.DatePickerWrapper
  font-family: inherit
  width: max-content
  .react-datepicker__close-icon:after
    background-color: var(--color-b800)
.DatePickerCalendar
  .react-datepicker
    color: var(--color-d800)
  .react-datepicker__navigation-icon--next::before
    top: 50%
    transform: translateY(-50%)rotate(45deg)
  .react-datepicker__navigation-icon--previous::before
    top: 50%
    transform: translateY(-33%) rotate(-135deg)
  .react-datepicker__header
    background: var(--color-white)
  .react-datepicker__time-list
    height: 272px !important
  .react-datepicker__header--time
    display: none !important
  .react-datepicker__input-time-container
    width: max-content
    position: absolute
    bottom: 37px
    right: 0px
    margin: 0
  .react-datepicker-time__input
    margin: 0 !important
  .react-datepicker-time__input[type="time"]
    width: 84px !important
    margin: 0 !important
    border: none
    box-shadow: 0px -2px 5px 0px rgba(0, 0, 0, 0.14)
    padding: 6px
    font-size: 14px
    &::-webkit-inner-spin-button, &::-webkit-calendar-picker-indicator
      display: none
      -webkit-appearance: none
  .react-datepicker__triangle::after
    border-bottom-color: var(--color-white) !important

  .react-datepicker__day, .react-datepicker__month-text
    color: var(--color-d800)
    &--today
      border: 1px solid var(--color-b500)
      border-radius: 4px

    &--selected
      background: var(--color-b800)
      color: var(--color-b100)

    &--disabled
      color: var(--color-d200)
    
    &:hover:not(.react-datepicker__day--disabled, .react-datepicker__month-text--disabled)
      background: var(--color-d200)

  .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected
    background: var(--color-b800)
  .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item:hover
    background: var(--color-d200)
  .react-datepicker__children-container
    width: 100%
    height: 100%
    margin: 0
    padding: 0
  
  .react-datepicker__day
    &--excluded,
    &--in-range
      border-radius: 4px
      background-color: var(--color-y300) !important
      color: var(--color-d600) !important

    &--range-start,
    &--range-end
      background-color: var(--color-y600) !important
      color: var(--color-d700) !important
