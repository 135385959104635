
.weekDays
  display: flex
  border: 1px solid var(--color-blue-smooth)
  font-size: 14px
  line-height: 19px
  border-radius: 10px
  width: 100%
  margin-bottom: 10px
  overflow: hidden
  background: var(--color-white)

.weekDay
  flex: 1
  text-align: center
  padding: 10px
  border-right: .5px solid var(--color-border-dark)
  border-left: .5px solid var(--color-border-dark)
  &:last-child
    border-right: none
  &:first-child
    border-left: none
.currentWeekDay
  border-color: var(--color-blue-smooth)
  background: var(--color-blue-light)
.select
  cursor: pointer
  &:hover
    color: var(--color-blue-smooth)
