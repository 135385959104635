
@keyframes show
    0%
        top: -20px
        opacity: 0.3
    80%
        top: 40px
    100%
        top: 20px
        opacity: 1

.message,
.error,
.success
    position: absolute
    width: max-content
    min-width: 320px
    height: 40px
    left: 50%
    top: 20px
    transform: translateX(-50%)
    font-family: 'SF Pro Light', sans-serif
    line-height: 40px
    letter-spacing: -0.0894342px
    text-align: center
    z-index: 9999999
    border-radius: 20px
    font-size: 14px
    padding: 0 10px
    animation: show .2s ease-in-out

.message
    color: var(--color-text-dark)
    background: var(--color-blue-items)

.error
    background: var(--color-background-orange)
    color: var(--color-white)

.success
    background: var(--color-green-extralight)
    color: var(--color-text-dark)

    &.row
        display: flex
        align-items: center
        justify-content: space-between
        gap: 19px
