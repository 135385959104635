.sliderNav
  width: max-content
  display: flex
  gap: 8px
  border-radius: 4px
  background-color: var(--color-white)
  padding: 10px 12px
  position: absolute
  left: 50%
  transform: translate(-50%)
  bottom: 33px
  z-index: 10

  &__btn
    width: 52px
