
.item
    width: 100%
    a
        display: flex
        padding: 6px 12px 6px 5px
        color: var(--dark-green-d-800, #274A42)
        width: 100%
        transition: all 0.3s ease-in-out
        align-items: center
        border-radius: 4px
        cursor: pointer
        svg
            fill: var(--color-d400, #869A95)
            height: 20px
            width: 20px
            transform: rotate(-90deg)
            transition: all 0.3s ease-in-out
            &.active
                transform: rotate(0)
        &:hover
            background: var(--light-blue-b-100, #B3E5FC)

    a.active
        background: var(--light-blue-b-200, #81D4FA)

    &.expanded
        background: #f0fbff
        border-radius: 4px
