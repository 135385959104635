.block
  padding: 8px
  background-color: var(--color-d50)
  &:not(:last-child)
    margin-bottom: 8px
  &:hover
    background-color: var(--color-b50)
    .btn
      visibility: visible

.info
  display: flex
  gap: 8px
  align-items: center

  .info
    flex-grow: 1
  .btn
    visibility: hidden
  .name
    color: var(--color-d800, #274A42)
    font-size: 16px
    font-weight: 700
    line-height: 20px
  .email
    color: var(--color-d700, #274A42)
    font-size: 12px
    line-height: 16px
  .inputBox
    width: 132px
.alert
  padding: 6px
  background-color: #99E2D0
  color: var(--color-d800, #274A42)
  font-size: 12px
  line-height: 16px
  border-radius: 4px
  margin-top: 8px
