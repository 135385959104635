
.root
  display: flex
  flex-direction: column
  row-gap: 20px
.infoWrapper
  display: flex
  flex-direction: column
  row-gap: 8px
  padding-top: 10px
  border-top: 1px solid var(--color-grey-secondary)
  overflow-y: auto
  &.paddingBottom
    padding-bottom: 30px
    border-bottom: 1px solid var(--color-grey-secondary)
.title
  font-family: 'SF Pro Text', sans-serif
  font-weight: 400
  font-size: 12px
  line-height: 14px
  color: var(--color-silver-chalice)
.card
  min-height: 45px
  display: flex
  align-items: center
  justify-content: space-between
  column-gap: 15px
  background: var(--color-white)
  border: 1px solid var(--color-border-dark)
  border-radius: 6px
  padding: 8px 15px
.iconWrapper
  display: flex
  align-items: center
  column-gap: 8px
.name
  font-family: 'SF Pro Text', sans-serif
  font-weight: 400
  font-size: 13px
  line-height: 16px
  color: var(--color-text-dark)
  word-break: break-word
  margin: 0
.optionsList
  display: flex
  align-items: center
  column-gap: 5px
.minitagWrap
  margin-right: 5px
.buttonWrapper
  margin-top: 12px
.restoreButton
  height: 30px
  display: flex
  align-items: center
  justify-content: center
  font-family: 'SF Pro Text', sans-serif
  font-weight: 400
  font-size: 12px
  line-height: 14px
  color: var(--color-text-dark)
  background: var(--color-background-light)
  border: 1px solid var(--color-gallery)
  border-radius: 6px
  padding: 0 10px
  margin: 0 auto
  &:hover
    background: var(--color-blue-items)
