.wrap
  width: 540px
  overflow: scroll
  .body
    padding: 12px 16px
    width: max-content
    max-width: 100%
    margin: 0 auto
    position: relative
    background: var(--color-white)
  .footer
    display: flex
    justify-content: flex-end
    gap: 8px
    padding: 12px 16px
