.listGroup
  &.isLinePreview
    width: 67.5%
    margin: 0 auto
    display: grid
    grid-template-columns: 1fr
    gap: 12px
  
.list
  display: grid
  grid-template-columns: repeat(auto-fit, 128px)
  gap: 8px

  &.isLinePreview
    grid-template-columns: 1fr
