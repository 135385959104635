
.wrapper
    display: flex
    justify-content: flex-start
    font-family: 'SF Pro Light'
    margin: 10px 15px
    .avatar
        width: 36px
        height: 36px
        border-radius: 50%
        margin-right: 15px
        object-fit: cover
        object-position: center
    .contentWrapper
        display: flex
        flex-direction: column
        max-width: 90%
        position: relative
        .content
            background: #F9F9F9
            box-shadow: 0 2px 4px rgba(197, 197, 197, 0.5)
            border-radius: 0 10px 10px 10px
            color: var(--color-text-grey-medium)
            padding: 12px 12px 12px 12px
            width: max-content
            max-width: 570px
            position: relative
            .textWrapper
                padding-right: 12px
                .text
                    margin: 0
                    font-size: 13px
                    word-break: break-word
            &.audio_content
                padding: 0
            &.file_content
                display: block
            &.video_content
                padding-right: 12px
            &.severalAttachments
                display: flex
                flex-direction: column
                padding: 12px
                .attachmentsWrapper
                    display: flex
                    flex-wrap: wrap
                    align-items: center
                    justify-content: center
                    position: relative
                    &.twoRows
                        justify-content: flex-start
                    &.withText
                        margin-bottom: 15px
                        padding-bottom: 15px
                        &::after
                            position: absolute
                            bottom: 0
                            left: 2%
                            width: 96%
                            content: ""
                            display: block
                            border-bottom: 1px solid rgba(255, 255, 255, 0.2)
                        &+.textWrapper
                            width: 96%
                            margin: 0 auto
                            padding: 0
                    &.previewImages
                        justify-content: flex-start
                        border-radius: 12px
                        background: var(--color-white)
                        padding: 5px
                        img
                            object-fit: cover
                            padding: 7px 7px
                            image-rendering: optimizequality
                            cursor: pointer
        .time
            font-size: 10px
            color: var(--color-grey_medium)
            text-align: right
            margin: 7px 4px
            padding-right: 20px
        .flexContainer
            display: flex
            .menuWrapper
                align-self: stretch
                opacity: 0
                transition: opacity .2s ease
                display: flex
                justify-content: center
                align-items: stretch
                .menu
                    display: flex
                    width: 20px
                    justify-content: center
                    align-items: center
                    cursor: pointer
                    .dot
                        position: relative
                        width: 5px
                        height: 5px
                        background: var(--color-background-gray)
                        transition: background 0.2s ease
                        border-radius: 50%
                        &::before, &::after
                            content: ''
                            position: absolute
                            width: 5px
                            height: 5px
                            background: var(--color-background-gray)
                            transition: background 0.2s ease
                            border-radius: 50%
                        &::before
                            transform: translateY(10px)
                        &::after
                            transform: translateY(-10px)
                .menu:hover .dot, .menu:hover .dot::before, .menu:hover .dot::after
                    background: var(--color-primary)
                    transition: background 0.2s ease
            &:hover
                .menuWrapper
                    opacity: 1

.wrapper.outbox
    justify-content: flex-end
    .contentWrapper
        align-items: flex-end
        .content
            align-self: flex-end
            background: var(--color-linear-message)
            color: var(--color-white)
            border-radius: 10px 0 10px 10px
            margin: 0
.wrapper.inbox
    margin: 0 15px 10px 15px
    .contentWrapper
        .content
            margin: 0 0 5px 0
        .time
            padding: 0

.wrapper.darkTheme
    .contentWrapper
        .content
            background: #292929
            box-shadow: 0 2px 4px #292929
            color: var(--color-white)
        .time
            color: var(--color-white)
    &.outbox
        .contentWrapper
            .content
                background: var(--color-background-gray_dark)
                color: var(--color-white)
.isHidden
    display: none !important
.isViewedStatus
    position: absolute
    right: 5px
    bottom: 0
    color: var(--color-white)
    display: flex
    .statusSend
        transform: translateX(5px)
    .statusViewed
        transform: translateX(-5px)
    .statusNotViewed
        visibility: hidden
