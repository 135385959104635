.container
    display: flex
    align-items: center
    gap: 8px
    cursor: pointer

.iconWrapper
    width: 35px
    min-width: 35px
    display: flex
    justify-content: flex-start

.text
    display: flex
    flex-direction: column
    overflow: hidden

    div:nth-child(1)
        font-weight: 500
        font-size: 14px
        line-height: 20px
        color: #274A42
        white-space: nowrap
        overflow: hidden
        text-overflow: ellipsis
    div:nth-child(2)
        font-weight: 400
        font-size: 12px
        line-height: 16px
        color: #56716B
        white-space: nowrap
        overflow: hidden
        text-overflow: ellipsis
