
.root
  display: flex
  align-items: center
  justify-content: center
.button
  width: 30px
  height: 24px
  padding: 6px
  display: flex
  flex-direction: column
  align-items: center
  justify-content: center
  border: 1px solid var(--color-border-dark)
  border-radius: 6px
  cursor: pointer
  &:hover
    background: var(--color-blue-smooth)
  &:not(:hover) svg path
    fill: var(--color-grey) !important
  svg
    flex: 0 0 auto
  & path
    fill: var(--color-white)

.notHover:hover
  background: inherit

.isStroke
  &:not(:hover) svg path
    stroke: var(--color-grey) !important

.isActive
  background: var(--color-blue-light)
  border: 1px solid var(--color-blue-smooth)
  &:not(:hover) svg path
    fill: var(--color-blue-smooth) !important

.white
  background: var(--color-white)
  border-radius: 6px

.grey
  background: var(--color-background-light)
  border-radius: 6px
.button
  &:disabled
    background: inherit
    cursor: not-allowed
    &.isStroke
      svg
        path
          stroke: var(--color-grey) !important
    &:not(:hover) svg path
      fill: var(--color-grey) !important
    &:hover svg path
      fill: var(--color-grey) !important
