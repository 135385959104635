.root
  min-height: 100vh
  background-color: var(--color-d20)
  display: flex
  flex-direction: column
  align-items: center
  justify-content: center
  overflow-y: auto
  overflow-x: hidden
  padding: 16px 20px
  position: relative

  .card
    display: flex
    column-gap: 8px
    max-width: 372px
    width: 100%
    border-radius: 8px
    box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.20), 0px 3px 14px 0px rgba(0, 0, 0, 0.12), 0px 8px 10px 0px rgba(0, 0, 0, 0.14)
    padding: 12px 16px

    &__icon
      flex-shrink: 0

    &__title
      font-size: 16px
      line-height: 1.25
      color: var(--color-d800)

    &__text
      font-size: 12px
      line-height: 1.33
      color: var(--color-d500)
      margin-bottom: 6px
