@import src/theme/global
.wrap
  width: 360px
.body
  padding: 12px 16px
  .text
    color: var(--color-d700)
    font-size: 14px
    font-weight: 400
    line-height: 20px
    margin-bottom: 12px

  .restoreBox
    display: flex
    gap: 10px
    align-items: center
    margin-bottom: 12px
    & p
      font-size: 12px
      line-height: 16px
  .inputs
    display: flex
    align-items: center
    gap: 8px
    margin-bottom: 4px
  .input
    display: flex
    width: 32px
    height: 40px
    padding: 10px 8px 10px 12px
    align-items: flex-start
    align-self: stretch
    border-radius: 4px
    border: 1px solid var(--color-d100)
    background: var(--color-white)
    color: var(--dark-green-d-800, #274A42)
    font-size: 14px
    line-height: 20px
    &.error
      border-color: var(--color-red)
  .errorText
    color: var(--color-red)
    font-size: 12px
    line-height: 12px
  .footer
    display: flex
    justify-content: space-between
    align-items: center
    margin-top: 24px
    width: 100%
    color: var(--colod-d600)
    font-size: 14px
  .btns
    display: flex
    gap: 8px
    justify-content: flex-end
    flex-grow: 1
  .images
    display: flex
    align-items: center
    margin-bottom: 16px
    .item
      display: flex
      flex-direction: column
      gap: 6px
      align-items: center
      span
        color: var(--color-d700)
        font-size: 10px
        font-weight: 400
        line-height: 14px
        max-width: 130px
        @include textEllipsis

    .delimetr
      width: 64px
      height: 2px
      background-color: var(--color-d700)
.tabBlock
  background-color: var(--color-d50)
  padding: 8px
  color: var(--color-d700)
  font-size: 12px
  line-height: 16px
