
.addUser
    display: flex
    align-items: center
    gap: 10px
    border-bottom: 1px solid var(--color-border-dark)
    padding: 0 10px
    & p
        font-size: 13px
        line-height: 39px
        margin: 0
        color: var(--color-text-grey)
        font-family: "SF Pro Text", sans-serif
.hide
    display: none
