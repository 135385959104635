
.wrap
  padding: 10px
  color: var(--color-text-grey)
  max-height: calc(100% - 210px)
  overflow-y: auto
  display: flex
  gap: 15px
  flex-wrap: wrap
  .file
    width: 100px
    height: 100px
    border: 1px solid
    display: flex
    align-items: center
    justify-content: center
