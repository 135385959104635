
.color
  width: 24px
  height: 24px
  border-radius: 6px
  display: flex
  align-items: center
  justify-content: center
  padding: 5px
  * path
    fill: var(--color-white)
.task
  background: var(--color-blue-smooth)
.meetings
  background: var(--color-purple)
.calls
  background: var(--color-green)
.mails
  background: var(--color-yellow)
.reminder
  background: var(--color-aqua)
.taskList
  margin: 0 10px
  padding-bottom: 2px
  max-height: 240px
  overflow-y: auto
