
.passWrap
    width: 380px
    padding: 10px 0 0
    position: relative
    .btns
        display: flex
        gap: 10px
        justify-content: center
        margin-top: 40px
    .label
        display: block
        color: var(--color-text-grey-medium)
        font-family: 'SF Pro Text', sans-serif
        font-size: 12px
        margin-bottom: 10px
        text-align: left
    .inputBox
        position: relative
        margin-bottom: 40px
        .input
            width: 100%
            padding: 11px 24px
            color: var(--color-text-grey-medium)
            font-family: 'SF Pro Text', sans-serif
            font-size: 11px
            border: 1px solid var(--color-border-dark)
            border-radius: 4px
        .eyeIcon
            position: absolute
            top: 50%
            right: 10px
            transform: translateY(-50%)
            width: 20px
            height: 12px
            cursor: pointer
            &:hover path
                fill: var(--color-blue-smooth)
        .active path
            fill: var(--color-blue-smooth)
