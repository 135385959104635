
.container
    width: 100%
    padding: 8px 0
    height: 100%

.content
    height: 100%
    overflow: auto
    padding-bottom: 100px

.header
    background: #F1F3F2
    display: flex
    height: 32px
    border-radius: 4px
    align-items: center
    padding: 0 12px
    font-weight: 400
    font-size: 12px
    line-height: 16px
    color: #274A42
    div
        flex: 1

.empty
    display: flex
    align-items: center
    justify-content: center
    height: 400px
