
.workAreaWrap
  display: flex
  width: 100%
  min-width: 100%
  height: calc(100vh - 60px)
  background-color: var(--color-white)
.safeList
  display: flex
  flex-direction: column
  row-gap: 5px
  overflow-x: hidden
  overflow-y: auto
  margin: 0px 10px 30px
.tabs
  margin: 8px 10px 4px

.btn
  display: flex
  align-items: center
  gap: 6px
  padding: 7px 2px 7px 12px
  border-radius: 4px
  height: 46px
  color: var(--color-d800)
  cursor: pointer
  &:hover
    background: var(--color-b50)
  &.collapsed .name
    opacity: 0
    transition: opacity 0.3s
  svg
    flex-shrink: 0
  .name
    flex-grow: 1
    font-size: 13.5px
    font-weight: 400
    line-height: normal
    letter-spacing: -0.062px
    transition: opacity 0.2s ease-in-out,
    white-space: nowrap
.alertBlock
  display: flex
  height: 100%
  width: 100%
  align-items: center
  justify-content: center
  flex-direction: column
  .alert
    display: flex
    width: 480px
    margin: 0 auto
    padding: 12px
    align-items: flex-start
    gap: 4px
    align-self: stretch
    border-radius: 4px
    background: #F99
    color: var(--color-d900)
    font-size: 14px
    line-height: 20px
  .icon
    flex-shrink: 0
    display: flex
  .title
    line-height: 20px
    font-size: 16px
    font-weight: 700
    line-height: 20px
  .text
    padding-top: 2px
  ul
    list-style: disc
    padding-left: 20px
    margin-bottom: 8px
