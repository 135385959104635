.wrap
  border-top: 1px solid var(--color-d100)
  padding: 12px 0
.block
  display: flex
  gap: 12px
.emptyArea
  height: 56px
  flex: 1 0
  border: 1px solid var(--color-d100)
  border-radius: 4px
  position: relative
  color: var(--DarkGreen-D400, #869A95)
  font-size: 12px
  line-height: 16px
  padding: 12px
  .sendBtn
    position: absolute
    bottom: 12px
    right: 12px
.fileList
  display: flex
  gap: 8px
  flex-wrap: nowrap
  overflow: auto
  max-width: 100%
  padding: 12px 0
.editorContainer
  position: relative
  width: 100%
  .loaderBox
    position: absolute
    width: 100%
    height: 100%
    top: 0
    left: 0
    backdrop-filter: blur(2px)
    z-index: 1
