.wrapper
    width: 350px
    padding: 11px 20px
    background-color: #F9F9F9
    box-shadow: 0 2px 4px rgba(197, 197, 197, 0.5)
    border-radius: 8px
    margin: 19px
    font-family: 'SF Pro Light'
    .text
        font-size: 11px
        margin-bottom: 15px
    .button
        font-size: 12px
        font-family: 'SF Pro Medium'
        color: #fff
        background-color: #4086F1
        width: 136px
        text-align: center
        padding: 10px 0
        border-radius: 4px
        margin-left: auto
        cursor: pointer
        &:hover
            background-color: #3b6dcb
    .sendInvite
        height: 100%
        display: flex
        align-items: center
        justify-content: center
        letter-spacing: -0.06px
        color: #2B353E
        font-family: 'SF Pro Text'
        .contentWrapper
            padding: 30px
            background: #FFFFFF
            border-radius: 7px
            position: relative
            .close
                cursor: pointer
                position: absolute
                top: 10px
                right: 10px
            .header
                display: flex
                justify-content: center
            .title
                margin: 0 0 27px 0
                font-size: 18px
                line-height: 21px
                font-family: 'SF Pro Text Medium'
            .content
                display: flex
                flex-direction: column
                align-items: center
                .socials
                    display: flex
                    align-items: center
                    list-style: none
                    overflow-x: auto
                    overflow-y: hidden
                    padding: 0
                    margin: 0 0 25px 0
                    .socialsItem.active
                        background: #EBF2FD
                        border-radius: 5px
                    .socialsItem
                        cursor: pointer
                        text-align: center
                        display: flex
                        flex-direction: column
                        align-items: center
                        padding: 10px 10px
                        box-sizing: border-box
                        margin: 0
                        border-radius: 10px
                        width: 100px
                        height: 100px
                        &:hover
                            background: #EBF2FD
                        p
                            margin: 0
                            font-family: "SF Pro Regular", sans-serif
                            font-size: 12px
                            line-height: 16px
                            color: rgba(43, 53, 62, 1)
                        img
                            width: 50px
                            height: 50px
                            border-radius: 50%
                            margin-bottom: 8px
                        &:not(:last-child)
                            margin: 0 10px 0 0
                .text
                    font-size: 12px
                    line-height: 21px
                    text-align: center
                    color: #4F555B
                    margin: 0 0 21px 0
                    max-width: 340px
                .actionBlock
                    display: flex
                    justify-content: center
                    .completeBtn
                        font-size: 12px
                        line-height: 14px
                        color: #FFFFFF
                        transition: background .2s ease
                        width: 145px
                        height: 38px
                        border: none
                        background: #4086F1
                        border-radius: 3.75px
                        &:hover:not(.disabledBtn)
                            background: #3b6dcb
                    .disabledBtn
                        background-color: #e3e3e3
                        color: grey
                        cursor: default
