.toolsLeft
  display: flex
  align-items: center
  .selectAll
    margin-right: 12px
  .favourite
    margin-right: 12px
.toolsRight
  display: flex
  align-items: center
  gap: 16px
.red path
  fill: red
