.wrap
  width: 360px
  .body
    padding: 16px
  .inputs
    display: flex
    flex-direction: column
    gap: 12px

  .footer
    display: flex
    justify-content: space-between
    padding-top: 24px
    .btns
      display: flex
      justify-content: flex-end
      flex-grow: 1
      gap: 8px
