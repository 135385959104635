@import  "src/theme/cutsStr"
  
.content
  width: 440px
  max-width: 100%
  padding: 8px
  
.list
  margin-top: 8px

  &__item
    display: flex
    gap: 5px
    position: relative
    padding: 4px 12px
    font-size: 12px
    line-height: 1.33
    transition: background-color .3s ease-in-out

    &:hover
      background-color: var(--color-lb50)

      .list__btn
        right: 0

  &__num
    font-weight: 700
    color: var(--color-b800)

  &__name
    color: var(--color-d700)
    font-weight: 500
    @include cutsStr(1)

  &__btn
    position: absolute
    display: flex
    right: -100%
    top: 50%
    transform: translateY(-50%)

.loaderBox
  position: relative
  width: 100%
  height: 100px