
.wrap
    padding: 10px 0
    max-height: calc(100% - 210px)
    overflow: auto
    font-size: 13px
    color: var(--color-text-grey)
    & p
        margin: 0
    .roleBlock
        margin-bottom: 20px
        padding: 0 18px
        & > div
            background: var(--color-background-light)
        & > div:not(:last-child)
            margin-bottom: 10px

    .roleName
        margin-bottom: 15px
    .noUser
        line-height: 17px
        padding: 5px 10px
        border: 1px solid var(--color-border-dark)
        border-radius: 5px
    .dark
        padding: 0 18px 10px
        background: var(--color-background-grey)
