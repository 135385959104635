
.root
  width: 520px

.mainContent
  display: flex
  flex-direction: column
  row-gap: 15px
  padding: 16px
.select
  border-bottom: 1px solid var(--color-d100)

.users
  min-height: 200px
  max-height: 324px
  overflow-y: auto
  overflow-x: hidden
  position: relative

.buttonsWrapper
  display: flex
  justify-content: space-between
  .rightBtns
    display: flex
    gap: 8px
    flex-grow: 1
.tab1
  .buttonsWrapper
    margin-bottom: 16px

.tab2
  max-height: 300px
  overflow: hidden
  overflow-y: scroll
  .email
    font-size: 15px
    font-weight: 500
    color: var(--color-d900)
  .sessions
    display: flex
    flex-direction: column
    gap: 4px

.tab3
  display: flex
  flex-direction: column
  gap: 8px

.customSend
  font-family: 'Roboto', sans-serif !important
  background: var(--color-b800) !important
  color: var(--color-b50) !important
  font-size: 14px !important
  font-weight: 400 !important
  width: unset !important
  cursor: pointer !important
.customEmoji
  width: 32px !important
.customClip
  background-color: #274A4229 !important
  width: 32px !important
.customSchedule
  margin-right: 8px !important
  background-color: #274A4229 !important
  width: 32px !important

.scheduleMessageModal
  padding: 1rem
  .scheduleMessageHeader
    .scheduleMessageTitle
      font-weight: 500
      font-size: 24px
      color: var(--color-text-dark)
  .scheduleMessageContent
    display: flex
    gap: 8px
    .scheduleInput
      border: 1px solid var(--color-d100)
      border-radius: 4px
      padding: 8px 12px
      font-size: 14px
      color: var(--color-d800)
