
.wrap
  width: 400px
  max-width: 100%
  padding: 12px 16px
  font-family: 'Roboto', sans-serif

.contentWrap
  flex: 1 1 auto
  display: flex
  flex-direction: column
  row-gap: 32px

.title
  font-size: 14px
  font-weight: 400
  line-height: 1.28
  color: var(--color-d800)

  > span
    font-weight: 700

.text
  font-size: 12px
  line-height: 1.33
  color: var(--color-d600)

.list
  margin-top: 8px
  padding-left: 18px

  &__item
    font-size: 12px
    line-height: 1.33
    color: var(--color-d600)
    position: relative

    &::before
      content: ""
      display: inline-block
      width: 3px
      height: 3px
      border-radius: 50%
      background-color: var(--color-d600)
      position: absolute
      top: 6px
      left: -10px
    

.buttonsWrap
  display: flex
  justify-content: flex-end
  gap: 10px
