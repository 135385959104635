.label
  width: 160px
  height: 28px
  border: 1px solid var(--color-d100)
  border-radius: 4px
  display: flex
  gap: 4px
  align-items: center
  padding-right: 8px
  padding-left: 4px
  &.active
    border-color: var(--color-b800)
  .value
    font-size: 12px
    white-space: nowrap
    text-overflow: ellipsis
    overflow: hidden
    min-width: 0
    flex-grow: 1
.arrow
  transform: rotate(-90deg)
  flex-shrink: 0
.arrowOpen
  transform: rotate(90deg)
  path
    fill: var(--color-b800)
.item
  padding: 8px 12px
  color: var(--color-d800)
  font-size: 12px
  font-style: normal
  font-weight: 400
  line-height: 20px
  cursor: pointer
  display: flex
  gap: 4px
  align-items: center
  &:hover
    color: var(--color-b800)

.date
  text-align: end
  flex-grow: 1
