
.content
    padding: 22px 16px
    overflow: hidden
    display: flex
    flex-direction: column
    gap: 10px
    position: relative
    button
        span
            span:nth-child(2)
                color: var(--DarkGreen-D800, #274A42)
                font-size: 14px
                font-weight: 400

.buttons
    margin-top: 40px
    display: flex
    width: 100%
    justify-content: flex-end
    gap: 10px

.input
    height: 32px

.emoji
    margin-top: 4px
