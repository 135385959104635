
.wrap
  padding: 16px
  height: 100%
  background: var(--color-white)
.header
  display: flex
  align-items: flex-start
  justify-content: space-between
  margin-bottom: 10px
  .info
    display: flex
    flex-direction: column
  .name
    font-weight: 600
    font-size: 16px
    line-height: 24px
    color: var(--color-d900)
  .size
    font-size: 14px
    line-height: 18px
    color: var(--color-d600)
  .buttons
    display: flex
    gap: 10px

.wrapAudio
  width: 50%
  margin: 0 auto
  border-radius: 8px
  overflow: hidden
.wrapVideo
  width: 70%
  margin: 0 auto
  border-radius: 8px
  overflow: hidden
.imgContainer
  flex: 1 0 1px
  margin: 0 auto
  padding: 10px
.image
  width: 100%
  height: 100%
  max-height: 70%
  object-fit: contain
  border-radius: 10px
.pdfContainer
  flex: 1 0 auto
  margin: 0 auto
  width: 100%
  .embed
    width: 100%
    height: 100%
    border-radius: 10px
.default
  display: flex
  flex-direction: column
  align-items: center
  justify-content: center
  flex-grow: 1
  gap: 20px
  .text
    font-size: 16px
    font-weight: 600
    color: var(--color-d900)
