@import "src/theme/colors"
  
.item
  display: flex
  flex-direction: column
  align-items: flex-start
  background-color: $color-b50
  border-radius: 8px
  padding: 12px 16px
  font-family: "Roboto", sans-serif
  position: relative
  z-index: 1

  &:hover,
  &:active
    background-color: $color-b100

    .item__btn
      opacity: 1

  &__title
    font-size: 32px
    font-weight: 500
    line-height: 1.25
    color: $color-d700

  &__desc
    margin-top: 4px
    font-size: 16px
    color: $color-d600
    line-height: 1.5

  &__img
    position: absolute
    right: 0
    bottom: 0
    z-index: -1

  &__btn
    margin-top: auto
    background-color: $color-grey-green-transparent-16
    border-radius: 4px
    padding: 4px 12px
    color: $color-d800
    font-size: 12px
    line-height: 1.66
    opacity: 0

  
