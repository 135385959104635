@import src/theme/global
.box
  padding: 4px 16px 8px 8px
  position: relative
  display: flex
  justify-content: space-between
  align-items: flex-start
  box-shadow: 0px -1px 0px 0px #ECECEC inset
  cursor: pointer
  &:hover
    background: var(--color-b50)
    .time
      visibility: hidden
    .menu
      display: flex
  &.current
    background: var(--color-b100)

.controls
  flex: 0 0 auto
  display: flex
  margin-right: 6px
  .icon path
    fill: var(--color-d400)
  .active path
    fill: var(--color-b800)
.main
  flex: 1 1 auto
  padding-top: 4px
  margin-right: 6px
  display: flex
  flex-direction: column
  overflow: hidden
  .info
    display: flex
    gap: 4px
  .name
    display: flex
    .starBtn
      display: flex
      flex-direction: column
      width: 20px
      height: 20px
      display: flex
      align-items: center
      justify-content: center
      padding: 0
      flex-shrink: 0
    .star
      width: 16px
      height: 16px
    & path
      fill: var(--color-d400)
    & .active path
      fill: var(--color-red)
    .from
      flex-shrink: 1
      color: var(--color-d700)
      font-size: 14px
      font-weight: 500
      line-height: 20px
      margin: 0
      @include textEllipsis
  .subject
    flex-shrink: 1
    color: var(--color-d800)
    font-size: 14px
    line-height: 20px
    margin: 0
    @include textEllipsis

  .attachments
    display: flex
    flex-wrap: wrap
    gap: 4px

.split
  .info
    max-width: 250px
    flex-direction: column

.time
  color: var(--color-d600)
  font-size: 14px
  line-height: 20px
  margin: 0
  padding-top: 4px
  display: flex
  gap: 4px
  align-items: center
  white-space: nowrap

.unread
  background: var(--color-b40)
  &:before
    content: ""
    width: 10px
    height: 10px
    background: var(--color-red)
    border-radius: 50%
    position: absolute
    top: 10px
    left: 3px

    .main
      .name
        color: var(--color-d900)
      .subject
        color: var(--color-d600)
.menu
  position: absolute
  top: 0
  right: 0
  gap: 8px
  flex-grow: 0
  display: none
  background: inherit
.amount
  min-width: 30px
  height: 30px
  border-radius: 15px
  display: flex
  align-items: center
  justify-content: center
  background: var(--color-d100)
