
.tabsWrapper
    width: 100%
    display: flex
    align-items: center
    justify-content: center
    margin-top: 10px

.content
    width: 100%
    background: #E7EBEA
    height: 100%
    overflow: scroll
    padding: 0 20px

.title
    display: block
    color: var(--dark-green-d-700, #3E5D56)
    text-align: center
    font-family: Roboto
    font-size: 12px
    font-style: normal
    font-weight: 400
    line-height: 16px
    margin: 16px auto

.subtitle
    color: var(--dark-green-d-700, #3E5D56)
    text-align: center
    font-family: Roboto
    font-size: 16px
    font-style: normal
    font-weight: 500
    line-height: 24px
    margin-top: 50px
