.list
  width: max-content
  display: flex
  border-radius: 4px
  overflow: hidden
  border: 1px solid var(--color-d300)
  font-size: 12px
  line-height: 1.66
  color: var(--color-d600)

  &__item
    &:not(:last-child) 
      border-right: 1px solid var(--color-d300)

  &__btn
    display: flex
    align-items: center
    padding: 4px 12px

    &.active,
    &:active,
    &:hover
      background-color: var(--color-d300)
      color: var(--color-plantation)
    
      



