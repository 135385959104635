.textMessage
    display: flex
    align-items: flex-end
    align-self: stretch
    justify-content: space-between
    width: 80%
    padding: 4px 30px 4px 20px
    position: relative

    .messageImg
        width: 22px
        height: 22px
        padding: 2px 0
        cursor: pointer
        path
            fill: #DCDCDC
    .messageImg.active
        cursor: pointer
        path
            fill: #4086F1
    .textInput
        width: 90%
        font-size: 13px
        font-family: "SF Pro Regular", sans-serif
        border: none
        outline: none
        resize: none
        height: 25px
        line-height: 20px
        max-height: 400px
        overflow-y: scroll
        overflow-x: hidden

.textMessage.darkTheme
    .messageImg
        path
            fill: #fff
    .textInput
        background: #323232
        color: #fff
