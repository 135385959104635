.category
    display: flex
    flex-direction: column
    padding: 10px 16px 0 16px
    border-radius: 8px
    height: 600px
    background: #cfd7d54d
    position: relative
    transition: all 0.3s ease-in-out
    width: 1090px
    max-width: 98vw

    h6
        color: var(--DarkGreen-D700, #3E5D56)
        font-family: Roboto
        font-size: 14px
        font-style: normal
        font-weight: 500
        line-height: 18px
        display: flex
        align-items: center
        justify-content: space-between
        height: 24px
        gap: 4px

.notesList
    display: flex
    width: 100%
    flex-wrap: wrap
    gap: 12px
    overflow: auto

.empty
    display: flex
    width: 100%
    height: 500px
    align-items: center
    justify-content: center

.notes
    display: flex
    flex-wrap: wrap
    gap: 12px
    margin-bottom: 10px

.header
    color: var(--DarkGreen-D700, #3E5D56)
    font-family: Roboto
    font-size: 14px
    font-style: normal
    font-weight: 500
    line-height: 18px
    padding: 16px 0
