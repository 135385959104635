
.box
  height: 340px
  position: relative
.listAccount
  height: 340px
  overflow-y: auto
.email
  color: var(--color-d900)
  font-size: 15px
  font-weight: 500
  line-height: 20px
  margin: 0 0 2px
