
.itemWrap
  font-size: 14px
  cursor: pointer
  padding: 10px 10px
  display: flex
  align-items: center
  gap: 4px
  &.nextGroup
    border-bottom: 1px solid #E3E3E3
  &:hover
    background-color: var(--color-b100)
