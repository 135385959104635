
.dateBlock
  padding: 5px 10px
  font-size: 11px
  display: flex
  align-items: center
  background: var(--color-blue-light)
  margin-bottom: 1px
  .date
    flex: 1
    margin-right: 20px
    display: flex
    justify-content: center
    color: var(--color-text-grey)
    span
      display: block
      padding: 0 15px
      border-radius: 10px
      width: max-content
      border: 1px solid var(--color-border-dark)
      background: var(--color-white)
      line-height: 20px
      color: var(--color-silver-chalice)
