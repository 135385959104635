@mixin scrollbar($width, $height, $color1, $color2)
  // scrollbar-width: thin
  // scrollbar-color: $color1 var(--color-white)
  &::-webkit-scrollbar-track
    background: $color2
  &::-webkit-scrollbar-track
    -webkit-box-shadow: none
    background-color: var(--color-white)
    border-radius: #{calc($width / 2)}px

  &::-webkit-scrollbar
    height: #{$height}px
    width: #{$width}px
    background-color: var(--color-white)
  &::-webkit-scrollbar-thumb
    background-color: $color1
    border-radius: #{calc($width / 2)}px
  @-moz-document url-prefix() // need test firefox
    scrollbar-width: thin
    scrollbar-color: red

//Blue theme
.scrollbar-blue
  @include scrollbar(12, 12, var(--color-scrollbar), var(--color-scrollbar)-dark)

.scrollbar-thin-blue
  @include scrollbar(3, 3, var(--color-scrollbar), var(--color-scrollbar)-dark)

.scrollbar-medium-blue
  @include scrollbar(6, 6, var(--color-scrollbar), var(--color-scrollbar)-dark)

//Orange theme
//TODO - Colors needs to be changed
.scrollbar-orange
  @include scrollbar(12, 12, var(--color-scrollbar)-orange, var(--color-scrollbar)-dark-orange)

.scrollbar-thin-orange
  @include scrollbar(3, 3, var(--color-scrollbar)-orange, var(--color-scrollbar)-dark-orange)

.scrollbar-medium-orange
  @include scrollbar(6, 6, var(--color-scrollbar)-orange, var(--color-scrollbar)-dark-orange)

//Turquoise theme
.scrollbar-turquoise
  @include scrollbar(12, 12, var(--color-scrollbar)-turquoise, var(--color-scrollbar)-dark-turquoise)

.scrollbar-thin-turquoise
  @include scrollbar(3, 3, var(--color-scrollbar)-turquoise, var(--color-scrollbar)-dark-turquoise)

.scrollbar-medium-turquoise
  @include scrollbar(6, 6, var(--color-scrollbar)-turquoise, var(--color-scrollbar)-dark-turquoise)

//Dark theme
.scrollbar-dark
  @include scrollbar(12, 12, var(--color-scrollbar)-dark, var(--color-scrollbar)-dark-dark)

.scrollbar-thin-dark
  @include scrollbar(3, 3, var(--color-scrollbar)-dark, var(--color-scrollbar)-dark-dark)

.scrollbar-medium-dark
  @include scrollbar(6, 6, var(--color-scrollbar)-dark, var(--color-scrollbar)-dark-dark)
