.container
    display: flex
    align-items: flex-start
    padding: 0 0 12px
    flex-direction: column
    overflow: hidden
    border-radius: 8px
    height: 100%
    .scrollWrapper
        width: 100%
        overflow-y: auto
        padding: 0 12px
    &_long
        .scrollWrapper
            display: flex
            flex-wrap: wrap
            gap: 2%
            > div
                width: 32%

.emptyWrapper
    padding-right: 16px
    width: 100%
    height: 100%
    display: flex
    align-items: center
    justify-content: center

.date
    color: var(--dark-green-d-800, #274A42)
    border-bottom: 1px solid var(--dark-green-d-50, #E7EBEA)
    font-size: 12px
    font-weight: 500
    line-height: 16px
    padding-bottom: 6px
    margin: 10px 0

.list
    width: 100%
    display: flex
    flex-direction: column
    gap: 4px
    padding-bottom: 10px
