
.wrap
  cursor: pointer
  .checkBox
    background: var(--color-d300)
    border-radius: 4px
    width: 16px
    height: 16px
    display: flex
    align-items: center
    justify-content: center
  & *
    fill: var(--color-d300)
  &:hover *
    fill: var(--color-d50)
  & .checked
    background: var(--color-b800)
    & *
      fill: var(--color-d50)
