@import "src/theme/global"
.asideWrap
  flex: 0 0 256px
  width: 256px
  height: 100%
  overflow-x: hidden
  overflow-y: auto
  padding: 10px 10px 35px
  transition: flex 0.2s ease-in-out, width 0.2s ease-in-out,
  &.collapsed
    flex: 0 0 70px
    width: 70px
.logoWrapper
  display: flex
  align-items: center
  column-gap: 3px
  margin-left: 3px
.logoIcon
  flex: 0 0 auto
.logoIconText
  flex: 0 0 auto
  position: relative
  top: 5px
  transition: opacity 0.2s ease-in-out, visibility 0.2s ease-in-out
  &.collapsed
    opacity: 0
    visibility: hidden
.burgerWrapper
  margin-top: 20px
  margin-bottom: 5px
.burgerButton
  width: 50px
  height: 50px
  display: flex
  align-items: center
  justify-content: center
  background-color: transparent
  border-radius: 4px
  transition: background-color 0.1s ease-in-out
  padding: 0
  &:hover
    background-color: rgba(0, 0, 0, 0.2)
.menuListWrapper
  width: 100%
.menuList
  display: flex
  flex-direction: column
  row-gap: 3px
  margin: 0
.menuItem
  width: 100%
.menuLink
  width: 100%
  height: 50px
  display: flex
  align-items: center
  column-gap: 15px
  border: 1px solid transparent
  border-radius: 6px
  padding: 0 10px
.imageWrap
  flex: 0 0 30px
  width: 30px
  height: 24px
  display: flex
  align-items: center
  justify-content: center
  border-radius: 6px
  svg
    flex: 0 0 14px
    width: 14px
    height: 14px
.menuName
  font-family: 'Roboto', sans-serif
  font-weight: 400
  font-size: 13.5px
  line-height: 16px
  color: var(--color-white)
  transition: opacity 0.2s ease-in-out, visibility 0.2s ease-in-out
  @include textEllipsis
  &.collapsed
    opacity: 0
    visibility: hidden
