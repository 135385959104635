
.wrap
  display: flex
  gap: 10px
  justify-content: space-between
  align-items: center
  padding: 10px
  border-bottom: 1px solid var(--color-border-dark)

  .filter
    display: flex
    gap: 10px
  .rightGroup
    display: flex
    gap: 10px
    align-items: center

    .selectBtn
      border: 1px solid var(--color-border-dark)
      border-radius: 6px
      background: var(--color-background-light)
      cursor: pointer
      font-family: "SF Pro Text", sans-serif
      font-size: 12px
      line-height: 14px
      padding: 4px 10px
    .active
      border: 1px solid var(--color-blue-smooth)
      background: var(--color-blue-light)

    .icons
      display: flex
      align-items: center
      gap: 6px
      border-left: 1px solid var(--color-border-dark)
      padding: 2px 0 2px 10px
      .icon path
        fill: var(--color-red)-dark

    .iconBox
      width: 30px
      height: 24px
      display: flex
      align-items: center
      justify-content: center
      border: 1px solid var(--color-border-dark)
      border-radius: 6px
      background: var(--color-background-light)
      cursor: pointer

  .roleSelectBlock
    position: relative
    .roleSelect
      position: absolute
      top: 110%
      right: 0
      width: max-content
      background: var(--color-white)
      padding: 5px
      z-index: 1
      & > div
        margin-bottom: 5px
