.container
    min-height: 100vh
    width: 100vw
    background: var(--color-b950)
    padding: 36px 64px
    display: flex

.content
    width: 45%
    min-height: 90vh
    padding: 0 30px 0 10px
    display: flex
    flex-direction: column
.header
    width: 100%
    .button
        display: none
.slider
    width: 55%

.logo
    width: 133px

.footer
    display: flex
    align-items: center
    justify-content: space-between
    margin-top: 30px
    margin-right: calc( 10% - 30px )

.buttons
    display: flex
    align-items: center
    justify-content: space-between

.next_button
    background: var(--color-c400)
    color: var(--color-black)
    font-family: "SF Pro Text", sans-serif
    font-style: normal
    font-weight: 650
    font-size: 16px
    line-height: 19px
    letter-spacing: -0.0865063px
    text-align: center
    padding: 14px 33px
    margin-left: 30px

.button
    font-family: "SF Pro Text", sans-serif
    font-style: normal
    font-weight: 590
    font-size: 16px
    line-height: 19px
    color: var(--color-c400)
    display: flex
    align-items: center
    svg
        margin-right: 15px
        path
            fill: var(--color-c400)

@media (max-width: 991px)
    .container
        flex-direction: column
        align-items: center
        height: auto

    .content
        width: 100%
        min-height: auto
        padding: 0

    .footer
        margin-right: 0

    .slider
        width: 100%
        margin-top: 40px

@media (max-width: 768px)
    .slider
        display: none
    .container
        padding: 15px
    .content
        min-height: 96vh
        justify-content: space-between
    .footer
        .button
            display: none
        .next_button, .buttons
            width: 100%
            margin-left: 0
    .header
        display: flex
        flex-wrap: wrap
        justify-content: space-between
        gap: 20px
        .button
            display: block
            flex-basis: 40%
        :nth-child(2)
            text-align: end
            color: var(--color-white)
        :nth-child(3)
            text-align: start
