.container
  height: 100%
  position: relative
  background: var(--DarkGreen-D20, #F5F7F7)
  overflow: hidden

.header
  padding: 10px 14px
  height: 60px
  display: flex
  align-items: center
  justify-content: space-between
  position: relative
  box-shadow: 0px 0.75px 0px 0px #ECECEC inset, 0px -0.75px 0px 0px #ECECEC inset

  h6
    color: var(--DarkGreen-D900, #0E352C)
    font-family: Roboto
    font-size: 16px
    font-style: normal
    font-weight: 500
    line-height: 24px
  .right
    display: flex
    align-items: center
    justify-content: flex-start
    gap: 24px

  .buttons
    display: flex
    align-items: center
    gap: 12px
    justify-content: flex-start
    button
      min-width: 28px
      span
        height: 14px
        width: 14px

.content
  padding: 12px
  height: 100%
  position: relative
  overflow: auto
  padding-bottom: 60px

.categories
  display: flex
  flex-wrap: wrap
  gap: 12px

.editHeader
  position: absolute
  right: -100%
  width: 100%
  height: 100%
  top: 0
  bottom: 0
  z-index: 1
  background: #B3E5FC
  display: flex
  align-items: center
  justify-content: space-between
  transition: all 0.3s ease-in-out
  padding: 10px 14px
  &.show
    right: 0
