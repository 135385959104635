@import "src/theme/global"

.taskWrap
  width: 769px
  font-family: 'SF Pro Text', sans-serif
  color: var(--color-text-grey)
  text-align: left
  .taskName
    font-size: 20px
    font-weight: 700
    line-height: 22px
    margin-bottom: 20px
  .attributes
    display: flex
    align-items: center
    gap: 10px
    font-size: 12px
    padding-bottom: 10px
    border-bottom: 1px solid var(--color-border-dark)
    margin-bottom: 20px
  .tag
    max-width: 110px
    min-width: max-content
    padding: 0 10px
    background: var(--color-blue-light)
    text-align: center
    font-size: 10px
    line-height: 18px
    border-radius: 9px
    @include textEllipsis
  .color
    width: 18px
    height: 18px
    border-radius: 50%
  .emo
    font-size: 18px
  .subtitle
    font-size: 12px
    font-weight: 700
  .body
    height: 400px
    overflow-y: auto
    border-bottom: 1px solid var(--color-border-dark)
    margin-bottom: 5px
  .list
    padding: 0 20px
    margin: 0
  .comment, .text
    box-shadow: 0 2px 8px var(--color-gray-light)
    border-radius: 6px
    width: max-content
    max-width: 100%
    min-width: 100px
    min-height: 60px
    margin: 0 0 20px 0
    font-size: 12px
    line-height: 16px
    position: relative
    &:last-child
      margin-bottom: 0
  .comment
    padding: 8px 10px 30px
  .text
    padding: 8px 10px
    margin-left: 20px
  .email
    font-size: 12px
    margin: 0 0 10px
    span
      color: var(--color-blue-smooth)
