.container
    color: var(--DarkGreen-D600, #56716B)
    font-size: 12px
    font-weight: 400
    line-height: 16px
    padding: 8px 10px 8px 25px
    height: auto
    max-height: 120px
    min-height: 120px
    width: 165px
    overflow: hidden
    cursor: pointer
    border-radius: 6px
    background: #FFF
    position: relative
    transition: all 0.3s ease-in-out
    &:hover
        background: var(--LightBlue-B50, #E1F5FE)
        .footer, .right
            background: var(--LightBlue-B50, #E1F5FE)

.noMargin > div, .noMargin
    margin: 0.2em 0

figure.noMargin
    height: 20px
    position: relative
    &::before
        position: absolute
        top: 50%
        transform: translateY(-50%)
        content: " media"
        padding-left: 20px
        width: 100%
        height: 17px
        background-image: url('/assets/icons/playMedia.svg')
        background-size: contain
        background-repeat: no-repeat
        background-position: left center
        display: block

.flagButton, .flag
    position: absolute
    left: 0
    top: 0
    width: 28px
    height: 28px
    display: flex
    align-items: center
    justify-content: center
    z-index: 1
    border-radius: 5px

.flagButton
    background: #C3DAE0
    transition: all 0.3s ease-in-out
    &:hover
        background: var(--color-d300)

.flagList
    display: flex
    flex-wrap: wrap
    gap: 2px
    padding: 4px 8px
    svg
        cursor: pointer

.header
    position: absolute
    top: 0
    z-index: 2
    width: 100%
    height: 28px
    left: 0
    right: 0
    display: flex
    justify-content: flex-end
    align-items: center

.flagWrapper
    position: absolute
    z-index: 2
    left: 0
    top: 0

.right
    display: flex
    align-items: center
    gap: 2px
    margin-right: 5px
    background: #FFF
    transition: all 0.3s ease-in-out

.lockIcon
    fill: #6E8680
    width: 16px
    height: 16px

.color
    border-radius: 50%
    width: 14px
    height: 14px

.footer
    position: absolute
    bottom: 0
    height: 24px
    left: 10px
    right: 0
    background: #FFF
    transition: all 0.3s ease-in-out
    z-index: 1

.menuWrapper
    position: absolute
    top: 0
    right: 0

.dotsButton
    width: 28px
    height: 28px
    display: flex
    align-items: center
    justify-content: center
    z-index: 3
    transition: all 0.3s ease-in-out
    border-radius: 5px
    background: #C3DAE0
    svg
        circle
            fill: #274A42
    &:hover
        background: var(--color-d300)
        svg
            circle
                fill: #274A42
