.container
    padding: 8px 12px
    border-top: 1px solid var(--color-gallery)
    border-bottom: 1px solid var(--color-gallery)
    display: flex
    justify-content: flex-start

.emojis
    display: flex
    flex-wrap: wrap
    justify-content: space-between
    gap: 1px
    height: 28px
    overflow: hidden
    transition: all 0.3s ease-in-out
    padding: 0 1px
.emojis.open
    height: 56px

.emoji
    cursor: pointer
    width: 22px
    height: 28px
    display: flex
    align-items: center
    justify-content: center
    position: relative
    span
        position: relative
        z-index: 1
    .wrapper
        position: relative

    &.active .wrapper::before
        border-radius: 50%
        content: ''
        position: absolute
        width: 26px
        z-index: 0
        height: 26px
        left: 50%
        top: 50%
        background: #E1F5FE
        border: 2px solid var(--color-blue-smooth, #4086F1)
        transform: translate(-50%, -50%)
.button
    margin-left: 5px
    min-width: 22px
    height: 22px

.arrow
    transform: rotate(-90deg)
    transition: all 0.3s ease-in-out

.arrow.open
    transform: rotate(90deg)
