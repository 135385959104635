.wrap
  display: flex
  width: 100%
  min-width: 100%
  height: 100%
  background-color: #fff
  position: relative

.emptyAccount
  height: 100%
  width: 406px
  margin: 0 auto
  display: flex
  justify-content: center
  flex-direction: column
  gap: 16px
  & div
    justify-content: flex-start
    span
      max-width: none
.btns
  display: flex
  align-items: center
  gap: 8px
