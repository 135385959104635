@import "src/theme/colors"
  
.item
  display: flex
  align-items: center
  justify-content: space-between
  background-color: $color-b50
  border-radius: 8px
  overflow: hidden
  position: relative
  z-index: 1
  padding: 12px 16px 12px 50px
  font-family: "Roboto", sans-serif

  &:hover,
  &:active
    background-color: $color-b100

    .item__wrappBtn
      opacity: 1
      background-color: $color-b100

  &__title
    font-size: 16px
    line-height: 1.5
    color: $color-d700

  &__imgBox
    width: 48px
    height: 48px
    position: absolute
    display: flex
    align-items: center
    justify-content: center
    top: 0
    left: 0
    padding: 4px
    overflow: hidden
    border-radius: 4px

  &__img
    height: 100%
    object-fit: contain
    border-radius: 4px

  &__wrappBtn
    width: 56px
    height: 100%
    background-color: $color-b50
    position: absolute
    top: 0
    right: 0
    z-index: 2
    opacity: 0
    padding: 10px 8px
    transition-duration: 300ms

  &__btnIcon
    width: 40px
    height: 28px
    border-radius: 4px

    &:hover,
    &:active
      background-color: $color-d300

  
