
.statusBox
  border: 1px solid var(--color-border-dark)
  border-radius: 6px
  display: flex
  align-items: center
  gap: 5px
  width: max-content
  min-width: 76px
  padding: 5px
  flex-shrink: 0
  background: var(--color-white)
  .name
    flex: 1
    font-size: 10px
    line-height: 12px
.isOpen
  border-color: var(--color-blue-smooth)
.isOpenArrow
  transform: scaleY(-1)
  & path
    fill: var(--color-blue-smooth)
.statusItem
  padding: 5px 10px
  display: flex
  gap: 5px
  align-items: center
  cursor: pointer
  &:hover
    background: var(--color-blue-light)
  span:nth-child(2)
    flex: 1

.checkMark
  width: 10px
  height: 10px
  *
    fill: var(--color-blue-smooth)
