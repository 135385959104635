.wrap
  width: 360px
  .body
    padding: 12px 16px
    display: flex
    flex-direction: column
    gap: 12px
    & > div > div
      width: 100%
  .input
    height: 32px
  .footer
    padding: 12px 16px
    display: flex
    width: 100%
    justify-content: flex-end
    gap: 8px
