
.num
  display: inline-block
  min-width: 17px
  height: 17px
  text-align: center
  color: var(--color-white)
  border-radius: 9px
  font-size: 10px
  line-height: 10px
  padding: 4px
  position: relative
  flex-shrink: 0

.epicInfo
  position: absolute
  top: 120%
  left: 0
  width: max-content
  background: var(--color-white)
  border-radius: 4px
  box-shadow: 0px 2px 10px var(--color-shadow)
  color: var(--color-text-grey)
  border: 5px solid var(--color-white)
  &::before
    content: ""
    position: absolute
    width: 5px
    height: 5px
    background: var(--color-white)
    top: -7px
    left: 2px
    transform: rotate(45deg)
.hidden
  display: none

.blue
  background: var(--color-primary)
.orange
  background: var(--color-primary-orange)
.dark
  background: var(--color-secondary-light-dark)
.turquoise
  background: var(--color-scrollbar-turquoise)
