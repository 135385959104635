
.wrap
    border: 1px solid
    border-radius: 6px
    .hiddenInput
        height: 0px
        margin: 0
        padding: 0
        border: none
        overflow: hidden
        position: absolute
.itemMenu
    display: flex
    align-items: center
    padding: 2px 0
    gap: 5px
    border-bottom: 1px solid var(--color-border-dark)
    &:last-child
        border: none
.itemMenu > div
    padding: 5px 0

.circle
    width: 12px
    height: 12px
    border-radius: 50%
    margin-left: 5px
    flex-shrink: 0
.status
    font-size: 12px
    flex-grow: 1
