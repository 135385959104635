.wrap
  width: 440px
  max-width: 100%
  padding: 12px 16px

.contentWrap
  display: flex
  flex-direction: column
  gap: 24px

.topWrap
  flex: 1 1 auto
  display: flex
  flex-direction: column
  align-items: flex-start
  row-gap: 12px

.buttonsWrap
  display: flex
  justify-content: flex-end
  gap: 10px
