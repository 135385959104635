.wrapper
  display: flex
  flex-direction: column
  justify-content: space-around
  gap: 7px
  padding: 12px 0
  width: 100%
  height: 100%
  align-items: flex-start

.tools
  display: flex
  gap: 10px
  align-items: center
  width: 100%

.btns
  display: flex
  align-items: center
  gap: 8px
  margin: 0 6px
  &.btnsIsMyTasksPage
    width: 100%
    justify-content: flex-end

.delimetr
  width: 1px
  height: 28px
  background-color: var(--color-d100)

.backlogBtn
  margin-left: -20px
