
.button
  flex: 0 0 40px
  width: 40px
  height: 28px
  display: flex
  align-items: center
  justify-content: center
  background-color: var(--color-background-light)
  border: 1px solid var(--color-gallery)
  border-radius: 6px
  margin-right: 4px
  padding: 0
  transition: background-color 0.2s ease-in-out, border-color 0.2s ease-in-out
  position: relative
  &.isSmallSize
    .icon
      rect
        fill: var(--color-blue-smooth)
  &.isMediumSize
    .icon
      path
        &:nth-child(2)
          fill: var(--color-blue-smooth)
  &.isBigSize
    .icon
      path
        &:first-child
          fill: var(--color-blue-smooth)
  &:hover
    background-color: var(--color-blue-items)
    border-color: var(--color-blue-smooth)
.icon
  path,
  rect
    fill: var(--color-fill-grey)
    transition: fill 0.2s ease-in-out
