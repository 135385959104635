
.ck-toolbar
    background: var(--color-white) !important
    border: none !important
    border-bottom: 1px solid var(--color-grey-secondary) !important
    padding-bottom: 8px !important
    &__items
        gap: 6px
.ck-button, .ck-dropdown
    margin: 0 !important
.ck-button__icon path
    stroke: var(--color-text-grey-light) !important
    fill: var(--color-text-grey-light) !important
.ck-focused
    box-shadow: none !important
    border: none !important
.ck-editor__editable_inline
    & ul
        list-style-type: disc
        padding-left: 20px
    & p
        margin: 0 0 10px !important
        &:last-child
            margin-bottom: 0

.htmlContent
    &  *
        background: none !important

    & ul
        list-style-type: disc
        padding-left: 20px
    & ol
        padding-left: 20px
    & p
        margin: 0 0 10px
        &:last-child
            margin-bottom: 0
    .table
        margin: 10px 0 !important
    table
        border: 1px solid var(--color-border-dark)
        border-spacing: 3px
        border-collapse: collapse

        td, th
            border: solid 1px var(--color-border-dark)
            padding: 6px

.commentsWrap .ck-editor__editable_inline
    height: 70px !important
    padding: 10px !important

.ck-dropdown__arrow
    z-index: 0 !important
