
.wrapper
    position: relative
    width: 100%

.input
    border-radius: 8px
    border: 1px solid var(--color-text-grey-light)
    height: 50px
    background: transparent
    padding: 13px 18px 13px 18px
    font-family: "SF Pro Text", sans-serif
    font-style: normal
    font-weight: 400
    font-size: 20px
    letter-spacing: -0.0629137px
    color: var(--color-background-grey)
    width: 100%
    transition: all 0.3s ease-in-out
    position: relative
    z-index: 1
    &:focus
        border: 1px solid var(--color-c400)

    &::placeholder
        color: var(--color-border-dark-grey)
        font-family: "SF Pro Text", sans-serif
        font-style: normal
        font-weight: 400
        font-size: 14px
        letter-spacing: -0.0629137px
    &:focus
        outline: none
    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus
        -webkit-text-fill-color: var(--color-background-grey)
        -webkit-box-shadow: 0 0 0px 40rem var(--color-b950) inset
        caret-color: var(--color-white)
    &.error_input
        border: 1px solid var(--color-red-dark)

.animation_wrapper
    border-radius: 8px
    position: relative
    overflow: hidden
    &:hover
        .circle
            transition: all 0.5s linear
            opacity: 0.3
            transform: scale(170)

.circle
    background: var(--color-c400)
    position: absolute
    border-radius: 50%
    left: 25%
    top: 50%
    width: 1%
    padding-bottom: 1%
    opacity: 0
    transition: all 0.3s linear
    z-index: 0

.withIcon
    padding-left: 50px

.error
    position: absolute
    left: 10px
    bottom: -13px
    font-family: "SF Pro Text", sans-serif
    font-style: normal
    font-weight: 510
    font-size: 10px
    line-height: 100%
    color: var(--color-red-dark)

.icon
    position: absolute
    top: 50%
    transform: translateY(-50%)
    left: 15px
    z-index: 2

.eye
    position: absolute
    right: 15px
    top: 50%
    transform: translateY(-50%)
    svg > path
    fill: var(--color-white)
    z-index: 2

@-webkit-keyframes autofill
  to 
    color: inherit
    background: transparent
