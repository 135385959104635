@import "src/theme/theme"

.list
  padding: 0
  margin: 0
  font-size: 12px
  color: $color-text-grey
  font-family: "Roboto", sans-serif
  overflow-y: auto
  max-height: 250px

  &__btn
    display: inline-flex
    align-items: center
    width: 100%
    padding: 4px 12px
    text-align: left
    line-height: 1.66

    &:hover
      color: $color-blue-smooth

    & span + span
      margin-left: 8px

    & > svg
      margin-right: 4px 

  .active
    background: $color-b100

    &:hover
      color: inherit
  
  &__label
    display: inline-block
    width: 100%
    background-color: $color-n100
    padding: 0 12px
    font-weight: 500
    color: $color-plantation
    
  &__btnName
    @include cutsStr(1)

.popoverBody
  border: 0
