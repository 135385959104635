@import "src/theme/theme"

.root
  width: 440px
.body
  padding: 12px 16px
  display: flex
  flex-direction: column
  & > div
    margin-bottom: 8px

.buttonsWrapper
  margin-top: 16px
  justify-content: space-between
  display: flex
  gap: 8px
  & > button
    width: 50%
