.loaderBox
  width: 100px
  height: 100px
  flex: 0 0 100px
  margin: 0 auto
  position: relative
  align-self: flex-end
  margin-bottom: 10px
.observer
  width: 100px
  height: 100px
  flex: 0 0 100px
