.wrap
  width: 100%

.headerWrap
  width: 100%
  background: var(--DarkGreen-D50, #E7EBEA)

.header
  padding: 0 12px
  display: flex
  height: 32px
  align-items: center
  justify-content: space-between
  width: 768px
  margin: 0 auto
  color: var(--DarkGreen-D800, #274A42)
  font-size: 12px
  font-weight: 400
  line-height: 16px

.name
  width: 250px

.user
  flex-grow: 1

.settings
  display: flex
  justify-content: flex-start
  width: 150px

.wrapper
  overflow: scroll
  height: 100%
  width: 100%

.table
  width: 768px
  margin: 12px auto
  display: flex
  flex-direction: column
  gap: 6px
  height: 100%
  margin-bottom: 30px

.empty
  display: flex
  align-items: center
  justify-content: center
  min-height: 400px
