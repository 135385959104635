.header
    padding: 6px 8px
    border-radius: 4px
    background: var(--Green-G500, #99E2D0)
    color: var(--DarkGreen-D800, #274A42)
    font-size: 12px
    font-weight: 700
    line-height: 16px
    display: flex
    align-items: center
    gap: 6px

.container
    margin: 0 2px
    overflow-y: auto
    overflow-x: hidden
    max-height: 400px
    margin-bottom: 10px

.footer
    margin: 16px 14px
    display: flex
    justify-content: flex-end

.item
    display: flex
    gap: 4px
    &:hover
        .buttons
            right: 10px
        .number
            background: var(--LightBlue-B900, #01579B)
        .content, .buttons
            background: var(--LightBlue-B100, #B3E5FC)
.number
    border-radius: 4px 0px 0px 4px
    background: var(--LightBlue-B500, #03A9F4)
    transition: all 0.3s ease-in-out
    display: flex
    width: 40px
    min-width: 40px
    justify-content: center
    align-items: center
    color: var(--DarkGreen-D20, #F5F7F7)
    font-size: 16px
    font-weight: 700

.content
    border-radius: 0px 4px 4px 0px
    background: var(--Blue-B50, #E3F2FD)
    width: 100%
    display: flex
    align-items: center
    gap: 4px
    padding: 0 12px
    overflow: hidden
    transition: all 0.3s ease-in-out

    .num
        color: var(--Blue-B800, #1565C0)
        font-size: 14px
        font-weight: 700
        line-height: 20px

    .title
        color: var(--DarkGreen-D800, #274A42)
        font-size: 14px
        font-weight: 500
        line-height: 20px
        white-space: nowrap
        overflow: hidden
        text-overflow: ellipsis

.buttons
    display: flex
    align-items: center
    position: absolute
    right: -100%
    top: 50%
    transform: translateY(-50%)
    background: var(--Blue-B50, #E3F2FD)
    padding-left: 5px
    transition: all 0.3s ease-in-out

.up
    transform: rotate(90deg)

.down
    transform: rotate(-90deg)

.dragIcon
    margin-left: 10px

.empty
    height: 300px
    width: 100%
    display: flex
    align-items: center
    justify-content: center
    position: relative
