.wrap
  width: 360px
  position: relative

.body
  padding: 12px 16px
  display: flex
  flex-direction: column
  gap: 8px
  .input
    height: 32px
  .selectBox
    width: 100%
    margin-top: 8px
    button
      width: 100%
.elements
  padding: 0 16px
.footer
  display: flex
  justify-content: flex-end
  gap: 8px
  padding: 12px 16px
