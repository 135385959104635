
.container
    padding: 14px 16px
    display: flex
    justify-content: space-between
    height: 100%

.title
    background: #FCFCFE
    box-shadow: inset -0.75px 0px 0px #ECECEC, inset 0px -0.75px 0px #ECECEC, inset 0px 0.75px 0px #ECECEC
    font-weight: 700
    font-size: 15px
    line-height: 18px
    color: #49494B
    height: 60px
    padding: 16px
    display: flex
    align-items: center

.content
    width: 100%
    padding-right: 16px
