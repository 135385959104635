.chatWorkSpaceWrap
  // width: 100%
  // min-width: 100%
  // height: calc(100% - 91px)
  // min-height: calc(100% - 91px)
  // max-height: calc(100% - 91px)
  background-color: inherit
  flex-grow: 1

  .main
    display: flex
    align-items: center
    height: 100%

.chatWorkSpaceWrap.darkTheme
  .header
    border-color: #4E4E4E
