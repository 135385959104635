
.container
  padding: 6px
  margin-bottom: 8px
  display: flex
  align-items: center
  gap: 8px
  background: var(--color-d100)
  border-radius: 4px
  .imageBox
    width: 30px
    height: 30px
    object-fit: cover
    border-radius: 4px
    flex: 0 0 auto
    display: flex
  .contactContentWrapper
    flex: 1 0 auto
    display: flex
    flex-direction: column
    font-size: 12px
    .name
      font-weight: 700
      color: var(--color-d800)
      line-height: 14px
    .email
      color: var(--color-d700)
      line-height: 16px
  .clockWrap
    position: relative
    border-radius: 4px
    &.active
      background: var(--color-d300)
  .marker
    position: absolute
    top: 0
    right: 0
  .clockBtn
    padding: 6px
  .timeBox
    padding: 8px 8px 8px 16px
    background: var(--color-white)
    border-radius: 4px
    position: absolute
    width: 394px
    top: 110%
    z-index: 1
    transform: translateX(-70%)
    box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.14), 0px 3px 14px rgba(0, 0, 0, 0.12), 0px 5px 5px rgba(0, 0, 0, 0.2)

  .toggleBox
    display: flex
    align-items: center
    gap: 10px
    justify-content: space-between
    margin-bottom: 8px
  .lineFlex
    display: flex
    align-items: center
    gap: 12px
    .text
      font-size: 16px
      margin: 0
      color: var(--color-d800)
  .timeBtn
    padding: 4px
    flex: 0 0 80px
    height: 32px
    display: flex
    align-items: center
    justify-content: space-between
    flex-direction: row
    position: relative
    background: var(--color-n100)
    border-radius: 4px
    color: var(--color-n800)
    font-size: 14px
    .text, .icon
      position: absolute
      transform: translateY(-50%)
      top: 50%
      transition: right 0.3s
    .text
      right: 4px
    .icon
      right: 52px
      z-index: 1
    &.on
      & .text
        right: 36px
        transition: right 0.3s
      & .icon
        right: 4px
        transition: right 0.3s
  .time
    padding: 6px 10px 6px 12px
    display: flex
    align-items: center
    justify-content: space-between
    font-weight: 500
    font-size: 14px
    line-height: 20px
    color: var(--color-d400)
    border: 1px solid var(--color-d100)
    border-radius: 4px
    background: var(--color-d50)
    &.active
      background: var(--color-white)
      color: var(--color-d800)
  .parent
    position: relative

  .period
    width: 112px
  .date
    width: 128px
  .hours
    width: 88px
.periodList
  position: absolute
  top: 100%
  width: 100%
  z-index: 2
  background: var(--color-white)
  border-radius: 4px
  box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.14), 0px 3px 14px rgba(0, 0, 0, 0.12), 0px 5px 5px rgba(0, 0, 0, 0.2)
.periodItem
  font-size: 14px
  line-height: 22px
  color: var(--color-d800)
  padding: 0 8px
  cursor: pointer
  &:hover
    background: var(--color-b100)

.actionButton
  flex: 0 0 170px
  display: flex
  justify-content: space-between
  align-items: center
  border-radius: 4px
  padding: 4px 12px
  gap: 8px
  color: var(--color-d800)
  font-size: 12px
  line-height: 20px
  &:focus
    background: var(--color-d300)
  &:hover
    background: var(--color-d200)
  p
    margin: 0
    white-space: nowrap
  .arrow
    flex-shrink: 0
    & path
      fill: var(--color-d800)
.aceesItem
  font-size: 14px
  line-height: 22px
  padding: 9px 12px
  cursor: pointer
  &:hover
    background: var(--color-b100)
.popoverBody
  padding: 0
  overflow: hidden
