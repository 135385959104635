.container
    width: 320px
    border-right: 1px solid #CFD7D5
    min-height: 100%
    overflow: scroll
    padding: 0 0 15px

.title
    padding: 10px 6px 4px
    color: #6E8680
    font-size: 12px
    font-weight: 700
    line-height: 16px
    border-bottom: 1px solid #CFD7D5
    display: block
    margin: 0 12px
