.header
  display: flex
  justify-content: space-between
  padding: 16px
  .name
    color: var(--color-d900, #0E352C)
    font-size: 16px
    font-weight: 500
    line-height: 24px
  .amount
    color: var(--color-d600, #56716B)
    font-size: 14px
    line-height: 18px
  .row
    display: flex
    gap: 8px
    align-items: center
