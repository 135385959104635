@import "src/theme/cutsStr"
.card
  width: 128px
  height: 128px
  font: 12px/1.33 "Roboto", sans-serif
  padding: 8px

  &__head
    height: 79px
    display: flex
    flex-wrap: wrap
    gap: 2px
    margin-bottom: 8px

  &__imgBox
    width: 55px
    height: inherit
    flex: 1 1 auto
    background-color: var(--color-d50)
    border-radius: 4px
    overflow: hidden

    > img
      object-fit: contain
      height: inherit

  &__name
    color: var(--color-d700)
    @include cutsStr(2)
