@import "src/theme/global"
.block
  @include borderBottom
  &:last-child
    box-shadow: none
  .item
    display: flex
    align-items: center
    gap: 4px
    padding: 8px 12px
    color: var(--color-d800)
    font-size: 12px
    line-height: 20px
    width: 100%
    &:hover
      color: var(--color-b700)
    &.active
      background: var(--color-b50)
  .status
    width: 16px
    height: 16px
    display: flex
    align-items: center
    justify-content: center
    & > div
      width: 13px
      height: 13px
      border-radius: 50%
    .online
      background-color: #34C6A2
    .offline
      background-color: #869A95
    .busy
      background-color: #F3DE5E
    .moved_away
      background-color: #F3A413
