
.role
    padding: 4px 10px
    display: flex
    justify-content: space-between
    align-items: center
    gap: 10px
    background: var(--color-background-light)
    border: 1px solid var(--color-border-dark)
    border-radius: 6px
    font-family: "SF Pro Regular", sans-serif
    font-size: 13px
    line-height: 16px
    color: var(--color-text-grey-light)
    flex-shrink: 0
    height: 30px

    .name
        flex: 1
        text-align: left
        cursor: pointer
    .check, .noCheck
        width: 19px
        height: 19px
    .noCheck
        border-radius: 50%
        border: 1px solid var(--color-border-dark)
    .mainMenuItems
        margin: 10px 0
.select
    background: var(--color-blue-light)
    border: 1px solid var(--color-primary)
.isContext
    padding-right: 0
