
.taskWrap
    font-family: 'SF Pro Text', sans-serif
    color: var(--color-text-grey)
    text-align: left
    position: relative
    .header
        display: flex
        align-items: center
        gap: 10px
        padding: 20px
        border-bottom: 1px solid var(--color-border-dark)
        .logo
            border-radius: 2px
        .name
            font-size: 13px
            line-height: 31px
            color: var(--color-text-grey)

    .container
        display: flex
        .subTitle
            font-weight: 700
            font-size: 14px
            line-height: 20px
            margin: 20px 0 10px
        .text
            font-size: 14px
            line-height: 20px
            margin: 0 0 20px
        .contentWrap
            width: 820px
        .content
            padding: 20px 30px 10px
            height: 400px
            border-bottom: 1px solid var(--color-border-dark)
            overflow-y: auto
            margin-bottom: 8px
            .taskName
                font-weight: 700
                font-size: 20px
                line-height: 22px
                margin: 0 0 20px
            .attributes
                display: flex
                gap: 10px
                align-items: center
                margin-bottom: 20px
                .tag
                    color: var(--color-blue-smooth)
                    background: var(--color-blue-light)
                    border-radius: 10px
                    font-size: 13px
                    line-height: 17px
                    padding: 0 10px
                .color
                    width: 20px
                    height: 20px
                    border-radius: 50%

                .textBlue, .text
                    font-size: 12px
                .textBlue
                    color: var(--color-blue-smooth)
            .childrenBox
                position: relative
                display: flex
                align-items: center
                gap: 6px

                .children
                    position: absolute
                    right: -20px
                    top: calc(100% + 10px)
                    &::before, &::after
                        content: ""
                        position: absolute
                        width: 10px
                        height: 10px
                        transform: rotate(45deg)
                        top: -6px
                        right: 20px
                        z-index: -1
                        box-shadow: 0 1px 5px var(--color-shadow)
                        background: var(--color-white)
                    &::after
                        z-index: 1
                        box-shadow: none
                    .childrenScroll
                        width: 200px
                        padding: 8px
                        max-height: 316px
                        overflow-y: auto
                        box-shadow: 0 1px 5px var(--color-shadow)
                        background: var(--color-white)
                        & > div
                            margin-bottom: 10px

                & span
                    margin: 0
            .list
                padding: 0 36px
                margin: 0
                .comment
                    box-shadow: 0 2px 8px var(--color-gray-light)
                    border-radius: 6px
                    width: max-content
                    max-width: 100%
                    min-width: 100px
                    min-height: 60px
                    padding: 8px 10px 30px
                    margin: 0 0 20px 0
                    font-size: 12px
                    line-height: 16px
                    position: relative
                    &:last-child
                        margin: 0
                    .btns
                        position: absolute
                        right: 1px
                        bottom: 1px
                        z-index: 1
                        display: flex
                        gap: 5px
                    .icon path
                        fill: var(--color-red-dark)

        .info
            flex: 0 1 auto
            width: 250px
            padding-top: 20px
            display: flex
            flex-direction: column
            justify-content: space-between
            border-left: 1px solid var(--color-border-dark)

            .select
                height: 40px
                background: var(--color-blue-smooth)
                border: none
                margin-bottom: 20px
                border-radius: 6px

                & > div
                    background: var(--color-white)

                & > div > div
                    border-radius: 6px
                    background: var(--color-blue-smooth)
                & > div > div > span
                    color: var(--color-white)
                & > div  > span
                    border-top: 5px solid var(--color-white)
            .selectDiv
                color: var(--color-white)
                text-align: center
                line-height: 40px
                font-size: 14px

            & >div:first-child
                padding: 0 20px
            .btnBox
                margin-top: 15px

            .sprint
                display: flex
                gap: 10px
                .date
                    flex: 1
                    background: var(--color-background-light)
                    border: 1px solid var(--color-border-dark)
                    border-radius: 6px
                    padding: 10px
                    text-align: center
                    font-size: 13px
                    line-height: 16px

            .settings
                display: flex
                flex-direction: column
                .addTask
                    position: relative
                    & > p
                        position: absolute
                        right: 102%
                        top: -39px
                        width: max-content
                        margin: 0
                        font-size: 13px
                        border: 1px solid var(--color-border-dark)
                        background: var(--color-background-light)
                        padding: 4px 8px
                        border-radius: 6px
                        cursor: pointer

                .settingsEdit
                    border-top: 1px solid var(--color-border-dark)
                    padding: 24px 0
                    display: flex
                    gap: 10px
                    align-items: center
                    justify-content: center
                    cursor: pointer
                .settingsIcon
                    width: 22px
                    height: 22px
                .settingsIcon path
                    fill: var(--color-text-grey-light)
    .plusIcon
        width: 15px
        height: 15px
    .addFileBlock
        position: absolute
        bottom: 0
        left: 0
        margin: 10px 0
        padding: 12.5px 20px
        border-right: 1px solid var(--color-border-dark)
        cursor: pointer
        font-size: 10px
        .addFileIcon
            width: 20px
            height: 20px

        .addFileMenu
            display: none
            position: absolute
            top: -60px
            left: 6px
            width: max-content
            background: var(--color-white)
            padding: 7px 0
            box-shadow: 0 1px 5px var(--color-shadow)

            .item
                display: flex
                align-items: center
                gap: 10px
                padding: 5px
                line-height: 14px

            .item:hover
                background: var(--color-blue-light)
            &::before, &::after
                content: ""
                position: absolute
                width: 10px
                height: 10px
                transform: rotate(45deg)
                bottom: -4px
                left: 20px
                z-index: -1
                box-shadow: 0 1px 5px var(--color-shadow)
                background: var(--color-white)
            &::after
                z-index: 1
                box-shadow: none
            .fileIcon
                width: 11px
                height: 14px
                & path
                    fill: var(--color-blue-smooth)
    .addFileBlock:hover .addFileMenu
        display: block
.hidden
    display: none
.files
    display: flex
    align-items: center
    span
        display: flex
        align-items: center
        font-size: 12px
        margin-left: 5px
        margin-bottom: 5px

    .downloadAll
        display: flex
        align-items: center
        background: var(--color-blue-smooth)
        border-radius: 20px
        padding: 4px 5px
        cursor: pointer
        color: var(--color-white)
        transition: all 0.3s ease
        font-size: 12px
        &:hover
            background: var(--color-secondary)
            transition: all 0.3s ease
        p
            margin: 0
            line-height: 12px
    & > div:nth-child(3)
        flex: 1 0
