
.root
  width: 460px
  max-height: 500px
  display: flex
  flex-direction: column
  padding: 30px 20px 15px
  position: relative
.content
  height: 100%
  display: flex
  flex-direction: column
.titleWrapper
  margin-bottom: 25px
.title
  width: 100%
  max-width: 330px
  font-family: 'SF Pro Text', sans-serif
  font-weight: 500
  font-size: 18px
  line-height: 21px
  letter-spacing: -0.08px
  color: var(--color-text-dark)
  text-align: center
  margin: 0 auto
.categoryList
  display: flex
  align-items: stretch
  column-gap: 5px
  margin-bottom: 10px
  &.addMarginBottom
    margin-bottom: 20px
.categoryItem
  width: 100%
.categoryButton
  width: 100%
  height: 30px
  display: flex
  align-items: center
  justify-content: center
  font-family: 'SF Pro Text', sans-serif
  font-weight: 400
  font-size: 12px
  line-height: 14px
  color: var(--color-text-dark)
  background-color: var(--color-background-light)
  border: 1px solid var(--color-gallery)
  border-radius: 6px
  padding: 0 5px
  &.selected
    background-color: var(--color-blue-items)
    border-color: var(--color-blue-smooth)
  &:hover
    background-color: var(--color-blue-items)
.categoriesWrapper
  height: 100%
  display: flex
  flex-direction: column
  overflow-y: auto
  overflow-x: hidden
  padding-right: 5px
