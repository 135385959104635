// General colors
$color-white: white
$color-black: black
$color-red: #FF0000
$color-white-transparent-20: rgba(255, 255, 255, 0.2)
$color-grey-transparent-40: rgba(231, 235, 234, 0.4)
$color-grey-green-transparent-16: rgba(39, 74, 66, 0.16)
$color-border-grey: #e5e5e5
$color-border-dark: #E3E3E3
$color-white-lilac: #FCFCFE
$color-text-dark: #2B353E
$color-text-grey: #49494B
$color-text-grey-light: #747474
$color-text-grey-medium: #5D5D5D
$color-text-scorpion: #5B5B5B
$color-text-scorpion-darker: #5B5B5B
$color-shadow: #DEDEDE
$color-background-grey: #F5F5F5
$color-background-alabaster: #F8F8F8
$color-background-alabaster-lighter: #F7F7F7
$color-gallery: #F0F0F0
$color-background-green-light: #EBF7E8
$color-background-blue-light: #E6EDF8
$color-background-orange: #FC7A7A
$color-background-danger: #AF4E4E
$color-background-orange-medium: #EF9E24
$color-grey: #bab8b8
$color-grey-secondary: #dfdfdf
$color-icon: #B6B6B6
$color-alto: #CFCFCF
$color-abbey: #4F555B
$color-periwinkle-gray: #D5DFEF
$color-alto-darker: #D8D8D8
$color-silver-chalice: #B0B0B0
$color-silver-chalice-darker: #AEAEAE
$color-gray: #818181
$color-dove-gray: #686868
$color-grey_dark: #7c7a7a
$color-grey_medium: #858585
$color-gray-light: #c1c0c0
$color-background-gray: #8C8C8C
$color-background-gray_dark: #5A5A5A
$color-background-light: #F9F9F9
$color-border-dark-grey: #888888
$color-fill-grey: #ababab
$color-blue-opacity: rgba(64, 134, 241, 0.1)
$color-note-gray: #4f4f4f
$color-red-extraLight: #fedbe2
$color-error: #e02255
$color-red-dark: #D72419
$color-maximum-red: #D61F1F
$color-red-extradark: #DB5555
$color-valencia: #D94A4A
$color-green: #08C326
$color-green-light: #5eb42f
$color-green-extralight: #ddeed4
$color-green-dark: #08ab23
$color-green-opacity: rgba(8, 171, 35, 0.5)
$color-yellow: #EFBB45
$color-corn: #F3B505
$color-background-chat: #2C2C2C
$color-blue-items: #ECF3FE
$color-input-background: #F9F9F9
$color-scorpion: #606060
$color-purple: #8D27BD
$color-aqua: #26C0CA
$color-granny-smith: #869A95
$color-plantation: #274A42
$color-sail: #BBDEFB
$color-curious-blue: #BBDEFB

//Blue theme colors
$color-primary: #477DE1
$color-secondary: #3b6dcb
$color-blue-smooth: #4086F1
$color-blue-dark: #0A3AAC
$color-scrollbar: #4086F1
$color-scrollbar-dark: #0A3AAB
$color-linear: linear-gradient(180deg, #4543F7 0%, #0A3AAB 100%)
$color-linear-message: linear-gradient(180deg, #4086F1 0%, #2565C8 99%)
$color-blue-light: #EBF2FD
$color-blue-lightest: #DFECFF
$color-blue-deleted: #C3D6F5
$color-blue-border: #C3D6F5
$color-blue-border-side-menu: #669EF4
$color-blue-border-inner-side-menu: #4086F1
$color-blue-background: #3B42EA

//Orange theme colors
//TODO - Colors needs to be changed
$color-primary-orange: orange
$color-secondary-orange: #c48b23
$color-secondary-dark-orange: #EA4631
$color-scrollbar-orange: orange
$color-scrollbar-dark-orange: #c48b23
$color-linear-orange: linear-gradient(180deg, #EA7D30 0%, #EA4631 100%)
$color-orange-border: #D05F18
$color-orange-border-secondary: #EB7830
$color-orange-smooth: #D25E15

//Turquoise theme colors
$color-primary-turquoise: #477DE1
$color-secondary-turquoise: #3b6dcb
$color-secondary-dark-turquoise: #18697C
$color-blue-light-turquoise: #4086F1
$color-scrollbar-turquoise: #10AADD
$color-scrollbar-dark-turquoise: #18697C
$color-linear-turquoise: linear-gradient(180deg, #10AADD 0%, #18697C 100%)
$color-turquoise-border: #41B1D4
$color-turquoise-smooth: #0280A8

//Dark theme colors
$color-primary-dark: #477DE1
$color-secondary-dark: #3b6dcb
$color-secondary-light-dark: #3F3F3F
$color-blue-light-dark: #4086F1
$color-scrollbar-dark: #3F3F3F
$color-scrollbar-dark-dark: #222222
$color-linear-dark: linear-gradient(180deg, #222222 0%, #3F3F3F 100%)
$color-dark-border: #4E4E4E
$color-dark-smooth: #454545
$color-dark-background: #323232
// new-designer
$color-n100: #EAEDED
$color-n200: #D5DBDA
$color-n800: #425D5A

$color-d40: #f1f3f2
$color-d50: #E7EBEA
$color-d100: #CFD7D5
$color-d200: #B6C2BF
$color-d300: #9FAEAB
$color-d400: #869A95
$color-d500: #6E8680
$color-d600: #56716B
$color-d700: #3E5D56
$color-d800: #274A42
$color-d900: #0E352C

$color-b40: #ecf8fe
$color-b50: #E3F2FD
$color-b100: #BBDEFB
$color-b200: #90CAF9
$color-b300: #64B5F6
$color-b400: #42A5F5
$color-b500: #2196F3
$color-b600: #1E88E5
$color-b700: #1976D2
$color-b800: #1565C0
$color-b900: #0D47A1

$color-g500: #99E2D0
$color-g800: #34C6A2
$color-g900: #2FB292

$color-y600: #F3DE5E
