.container
  width: 670px
  height: 450px
  display: flex

.block
  width: 50%
  padding: 16px
  display: flex
  flex-direction: column

  &:first-child
    background: #F5F7F7

.head
  display: flex
  justify-content: space-between

.title
  display: flex
  flex-direction: column
  flex-grow: 1
  padding-left: 8px
  justify-content: space-evenly
  h6
    font-family: 'Roboto'
    font-style: normal
    font-weight: 500
    font-size: 24px
    line-height: 28px
    margin-bottom: 0
    color: #0E352C
    opacity: 0.8

  span
    font-family: 'Roboto'
    font-style: normal
    font-weight: 400
    font-size: 14px
    line-height: 20px
    color: #274A42
    opacity: 0.8

.prices
  display: flex
  flex-direction: column

.sum
  margin-top: 16px
  div
    display: flex
    justify-content: space-between
    padding-top: 6px
    font-family: 'Roboto'
    font-style: normal
    font-weight: 400
    font-size: 14px
    line-height: 20px
    color: #274A42

  div:last-child
    font-family: 'Roboto'
    font-style: normal
    font-weight: 700
    font-size: 14px
    line-height: 20px
    text-align: center
    color: #0E352C
    border-top: 1px solid #B6C2BF
    padding-top: 16px
    margin-top: 16px

.rightTitle
  font-family: 'Roboto'
  font-style: normal
  font-weight: 500
  font-size: 12px
  line-height: 16px
  color: #56716B
  padding: 2px 8px
  display: inline
  background: rgba(39, 74, 66, 0.16)
  border-radius: 4px
  width: fit-content

.payment
  display: flex
  flex-direction: column
  margin-top: 12px

.agreement
  margin-top: 12px
  display: flex
  align-items: center
  font-family: 'Roboto'
  font-style: normal
  font-weight: 400
  font-size: 12px
  line-height: 19px
  color: #274A42
  a
    color: #0288D1
    margin-left: 3px

.checkbox
  cursor: pointer
  margin-right: 6px

.button
  margin-top: 12px
  font-family: 'Roboto'
  font-style: normal
  font-weight: 400
  font-size: 14px
  line-height: 20px
  width: fit-content
  padding: 8px 16px
  align-self: flex-end
  svg
    margin-right: 5px

.select
  margin-top: 20px
  position: relative

.tip
  position: absolute
  z-index: 1
  left: 7px
  top: -5px
  padding: 0px 4px
  background: #FFFFFF
  border-radius: 2px
  font-family: 'Roboto'
  font-style: normal
  font-weight: 400
  font-size: 12px
  line-height: 10px
  color: #869A95

.goBack
  padding: 2px 8px
  display: inline
  background: rgba(39, 74, 66, 0.16)
  border-radius: 4px
  width: fit-content
  color: #274A42
  font-family: Roboto
  font-size: 12px
  font-style: normal
  font-weight: 500
  line-height: 16px
  cursor: pointer
  display: flex
  align-items: center
  margin-bottom: 12px
  svg
    fill: #274A42
    margin-right: 4px
    height: 10px

.info
  padding: 6px 12px 6px 8px
  border-radius: 4px
  background: #99E2D0
  display: flex
  margin: 0 0 12px
  &:nth-child(3)
    background: #E7EBEA
    flex-wrap: wrap
  span
    color: #274A42
    font-family: Roboto
    font-size: 12px
    font-style: normal
    font-weight: 400
    line-height: 16px
    strong
      margin-right: 4px
  svg
    margin-right: 6px
