
.wrap
  display: flex
  align-items: center
  border-top: 1px solid var(--color-border-dark)
  border-bottom: 1px solid var(--color-border-dark)

  .usersBlock
    flex: 1 1 auto
    border-right: 1px solid var(--color-border-dark)
  .serviceBlock
    display: flex
    gap: 10px
    justify-content: space-between
    flex: 0 0 auto
    padding: 0 20px
