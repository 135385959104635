.arrow
  transform: rotate(-90deg)
  flex-shrink: 0
.arrowOpen

.select
  padding: 8px

.item
  color: var(--color-d800)
  font-size: 12px
  font-style: normal
  font-weight: 400
  line-height: 20px
  display: flex
  gap: 4px
  margin-bottom: 4px
  & > div
    height: auto
  & > button
    flex-shrink: 0
.times
  display: flex
  flex-wrap: wrap
  gap: 4px
  justify-content: space-between
  & > .btn
    flex: 1 0 30%

.label
  &:hover, &.active
    background: var(--color-b100)
