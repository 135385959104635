.block
  display: flex
  gap: 12px
  margin-top: 8px
.comment
  position: relative
  flex: 1 1 auto
  &:hover .actions
    display: flex

.actions
  display: none
  gap: 8px
  position: absolute
  top: 0
  right: 0
  background: #fff
.title
  color: var(--DarkGreen-D800, #274A42)
  font-size: 12px
  font-weight: 700
  line-height: 16px
  .date
    color: var(--color-b700)
    font-weight: 400
.text
  a
    color: var(--Blue-B700, #1976D2)
  ul, li, ol
    list-style: disc
  ul, ol
    padding-left: 6px
  li
    margin-left: 10px
.fileList
  display: flex
  gap: 8px
  flex-wrap: nowrap
  overflow: auto
  max-width: 100%
  padding: 12px 0
.loaderBox
  position: absolute
  width: 100%
  height: 100%
  top: 0
  left: 0
  backdrop-filter: blur(2px)
  z-index: 1
