.arrow
  margin-top: 4px
  transform: rotate(-90deg)
  flex-shrink: 0
.arrowOpen
  transform: rotate(90deg)

.item
  padding: 8px 12px
  color: var(--color-d800)
  font-size: 12px
  font-style: normal
  font-weight: 400
  line-height: 20px
  cursor: pointer
  display: flex
  gap: 4px
  align-items: center
  &:hover
    color: var(--color-b800)

.label
  align-items: flex-start !important
  &:hover, &.active
    background: var(--color-b100)
.date
  text-align: end
  flex-grow: 1
