@import "src/theme/global"
.btn
  display: block

.popoverRoot
  overflow: hidden
  z-index: 10009
  @include boxShadow

  &:global(.bs-popover-top)
    width: 100%
    max-width: 250px
    border: 1px solid var(--color-gallery)
    \:global(.popover-arrow)
      &::after
        border-top-color: var(--color-blue-items)

  &:global(.bs-popover-bottom)
    width: 100%
    max-width: 250px
    border: 1px solid var(--color-gallery)
    \:global(.popover-arrow)
      &::after
        border-bottom-color: var(--color-blue-items)

  &:global(.bs-popover-start)
    width: 100%
    max-width: 250px
    border: 1px solid var(--color-gallery)
    \:global(.popover-arrow)
      &::after
        border-left-color: var(--color-blue-items)

  &:global(.bs-popover-end)
    width: 100%
    max-width: 250px
    border: 1px solid var(--color-gallery)
    \:global(.popover-arrow)
      &::after
        border-right-color: var(--color-blue-items)
.popoverHeader
  &:global(.popover-header)
    font-weight: 600
    font-size: 11px
    line-height: 13px
    letter-spacing: -0.06px
    color: var(--color-text-grey)
    background-color: var(--color-blue-items)
    border-bottom: 1px solid var(--color-gallery)
    padding: 10px 10px 8px
.popoverBody
  &:global(.popover-body)
    padding: 0
    border-radius: 8px
