
@keyframes warning
  0%
    opacity: 0
  100%
    opacity: 1

@keyframes showPopup
  0%
    width: 10px
    height: 10px
    background: white
    opacity: 0
  99%
    width: 450px
    height: 350px
    opacity: 0

.successWrap
  display: flex
  align-items: flex-start
  gap: 16px
  width: 400px
  min-height: 124px
  padding: 16px 20px 20px
  animation: showPopup 0.2s ease-in-out
  font-family: 'Roboto', sans-serif

  .title
    font-weight: 600
    font-size: 18px
    line-height: 20px
    color: var(--color-text-dark)
    margin-bottom: 6px
  .check
    flex-shrink: 0
    width: 72px
    height: 72px
    animation: warning 2s ease-in-out 0.2s
  .text
    margin: 0 0 16px
    font-size: 12px
    line-height: 16px
    color: var(--color-abbey)
  .buttonsWrap
    display: flex
    gap: 10px
  .button
    min-width: 68px
    height: 26px
    padding: 6px
    font-size: 12px
