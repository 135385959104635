
.wrap
  width: 360px
  .body
    padding: 16px 16px 12px
  .text
    color: var(--color-d700)
    font-family: Roboto
    font-size: 12px
    font-style: normal
    font-weight: 400
    line-height: 16px
    margin: 0 0 8px
  .select
    padding: 4px
    border: 1px solid var(--color-d100)
    border-radius: 4px
    position: relative
    display: flex
    gap: 5px
  .btns
    padding-top: 12px
    display: flex
    justify-content: space-between
    div
      display: flex
      gap: 10px
