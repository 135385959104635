.container
    display: flex
    align-items: flex-start
    padding: 10px 0 0 16px
    flex-direction: column
    overflow: hidden
    border-radius: 8px
    height: 100%

.header
    display: flex
    justify-content: space-between
    align-items: center
    width: 100%
    padding-right: 16px

.emptyWrapper
    padding-right: 16px
    width: 100%
    height: 100%
    display: flex
    align-items: center
    justify-content: center

.title
    color: var(--light-blue-b-700, #0288D1)
    font-family: Roboto
    font-size: 16px
    font-weight: 700
    line-height: 24px

.content
    display: flex
    align-items: flex-start
    padding: 10px 0 0 0
    flex-direction: column
    border-radius: 8px
    height: 100%
    width: 100%
    .list
        width: 100%
        display: flex
        flex-wrap: wrap
        gap: 20px
        overflow: auto
        flex-wrap: nowrap
        padding-bottom: 19px
        padding-right: 16px
