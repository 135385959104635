@import "src/theme/global"
.taskBox
  border-radius: 6px
  border: 1px solid
  padding: 10px
  margin: 10px 0
  .top
    display: flex
    align-items: center
    gap: 5px
  .attr
    display: flex
    align-items: center
    gap: 5px
    justify-content: flex-end
    flex-grow: 1
  .num
    min-width: 17px
    height: 17px
    border-radius: 50%
    line-height: 17px
    font-size: 10px
    text-align: center
    color: var(--color-white)
  .tag
    max-width: 110px
    min-width: max-content
    padding: 0 10px
    background: var(--color-white)
    text-align: center
    font-size: 10px
    line-height: 18px
    border-radius: 9px
    border: 1px solid
    @include textEllipsis

  .color
    width: 14px
    height: 14px
    border-radius: 50%
    flex-shrink: 0
  .emo
    font-size: 14px
  .commentBox
    position: relative
    width: 16px
    height: 16px
    display: flex
    flex-shrink: 0
    svg
      width: 100%
      height: 100%
  .isComment:before
    content: ""
    position: absolute
    top: 0
    right: 0
    width: 5px
    height: 5px
    border-radius: 50%
    background: var(--color-red-dark)
  .name
    display: flex
    gap: 10px
    padding: 10px 0
    margin-bottom: 10px
    border-bottom: 1px solid
    .fireIcon
      flex-shrink: 0
    span
      font-size: 12px
      font-weight: 700
      @include textEllipsis

  .subtitle
    color: var(--color-blue-smooth)
    margin: 0
    font-size: 10px
  .text
    font-size: 10px
    display: -webkit-box
    max-width: 400px
    -webkit-line-clamp: 2
    -webkit-box-orient: vertical
    overflow: hidden
    text-overflow: ellipsis
    p
      margin: 0
  .bodyItem
    display: flex
    align-items: center
    gap: 10px
    justify-content: space-between
    margin-bottom: 10px
    &:last-child
      margin: 0
  .sprint
    font-size: 9px
    line-height: 18px
    border-radius: 9px
    width: 120px
    padding: 0 5px
    border: 1px solid
    background: var(--color-white)
