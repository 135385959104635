.wrap
  width: 440px
  max-height: 100%
.body
  padding: 12px 16px
  .tabBlock
    padding-bottom: 16px
  .form
    padding-bottom: 8px
    max-height: calc(100vh - 280px)
    overflow-y: auto

  .btns
    padding-top: 16px
    display: flex
    justify-content: flex-end
    gap: 8px
.repeateBlock
  margin-bottom: 8px
  padding: 6px 12px 6px 8px
  display: flex
  gap: 4px
  background-color: var(--color-y300, #F8EDA9)
  border-radius: 4px
  .svgBlock
    flex-shrink: 0
    width: 24px
    height: 24px
    display: flex
    flex-direction: column
    align-items: center
    justify-content: center
  .text
    flex-grow: 1
