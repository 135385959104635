
.labelIcon
  font-size: 12px
  color: var(--color-700)
  line-height: 0.83
  margin-bottom: 4px

.iconBlock
  display: flex
  gap: 8px
  
.inputName
  width: 408px
  max-width: 100%
  height: 32px