
.titleCell
    display: flex
    border-left: 1px solid #E7EBEA
    width: 18%
    height: 100%
    display: flex
    align-items: center
    justify-content: center
    color: var(--main-green-2, #33A333)
    font-family: Roboto
    font-size: 32px
    font-style: normal
    font-weight: 700
    line-height: 32px
    flex-direction: column

    sup
        color: var(--main-green-2, #33A333)
        font-family: Roboto
        font-size: 16px
        font-style: normal
        font-weight: 700
        line-height: 24px
        top: -10px

    &:nth-child(2)
        color: var(--dark-green-d-700, #3E5D56)
        font-family: Roboto
        font-size: 18px
        font-style: normal
        font-weight: 300
        line-height: 32px

.discount
    color: var(--main-red, #F00)
    font-family: Roboto
    font-size: 20px
    font-style: normal
    font-weight: 400
    line-height: 24px
    sup
        color: var(--main-red, #F00)
        font-family: Roboto
        font-size: 12px
        font-style: normal
        font-weight: 400
        line-height: 18px
        top: -5px
