.item
  padding: 8px 12px
  color: var(--DarkGreen-D800, #274A42)
  font-size: 12px
  line-height: 20px
  cursor: pointer
  &:hover
    background: var(--color-b50)
.border
  border-bottom: 1px solid var(--color-d100)
.dependence
  display: flex
  gap: 12px
  align-items: center

  position: relative
  padding: 6px
  &:hover
    background: var(--color-b50)
    .btn
      display: flex
  .name
    flex-grow: 1
    color: var(--DarkGreen-D700, #3E5D56)
    font-size: 12px
    font-weight: 500
    line-height: 16px
    min-width: 0
    white-space: nowrap
    text-overflow: ellipsis
    overflow: hidden
    cursor: pointer

    .num
      color: var(--color-b800)

  .btn
    display: none
    flex-shrink: 0
