
.wrap
    width: 410px
    padding-top: 10px
    font-family: "SF Pro Regular", sans-serif
    text-align: left
    .subTitle, .error
        font-size: 13px
        line-height: 16px
        color: var(--color-text-grey)
    .selectBlock
        position: relative
        margin: 8px 0 20px
        display: flex
        align-items: center
        background: var(--color-background-light)
        border: 1px solid var(--color-border-dark)
        border-radius: 6px
        padding: 9px 20px
        font-family: "SF Pro Regular", sans-serif
        font-size: 13px
        line-height: 16px
        color: var(--color-text-grey)
        gap: 10px
    .selectList
        position: absolute
        top: calc(100% - 4px)
        left: -1px
        z-index: 3
        background: var(--color-white)
        width: calc(100% + 2px)
        padding: 4px 0
        border: 1px solid var(--color-border-dark)
        border-radius: 0 0 6px 6px
        border-top: none
        max-height: 148px
        overflow: auto

        .item
            padding: 4px 10px
            cursor: pointer
            &:hover
                background: var(--color-blue-light)

    .input
        background: inherit
        border: none
        flex-grow: 1

    .text
        margin: 0 0 27px
        padding: 0 10px
        text-align: center
        font-size: 13px
        line-height: 16px
        color: var(--color-text-grey)

    .btns
        display: flex
        justify-content: center
        align-items: center
        gap: 10px
        padding-top: 30px
