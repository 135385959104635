.box
    display: flex
    justify-content: space-between
    gap: 5px
    margin-bottom: 6px

.currentSize
    color: var(--color-d800)
    font-size: 14px
    font-weight: 500
    line-height: 1.14

.total
   color: var(--color-d600)
   font-size: 12px
   line-height: 1.33