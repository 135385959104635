@import src/theme/global

.root
  display: flex
  align-items: center
  justify-content: center
  position: relative
  svg
    flex: 0 0 auto
    width: inherit
    height: inherit
  &.increaseArea
    @include clickAreaEnlargement(200%, 200%)
