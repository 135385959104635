.wrap
  width: 100%
  padding: 16px 0
  display: flex
  align-items: center
  justify-content: space-between

.search
  display: flex
  align-items: center
  gap: 8px
