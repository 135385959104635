
.block
    padding: 5px

.taskBox
    background: var(--color-background-light)
    margin-bottom: 10px
    &:last-child
        margin-bottom: 0
.arrowBoxTop, .arrowBoxBottom
    border: 1px solid
    width: 90%
    margin: 0 auto
    padding: 5px

.arrowBoxTop
    border-bottom: none
    border-radius: 6px 6px 0 0

.arrowBoxBottom
    border-radius: 0 0 6px 6px
    border-top: none
    text-align: center
    font-size: 10px
    font-family: "SF Pro Text", sans-serif

.userBox
    display: flex
    align-items: center
    justify-content: space-between
    gap: 5px
    margin-bottom: 10px
    font-size: 10px
    font-family: "SF Pro Text", sans-serif

    div
        padding: 0
        font-size: 10px
    & > div > div
        max-width: 120px

    & > div:last-child
        flex: 0 0 auto
        margin-right: 5px
