
.button
  flex: 1 1 82px
  width: 80px
  height: 24px
  border: 1px solid var(--color-border-dark)
  border-radius: 6px
  display: flex
  flex-direction: column
  align-items: center
  justify-content: center
  &:hover:not(:disabled)
    border-color: var(--color-blue-smooth)
    .default
      *
        fill: var(--color-blue-smooth)

.wrap
  width: 250px
  padding: 10px
  margin: 0
  position: relative
  display: flex
  flex-wrap: wrap
  gap: 3px 2px
  padding-right: 30px

.closeBtn
  display: flex
  align-items: center
  justify-content: center
  position: absolute
  top: 12px
  right: 15px
  width: 22px
  height: 22px
  border-radius: 50%
  background: var(--color-blue-items)
  *
    width: 10px
    height: 10px
    fill: var(--color-icon)
  &:hover
    *
      fill: var(--color-blue-smooth)
.color
  border-radius: 50%
  cursor: pointer
  border: 2px solid #ffffff00
  span
    display: block
    border-radius: 50%
    width: 20px
    height: 20px
    border: 2px solid var(--color-white, #fff)
  &.active
    border: 2px solid var(--color-blue-smooth, #4086F1)

.selected
  background: var(--color-blue-light)
  border-color: var(--color-blue-smooth)
