
.addedDateText
  align-self: flex-end
  font-family: 'SF Pro Text', sans-serif
  font-weight: 400
  font-size: 11px
  line-height: 13px
  color: var(--color-border-dark-grey)
  white-space: nowrap
  margin-right: 10px
  user-select: none
