@import "src/theme/global"
.wrap
  border-radius: 10px
  border: 1px solid
  margin-bottom: 10px
  overflow: hidden
  &:last-child
    margin-bottom: 0
.task
  border-color: var(--color-blue-smooth)
  .header
    *
      fill: var(--color-blue-smooth)
.meetings
  border-color: var(--color-purple)
  .header
    *
      fill: var(--color-purple)
.calls
  border-color: var(--color-green)
  .header
    *
      fill: var(--color-green)
.mails
  border-color: var(--color-yellow)
  .header
    *
      fill: var(--color-yellow)

.header
  display: flex
  align-items: center
  justify-content: space-between
  padding: 10px
  border-bottom: 1px solid var(--color-border-dark)
  font-size: 13px
  font-family: "SF Pro Text", sans-serif
  .block
    display: flex
    align-items: center
    gap: 10px
  .addIcon
    width: 18px
    height: 18px
  .taskIcon
    width: 15px
    height: 15px
  .arrowBtn
    width: 10px
    height: 10px
    display: flex
    cursor: pointer
    position: relative
    flex-direction: column
    align-items: center
    justify-content: center
    padding: 0
    @include clickAreaEnlargement(200%, 200%)
  .open
    transform: scaleY(-1)
.amount
  padding: 9px
  text-align: center
  background: var(--color-blue-light)
  font-size: 10px
  font-family: "Sf Pro Text", sans-serif
  font-weight: 700
.taskList
  margin: 0
  padding: 10px
.full
  height: calc(100% - 10px)
  .taskList
    max-height: calc(100% - 45px - 32px)
    overflow-y: auto
.grid
  height: 100%
