
.callRoom
  position: relative
  display: flex
  flex-direction: column
  align-items: center
  justify-content: flex-start
  width: 98vw
  height: 96vh
  max-width: 98vw
  max-height: 96vh
  color: var(--color-white)
  background: var(--color-background-chat)

  .backgroundImage
    position: absolute
    width: 100%
    height: 100%
    filter: blur(3px)
    -webkit-filter: blur(3px)
    background-size: cover
    z-index: -1
    background: var(--color-grey_medium)
    object-fit: cover

  .cross
    position: absolute
    top: 20px
    right: 11px
    width: 18px
    height: 2px
    background: #a7a4a4
    transform: rotate(45deg)
    cursor: pointer
    &:before
      content: ''
      position: absolute
      top: 0
      right: 0
      width: 18px
      height: 2px
      background: #a7a4a4
      transform: rotate(90deg)

  .receiver
    font-family: "SF Pro Medium", sans-serif
    font-size: 18px
    margin-top: 50px
    position: relative
    bottom: 50px
    z-index: 2

  .avatarWrap
    padding: 5px
    border-radius: 50%
    border: 1px solid var(--color-white)
    margin-bottom: 20px
    position: relative
    z-index: 2
  .avatar
    width: 80px
    height: 80px
    background-size: cover
    object-fit: cover
    border-radius: 50%

  .stopwatch
    margin-bottom: 30px
    text-shadow: 1px 1px 1px var(--color-text-dark)
    position: relative
    z-index: 2

  .buttonsPanel
    display: flex
    align-items: center
    width: 55%
    justify-content: space-between
    position: absolute
    bottom: 50px
    z-index: 2
    .cameraIcon
      path, polygon
        fill: var(--color-white)
    .slash
      position: absolute
      width: 80%
      height: 2px
      background: var(--color-white)
      transform: rotateZ(-45deg)
    .phoneIcon
      margin-left: 3px
  .centeredButton
    justify-content: center
  .buttonsPanelIncomingCall
    display: flex
    align-items: center
    width: 40%
    justify-content: space-between
    margin-top: 50px
    position: relative
    z-index: 2
    .phoneAcceptIcon
      path
        fill: var(--color-white)
.videoCallRoom
  position: relative
  display: flex
  flex-direction: column
  align-items: center
  justify-content: center
  width: 98vw
  height: 96vh
  max-width: 98vw
  max-height: 96vh
  color: var(--color-white)
  .videoWrap
    position: absolute
    top: 0
    width: 100%
    height: 85%

    .videoInnerWrap
      display: flex
      align-items: center
      justify-content: center
      flex-wrap: nowrap
      width: 100%
      min-width: 100%
      min-height: 100%
      height: 100%
      position: relative
      .video
        display: flex
        justify-content: center
        align-items: center
        object-fit: fill
        border: 1px solid var(--color-white)
        border-radius: 5px
        position: relative
        .statusIcons
          display: flex
          gap: 10px
          position: absolute
          top: 10px
          right: 10px
        .muted
          display: flex
          justify-content: center
          align-items: center
          background: rgba(128, 128, 128, 0.53)
          border-radius: 50%
          width: 30px
          height: 30px
          position: relative

          &:before
            content: ""
            position: absolute
            width: 90%
            height: 1px
            background: var(--color-white)
            transform: rotateZ(-45deg)
        .handUp
          display: flex
          justify-content: center
          align-items: center
          background: var(--color-green)
          border-radius: 50%
          border: 1px solid var(--color-white)
          width: 30px
          height: 30px
        .fullName
          position: absolute
          bottom: 10px
          left: 10px
          color: var(--color-white)
          text-shadow: 1px 1px 1px var(--color-text-dark)
          overflow: hidden
          text-overflow: ellipsis
          text-wrap: none
          width: 75%
          span:first-child
            margin-right: 10px

      .background
        .image
          width: 100%
          max-width: 100%
          max-height: 100%
          min-height: 100%
          height: 100%
          border-radius: 5px
          object-fit: cover
        video
          width: 100%
          height: 100%
          min-height: 100%
          object-fit: cover
          border-radius: 5px
      .noScreen
        display: none
      .isScreen
        display: flex
        justify-content: center
        width: 80%
        height: 100%
      .videoLeftSide
        display: flex
        flex-direction: column
        justify-content: center
        align-items: center
        width: 19%
        height: 100%
      .fullPage
        display: flex
        align-items: center
        justify-content: space-evenly
        flex-wrap: wrap
        width: 100%
        min-width: 100%
        min-height: 100%
        height: 100%
        position: relative
        gap: 20px
        padding: 20px 20px 0 20px

.threeDots
  width: 6px
  height: 6px
  border-radius: 50%
  background: var(--color-white)
  margin: 0 1px
.contextMenuItem
  display: flex
  gap: 15px
  padding: 10px 15px
  cursor: pointer
  border-radius: 6px
  border-bottom: 1px solid var(--color-grey-secondary)
  &:hover
    background: var(--color-blue-light)
  &[disabled]
    background: var(--color-gray-light)
    cursor: default

.paintButtons
  display: flex
  gap: 20px
  position: absolute
  bottom: 50px
  right: 50px

.noUserIcon
  width: 200px
  height: 200px
  max-width: 50%
  max-height: 50%
