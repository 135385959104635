.wrap
  width: 440px
  .body
    padding: 12px 16px
  .footer
    display: flex
    align-items: center
    justify-content: space-between
    padding-top: 24px
    .btns
      display: flex
      align-items: center
      justify-content: flex-end
      gap: 8px
      flex-grow: 1
