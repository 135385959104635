@import "src/theme/global"
.note
    width: 125px
    height: 125px
    border: 1px solid var(--color-border-grey)
    background: var(--color-background-light)
    border-radius: 8px
    overflow: hidden
    position: relative
    border: 1px solid var(--color-border-grey)
    padding: 10px 6px 6px
    font-family: "SF Pro Text",sans-serif
    color: var(--color-white)
    flex: 0 0 auto
    .header
        display: flex
        justify-content: space-between
        margin-bottom: 2px

    .name
        font-size: 11px
        font-weight: 500
        line-height: 15px
        margin: 0
        flex: 1
        @include textEllipsis
    .info
        display: flex
        align-items: flex-start
        gap: 5px
    .tag
        padding: 1px 9px
        border-radius: 10px
        color: var(--color-text-grey)
        background: var(--color-blue-light)
        font-size: 8px
        display: inline-block
        @include textEllipsis
        flex: 0 0 auto
    .elements
        display: flex
        flex-wrap: wrap
        gap: 5px
    .date
        font-size: 8px
        color: var(--color-white)
        margin-left: auto

    .corner
        position: absolute
        bottom: -1px
        right: -1px
        width: 15px
        height: 15px
        background: var(--color-border-grey)
        border-radius: 8px 0 8px 0

    .text
        overflow-y: auto
        height: 77px
        font-size: 9px
        line-height: 12px
        padding-top: 5px
        p
            margin-bottom: 5px

    .lockBlock
        cursor: pointer
        text-align: center
        .icon path
            fill: var(--color-white)
.dots
    *
        fill: var(--color-white)
.noteGrey
    color: var(--color-text-grey)
    .fig
        *
            fill: var(--color-text-grey)
