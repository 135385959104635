.btnBox
  display: flex
  gap: 8px
  width: 176px
  max-width: 100%
  background-color: var(--color-d20)
  border-radius: 4px
  padding: 2px 8px

  &:hover,
  &:active
    background-color: var(--color-lb40)

  &.open
    background-color: var(--color-lb50)

  &__info
    display: flex
    flex-direction: column
    max-width: 128px
    width: 100%

  &__head
    display: flex
    align-items: center
    justify-content: space-between
    gap: 4px
    color: var(--color-d800)
    font-size: 12px
    font-weight: 500
    line-height: 1.33
    
    &::after
      content: ""
      border-left: 4px solid transparent
      border-right: 4px solid transparent
      border-top: 4px solid var(--color-d800)
      transition: transform 300ms, border-top-color 300ms

    &.open
      &::after
        transform: rotate(-180deg)
  
  &__progress
    display: flex
    width: 100%
    height: 4px
    border-radius: 2px
    background-color: var(--color-d100)

  &__progressBusy
    border-radius: 2px
    height: 100%

    &.small
      background-color: var(--color-lb800)

    &.medium
      background-color: var(--main-orange)

    &.big
      background-color: var(--color-red)
