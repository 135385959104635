
.leftSidebar
    z-index: 3
    width: 360px
    height: 100%
    border-radius: 4px
    background: var(--blue-b-100, #BBDEFB)
    box-shadow: 2px 0px 4px 0px rgba(0, 0, 0, 0.20), 1px 0px 10px 0px rgba(0, 0, 0, 0.12), 4px 0px 5px 0px rgba(0, 0, 0, 0.14)
    position: fixed
    transition: all 0.3s ease-in-out
    transform: translateX(0)
    left: 0
    top: 0
    padding-bottom: 30px
    &.hiddenSidebar
        transform: translateX(-360px)

.contentWrapper
    height: 100%
    width: 100%
    overflow: auto
    padding: 0 7px

.categoryGrid
    display: grid
    grid-template-columns: repeat(6, 51px)
    grid-auto-rows: 58px
    gap: 8px
    padding: 10px 0

.sidebarTitle
    width: 100%
    position: relative
    left: 0
    top: 0
    z-index: 2
    height: 40px
    font-size: 14px
    line-height: 18px
    padding: 12px
    width: 100%
    border-radius: 0 0 4px 4px
    color: var(--color-white)
    background: var(--blue-b-800, #1565C0)
    display: flex
    justify-content: space-between
    align-items: center

.closeButton
    color: var(--color-white)
    background: var(--blue-b-800, #1565C0)
    &:hover
        background: var(--color-b600, #1E88E5)
    svg
        fill: var(--color-white)
        path
            fill: var(--color-white)

.categoryTitle
    color: var(--dark-green-d-800, #274A42)
    font-size: 14px
    line-height: 22px
    padding: 0 6px
    margin: 20px 0 0
    display: flex
    align-items: center
    svg
        margin-right: 5px

.leftItem
    background-position: center
    background-size: cover
    position: relative
    padding: 4px 6px
    border-radius: 4px
    overflow: hidden
    .shadow
        background: #00000049
        position: absolute
        width: 100%
        height: 100%
        left: 0
        top: 0
        right: 0
        left: 0
        display: flex
        align-items: center
        justify-content: center
        opacity: 0
        transform: scale(0)
        transition: all 0.2s ease-in-out
    &:hover
        .shadow
            opacity: 1
            transform: scale(1)
    .title
        font-weight: 700
        font-size: 12px
        color: #0288D1

.line
    width: 100%
    height: 1px
    background: var(--dark-green-d-400, #869A95)
    margin: 10px 0

.alert
    width: fit-content
    display: flex
    align-items: center
    justify-content: center
    padding: 5px 20px
    margin: 10px auto
    background: #DDEEDC
    border-radius: 4px

.content
    width: 100%
    height: 100%

.contentOpen
    min-height: 400px

.mainWrapper
    height: 100%
    overflow: auto
    max-width: 1400px
    margin: 0 auto
    position: relative
    &.openSidebar
        min-height: 100vh
        width: calc(100vw - 360px)
        position: absolute
        right: 0
        max-width: 1400px
        padding-bottom: 150px

.item
    border-radius: 8px
    background: var(--color-white)
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.20), 0px 2px 5px 0px rgba(0, 0, 0, 0.12), 0px 2px 3px 0px rgba(0, 0, 0, 0.14)
    &.blur
        box-shadow: 0px 0px 61px 0px rgba(221, 0, 255, 0.60)
        background: #ff00fb48
        opacity: 0.8
    &.dragFromOutside
        transition: all 200ms ease 500ms

.widgetInteraction
    pointer-events: none

.removeButton
    position: absolute
    z-index: 1
    right: -5px
    top: -5px
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.20), 0px 1px 10px 0px rgba(0, 0, 0, 0.12), 0px 4px 5px 0px rgba(0, 0, 0, 0.14)
    svg
        fill: var(--color-white)

.addButton
    margin: 30px auto
    padding: 0 15px
    svg
        fill: var(--color-white)

.settingsButtonWrapper
    position: fixed
    bottom: 0
    background: var(--color-white)
    width: inherit
    .addButton
        margin: 30px auto
