
.header
  display: flex
  align-items: center
  justify-content: space-between
  border-bottom: 1px solid var(--color-border-dark)
  padding: 7px 36px 7px 10px
  background: inherit
  width: 100%
  .infoHeader
    display: flex
    justify-content: space-between
    align-items: center
    gap: 20px
    background: inherit
