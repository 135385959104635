
.wrap
  flex: 1 1 290px
  min-width: 290px
  height: 100%
  overflow-y: auto
  padding: 10px
  border-right: 1px solid var(--color-border-dark)
.date
  display: flex
  gap: 10px
  align-items: center
  padding: 9px 10px
  border-radius: 10px
  border: 1px solid var(--color-blue-smooth)
  margin-bottom: 10px
  font-size: 14px
  .day
    width: 22px
    line-height: 22px
    border-radius: 50%
    background: var(--color-blue-smooth)
    color: var(--color-white)
    text-align: center
    font-family: "SF Pro Light", sans-serif

  .month
    line-height: 22px
    font-size: 14px
    font-family: "SF Pro Text", sans-serif
