.container
    color: var(--dark-green-d-500, #6E8680)
    font-family: Roboto
    font-size: 16px
    font-style: normal
    font-weight: 400
    line-height: 20px
    width: 100%
    height: 100%
    display: flex
    align-items: center
    justify-content: center
    text-align: center
    svg
        margin-right: 10px
