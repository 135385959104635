.wrapper
    .inputWrap
        margin: 15px 24px
    .text
        font-size: 13px
        text-align: center
        margin: 3px 0 7px
    .addContactsDatabase
        display: flex
        align-items: center
        padding: 0 30px
        .text
            margin-right: 5px
            color: #B0B0B0
        .button
            color: #4086F1
            text-decoration: underline
            cursor: pointer
        .icon
            margin-right: 10px
