
@keyframes warning
  0%
    opacity: 0
  100%
    opacity: 1

@keyframes showPopup
  0%
    width: 10px
    height: 10px
    background: white
    opacity: 0
  99%
    width: 450px
    height: 350px
    opacity: 0

.wrap
  animation: showPopup 0.2s ease-in-out
  .container
    display: flex
    align-items: flex-start
    gap: 16px
    min-width: 400px
    padding: 16px 20px 20px
  .body
    flex: 1 1 auto
    display: flex
    flex-direction: column
    justify-content: space-between

  .title
    font-weight: 600
    font-size: 18px
    line-height: 20px
    color: var(--color-text-dark)
    margin-bottom: 6px
  .text
    margin: 0 0 16px
    font-size: 12px
    line-height: 16px
    color: var(--color-abbey)
  .buttonsWrap
    display: flex
    gap: 10px
    justify-content: flex-end
