
.container
    max-height: 400px
    width: 400px
    padding: 20px 16px
    position: relative
    min-height: 200px

.content
    display: flex
    flex-direction: column
    gap: 8px
    display: flex
    padding: 7px 12px 20px
    flex-direction: column
    align-items: flex-start
    align-self: stretch
    border-radius: 8px
    background: var(--DarkGreen-D20, #F5F7F7)
    overflow: auto
    max-height: 290px

    h6
        color: var(--DarkGreen-D600, #56716B)
        font-family: Roboto
        font-size: 12px
        font-style: normal
        font-weight: 400
        line-height: 12px

.item
    display: flex
    align-items: center
    gap: 8px
    color: var(--DarkGreen-D800, #274A42)
    font-size: 12px
    font-weight: 500

.buttons
    margin-top: 25px
    display: flex
    justify-content: flex-end
    gap: 8px
