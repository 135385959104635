
.itemWrap
  width: 100%
  border-bottom: 1px solid var(--color-d100)
  display: flex
  align-items: center
  justify-content: space-between
  font-size: 13px
  padding: 12px
  gap: 10px
  &:last-child
    border-bottom: none
  &:hover
    background: var(--color-b50)
  .iconBox
    width: 40px
    height: 40px
    display: flex
    align-items: center
    justify-content: center
    flex-direction: column
  .nameBlock
    flex: 1 1 auto
    padding-top: 4px
  .programName
    white-space: nowrap
    overflow: hidden
    font-weight: 600
    color: var(--color-d800)
    font-size: 14px
    line-height: 18px
  .programLink
    color: var(--color-d600)
    font-size: 13.5px
    line-height: 16px

  .btn
    padding: 0
