
.notesWrap
  display: flex
  gap: 10px
  width: 100%
  border-bottom: 1px solid var(--color-border-grey)
  overflow-x: auto
  padding: 10px 20px
  .emptyNote
    display: flex
    justify-content: center
    align-items: center
    flex-direction: column
    flex: 0 0 auto
    cursor: pointer
    position: relative
    width: 125px
    height: 125px
    font-size: 10px
    gap: 10px
    border: 1px solid var(--color-border-grey)
    background: var(--color-background-light)
    border-radius: 8px
    .corner
      position: absolute
      bottom: -1px
      right: -1px
      width: 15px
      height: 15px
      background: var(--color-border-grey)
      border-radius: 8px 0 8px 0

  .emptyImg
    position: absolute
    right: calc(-100% - 60px)
    transform: rotateZ(240deg)
    bottom: 10px
  .inscription
    position: absolute
    right: calc(-100% - 160px)
    bottom: 20px
.text
  font-size: 10px
  color: var(--color-text-grey)
  font-family: "SF Pro Regular", sans-serif
.corner
  position: absolute
  bottom: -3px
  right: 0
.mainMenuItems
  margin: 10px 0
