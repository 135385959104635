
.root
  width: 440px

  .body
    padding: 12px
  .container
    background-color: var(--color-d50)
    margin-bottom: 12px
    border-radius: 4px
    position: relative
  .block
    padding: 4px 8px 8px 12px
  .subtitle
    color: var(--dark-d700, #3E5D56)
    font-size: 14px
    line-height: 20px
    margin-bottom: 4px
  .inputBox
    padding: 4px
    background-color: var(--color-d100)
    border-radius: 4px
    margin-bottom: 6px
  .userList
    height: 340px
    overflow-y: auto
  .user
    display: flex
    gap: 8px
    padding: 4px 8px 4px 12px
    align-items: center
    cursor: pointer
    margin-bottom: 2px
    &:hover, &.checked
      background-color: var(--color-b50)

    .name
      flex-grow: 1
  .emailsBox
    display: flex
    gap: 8px
  .emails
    flex: 1
    height: 28px
    margin: 0
    padding: 0 4px
    display: flex
    align-items: center
    flex-wrap: nowrap
    gap: 4px
    border: 1px solid var(--color-d100)
    background-color: var(--color-white)
    border-radius: 4px
    overflow-x: auto
    overflow-y: hidden
    &.error
      border-color: var(--color-red)
    .inpupLi
      flex-grow: 1
      input
        font-size: 14px
        line-height: 24px
        color: var(--color-d700)
        border: none
        height: 24px
        padding: 0
        min-width: 100%
    .emailItem
      width: max-content
      display: flex
      align-items: center
      column-gap: 8px
      flex-grow: 0
      padding: 0 4px
      background-color: var(--color-b50)
      font-size: 14px
      line-height: 24px
      color: var(--color-d700)
      border-radius: 4px
      &:hover
        background-color: var(--color-b100)
    .removeBtn
      padding: 4px
      display: flex

  .footer
    display: flex
    justify-content: flex-end
    gap: 8px
    padding-top: 12px
  .disabled
    position: absolute
    top: 0
    left: 0
    width: 100%
    height: 100%
    border-radius: 4px
    background: rgba(255,255,255, 0.4)
