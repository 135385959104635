.wrap
  width: 440px
.body
  padding: 16px 16px 10px
.footer
  margin-top: 16px
  display: flex
  justify-content: flex-end
  gap: 8px
.avatarBlock
  display: flex
  align-items: stretch
  gap: 8px
  margin-bottom: 16px
  & > .uploadBtns
    display: flex
    flex-direction: column
    align-items: stretch
    justify-content: space-between
    & > span
      flex: 0
.period
  padding: 8px 12px 24px
  margin-top: 12px
  background-color: var(--color-d100)
  border-radius: 8px
  .top
    display: flex
    justify-content: space-between
    color: var(--DarkGreen-D700, #3E5D56)
    font-size: 14px
    line-height: 20px
    margin-bottom: 10px
  .dates
    display: flex
    justify-content: space-between
