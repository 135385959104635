
.rolesWrap
    padding-top: 8px
    display: flex
    align-items: flex-start
    justify-content: space-between
    border-bottom: 1px solid var(--color-border-dark)
    .addRole
        position: relative
        padding: 0 10px
        width: 180px
        border-left: 1px solid var(--color-border-dark)

        .creaeteArrow
            position: absolute
            right: -25px
            bottom: -134px
            transform: rotate(-50deg)
        .info
            position: absolute
            left: -150px
            top: 125px
            width: 320px
            z-index: 1
    .plusIcon
        width: 15px
        height: 15px
