
.listWrap
  flex: 0 0 265px
  width: 265px
  height: 100%
  display: flex
  flex-direction: column
  background: var(--color-white-lilac)
  border-right: 1px solid var(--color-border-grey)
  position: relative
  transition: flex 0.2s ease-in-out, width 0.2s ease-in-out
  &.collapsed
    &.small
      flex: 0 0 56px
      width: 56px
    &.medium
      flex: 0 0 100px
      width: 100px
    &.large
      flex: 0 0 120px
      width: 120px
.header
  width: 100%
  height: 60px
  flex: 0 0 60px
  display: flex
  justify-content: space-between
  align-items: center
  border-bottom: 1px solid var(--color-border-grey)
  padding-left: 25px
  padding-right: 15px
  .leftWrapper
    display: flex
    align-items: center
.title
  margin-left: 8px
  font-weight: 700
  font-size: 15px
  line-height: 18px
  color: var(--color-text-grey)
  white-space: nowrap
.icon
  display: flex
  align-items: center
  justify-content: center
  padding: 0
  position: relative
  &.reverse
    svg
      transform: rotateZ(180deg)
.imgWrap
  display: flex
  align-items: center
  column-gap: 18px
.children
  overflow-y: auto
  overflow-x: hidden
  height: 100%
