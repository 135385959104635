// Global styles for project
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap')
@import 'folderColors'
@import 'theme'
@import "_datePickerWrapper"

*
  box-sizing: border-box

html
  height: 100%
  scrollbar-width: none

body
  height: 100%
  margin: 0
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif
  -webkit-font-smoothing: antialiased
  -moz-osx-font-smoothing: grayscale
  user-select: none
  min-height: max-content
  ul
    margin: 0

#root
  height: 100%
  font-family: 'Roboto', sans-serif

code
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace

::-webkit-scrollbar
  width: 0

input
  &:focus
    outline: none

h1:hover,
h2:hover ,
h3:hover,
h4:hover,
h5:hover,
h6:hover,
p:hover
  cursor: inherit
