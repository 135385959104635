.container
  width: 100%
  min-height: 100vh
  height: 100%
  display: flex
  flex-direction: column
  justify-content: space-between
  position: absolute

.content
  display: flex
  flex-wrap: wrap
  justify-content: flex-start
  gap: 24px
  max-width: 1280px
  margin: 64px auto

.block
  width: 300px
  height: 208px
  display: flex
  flex-direction: column
  align-items: center
  justify-content: flex-start
  border-radius: 12px
  background: var(--dark-green-d-40, #F1F3F2)
  gap: 6px
  padding: 20px
  transition: all 0.3s ease-in-out
  svg
    fill: var(--dark-green-d-700, #6E8680)
  &:hover
    background: var(--dark-green-d-100, #CFD7D5)

.title
  color: var(--dark-green-d-700, #3E5D56)
  font-size: 20px
  font-weight: 500
  text-align: center

.desc
  color: var(--dark-green-d-600, #6E8680)
  font-size: 14px
  line-height: 18px
  text-align: center

@media (max-width: 1280px)
  .content
    justify-content: center
    margin: 64px auto
