@import "src/theme/cutsStr"

.card
  background-color: var(--color-grey-transparent-40)
  border-radius: 4px
  font: 12px/1.33 "Roboto", sans-serif
  color: var(--color-d800)
  overflow: hidden

  &.bars,
  &.preview
    width: 120px
    height: 97px
    padding: 10px 8px

  &.lines
    border: 1px solid var(--color-grey-transparent-40)
    height: 44px
    padding: 6px

  &__wrapp
    display: flex
    position: relative
    align-items: center
    height: 100%

    &.bars,
    &.preview
      flex-direction: column
      text-align: center

    &.lines
      gap: 6px
      align-items: center
      padding-right: 36px

  &__iconWrapp
    flex-shrink: 0
    margin-bottom: 4px
  
  &__info
    margin-top: auto

  &__name
    font-size: 12px
    font-weight: 400
    @include cutsStr(1)
    text-align: center

    &.bold
      font-weight: 700

