
.wrapper
  width: 100%
  display: flex
  align-items: center
  column-gap: 15px
  margin-bottom: 15px
.iconWrapper
  position: relative
  img
    position: absolute
    bottom: 0
    left: 0
    width: 20px
.fileIcon
  path
    &:first-child
      fill: currentColor
.extInfo
  width: 100%
  position: absolute
  top: calc(50% + 8px)
  transform: translateY(-50%)
  font-family: 'SF Pro Text', sans-serif
  font-weight: 700
  font-size: 15px
  text-align: center
  color: var(--color-white)
.picPreview
  width: 100%
  display: flex
  flex-direction: column
  justify-content: space-between
  row-gap: 8px
  overflow: hidden
.topInfo
  display: flex
  flex-direction: column
  row-gap: 1px
.name
  display: block
  font-family: 'SF Pro Text', sans-serif
  font-weight: 500
  font-size: 13px
  line-height: 16px
  color: var(--color-text-dark)
  overflow: hidden
  white-space: nowrap
  text-overflow: ellipsis
.fileSize
  font-family: 'SF Pro Text', sans-serif
  font-weight: 500
  font-size: 10px
  line-height: 12px
  color: var(--color-silver-chalice)
  white-space: nowrap
.options
  display: flex
  align-items: center
  column-gap: 10px
.minitagWrap
  padding: 0
.colorWrap
  min-width: 20px
  min-height: 20px
  position: relative
  cursor: pointer
  padding: 0
.circle
  width: 20px
  height: 20px
  border-radius: 50%
.signButton,
.emojiButton
  padding: 0
.redCross
  position: relative
  cursor: pointer
  &::before
    display: none
    content: ""
    position: absolute
    top: -2px
    right: -7px
    background-image: url('/assets/PrivateCabinet/remove-red.svg')
    background-repeat: no-repeat
    background-size: cover
    width: 8px
    height: 8px
  &:hover
    &::before
      display: inline-block
.emo
  font-size: 20px

.tags
  display: flex
  flex-wrap: wrap
  gap: 6px

.tag
  border-radius: 4px
  display: inline
  padding: 2px 6px
  background: var(--DarkGreen-D900, #0E352C)
  color: var(--DarkGreen-D50, #E7EBEA)
  font-size: 12px
  font-style: normal
  font-weight: 500
  line-height: 16px
