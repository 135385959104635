
@import src/theme/global

.atachment
  flex: 0 0 auto
  cursor: pointer !important
  align-items: center !important
  display: flex !important
  gap: 4px !important
  font-weight: 500 !important
  color: var(--color-d800) !important
  border-radius: 14px !important
  background: var(--color-d100) !important
  font-size: 14px !important
  padding: 6px 8px !important
  line-height: 1 !important
  max-width: 160px !important
  height: 30px !important
  .filename
    flex: 1
    line-height: 16px
    margin-bottom: 2px
    display: inline-block
    @include textEllipsis
