.team
  padding: 8px 12px 12px
  background-color: var(--color-d40)
  border-radius: 8px
  &:not(:last-child)
    margin-bottom: 4px
  &:hover .actions, &.isActive .actions
    display: flex
  .name
    color: var(--color-d800, #274A42)
    font-size: 16px
    line-height: 24px
    display: flex
    align-items: center
    gap: 4px
  .prim
    color: var(--color-d600)
    font-size: 12px
    line-height: 16px
  .avatars
    max-width: 100%
    display: flex
    gap: 6px
    flex-wrap: nowrap
    overflow-x: auto
  .block
    display: flex
    justify-content: space-between
    gap: 8px
    margin-bottom: 12px
    min-height: 40px
  .actions
    display: none
