
.avatar
  position: relative
  border-radius: 4px
  flex-shrink: 0
  background-color: var(--color-white)
  display: flex
  align-items: center
  justify-content: center
  .name
    font-size: 0.5em
    color: var(--color-white)
  .image
    width: 100%
    height: auto
    display: block
    border-radius: 4px
    &.hidden
      display: none
  &.round
    border-radius: 50%
    .image
      border-radius: 50%
  &.online::after, &.offline::after, &.busy:after, &.moved_away:after
    content: ""
    position: absolute
    top: 0
    right: 0
    width: 13px
    height: 13px
    border: 2px solid var(--color-white)
    border-radius: 50%
    transform: translate(30%, -30%)
  &.online::after
    background-color: #34C6A2
  &.offline::after
    background-color: #869A95
  &.busy:after
    background-color: #F3DE5E
  &.moved_away:after
    background-color: #F3A413
