
.box
  display: flex
  align-items: center
  gap: 5px
  .btn
    width: 30px
    height: 24px
    border-radius: 6px
    border: 1px solid var(--color-border-dark)
    background: var(--color-background-light)
    line-height: 22px
    &:hover
      box-shadow: 0 2px 8px var(--color-shadow)
  .bold
    font-weight: 700
  .italic
    font-style: italic
  .underline
    text-decoration: underline
  .strikethrought
    text-decoration: line-through
  .align
    position: relative
    .group
      position: absolute
      top: 100%
      display: flex
      flex-direction: column
      gap: 5px
      z-index: 1
      padding-top: 5px
      background: var(--color-white)
  .active
    border-color: var(--color-blue-smooth)
    background: var(--color-blue-light)
