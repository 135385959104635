
.root
  position: relative
  padding-top: 10px
  display: block
.inputField
  display: block
  text-align: left
  width: 100%
  height: 32px
  font-size: 14px
  line-height: 20px
  color: var(--color-d800)
  background: transparent
  border: 1px solid var(--color-border-dark)
  border-radius: 6px
  padding: 6px 8px 6px 12px
  cursor: pointer
  &.open
    border-color: var(--color-b600)
  .placeholder
    color: var(--color-d400)
.arrow
  position: absolute
  top: calc(50% + 5px)
  transform: translate(0, -50%)
  right: 10px
  cursor: pointer
  transition: transform 0.2s ease-in-out
  &.active
    transform: translate(0, -50%) scaleY(-1)
    path
      fill: var(--color-b600)
.label
  background-color: var(--color-white)
  position: absolute
  top: 3px
  left: 6px
  z-index: 1
  padding: 0 4px
  font-size: 12px
  line-height: 0.83
  color: var(--color-d700)
.list
  position: absolute
  top: 100%
  left: 0
  z-index: 4
  width: 100%
  background: var(--color-white)
  border: 1px solid var(--color-border-dark)
  border-bottom-left-radius: 6px
  border-bottom-right-radius: 6px
  border-top: none
