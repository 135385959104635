
.wrap
    width: 420px
    padding-top: 14px
    font-family: "SF Pro Text",sans-serif
    font-size: 11px
    text-align: left

    & button
        margin: 20px auto 0
        display: block
    .subtitle
        margin: 0 auto 30px
        width: 340px
        text-align: center

    .create
        padding-bottom: 10px
        display: flex
        align-items: center
        gap: 20px
        font-size: 12px
    .list
        margin: 0
        max-height: 300px
        overflow: auto
    .border
        border-bottom: 1px solid var(--color-border-dark)
        margin-bottom: 10px

    .robotIcon
        width: 20px
        height: 20px
    .circle
        display: inline-block
        width: 10px
        height: 10px
        border-radius: 50%
        margin-right: 2px
