
.wrap
  border-radius: 6px
  overflow: hidden
  background: var(--color-white)
  border: 1px solid var(--color-border-dark)
  display: flex
  height: 24px

  .box
    width: 30px
    height: 22px
    background: var(--color-white)
    cursor: pointer
    display: flex
    flex-direction: column
    align-items: center
    justify-content: center
    border-right: 1px solid var(--color-border-dark)
    &:hover
      background: var(--color-blue-smooth)
      & .icon path
        fill: var(--color-white)

    .icon
      width: 15px
      height: 13px
      margin: 0 auto
      & path
        fill: var(--color-grey)

  .box:last-child
    border: none
  .iconViewChosen
    background: var(--color-blue-smooth)
    & .icon path
      fill: var(--color-white)
