@import src/theme/global
.item
  display: flex
  align-items: flex-start
  padding: 4px 16px
  min-height: 40px
  color: var(--color-d800, #274A42)
  font-size: 12px
  line-height: 16px
  &:hover, &.isActive
    background-color: var(--color-b100)
    .actions
      display: flex

  .nameBox
    display: flex
    gap: 4px
    align-items: center
    flex: 0 0 360px
    max-width: 360px
    padding: 4px 8px 4px 0

    .name
      flex-shrink: 1
      @include textEllipsis
    .isBlock
      background-color: var(--color-d800)
      color: var(--color-white)
      border-radius: 4px
      padding: 0 8px
      line-height: 20px
      margin-left: 8px
  .emails
    display: flex
    flex-direction: column
    flex: 0 0 270px
    padding-right: 8px
    padding-top: 4px
    @include textEllipsis

    span
      @include textEllipsis

  .tels
    display: flex
    flex-direction: column
    gap: 2px
    flex: 0 0 150px
    padding-right: 8px
    padding-top: 4px
  .actions
    display: none
    gap: 4px
    flex: 1 0 auto
    align-items: center
    justify-content: flex-end
