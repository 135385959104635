
@import src/theme/global
@keyframes show
  0%
    margin-bottom: -20px
    opacity: 0.5

  100%
    margin: 0
    opacity: 1

.wrap
  width: 100%
  max-width: max-content
  position: absolute
  z-index: 10
  left: 50%
  transform: translateX(-50%)
  bottom: 40px
  background-color: var(--color-white)
  border-radius: 6px
  @include boxShadow
  animation: show .2s linear
