@import src/theme/global
.root
  position: relative
  font-family: "Roboto", sans-serif
.selectedContactsWrapper
  max-height: 94px
  overflow-y: auto
  background: var(--color-white)
  border: 1px solid var(--color-border-dark)
  border-radius: 6px
  padding: 2px 45px 2px 2px
.contactList
  display: flex
  flex-wrap: wrap
  gap: 4px
  margin: 0
.contactListWrapper
  position: absolute
  top: 100%
  left: 0
  z-index: 4
  width: 100%
  max-height: 168px
  background-color: var(--color-white)
  box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.14), 0px 3px 14px rgba(0, 0, 0, 0.12), 0px 5px 5px rgba(0, 0, 0, 0.2)
  border-radius: 4px
  overflow-y: auto
  overflow-x: hidden
.contactListFlex
  display: flex
  flex-direction: row
  column-gap: 4px
.contactItem
  flex: 0 0 auto
.contactButton
  height: 42px
  width: 100%
  display: flex
  align-items: center
  column-gap: 8px
  background-color: transparent
  padding: 6px
  &:hover
    background-color: var(--color-sail)
  &.selected
    background-color: var(--color-curious-blue)
    border-radius: 4px
.contactImage
  flex: 0 0 30px
  width: 30px
  height: 30px
  &:not(.default)
    border-radius: 4px
.contactContentWrapper
  display: flex
  flex-direction: column
  text-align: left
.contactName
  font-family: 'Roboto', sans-serif
  font-weight: 700
  font-size: 12px
  line-height: 14px
  color: var(--color-d800)
  @include textEllipsis
  &.selected
    color: var(--color-white)
.contactEmail
  font-family: 'Roboto', sans-serif
  font-weight: 400
  font-size: 12px
  line-height: 16px
  color: var(--color-d700)
  @include textEllipsis
  &.selected
    color: var(--color-white)
.inputBox
  flex: 1 1
.searchInput
  display: inline-block
  width: 100%
  min-width: 100px
  border: none
  padding: 9px 4px
.searchIcon
  position: absolute
  top: 50%
  transform: translateY(-50%)
  right: 15px
.removeBtn
  display: flex
  padding: 10px
  &:hover path
    fill: var(--color-b800)
.noUserBox
  padding: 12px 20px
.noUser
  font-size: 24px
  font-weight: 500
  line-height: 28px
  margin: 0 0 6px
.helperText
  font-size: 14px
  line-height: 18px
  margin: 0 0 18px
  & > span
    font-weight: 700
.btns
  display: flex
  justify-content: flex-end
  gap: 8px
