
.wrap
  display: flex
  align-items: center
  column-gap: 8px
.innerItemWrap
  position: relative
.extInfo
  width: 100%
  position: absolute
  top: calc(50% + 8px)
  transform: translateY(-50%)
  font-family: 'SF Pro Text', sans-serif
  font-weight: 700
  font-size: 15px
  text-align: center
  color: var(--color-white)
.lockedIcon
  position: absolute
  &.folder
    top: 50%
    left: 50%
    transform: translate(-50%, -50%)
  &.file
    top: -1px
    right: 8px
.fileIcon
  path
    &:first-child
      fill: currentColor
.descriptionWrap
  display: flex
  flex-direction: column
  row-gap: 10px
.topContentWrap
  display: flex
  flex-direction: column
  row-gap: 1px
.itemName
  display: block
  max-width: 400px
  font-family: 'SF Pro Text', sans-serif
  font-weight: 500
  font-size: 13px
  line-height: 16px
  letter-spacing: -0.08px
  color: var(--color-text-dark)
  overflow: hidden
  white-space: nowrap
  text-overflow: ellipsis
.itemSize
  font-family: 'SF Pro Text', sans-serif
  font-weight: 500
  font-size: 10px
  line-height: 12px
  letter-spacing: -0.08px
  color: var(--color-silver-chalice)
  white-space: nowrap
.descriptionGroup
  display: flex
  align-items: center
  column-gap: 10px
.emo
  font-size: 14px
.itemsIconList
  display: flex
  align-items: center
  column-gap: 8px
  overflow-x: auto
  overflow-y: hidden
  padding-bottom: 3px
  margin: 0
.itemIcon
  flex: 0 0 auto
.severalChosen
  flex: 0 0 auto
  font-family: 'SF Pro Text', sans-serif
  font-weight: 500
  font-size: 13px
  line-height: 15px
  color: var(--color-black)
