.panel
    display: flex
    flex-direction: column
    background-color: var(--color-white)
    border-radius: 4px
    position: relative
    height: 100%
    
    &__title
        padding: 8px 12px
        font-size: 16px
        font-weight: 500
        line-height: 1.5
        color: var(--color-d800)
        flex-basis: 40px

    &__content
        display: flex
        flex-direction: column
        height: 100%
        overflow-y: auto
        overflow-x: hidden
