
.list
  display: flex
  align-items: stretch
  flex-wrap: wrap
  gap: 15px
  margin: 0
  padding: 0 10px
.item
  display: flex
  align-items: center
  justify-content: center
