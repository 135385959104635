
@import src/theme/global
.letterItem
  background: var(--color-d50)
  margin-bottom: 8px
  margin-right: 32px
  padding: 6px
  border-radius: 4px
  color: var(--color-d700)
  &:hover
    .time
      display: none
    .menu
      display: flex
  &.isMe
    background: var(--color-b100)
    margin-right: 0
    margin-left: 24px
  &.isOne
    margin-right: 0
    margin-left: 0
  &_body
    padding: 16px
  .more
    margin-top: 16px
    margin-bottom: 5px
    & > button
      height: 16px
    &.show
      transform: scaleY(-1)
  .text
    font-size: 14px

.messageHeader
  display: flex
  justify-content: space-between
  align-items: center
  margin-bottom: 8px
  .time
    font-size: 14px
    color: var(--color-d600)
    line-height: 20px
    flex: 0 0
    display: flex
    align-items: center
    gap: 4px
    white-space: nowrap

  .messageHeaderLeft
    display: flex
    gap: 8px
    flex: 1 1
    overflow: hidden
    padding: 1px 0
    .messageName
      display: flex
      flex-direction: column
      justify-content: space-between
    .name
      line-height: 1
      font-size: 12px
      color: var(--color-d800)
      font-weight: 700
      @include textEllipsis
    .email
      line-height: 1
      font-size: 12px
      @include textEllipsis

.menu
  gap: 8px
  flex-grow: 0
  display: none
.attachments
  display: flex
  flex-wrap: wrap
  gap: 4px
