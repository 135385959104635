.wrap
  width: 440px
.body
  padding: 16px 16px 10px
  .subTitle
    color: var(--color-d600, #56716B)
    font-size: 12px
    font-weight: 400
    line-height: 20px
    margin-top: 16px
  .list
    padding: 8px 0
    height: 308px
    overflow-y: auto
  .item
    display: flex
    gap: 8px
    &:not(:last-child)
      margin-bottom: 8px

    .title
      color: var(--colord800, #274A42)
      font-size: 12px
      font-weight: 500
      line-height: 20px
    .prim
      color: var(--color-n800, #425D5A)
      font-size: 12px
      font-weight: 400
      line-height: 16px

.footer
  margin-top: 16px
  display: flex
  justify-content: flex-end
  gap: 8px
