.list
    display: grid
    grid-template-columns: repeat(4, 88px)
    gap: 10.6px

    &__item
        display: flex
        flex-direction: column
        align-items: center
        cursor: pointer

    &__theme
        width: 100%
        height: 80px
        border-radius: 8px
        margin-bottom: 4px

        &.blue
            background: linear-gradient(180deg, #4543F7 0%, #0A3AAB 100%)

        &.orange
            background: linear-gradient(180deg, #EA7D30 0%, #EA4631 100%)

        &.turquoise
            background: linear-gradient(180deg, #10AADD 0%, #18697C 100%)

        &.dark
            background: linear-gradient(180deg, #222 0%, #3F3F3F 100%)

    &__label
        font-size: 12px
        line-height: 1.58
        color: var(--color-d900)
        cursor: pointer
