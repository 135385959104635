.block
  padding: 12px
  &:not(:last-child)
    border-bottom: 1px solid var(--color-d50)
.list
  display: flex
  flex-wrap: wrap
  gap: 6px
  .element
    width: 34px
    height: 34px
    display: flex
    align-items: center
    justify-content: center
    border-radius: 50%
    border: 2px solid transparent
    cursor: pointer

    &.active
      background-color: var(--color-b40)
      border-color: var(--color-b700)
    &:hover
      background-color: var(--color-b40)

    .color
      width: 21px
      height: 21px
      border-radius: 50%
    .emoji
      font-size: 21px
      line-height: 21px
      padding-bottom: 1px
