.wrapper
  display: flex
  flex-direction: column
  justify-content: space-around
  gap: 7px
  padding: 12px 0
  width: 100%
  height: 100%
  align-items: flex-start

.tools
  display: flex
  gap: 10px
  align-items: center
  width: 100%

.delimetr
  width: 2px
  height: 28px
  background-color: var(--color-d100)
