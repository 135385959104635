.start
  display: flex
  align-items: center
  justify-content: center
  gap: 12px
  flex-direction: column
  height: 100%
  width: 100%
  position: relative
  & > div
    flex-grow: 0
