
.wrap
    width: 390px
    .title
        margin: 0
    .text
        margin: 0 0 27px
        padding: 0 10px
        text-align: center
        font-size: 13px
        line-height: 18px
        color: var(--color-text-grey)
        font-family: 'SF Pro Text', sans-serif
    .icon
        width: 60px
        height: 60px
        margin: 20px auto
