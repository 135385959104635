
.wrap
  padding: 10px
  height: calc(100% - 45px)

.top
  display: grid
  grid-template-columns: repeat(6, 1fr)
  grid-template-rows: 1fr
  grid-column-gap: 10px
  grid-row-gap: 10px

.date
  grid-area: 1 / 1 / 2 / 3
  display: flex
  gap: 10px
  align-items: center
  padding: 9px 10px
  border-radius: 10px
  border: 1px solid var(--color-blue-smooth)
  margin-bottom: 10px
  font-size: 14px
  .day
    width: 22px
    line-height: 22px
    border-radius: 50%
    background: var(--color-blue-smooth)
    color: var(--color-white)
    text-align: center
    font-size: 13px
    font-family: "SF Pro Light", sans-serif
.weekBox
  grid-area: 1 / 3 / 2 / 7
  margin-left: 50px

.girdBoardWrap
  width: 100%
  height: calc(100% - 55px)

.grid-bar
  display: grid
  grid-template-columns: repeat(6, minmax(150px, 1fr))
  grid-template-rows: repeat(2, 1fr)
  gap: 10px
  .medium-item
    grid-area: 1 / 1 / 2 / 3
  .small-item-left
    grid-area: 2 / 1 / 3 / 2
  .small-item-right
    grid-area: 2 / 2 / 3 / 3
  .big-item
    grid-area: 1 / 3 / 3 / 7

.hidden
  display: none

.expanded-meetings-board
  display: flex
  align-items: flex-start
  justify-content: center
  .expanded-board
    height: 100%
    width: 100%

.expanded-calls-board
  display: flex
  align-items: flex-start
  justify-content: center
  .expanded-board
    height: 100%
    width: 100%

.expanded-mail-board
  display: flex
  align-items: flex-start
  justify-content: center
  .expanded-board
    height: 100%
    width: 100%

.expanded-tasks-board
  display: flex
  align-items: flex-start
  justify-content: center
  .expanded-board
    height: 100%
    width: 100%
