.taskBlock
  &:hover .point
    display: block
.point
  display: none
  filter: drop-shadow(0 0 5px rgba(0,0,0,0.25))
  cursor: pointer
  &:hover
    r: 10px

.pointChild
  filter: drop-shadow(0 0 5px rgba(0,0,0,0.25))
  cursor: pointer
  &:hover
    r: 10px
