.container
    padding: 24px 12px 24px 40px
    flex-grow: 1

.header
    display: flex
    align-items: center

.names
    margin-left: 8px
    display: flex
    flex-direction: column
    justify-content: space-between
    flex-grow: 1

.name
    color: #274A42
    font-family: Roboto
    font-size: 20px
    font-weight: 500
    line-height: 28px

.date
    color: #56716B
    font-size: 12px
    line-height: 16px

.body
    margin-top: 20px

.list
    margin-top: 20px

.listFlex
    display: flex
    align-items: center

.title
    display: inline-block
    color: #6E8680
    font-size: 12px
    line-height: 16px
    width: 96px
    padding-right: 4px

.value
    color: #274A42
    font-size: 12px
    font-weight: 500
    line-height: 16px

.inlineList
    display: inline-flex
    align-items: center
    gap: 8px
