@keyframes appearance
    0%
        width: 1px
    99%
        width: 54vw
.contentWrapper
    background: #fff
    user-select: none
    -moz-user-select: none
    -webkit-user-select: none
    position: relative
    border-radius: 7px
    .select
        position: absolute
        right: 50%
        transform: translateX(100px)
        bottom: 41px
        background: #fff
        width: 54px
        height: 34px
        border-radius: 3px
        text-align: center
        -webkit-appearance: none
        -moz-appearance: none
    &::-ms-expand
        display: none
    .contentPreview
        animation: appearance .2s ease
        max-width: 54vw
        padding: 20px
        .videoWrapper
            text-align: center
            height: 100%

.darkTheme.contentWrapper
    background-color: #292929
    .select
        background: #292929
        color: #fff
        border: 1px solid #4E4E4E
