
.editTaskWrap
  width: 380px
  padding-top: 10px
  display: flex
  flex-direction: column
  gap: 15px
  line-height: 18px
  font-size: 13px
  text-align: left
  .eventsList
    padding-top: 10px
    .eventItem
      padding: 5px 10px
      cursor: pointer
      p
        margin: 0
      &:hover
        background: var(--color-blue-light)
  .btns
    display: flex
    justify-content: center
    gap: 15px
  .dates
    display: flex
    justify-content: space-between
