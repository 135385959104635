.container
    height: 44px
    width: 100%
    border-radius: 4px
    background: #F0F5FE
    padding: 6px 8px 6px 20px
    position: relative
    overflow: hidden
    cursor: pointer
    transition: all 0.1s ease-in-out
    &:hover
        background: #cae8f8
    // стили для звонков
    &_calls
        background: #DDEEDC
        .info
            color: var(--main-green)
            svg
                fill: var(--main-green)
                width: 14px
                height: 14px
        .border
            background: var(--main-green)
        &:hover
            background: #e2f5e0
    // стили для почты    
    &_mails
        background: #FAF6EC
        .info
            color: var(--color-secondary-orange)
            svg
                fill: var(--color-secondary-orange)
        .border
            background: var(--color-secondary-orange)
        &:hover
            background: #f9f0db
    // стили для митов
    &_meetings, &_offlineMeetings, &_onlineMeetings
        background: #F6EFFA
        .info
            color: var(--tag-7, #943BBC)
            svg
                fill: var(--tag-7, #943BBC)
        .border
            background: var(--tag-7, #943BBC)
        &:hover
            background: #eedcf8
    // стили для напоминания
    &_reminder
        background: #F1F6F7
        .info
            color: var(--tag-9, #4F8F9F)
            svg
                fill: var(--tag-9, #4F8F9F)
        .border
            background: var(--tag-9, #4F8F9F)
        &:hover
            background: #e4f5f9
.border
    content: ''
    position: absolute
    width: 5px
    height: 32px
    left: 8px
    top: 50%
    transform: translateY(-50%)
    background: var(--color-blue-smooth)
    border-radius: 2px

.info
    font-size: 12px
    line-height: 16px
    color: var(--color-blue-smooth)
    display: flex
    justify-content: space-between

.title
    font-weight: 500
    white-space: nowrap
    overflow: hidden
    text-overflow: ellipsis
    svg
        margin-right: 5px

.text, .text p
    white-space: nowrap
    overflow: hidden
    text-overflow: ellipsis
