.wrap
  width: 360px
  max-width: 100%
  padding: 12px 16px

.contentWrap
  display: flex
  flex-direction: column
  gap: 24px

.fieldBox:not(:last-child)
  margin-bottom: 12px

.buttonsWrap
  display: flex
  gap: 10px

.rightBtns
  display: flex
  gap: 10px
  margin-left: auto

.boxCheked
  display: flex
  gap: 8px
  align-items: center

  &__content
    font-size: 12px
    color: var(--color-d800)