
.wrap
    padding: 10px
    color: var(--color-text-grey)
    max-height: calc(100% - 210px)
    overflow-y: auto
    .block
        margin-bottom: 20px
    .date
        display: block
        margin: 0 auto 16px
        width: max-content
        padding: 4px 11px
        font-size: 11px
        color: var(--color-silver-chalice-darker)
        border: 1px solid var(--color-silver-chalice-darker)
        border-radius: 10px
        font-family: "SF Pro Text", sans-serif
.rolesWrap
    padding-top: 10px
    border-bottom: 1px solid var(--color-border-dark)
