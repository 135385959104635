.arrow
  transform: rotate(-90deg)
  flex-shrink: 0
.arrowOpen
  transform: rotate(90deg)
  flex-shrink: 0

.item
  padding: 8px 12px
  color: var(--color-d800)
  font-size: 12px
  font-style: normal
  font-weight: 400
  line-height: 20px
  cursor: pointer
  &:hover
    color: var(--color-b800)

.label
  &:hover, &.active
    background: var(--color-b100)
