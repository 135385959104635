
.container
    display: flex
    gap: 2px

.leftButton
    border-top-right-radius: 0
    border-bottom-right-radius: 0

.rightButton
    border-top-right-radius: 4px
    border-bottom-right-radius: 4px
    width: 20px
    height: 28px
    background: var(--color-border-dark)

.content
    display: flex
    flex-direction: column
    border-radius: 8px
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.20), 0px 1px 10px 0px rgba(0, 0, 0, 0.12), 0px 4px 5px 0px rgba(0, 0, 0, 0.14)

.title
    background: var(--Primary-N100, #EAEDED)
    padding: 2px 12px
    color: var(--DarkGreen-D800, #274A42)
    font-size: 12px
    font-weight: 500
    line-height: 20px

.item
    padding: 0 12px
    height: 32px
    display: flex
    gap: 4px
    align-items: center
    color: var(--DarkGreen-D800, #274A42)
    font-size: 12px
    font-weight: 400
    line-height: 20px
    cursor: pointer
    transition: all 0.2s ease-in-out
    &:hover
        background: #EDF9FE
.item:last-child
    border-top: 1px solid #EAEDED
