
.wrap
    padding: 10px
    max-height: calc(100% - 150px)
    overflow-y: auto
    .date
        text-align: center
        font-family: 'SF Pro Text', sans-serif
        font-size: 11px
        line-height: 13px
        color: var(--color-text-grey-light)
        margin: 30px 0 18px
    .taskBox:not(:last-child)
        margin-bottom: 20px
