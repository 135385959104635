.container
    padding: 8px
    display: flex
    align-items: center
    gap: 8px
    border-radius: 4px
    background: var(--DarkGreen-D40, #F1F3F2)
    width: 186px
    position: relative
    overflow: hidden
    img
        width: 32px
        height: 32px

.info
    display: flex
    flex-direction: column
    overflow: hidden
.title
    color: var(--DarkGreen-D800, #274A42)
    font-size: 14px
    font-weight: 500
    line-height: 20px
    white-space: nowrap
    overflow: hidden
    text-overflow: ellipsis
    padding-right: 8px

.link
    color: var(--Primary-N800, #425D5A)
    font-size: 12px
    font-weight: 400
    line-height: 16px
    white-space: nowrap
    overflow: hidden
    text-overflow: ellipsis

.date
    color: var(--Primary-N800, #425D5A)
    font-size: 12px
    font-weight: 500
    line-height: 16px
    white-space: nowrap
    overflow: hidden
    text-overflow: ellipsis

.settings
    position: absolute
    top: 0
    right: -100%
    bottom: 0
    background: var(--DarkGreen-D40, #F1F3F2)
    transition: all 0.3s ease-in-out
    display: flex
    align-items: center
    gap: 4px
    padding: 0 8px
    &.show
        right: 0

.emoji
    position: absolute
    width: 15px
    height: 15px
    top: 5px
    right: 8px
    font-size: 14px
