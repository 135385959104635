
.container
  position: relative

  .wrap
    position: absolute
    top: calc(100% + 10px)
    right: 0
    width: 200px
    box-shadow: 0px 2px 10px var(--color-shadow)
    background: var(--color-white)
    border: 5px solid var(--color-white)
    border-radius: 4px
    cursor: pointer
    z-index: 1
    &::before
      content: ""
      position: absolute
      width: 7px
      height: 7px
      background: var(--color-white)
      top: -8px
      right: 2px
      transform: rotate(45deg)
  .left
    left: 0
    top: 100%
    z-index: 1
    &::before
      left: 2px

  .filters
    max-height: 370px
    overflow-y: auto
    color: var(--color-text-grey)
    font-size: 10px

  .row
    display: flex
    align-items: center
    justify-content: space-between
    gap: 10px
    padding: 5px
    border-bottom: 1px solid var(--color-border-dark)
    line-height: 16px
    & span
      flex-grow: 1
  .calendar path
    fill: var(--color-blue-smooth)

  .inputBox
    margin: 5px 0 5px 5px
    border-radius: 20px
    padding: 2px
    display: flex
    align-items: center
    gap: 5px
  .border
    border: 1px solid var(--color-border-dark)
  .input
    border: none
    width: 80%
    font-size: 10px
    line-height: 12px
  .clear
    position: relative
    width: 16px
    height: 16px
    cursor: pointer
    padding: 0
    .span
      position: absolute
      top: 7px
      left: 2px
      width: 11px
      height: 2px
      background: var(--color-red)
      transform: rotate(45deg)
    .span:before
      content: ''
      position: absolute
      top: 0
      right: 0
      width: 11px
      height: 2px
      background: inherit
      transform: rotate(90deg)
  .task
    padding: 10px 5px
    border-top: 1px solid var(--color-border-dark)
    display: flex
    gap: 5px
  .hightlight
    color: var(--color-blue-smooth)
    text-decoration: underline
