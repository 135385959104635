
.root
  flex-grow: 1
  display: flex
  flex-direction: column
  overflow-y: auto
  overflow-x: hidden
  padding-bottom: 20px
  position: relative
  &.marginTop
    margin-top: 10px
.bgnd
  background: var(--color-blue-light)
.drop
  position: absolute
  top: 0
  left: 0
  width: 100%
  height: 100%
  background: transparent
