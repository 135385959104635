@import "src/theme/theme"

.root
  width: 660px

.mainContent
  display: flex
  flex-direction: column
  row-gap: 15px
  padding: 16px

.users
  min-height: 200px
  max-height: 324px
  overflow-y: auto
  overflow-x: hidden
  position: relative

.buttonsWrapper
  display: flex
  justify-content: space-between
  .rightBtns
    display: flex
    justify-content: flex-end
    gap: 10px
    flex-grow: 1
