@import src/theme/global

.sidebar
  width: 296px
  background: var(--color-b50)
  padding: 0 16px 16px
  border-radius: 4px
  flex-grow: 0
  align-self: flex-start
  .row
    padding: 8px 0
    display: flex
    gap: 4px
    .label
      padding: 6px 0
      @include textEllipsis
      color: var(--color-d600)
      font-size: 12px
      line-height: 16px
      flex: 0 0 104px
    .select
      flex: 1 0 auto
      color: var(--color-d800 )
      font-size: 12px
      font-weight: 500
      line-height: 20px
      & > div > div
        display: flex
        align-items: center
        gap: 8px
        max-width: 154px
        width: max-content
        padding: 4px 12px
        cursor: pointer
        border-radius: 4px
        span
          @include textEllipsis
    .authorBlock
      flex: 1 0 auto
      color: var(--color-d800 )
      font-size: 12px
      font-weight: 500
      line-height: 20px
      display: flex
      align-items: center
      gap: 4px
      max-width: 154px

      span
        @include textEllipsis
    .selectDate
      flex: 1 0 auto
      color: var(--color-d800 )
      font-size: 12px
      font-weight: 500
      line-height: 20px
      & input
        width: 156px
        background: none
        border: none
        color: var(--color-d800 )
        font-size: 12px
        font-weight: 500
        line-height: 20px
        padding: 4px 12px
        cursor: pointer
