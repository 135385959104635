
.deleteWrap
  width: 408px
.deleteTaskText
  max-width: 257px
  margin: 0 auto
  font-size: 12px
  line-height: 22px
  text-align: center
  padding: 15px 0
  color: var(--color-text-grey-light)
.btns
  display: flex
  gap: 10px
  justify-content: center
