
.container
    max-height: 400px
    width: 400px
    padding: 20px 16px

.content
    display: flex
    flex-direction: column
    gap: 10px

.buttons
    margin-top: 25px
    display: flex
    justify-content: flex-end
    gap: 8px
