.wrap
  width: 360px
  max-width: 100%

  .body
    padding: 16px
    
    .btns
      margin-top: 24px
      display: flex
      justify-content: flex-end
      flex-grow: 1
      gap: 8px
