
.addButtonBlock
  display: flex
  align-items: center
  padding: 0 10px
.addFileButtonInput
  display: flex
  flex-direction: column
  justify-content: center
  align-items: center
  width: 175px
  height: 185px
  background: transparent
  border: 1px dashed var( --color-grey-secondary)
  border-radius: 6px
  margin: 0 30px 15px 0
  padding: 0
  position: relative
  cursor: pointer
  .addIcon
    margin: 0 0 23px 0
    path
      transition: fill 0.2s ease-in-out
  span
    font-weight: 400
    font-size: 10px
    line-height: 14px
    text-align: center
    color: #9F9F9F
    // input: hover ~ .addIcon path
    fill: var(--color-primary)
    transition: fill 0.2s ease-in-out
  &:hover
    border: 1px dashed var(--color-primary)
