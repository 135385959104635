
.miniTag
  width: 100%
  max-width: 100px
  line-height: 14px
  font-family: 'Roboto', sans-serif
  font-weight: 400
  font-size: 10px
  border-radius: 100px
  border: 1px solid transparent
  overflow: hidden
  white-space: nowrap
  text-overflow: ellipsis
  user-select: none
  padding: 0 8px
  &.light
    color: var(--color-blue-smooth)
    background: var(--color-white)
    border-color: var(--color-periwinkle-gray)
  &.blue
    color: var(--color-text-grey)
    background: var(--color-blue-light)
    border-color: var(--color-blue-light)
  &.grey
    color: var(--color-text-grey)
    background: var(--color-background-light)
    border-color: var(--color-background-light)
