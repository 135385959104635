.tox-tinymce
  border: none !important
  .tox-editor-header
    border-top: unset !important
  .tox-toolbar__primary
    display: flex
    justify-content: space-between
  .atachments-tinymce__list
    display: flex
    flex-wrap: wrap
    gap: 6px
    padding: 0 1rem 4px 1rem
    list-style: none
    margin: 0
