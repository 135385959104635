@import "src/theme/cutsStr"
  
.container
  flex: 1
  display: flex
  align-items: center
  justify-content: center
  gap: 10px
  color: var(--color-d500)
  font-size: 16px
  font-family: "Roboto", sans-serif
  width: 100%
  height: 100%
  

  &.column
    flex-direction: column
    font-size: 14px

  span
    max-width: 300px
  svg
    flex-shrink: 0

  .text
    & > strong
      @include cutsStr(1)
