
.container
    height: 100%
    display: flex
    width: 100%
    max-width: 450px
    margin: 0 auto
    flex-direction: column
    justify-content: center
    h4
        font-family: "SF Pro Text", sans-serif
        font-style: normal
        font-weight: 590
        font-size: 36px
        line-height: 43px
        color: var(--color-white)
    p
        font-family: "SF Pro Text", sans-serif
        font-style: normal
        font-weight: 400
        font-size: 16px
        line-height: 19px
        color: var(--color-white)
        margin: 15px 0

.button
    display: flex
    align-items: center
    justify-content: flex-start
    width: 100%
    max-width: 450px
    margin-top: 15px

.confirm_button
    width: 100%
    background: var(--color-c400)
    color: var(--color-black)
    font-family: "SF Pro Text", sans-serif
    font-style: normal
    font-weight: 650
    font-size: 16px
    line-height: 19px
    letter-spacing: -0.0865063px
    text-align: center
    margin-top: 45px

.animation
    background: var(--color-blue-dark)

.info
    font-family: "SF Pro Text", sans-serif
    font-style: normal
    font-weight: 400
    font-size: 12px
    line-height: 20px
    color: var(--color-white)
    display: block
    margin-top: 15px

.resendButton
    color: var(--color-c400)
    margin-left: 5px

@media (max-width: 768px)
    .wrapper
        flex-grow: 1
        margin-top: 70px
