
.wrap
  display: flex
  margin-left: 24px
  border: 1px solid var(--color-border-dark)
  border-radius: 6px
  .button
    padding: 0 12px
    border-right: 1px solid var(--color-border-dark)
    font-size: 12px
    color: var(--color-d700)
    white-space: nowrap
    &.active
      background: var(--color-d50)
    &:last-child
      border: none
