
.wrap
    width: 700px
    padding: 10px 10px 0 0
    text-align: left
    font-family: 'SF Pro Text', sans-serif
    font-size: 13px
    line-height: 16px
    color: var(--color-text-grey)
    max-height: 80vh
    overflow: auto
    .label
        margin: 0 0 13px
    .row
        display: flex
        gap: 10px
        margin-bottom: 12px
        .container
            width: 100%
        .executorsSelect
            max-width: 338px

    .input, .epic
        width: 100%
        padding: 10px
        line-height: 17px
        border: 1px solid var(--color-border-dark)
        border-radius: 5px

        &::placeholder
            color: var(--color-grey)
    .placeholder
        color: var(--color-grey)

    .epic
        cursor: pointer
    .activeEpic
        background: var(--color-blue-smooth)
        color: var(--color-white)
    .dateBox
        flex: 0 0 auto
        width: 170px
        height: 39px
        border-radius: 5px
        padding: 8px
        border: 1px solid var(--color-border-dark)
        cursor: pointer
        font-family: "SF Pro Text", sans-serif
        display: flex
        align-items: center
        justify-content: space-between
        .calendarIcon
            width: 100%
            height: 100%
        .clear
            position: relative
            width: 14px
            height: 2px
            background: var(--color-border-dark)
            transform: rotate(45deg)
        .clear:before
            content: ''
            position: absolute
            top: 0
            right: 0
            width: 14px
            height: 2px
            background: inherit
            transform: rotate(90deg)
        .clear:hover
            background: var(--color-secondary)

    .tags
        padding: 10px 0
        p
            margin: 0
            padding: 5px
            cursor: pointer
            &:hover
                background: var(--color-blue-light)

    .loadFile
        width: 100%
        padding: 4px
        border: 1px solid var(--color-border-dark)
        border-radius: 5px

        .filesBox, .files
            display: flex
            align-items: center
            gap: 8px
        .filesBox
            justify-content: space-between
        .loadFileBtn
            color: var(--color-blue-smooth)
            text-decoration: underline
            line-height: 27px
            padding: 0
        .fileName
            width: 180px
            white-space: nowrap
            text-overflow: ellipsis
            overflow: hidden
        .files
            width: 230px
            overflow: hidden
            padding-top: 2px
            padding-left: 2px

    .textarea
        outline: 0
        resize: none
        width: 100%
        font-size: 11px
        line-height: 16px
        font-family: "SF Pro Text", sans-serif
        letter-spacing: 0.023em
        padding: 16px 20px
        border: 1px solid var(--color-border-dark)
        border-radius: 4px
        margin-bottom: 30px

    .tasksList
        margin: 10px 0
        .user:hover
            background: var(--color-blue-light)
        .task
            padding: 6px 10px
            &:hover
                background: var(--color-blue-light)
        .noIcon
            width: 14px
    .task
        display: flex
        gap: 10px
        align-items: center
        padding: 0
        cursor: pointer

    .btns
        display: flex
        gap: 10px
        justify-content: center

    .image
        width: 23px
        height: 23px
        border-radius: 50%
        object-fit: cover
    .authorSelect > div
        padding: 3px 10px
    .parentTask
        position: relative
    .taskFilter
        display: flex
        width: 100%
        background: var(--color-white)

    .filterList
        width: 25%
        border-right: 1px solid var(--color-border-dark)
        height: 275px
        overflow-y: scroll
        .item
            display: flex
            align-items: center
            justify-content: space-between
            padding: 5px
            cursor: pointer
            min-height: 32px
            &:hover
                background: var(--color-blue-light)

            & > div
                padding: 0
                font-size: 10px
            & img
                width: 22px
                height: 22px
            & > div:first-child
                max-width: 80%
                overflow: hidden
        &:last-child
            width: 50%
        .task
            margin-bottom: 8px
            padding-left: 10px
            font-size: 10px
            &:hover
                background: var(--color-blue-light)
    .task
        display: block

    .header
        position: sticky
        top: 0
        z-index: 10
        background: var(--color-white)
        text-align: center
        padding: 8px
        border-bottom: 1px solid var(--color-border-dark)
    .check, .noCheck
        width: 19px
        height: 19px
    .noCheck
        border-radius: 50%
        border: 1px solid var(--color-border-dark)
    .open
        border-radius: 5px 5px 0 0
        border-bottom: none
.hidden
    display: none
