
.container
  position: relative
  width: 100%
  height: 100%
  max-height: 90vh
  &:hover .servicesPanel
    opacity: 1
    transition: opacity 0.1s ease 0s
.video
  display: block
  margin: 0 auto

.servicesPanel
  opacity: 0
  position: absolute
  bottom: calc(10% + 52px)
  left: 50%
  width: 360px
  transform: translateX(-50%)
  background: rgba(0, 0, 0, 0.6)
  backdrop-filter: blur(6px)
  padding: 15px
  border-radius: 8px
  transition: opacity 0.5s ease 0.5s
.controls
  display: flex
  justify-content: space-between
  align-items: center
  margin-bottom: 20px
.volumeBlock
  display: flex
  justify-content: space-between
  align-items: center
  gap: 13px
  .volumeInput
    width: 70px
    height: 4px
.services
  width: 117px
  display: flex
  justify-content: flex-end
  align-items: center
  gap: 20px
.close
  padding: 0
  & path
    fill: var(--color-white)
.progressBar
  display: flex
  justify-content: space-between
  align-items: center
  gap: 10px
  .progressInput
    flex: 1 1 auto
    height: 4px
.time
  color: var(--color-d50)
  font-size: 14px
  line-height: 18px
.empty
  width: 117px

input[type="range"]
  -webkit-appearance: none
  width: 100%
  border-radius: 6px
  outline: 0
  background: #E7EBEA

input[type=range]::-webkit-slider-thumb
  -webkit-appearance: none
  height: 12px
  width: 12px
  background: #E7EBEA
  border-radius: 50%
  border: 0
  cursor: pointer

/* All the same stuff for Firefox */
input[type=range]::-moz-range-thumb
  height: 12px
  width: 12px
  background: #E7EBEA
  border: 0
  border-radius: 50%
  cursor: pointer

  /* All the same stuff for IE */
input[type=range]::-ms-thumb
  height: 12px
  width: 12px
  background: #E7EBEA
  border-radius: 50%
  border: 0
  cursor: pointer
