
.tooltipe:hover:after
    content: attr(data-title)
    z-index: 999
    position: absolute
    top: -35px
    right: -10px
    width: max-content
    padding: 6px 9px
    font-size: 9px
    color: var(--color-text-grey)
    background-color: var(--color-white)
    border-radius: 3px
    box-shadow: 0 1px 5px var(--color-shadow)

.tooltipe:hover:before
    content: ""
    z-index: 1000
    position: absolute
    top: -10px
    right: 3px
    border-style: solid
    border-width: 6px 6px 0 6px
    border-color: var(--color-white) transparent
