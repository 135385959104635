@import "src/theme/global"
.workAreaWrap
  display: flex
  width: 100%
  height: 100%
  .category
    display: flex
    padding: 12px 10px 10px 8px
    align-items: center
    gap: 14px
    align-self: stretch
    background: #FFF
    box-shadow: 0px 1px 0px 0px #DFDFDF inset
    font-size: 13.5px
    font-weight: 400
    letter-spacing: -0.062px
    color: var(--color-d800)
    .imgBox
      flex-shrink: 0
    .name
      flex: 1 1 auto
      opacity: 1
      transition: opacity 0.2s ease
      @include textEllipsis
    .amount
      opacity: 1
      transition: opacity 0.2s ease
    &:hover
      background: var(--color-b50)
    &.menuItemCollapsed .name, &.menuItemCollapsed .amount
      opacity: 0
      transition: opacity 0.2s ease

  .active
    background: var(--color-b50)
