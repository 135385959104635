.progress
    width: 22px
    height: 22px
    border-radius: 50%
    position: absolute
    top: 50%
    left: 50%
    z-index: 20
    transform: translate(-50%, -50%)
    transition: all .2s linear

    &::after
        content: ""
        width: 70%
        height: 70%
        border-radius: 50%
        transform: translate(-50%, -50%)
        position: absolute
        top: 50%
        left: 50%
        background: var(--color-white)
        z-index: 1

.checkIcon
    position: absolute
    top: 50%
    left: 50%
    transform: translate(-50%, -50%)
    transition: all .2s linear
    flex-shrink: 0
