
.wrap
  display: flex
  padding: 10px
  border-bottom: 1px solid var(--color-border-dark)
  .mainPanel
    display: flex
    justify-content: center
    flex: 1 1 auto
  .btnsPanel
    display: flex
    gap: 5px
    justify-content: flex-end
    width: 180px
    flex: 0 0 auto
    border-left: 1px solid var(--color-border-dark)
  .trashIcon path
    fill: var(--color-red)-dark
  .selectBtn
    border: 1px solid var(--color-border-dark)
    border-radius: 6px
    background: var(--color-background-light)
    cursor: pointer
    font-family: "SF Pro Text", sans-serif
    font-size: 12px
    line-height: 14px
    padding: 4px 10px
    margin-right: 10px
  .active
    border: 1px solid var(--color-blue-smooth)
    background: var(--color-blue-light)
  .toolbar
    margin-left: 80px
  .left
    margin-left: 180px
