
.avatarBlock
    display: flex
    align-items: stretch
    gap: 8px
    margin-bottom: 16px
    & > .uploadBtns
        display: flex
        flex-direction: column
        align-items: stretch
        justify-content: space-between
        & > span
            flex: 0
