
.wrap
  height: 100%
  width: 910px
  padding: 10px
  overflow-y: auto
  font-family: "SF Prop Text", sans-serif
  color: var(--color-text-grey)
  .month
    border: 1px solid var(--color-blue-smooth)
    border-radius: 10px
    height: calc(100% - 55px)
    max-height: 890px
    display: flex
    flex-direction: column
    align-items: stretch
    overflow: hidden
  .weekRow
    flex: 1 1
    display: flex
  .dayBox
    flex: 1
    padding: 3px 5px
    border: 1px solid var(--color-border-dark)
    font-size: 13px
    display: flex
    position: relative
  .dayValue
    text-align: end
    position: absolute
    top: 2px
    right: 2px
    line-height: 20px
    width: 22px
    border: 1px solid transparent
    text-align: center
    border-radius: 50%
    cursor: pointer
    &:hover
      border-color: var(--color-blue-smooth)
  .light
    color: var(--color-text-grey-light)
  .hidden
    visibility: hidden
  .today
    border: 1px solid var(--color-blue-smooth)
    border-radius: 6px
    background: var(--color-blue-light)
  .tasksContainer
    height: 100%
    width: 100%
    display: flex
    align-items: center
    justify-content: center
  .tasks
    display: flex
    flex-wrap: wrap
    gap: 8px
    align-items: center
    justify-content: center
    max-width: 92px
