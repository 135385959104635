.wrapper
    width: 100%
    display: flex
    justify-content: space-between
    align-items: center
    gap: 8px

.buttons
    display: flex
    justify-content: flex-end
    gap: 8px
