
.wrap
    width: 410px
    margin-top: 20px
    border-top: 1px solid var(--color-border-dark)
    padding-top: 30px
    .text
        margin: 0 auto 16px
        font-family: 'SF Pro Light', sans-serif
        font-weight: 400
        font-size: 14px
        line-height: 20px
        color: var(--color-grey)
        width: 270px
    .btns
        display: flex
        justify-content: space-between
        gap: 10px
        margin-bottom: 40px
        .input
            display: none
