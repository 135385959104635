@import src/theme/global

.list
  width: 440px
  padding: 8px 0
  li:not(:last-child)
    box-shadow: 0px -1px 0px 0px var(--color-d100) inset
.letter
  padding: 8px 12px
  display: flex
  gap: 10px
  cursor: pointer
  &:hover
    background: var(--color-b50)
  .left
    flex: 1
.from
  color: var(--color-d700)
  font-size: 14px
  font-weight: 500
  line-height: 20px
  margin: 0
  width: 330px
  @include textEllipsis
.subject, .time
  color: var(--color-d600)
  font-size: 14px
  line-height: 20px
  margin: 0
.text
  font-size: 12px
  width: 330px
  @include textEllipsis
.time
  flex: 0 0
.loaderBox
  width: 440px
  height: 100px
.highlight
  background: var(--color-b800)
  color: var(--color-white)
