.content
    padding: 12px 16px
    width: 450px

.title
    font-weight: 400
    font-size: 14px
    line-height: 18px
    color: #3E5D56
    margin-bottom: 12px
    span
        display: block
        font-weight: 400
        font-size: 12px
        line-height: 16px
        color: #56716B

.select
    margin-top: 10px
    padding: 12px 12px 8px
    background: #CFD7D5
    border-radius: 4px
    font-weight: 400
    font-size: 14px
    line-height: 18px
    color: #3E5D56
    margin-bottom: 12px
    display: flex
    flex-direction: column
    gap: 10px

.buttons
    margin-top: 30px
    display: flex
    justify-content: space-between

.rightButtons
    display: flex
    gap: 6px

.option
    display: flex
    gap: 6px
    align-items: center
