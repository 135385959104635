
.wrap
  position: relative
.container
  position: absolute
  top: calc(100% + 10px)
  width: 350px
  right: 0
  border-radius: 6px
  box-shadow: 0 4px 4px var(--color-shadow)
  font-size: 11px
  line-height: 16px
  z-index: 100
  .title
    font-family: "SF Pro SemiBold", sans-serif
    background: var(--color-blue-light)
    padding: 12px
    letter-spacing: 0.5px
  .textBlock
    padding: 15px 12px
    background: var(--color-white)
    max-height: 280px
    overflow-y: auto
  &::before
    content: ""
    width: 10px
    height: 10px
    position: absolute
    top: -5px
    right: 10px
    transform: rotate(45deg)
    background: var(--color-blue_light)
