@import src/theme/global
.wrap
  padding: 0 12px 12px
  height: 100%
  flex: 1 1 auto
  overflow-x: hidden
  overflow-y: auto
  border-left: 1px solid var(--color-d100)
  position: relative
.header
  display: flex
  justify-content: space-between
  align-items: center
  padding: 12px 0 8px
  position: sticky
  top: 0
  background: var(--color-white)

  .subject
    color: var(--color-d800)
    font-size: 16px
    font-weight: 500
    line-height: 24px
    flex: 1 1
    padding: 4px 0
    @include textEllipsis

.date
  color: var(--color-d700), #3E5D56
  font-size: 12px
  font-weight: 500
  line-height: 16px
  text-align: center
  margin-bottom: 8px

.text
  font-size: 14px
  margin-bottom: 8px
.btns
  display: flex
  gap: 12px
  padding-top: 16px
.answer
  display: flex
  gap: 10px
  margin-bottom: 12px
.form
  flex: 1
  @include boxShadow
.attachments
  display: flex
  flex-wrap: wrap
  pap: 8px
