
.wrap
    margin: 10px
    height: calc(100% - 160px)
    display: flex
    align-items: flex-start
    overflow: auto
    color: var(--color-text-grey)

    .diagram
        display: table
        .head
            position: sticky
            top: 0
            z-index: 4
            background: var(--color-white)
        .monthName
            line-height: 28px
            font-size: 13px
            padding: 0 10px
            border-top: 1px solid var(--color-border-dark)
            border-right: 1px solid var(--color-border-dark)
            border-bottom: 1px solid var(--color-border-dark)
            flex: 1 0 auto
            &:first-child
                border-left: 1px solid var(--color-border-dark)
        .cellDay
            border-right: 1px solid var(--color-border-dark)
            border-bottom: 1px solid var(--color-border-dark)
            font-size: 10px
            padding: 2px
            color: var(--color-text-grey)
            text-align: center
            flex: 1 0 auto
            line-height: 26px
            &:first-child
                border-left: 1px solid var(--color-border-dark)
        .date
            padding: 0
        .today
            background: var(--color-red)-extraLight
        .epicPeriod
            position: absolute
            top: 50%
            z-index: 1
            height: 20px
            border-bottom: 4px solid var(--color-green-dark)
            transform: translateY(-50%)
        .sprint
            font-size: 13px
            color: var(--color-green)-dark
            position: sticky
            left: 635px
            white-space: nowrap
        .extraPeriodEpic
            border-bottom: 4px solid var(--color-green)-opacity
        .taskPeriod
            position: absolute
            top: 50%
            z-index: 1
            border-radius: 4px
            height: 20px
            transform: translateY(-50%)
            text-overflow: ellipsis
            overflow: hidden
            white-space: nowrap
            font-size: 10px
            color: var(--color-white)
            padding: 5px
            display: flex
            align-items: center
            justify-content: space-between
        .extraPeriod
            border-radius: 0 4px 4px 0
            opacity: 0.9
            background: repeating-linear-gradient(-60deg, var(--color-white) 0 2px, var(--color-red-dark) 0 20px)
        .withExtraPeriod
            border-radius: 4px 0 0 4px
        .icon path
            fill: var(--color-white)

    .tasks
        position: sticky
        left: 0
        background: #fff
        z-index: 10
        .bold
            font-weight: 700
            font-size: 14px

        .visible
            width: 110px
            padding: 5px
        .hidden
            width: 0
            padding: 0
            border: none !important
            overflow: hidden
        .head
            display: flex
            height: 57px
            border-bottom: 1px solid var(--color-border-dark)
            border-top: 1px solid var(--color-border-dark)
            border-left: 1px solid var(--color-border-dark)
            text-align: center
            font-size: 13px
            background: var(--color-white)
            line-height: 45px
            position: sticky
            top: 0
            z-index: 5
            & >div
                border-right: 1px solid var(--color-border-dark)
            .name
                width: 250px
                padding: 5px
        .plusBox
            display: flex
            align-items: center
            width: 40px
            justify-content: center
            .plusIcon
                cursor: pointer
        .body
            font-size: 12px
            border-left: 1px solid var(--color-border-dark)
            .row
                height: 30px
                display: flex
                border-bottom: 1px solid var(--color-border-dark)
                & > div
                    border-right: 1px solid var(--color-border-dark)
            .nameCell
                display: flex
                gap: 8px
                align-items: center
                justify-content: space-between
                width: 250px
                padding: 0 10px 0 5px
                .name
                    flex-grow: 1
                    display: block
                    text-overflow: ellipsis
                    overflow: hidden
                    white-space: nowrap
            .nameChild
                padding-left: 10px
            .executors
                display: flex
                align-items: center
                gap: 5px
                text-overflow: ellipsis
                overflow: hidden
                white-space: nowrap
            .circle
                display: inline-block
                width: 12px
                height: 12px
                border-radius: 50%
                transform: translateY(2px)
            .status
                width: max-content
                max-width: 90px
                white-space: nowrap
                text-overflow: ellipsis
                overflow: hidden
                margin: 0
            .role
                display: block
                color: var(--color-blue-smooth)
                border: 1px solid var(--color-blue-smooth)
                border-radius: 10px
                background: var(--color-white)
                padding: 3px 5px
                font-size: 10px
                width: max-content
                max-width: 90px
                white-space: nowrap
                text-overflow: ellipsis
                overflow: hidden
    .relative
        position: relative
    .fireIcon
        flex-shrink: 0
    .flex
        display: flex
    .indent
        height: 10px
        background: var(--color-gray-light)
        border: none !important
    .hiddenInput
        height: 0px
        margin: 0
        padding: 0
        border: none
        overflow: hidden
        position: absolute
    .tableTop
        transform: translateY(-30px)
    .openBtn
        width: 10px
        height: 30px
        border-radius: 0 4px 4px 0
        background: var(--color-border-dark)
        position: absolute
        top: calc(50% + 25px)
        left: 100%
        transform: translateY(-50%)
        line-height: 30px
        padding: 2px 2px 0
        cursor: pointer
        z-index: 2
        &:hover .arrowIcon path
            stroke: var(--color-blue-smooth)
            fill: var(--color-blue-smooth)

    .arrowIcon path
        stroke: var(--color-white)
        fill: var(--color-white)
    .arrowIconOpen
        transform: scaleX(-1)
    .openBtnStyky
        position: sticky
        top: 250px
        transform: translate(100%,-50%)
    .SVGBox
        position: absolute
        top: 0
