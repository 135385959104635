
.category
    width: 200px
    background: var(--color-background-light)
    border: 1px solid var(--color-border-dark)
    border-radius: 6px
    height: 100%
    padding: 5px
.categoryRow
    width: 100%
    height: auto
    min-height: 135px
    margin-bottom: 10px
.categoryColor
    width: 16px
    height: 16px
    border-radius: 50%
.title
    display: flex
    align-items: center
    justify-content: space-between
    line-height: 30px
    font-size: 13px
    color: var(--color-text-grey)
    border-bottom: 1px solid var(--color-border-dark)
    gap: 8px
    margin-bottom: 10px

.name
    flex-grow: 1
    text-overflow: ellipsis
    white-space: nowrap
    overflow: hidden
    font-family: "SF Pro Text", sans-serif
.noTask
    position: relative
    height: 260px
    background: var(--color-blue-light)
    border: 1px solid var(--color-blue-smooth)
    border-radius: 6px
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center
    cursor: pointer
.noTaskRow
    height: 135px

.list
    height: calc(100% - 34px)
    overflow-y: auto
    .taskWrap
        padding: 5px
    .dragEnter
        padding-top: 155px
        position: relative
        transition: padding 0.2s
        &::before
            content: ""
            width: calc(100% - 20px)
            height: 150px
            position: absolute
            top: 0
            left: 10px
            box-sizing: border-box
            border-radius: 6px
            background: url('/assets/PrivateCabinet/plus-3.svg')
            background-position: center
            background-repeat: no-repeat
            box-shadow: inset 0 0 10px 5px var(--color-shadow)

    .dragStart
        position: relative

        &::after
            content: ""
            position: absolute
            width: 100%
            height: 100%
            top: 0

.dragBoard
    position: relative
    box-shadow: inset 0 0 10px 5px var(--color-shadow)

.createArrow
    position: absolute
    left: 160px
    top: 70px
    transform: rotate(-70deg)
.createArrowRow
    left: 50%
.info
    position: absolute
    left: 200px
    top: 190px
    width: 320px
.infoRow
    left: 50%
.mainMenuItems
    margin: 10px 0

.noScroll
    overflow: visible
.opacity
    opacity: 0.1
