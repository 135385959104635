.wrap
    width: 440px
    max-height: 100%
.body
    padding: 12px 16px
.tabBlock
    padding-bottom: 16px
.form
    padding-bottom: 8px
    max-height: calc(100vh - 280px)
    overflow-y: auto

.btns
    padding-top: 16px
    display: flex
    justify-content: flex-end
    gap: 8px

.inputs
    display: flex
    flex-direction: column
    gap: 8px

.input
    height: 32px
    padding: 6px 8px 6px 12px
    width: 244px

.inputCompany
    width: 408px

.contactBox
    padding: 12px 12px 12px 0

.emailBox
    padding: 10px 12px
    background-color: var(--dark-green-d-530, #E7EBEA)
    border-radius: 4px
    .input
        width: 236px

.inputDate
    margin: 25px 0 8px
    position: relative
    input
        height: 32px
    & > span
        top: -7px
        position: absolute
        left: 6px
        background: var(--color-white)
        padding: 0 4px
        color: var(--color-d700)
        font-size: 12px
        line-height: 12px
        border-radius: 2px
        z-index: 1

.block
    margin-top: 20px
