
.wrap
    width: 410px
    padding-top: 10px

    .checkIcon
        width: 60px
        height: 60px
        margin: 0 auto 20px
        display: block
    .roles >div
        margin-bottom: 10px

    .text
        font-family: "SF Pro Regular", sans-serif
        font-size: 13px
        line-height: 16px
        color: var(--color-text-grey)
        margin: 0 auto 20px
        width: 80%
    .btns
        display: flex
        gap: 10px
        justify-content: center
