
.linear-blue
  background: var(--color-linear)

.linear-orange
  background: var(--color-linear-orange)

.linear-turquoise
  background: var(--color-linear-turquoise)

.linear-dark
  background: linear-gradient(180deg, #222222 0%, #3F3F3F 100%)
