@import "src/theme/global"
.item
  display: flex
  justify-content: space-between
  align-items: center
  gap: 10px
  padding: 10px 15px 10px 20px
  border-bottom: 1px solid var(--color-border-dark)
  &:last-child
    border: none
  .name
    flex: 1 1 auto
    font-size: 13px
    @include textEllipsis
.progress
  position: relative
  top: 0
  left: 0
  transform: translate(0, 0)
