.cabinetWrap
  width: 100vw
  height: 100vh
  display: flex
  overflow: hidden
  .workArea
    flex-grow: 1
    height: 100%
    overflow-y: hidden
    overflow-x: auto
    transition: width 0.2s ease, min-width 0.2s ease
  .main
    height: calc(100% - 50px)
.mainWrap
  height: 100%
  background: linear-gradient(90.08deg, #4543F7 -1.1%, #0A3AAB 102.36%)
