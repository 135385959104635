.btns
    display: flex
    border-radius: 4px
    border: 1px solid var(--color-d100)
    overflow: hidden

.btn
    padding: 4px 12px
    font-size: 12px
    line-height: 1.33em
    color: var(--color-d600)

    &.active
        background-color: var(--color-d100)
        color: var(--color-d800)
