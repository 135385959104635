
.root
  height: 100%
  display: flex
  flex-direction: column
  row-gap: 8px
.itemWrapper
  display: flex
  flex-direction: column
  row-gap: 8px
  &.margin
    margin-top: 16px
    margin-bottom: 8px
  &.flex
    flex: 1 1 auto
.topTitle
  font-family: 'SF Pro Text', sans-serif
  font-weight: 400
  font-size: 12px
  line-height: 14px
  color: var(--color-silver-chalice)
.propertiesInfo
  height: 45px
  display: flex
  align-items: center
  column-gap: 10px
  background: var(--color-white)
  border: 1px solid var(--color-border-dark)
  border-radius: 6px
  padding: 0 15px
.iconWrapper
  position: relative
.itemText
  font-family: 'SF Pro Text', sans-serif
  font-weight: 400
  font-size: 13px
  line-height: 16px
  color: var(--color-text-dark)
  word-break: break-word
  margin: 0
.parameterList
  display: flex
  flex-direction: column
  row-gap: 15px
  background: var(--color-white)
  border: 1px solid var(--color-border-dark)
  border-radius: 6px
  padding: 10px
  margin: 0
.parameterItem
  display: flex
  align-items: center
  column-gap: 10px
.parameterText
  font-family: 'SF Pro Text', sans-serif
  font-weight: 400
  font-size: 13px
  line-height: 16px
  color: var(--color-silver-chalice)
  margin: 0
  span
    color: var(--color-black)
.buttonsWrapper
  display: flex
  justify-content: flex-end
  gap: 8px
  margin-top: 45px
