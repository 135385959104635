.wrapper
    position: absolute
    display: flex
    flex-direction: column
    justify-content: space-between
    align-items: center
    height: 80%
    width: 30%
    background: #fff
    top: 50%
    left: 50%
    transform: translate(-50%, -50%)
    border-radius: 6px
    color: #575757
    font-size: 13px
    padding-bottom: 5px
    .valueItemsWrapper
        align-self: stretch
    .valueItem
        border-bottom: 1px solid #DFDFDF
        padding: 8px 0
        text-align: center
        cursor: pointer
        &:hover
            background: rgba(64,134,241,.1)
