
.wrap
    width: 560px
    .title
        font-family: 'SF Pro Medium', sans-serif
        font-weight: 500
        font-size: 18px
        line-height: 21px
        margin: 0
    .text
        padding: 0 10px
        text-align: center
        font-size: 13px
        line-height: 21px
        color: var(--color-text-grey)
        font-family: 'SF Pro Text', sans-serif
        margin: 0
        &:last-child
            margin-bottom: 25px
    .icon
        width: 50px
        height: 50px
        margin: 25px 0
    .iconGreen
        width: 50px
        height: 50px
        margin: 25px 0
        & path
            fill: var(--color-green-dark)
