.container
    display: flex
    align-items: flex-start
    padding: 10px 16px 0
    flex-direction: column
    overflow: hidden
    border-radius: 8px
    height: 100%

.header
    display: flex
    justify-content: space-between
    align-items: center
    width: 100%

.emptyWrapper
    padding-right: 16px
    width: 100%
    height: 100%
    display: flex
    align-items: center
    justify-content: center

.title
    color: var(--light-blue-b-700, #0288D1)
    font-family: Roboto
    font-size: 16px
    font-weight: 700
    line-height: 24px

.scrollWrapper
    width: 100%
    overflow: auto
    padding-bottom: 10px
    margin-top: 12px

.list
    width: 100%
    display: flex
    flex-direction: column
    gap: 4px
