.container
    padding: 12px
    background: #E7EBEA
    border-radius: 8px
    width: 320px
    height: 100%
    overflow: scroll
    max-width: 320px
    min-width: 320px

.block
    padding: 0 0 15px 0
    margin: 0 0 20px 0
    border-bottom: 1px solid #B6C2BF

.title
    font-weight: 500
    font-size: 14px
    line-height: 20px
    color: #56716B
    display: flex
    align-items: center
    gap: 6px
    svg
        fill: #56716B

.button, .inputDate
    display: flex
    justify-content: center
    align-items: center
    padding: 4px 8px
    background: #CFD7D5
    border-radius: 4px
    font-weight: 500
    font-size: 12px
    line-height: 16px
    color: #274A42
    cursor: pointer
    gap: 6px
    border: 2px solid #1565c000
    transition: all 0.3s ease-in-out
    svg
        max-width: 20px
        max-height: 16px
    &.active
        border: 2px solid #1565C0

.inputDate
    width: 220px
    padding: 4px 6px 4px 30px
    background-repeat: no-repeat
    background-position: left 6px center
    background-image: url("../../../assets/icons/calendar_5.svg")
    &::placeholder
        color: #274A42

.content
    margin-top: 6px
    display: flex
    flex-wrap: wrap
    gap: 6px

.color
    width: 22px
    height: 22px
    border-radius: 50%
    margin: 2px 4px
    cursor: pointer
    position: relative
    display: flex
    align-items: center
    justify-content: center
    &.active::before
        content: ''
        position: absolute
        width: 28px
        height: 28px
        border: 2px solid #1565C0
        top: 50%
        left: 50%
        transform: translate(-50%, -50%)
        border-radius: 50%
