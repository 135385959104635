.container
    height: 44px
    width: 100%
    border-radius: 4px
    background: var(--color-d40)
    padding: 6px 70px 6px 8px
    position: relative
    overflow: visible
    cursor: pointer
    transition: all 0.3 ease-in-out
    &:hover
        background: var(--color-d50)

.titleWrapper
    font-weight: 500
    font-size: 12px
    line-height: 16px
    color: var(--dark-green-d-800, #274A42)
    white-space: nowrap
    overflow: hidden
    text-overflow: ellipsis
    display: flex
    align-items: center
    justify-content: flex-start
    div
        width: 12px
        margin-right: 4px
        display: flex
        align-items: center
        svg
            width: 12px

.title
    white-space: nowrap
    overflow: hidden
    text-overflow: ellipsis

.desc
    display: flex
    width: 100%

.info
    font-size: 12px
    line-height: 16px
    color: var(--color-d600, #56716B)
    width: 100%
    white-space: nowrap
    overflow: hidden
    text-overflow: ellipsis
    p
        white-space: nowrap
        overflow: hidden
        text-overflow: ellipsis

.date
    font-weight: 700
    font-size: 12px
    line-height: 16px
    color: var(--dark-green-d-800, #274A42)
    margin-right: 4px
    white-space: nowrap

.tip
    position: absolute
    cursor: pointer
    font-size: 12px
    height: 24px
    width: 70px
    display: flex
    flex-direction: column
    align-items: center
    justify-content: center
    line-height: 16px
    right: 0
    top: 0
    padding: 2px 6px
    border-radius: 4px
    color: var(--dark-green-d-800, #274A42)
    background: var(--dark-green-d-100, #CFD7D5)
    &_IN_PROGRESS
        background: var(--dark-green-d-900, #0E352C)
        color: var(--color-d50, #E7EBEA)

.tooltip
    padding: 6px !important
    border-radius: 4px
    background: var(--color-white)
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25)
    gap: 6px
    display: flex
    flex-direction: column
    div
        width: 100%
        display: flex
        gap: 6px
        svg
            width: 16px
        button
            width: 83px
