
.wrapper
    display: flex
    justify-content: center
    align-items: center
    text-align: center
    width: 140px
    min-height: 39px
    border-radius: 5px
    cursor: pointer
    font-size: 14px
    transition: background .2s ease
    user-select: none
    width: max-content
    padding: 0 15px
    -moz-user-select: none
    -webkit-user-select: none
.cancel
    background: var(--color-border-dark)
    border: 1px solid var(--color-border-grey)
    color: grey
.cancel:hover
    background: var(--color-background-orange) !important
    color: var(--color-white)
.cancelRed
    background: var(--color-red)
    border: 1px solid var(--color-red)
    color: var(--color-white)
.cancelRed:hover
    background: var(--color-red) !important
    color: var(--color-white)
.ok
    background: var(--color-primary)
    border: 1px solid var(--color-primary)
    color: var(--color-white)
.ok:hover
    background: var(--color-secondary)
.yellow
    background-color: var(--color-yellow)
    text-align: center
    border-color: var(--color-yellow)
    width: max-content
    padding: 0 10px
    color: var(--color-white)
    &:hover
        background-color: var(--color-secondary-orange)
        border-color: var(--color-secondary-orange)
.disabled
    background: var(--color-border-grey)
    border: none
    color: grey
    cursor: default
    opacity: .7
    &:hover
        background: var(--color-border-grey)
        color: grey
