.chatComponent
  overflow: hidden
  height: 100%
  display: flex
  .ContextMenuItems
    margin: 10px 0
    div
      margin: 7px 0
  .sideMenu
    display: flex
    flex-direction: column
    height: 100%
    border-right: 1px solid rgb(174, 174, 174)
    .header
      display: flex
      align-items: center
      justify-content: space-between
      box-sizing: border-box
      padding: 0 15px
      width: 309px
      min-height: 68px
      .headerName
        display: flex
        align-items: center
        justify-content: space-between
        // color: #49494B
        font-family: "SF Pro Bold", sans-serif
        font-size: 15px
      #headerIcon
        margin-right: 9px
      #headerIcon path
        fill: #4086F1
      #headerArrow
        cursor: pointer
      #headerArrow:hover path
        fill: #4086F1
    .boardOptions
      display: flex
      align-items: center
      justify-content: space-around
      min-height: 68px
      border-bottom: 1px solid #DFDFDF
      border-top: 1px solid #DFDFDF
      .option
        cursor: pointer
        path
          fill: rgb(186, 186, 186)
          transition: fill 0.2s ease-in-out
      .selected path
        fill: rgb(64, 134, 241)
        transition: fill 0.2s ease-in-out
        cursor: pointer
    .borderBottom
      border-bottom: 1px solid #DFDFDF
  .sideMenuCollapsed
    width: 100px
    .header
      width: 100px
    #headerArrow
      transform: rotate(180deg)
    .boardOptions
      .option:not(.selected)
        display: none
.groupLogoWrap
  .groupLogo
    width: 36px
    height: 36px
    border-radius: 50%

.chatComponent.darkTheme
  .sideMenu
    border-color: #4E4E4E
    .header
      #headerArrow
        path
          fill: #fff
        &:hover
          path
            fill: #4086F1
    .boardOptions
      border-bottom: 1px solid #4E4E4E
      border-top: 1px solid #4E4E4E
      background-color: #272727
      .option
        path
          fill: #fff
      .selected path
        fill: rgb(64, 134, 241)
    .borderBottom
      border-bottom: 1px solid #4E4E4E
