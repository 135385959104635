
@keyframes showPopup
  0%
    width: 10px
    background: white
    opacity: 0
  99%
    width: 420px
    opacity: 0

.createFolderWrap
  width: 420px
  position: relative
  padding: 20px 30px 15px
  animation: showPopup 0.2s ease-in-out

.title
  width: 100%
  max-width: 300px
  font: 500 24px/1.33 'Roboto', sans-serif
  text-align: center
  color: var(--color-text-dark)
  margin: 0 auto 15px

.inputFieldsWrap
  width: 100%
  display: flex
  flex-direction: column
  gap: 15px

.buttonsWrap
  display: flex
  justify-content: center
  gap: 8px
  margin-top: 30px

  button
    min-width: 0
    width: max-content
    padding: 8px 22px
