
.wrap
    width: 430px
    padding-top: 20px
    font-family: "Sf Pro Text", sans-serif
    font-size: 13px
    .text
        padding: 0 10px
        text-align: center
        line-height: 21px
        color: var(--color-text-grey)
        margin: 25px auto
    .btns
        display: flex
        gap: 10px
        justify-content: center
.style
    background: red
    margin: 100px
