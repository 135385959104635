.wrap
  width: 768px

  .close
    display: flex
    gap: 8px
    .title
      color: var(--colord800, #274A42)
      font-size: 12px
      font-weight: 500
      line-height: 20px
    .text
      color: var(--color-n800, #425D5A)
      font-size: 12px
      font-weight: 400
      line-height: 16pxpx
  .body
    padding: 16px 16px 10px
    textarea
      height: 224px
    .input
      width: 420px
  .head
    display: flex
    justify-content: space-between
    margin-bottom: 12px
  .block
    display: flex
    flex-direction: column
    gap: 12px
    height: 332px
  .list
    height: 100%
    overflow-y: auto

.footer
  margin-top: 16px
  display: flex
  justify-content: flex-end
  gap: 8px
