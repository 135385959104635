.wrapper
    width: 100%
    height: 100%
    position: fixed
    right: -100%
    top: 0
    z-index: 2
    transition: all 0.3s ease-in-out
    &.open
        right: 0

.container
    width: 300px
    padding: 12px
    height: 100%
    position: absolute
    right: 0
    background: var(--color-white)
    border-bottom-left-radius: 8px
    border-top-left-radius: 8px
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.20), 0px 1px 10px 0px rgba(0, 0, 0, 0.12), 0px 4px 5px 0px rgba(0, 0, 0, 0.14)
    z-index: 2
    overflow: auto

.buttons
    margin-top: 10px
    display: flex
    justify-content: space-between

.content
    margin-top: 20px

.header
    color: var(--DarkGreen-D700, #3E5D56)
    font-family: Roboto
    font-size: 14px
    font-style: normal
    font-weight: 500
    line-height: 18px
    margin-top: 10px

.notes
    background: var(--color-d50)
    border-top-left-radius: 4px
    border-top-right-radius: 4px
    margin-top: 4px
