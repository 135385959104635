@import  "src/theme/cutsStr"
    
.card
    width: 100%
    display: flex
    flex-direction: column
    gap: 14px
    padding: 8px 12px 8px 8px
    position: relative
    border-radius: 4px
    background-color: var(--color-d40)
    transition: all 0.3s ease-in-out
    overflow: hidden
    font-size: 12px
    line-height: 1.33
    color: var(--color-d600)

    &:hover
        background: var(--color-d50)

        .menuButton
            right: 12px

    &__title
        color: var(--color-d800)
        font-size: 16px
        font-weight: 400
        line-height: 1.5
        @include cutsStr(1)

    &__bottom 
        display: flex
        align-items: center
        gap: 24px

    &__info
        display: flex
        align-items: center
        gap: 2px

.menuButton
    position: absolute
    right: -100%
    top: 50%
    transform: translateY(-50%)
    transition: right 300ms ease-in-out
