.wrap
  width: 440px
  max-width: 100%
  padding: 12px 16px
  font-family: 'Roboto', sans-serif

.contentWrap
  display: flex
  flex-direction: column
  gap: 24px

.topWrap
  flex: 1 1 auto
  display: flex
  flex-direction: column
  align-items: flex-start
  row-gap: 12px

.title
  font-size: 14px
  font-weight: 400
  line-height: 1.28
  color: var(--color-d800)

  span
    font-weight: 700

.buttonsWrap
  display: flex
  justify-content: flex-end
  gap: 10px
