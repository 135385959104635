@import "src/theme/global"
@import "src/theme/cutsStr"

.container
  height: 100%
  position: relative
  z-index: 0

  &.bars,
  &.preview
    padding: $base-item-padding 0 0

  &.lines
    padding: 0

  &.selected
    .context
      background: var(--color-blue-smooth)

      .dots
        path
          fill: var(--color-white)

      &::after
        background: var(--color-blue-smooth)

    .body
      background: var(--color-blue-items)
      border-color: var(--color-blue-smooth)

  &.isMediumSize
    .body
      width: 129px
      min-height: 102px

    .name
      font-size: 11px
      line-height: 13px

    .bottomInfo
      margin-top: 8px

  &.isBigSize
    .body
      width: 150px
      min-height: 125px

    .name
      font-size: 12px
      line-height: 14px

    .bottomInfo
      margin-top: 15px

    .size,
    .dateCreation
      font-size: 7px
      line-height: 8px

      &.lines
        font-size: 8px
        line-height: 1

.body
  height: 100%
  display: flex
  border-radius: 4px
  position: relative
  cursor: pointer

  &.bars,
  &.preview
    height: 100%
    display: flex
    flex-direction: column
    width: 110px
    min-height: 87px
    padding: 5px
    border: 1px solid var(--color-gallery)
    background-color: var(--color-background-light)
    text-align: center
    position: relative
    cursor: pointer
    margin: 0 auto

  &.lines
    justify-content: space-between
    gap: 4px
    border: 1px solid var(--color-grey-secondary)
    background-color: var(--color-white)
    padding: 6px 4px
    min-height: 46px

.color
  width: 12px
  height: 12px
  border-radius: 50%

.emo
  font-size: 12px

.context
  position: absolute
  top: -$base-item-padding
  left: -1px
  z-index: -1
  width: 50px
  height: 20px
  background: var(--color-gallery)
  border-radius: 6px 6px 0 0
  cursor: pointer

  button
    width: 100%
    height: 100%

  &::after
    content: ""
    position: absolute
    top: 0
    right: -10px
    width: 20px
    height: 20px
    background: var(--color-gallery)
    border-radius: 0 3px 0 0
    transform: skew(30deg, 0deg)

  &:hover
    .dots
      path
        fill: var(--color-blue-smooth)

.bodyContent
  display: flex
  flex-direction: column
  width: 100%
  height: 100%
  position: relative

.dots
  position: absolute
  top: 5px
  left: 18px
  z-index: 2

  path
    fill: var(--color-icon)

.options
  display: flex
  align-items: flex-start
  min-height: 14px
  margin-bottom: 5px

.symbols
  width: calc(100% - 32px)
  position: absolute
  z-index: 1
  top: 5px
  right: 5px
  display: flex
  flex-direction: column
  align-items: flex-end
  row-gap: 4px
  overflow: hidden

.emoFigWrapper
  display: flex
  align-items: center
  column-gap: 4px

.iconWrapperFlex
  flex: 1 1 auto
  display: flex

  &.bars,
  &.preview
    flex-direction: column
    justify-content: flex-end

  &.lines
    flex-direction: row
    justify-content: normal
    align-items: center
    gap: 6px
    padding-right: 36px

.iconWrapper
  display: flex
  flex-direction: column
  justify-content: center
  position: relative

.lockedIcon
  position: absolute
  top: 50%
  left: 50%
  transform: translate(-50%, -50%)
  width: 10px
  height: 10px

.name
  font-family: "SF Pro Text", sans-serif
  line-height: 1.33
  user-select: none
  @include cutsStr(2)

  &.bars,
  &.preview
    font-size: 10px
    margin-top: 5px

  &.lines
    font-size: 12px
    margin-top: 0
    @include cutsStr(1)

.bottomInfo
  display: flex
  align-items: center

  &.bars,
  &.preview
    margin-top: 5px
    justify-content: space-between

  &.lines
    justify-content: normal
    gap: 10px

.size,
.dateCreation
  font-family: 'SF Pro Text', sans-serif
  font-weight: 400
  font-size: 6px
  line-height: 7px
  color: var(--color-border-dark-grey)
  user-select: none

.tags
  position: absolute
  top: 50%
  transform: translateY(-50%)
  right: 0
