.fileWrapper
  position: relative
  height: 100%
  overflow: hidden
  .corner
    position: absolute
    right: -1px
    top: -1px
    z-index: 1
    width: 26%
    height: 26%
    svg
      width: 100%
      height: 100%
  &.small
    .corner
      top: -4px
  .file
    width: 86%
    margin-left: 14%
    height: 81%
    position: absolute
    bottom: 0
    background-color: #E2E5E7
    border-radius: 0 2px 4px 4px
    font-family: "SF Pro Medium", sans-serif
    &::before
      content: ""
      position: absolute
      width: 5px
      height: 5px
      background: inherit
      right: 7px
      top: -3px
    &::after
      content: ""
      display: block
      position: absolute
      transform: rotate(225deg) translate(0, 70%)
      right: 0
      top: 0
      border: 5px solid transparent
      border-top: 5px solid #CAD1D8
      border-radius: 0 0 0 5px

    .label
      position: absolute
      top: 40%
      left: -14%
      display: flex
      justify-content: center
      align-items: center
      width: 86%
      height: 37%
      border-radius: 10%
      color: white
      font-size: 8px
      .labelZip
        margin: 0 0 0 25%
    .zip
      position: absolute
      left: 0
      margin: 0 30% 0 0
      width: 30%
      img
        width: 45%
  &::before
    position: absolute
    content: ""
    width: 65%
    height: 26%
    top: 1px
    left: 14%
    background: #E2E5E7
    border-radius: 4px 1px 0 0
