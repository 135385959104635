.wrapp
    width: 80vw
    height: 80vh
    background: var(--color-white)
    display: flex
    align-items: center
    justify-content: center
    flex-direction: column
    gap: 30px

    > svg
        height: 30vh
        max-height: 213px

    &__text
        font-size: 16px
        font-weight: 600
        color: var(--color-d900)
