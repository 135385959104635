.container
    padding: 8px 12px
    border-top: 1px solid var(--color-gallery)
    border-bottom: 1px solid var(--color-gallery)
    display: flex
    justify-content: flex-start

.colors
    display: flex
    flex-wrap: wrap
    justify-content: space-between
    gap: 2px 1px
    height: 24px
    overflow: hidden
    transition: all 0.3s ease-in-out
.colors.open
    height: 48px

.color
    border-radius: 50%
    cursor: pointer
    border: 2px solid #ffffff00
    span
        display: block
        border-radius: 50%
        width: 20px
        height: 20px
        border: 2px solid var(--color-white, #fff)

    &.active
        border: 2px solid var(--color-blue-smooth, #4086F1)

.button
    margin-left: 5px
    min-width: 22px
    height: 22px

.arrow
    transform: rotate(-90deg)
    transition: all 0.3s ease-in-out

.arrow.open
    transform: rotate(90deg)
