
.wrap
    display: flex
    justify-content: space-between
    align-items: center
    gap: 10px
    padding: 5px 12px 0
    background-color: var(--color-background-grey)
    border-bottom: 1px solid var(--color-border-dark)
    font-family: "SF Pro Text", sans-serif
    color: var(--color-text-grey)
    font-size: 13px
    .date
        display: flex
        align-items: center
        gap: 10px
        align-self: stretch
        padding-right: 10px
        margin-bottom: 5px
        .icon
            width: 22px
            height: 22px
        .icon path
            fill: var(--color-grey)
    .row
        display: flex
        gap: 5px
    .box
        box-shadow: 0 2px 4px var(--color-shadow)
        width: 30px
        height: 24px
        background: var(--color-white)
        padding: 6px
        border-radius: 3px
        cursor: pointer
        display: flex
        flex-direction: column
        align-items: center
        justify-content: center
        &:hover
            background: var(--color-blue-smooth)
            & .icon path
                fill: var(--color-white)

        .icon
            width: 15px
            height: 13px
            margin: 0 auto
            & path
                fill: var(--color-blue-smooth)
