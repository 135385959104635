
.header
  display: flex
  align-items: center
  justify-content: space-between
  padding: 8px 16px
  background: linear-gradient(90.08deg, #4543F7 -1.1%, #0A3AAB 102.36%)

  .left
    display: flex
    align-items: center
    gap: 16px
    color: var(--color-white)
  .link
    font-size: 14px
    color: var(--color-white)
    line-height: 18px
    padding: 8px 10px
  .registration
    border: 1px solid var(--color-white)
    border-radius: 6px
    &.active
      border-color: var(--color-b100)
  .active
    color: var(--color-b100)
  .info.active *
    fill: var(--color-b100)
