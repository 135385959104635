
.item
    display: flex
    align-items: center
    justify-content: space-between
    box-sizing: border-box
    padding: 15px 20px
    border-bottom: 1px solid #DFDFDF
    width: 100%
    position: relative
    &:hover, &.active
        background-color: #EBF1FD
    &.disableHover:hover,
    &.disableActions:hover
        background: #fff
    .groupName
        display: flex
    .avatarWrapper
        margin-right: 10px
        display: flex
        align-items: center
        justify-content: center
        position: relative
        .avatar
            width: 30px
            height: 30px
            border-radius: 50%
            object-fit: cover
            object-position: center
        .onlineIndicator
            position: absolute
            width: 9px
            height: 9px
            bottom: 0
            right: 0
            background: #48A814
            border: 1px solid #fff
            border-radius: 50%
    .info
        display: flex
        flex-direction: column
        justify-content: center
        align-items: flex-start
        max-width: 180px
        .title
            display: flex
            align-items: center
            font-size: 16px
            cursor: default
            max-width: 100%
            text-overflow: ellipsis
            overflow: hidden
            white-space: nowrap
            .secretChatIcon
                height: 15px
                width: 15px
                margin-left: 10px
                path
                    fill: #4086F1
        .subtitle
            font-size: 11px
            color: rgb(133, 133, 133)
            text-overflow: ellipsis
            overflow: hidden
            white-space: nowrap
            max-width: 100%
            cursor: default
    .radioContact
        width: 18px
        height: 18px
        border: 1px solid #B8B8B8
        border-radius: 50%
        margin: 0 10px 0 0
    .radioContactChosen
        border: 1px solid rgb(64, 134, 241)
        border-radius: 50%
        background: rgb(64, 134, 241)
    .menuWrap
        display: flex
        width: 20px
        height: 20px
        justify-content: center
        align-items: center
        cursor: pointer
    .menu
        position: relative
        width: 3px
        height: 3px
        background: #c1c0c0
        transition: background 0.2s ease
    .menu::before, .menu::after
        content: ''
        position: absolute
        width: 3px
        height: 3px
        background: #c1c0c0
        transition: background 0.2s ease
    .menu::before
        transform: translateY(8px)
    .menu::after
        transform: translateY(-8px)
    .menuWrap:hover .menu, .menuWrap:hover .menu::before, .menuWrap:hover .menu::after
        background: #477DE1
        transition: background 0.2s ease
    &.sublist
        padding-left: 40px
        border: none
        &.sideMenuCollapsed
            padding-left: 25px
            padding-right: 10px
    &.disableActions
        border: none
        padding: 5px 0
        .info
            .title
                display: inline
                text-overflow: ellipsis
                max-width: 80%
                font-size: 13px

    .isCallOngoing
        display: flex
        justify-content: center
        align-items: center
        width: 20px
        height: 20px
        background: var(--color-primary)
        border-radius: 50%
        position: absolute
        top: calc(50% - 10px)
        right: 50px
        .ongoingCallIcon
            width: 12px
            height: 12px
            animation: hide-show 3s cubic-bezier(.83,.04,0,.68) infinite
            path, polygon
                fill: var(--color-white)

.item.darkTheme
    border-color: #4E4E4E
    &:hover, &.active
        background-color: rgba(196, 196, 196, .1)
.sideMenuCollapsed
    .groupName
        position: relative
        .secretChatIcon
            width: 8px
            height: 8px
            position: absolute
            right: 0
            bottom: 0
            path
                fill: #4086F1
.rightWrap
    display: flex
    align-items: center
    .notificationsCounter
        background-color: #D72419
        border-radius: 50%
        min-width: 16px
        min-height: 16px
        display: flex
        align-items: center
        justify-content: center
        font-size: 9px
        color: #fff
        margin-right: 5px
        line-height: 16px
        animation: showCounter 2.2s ease-in-out
        &.hidden
            display: none

@media (max-width: 1440px)
    .item
        .info
            .title
                font-size: 14px
                .secretChatIcon
                    height: 12px
                    width: 12px
                    margin-left: 8px
            .subtitle
                font-size: 10px
