.wrap
  width: 440px
  .body
    padding: 8px 12px
  .folders
    max-height: 460px
    overflow-y: auto
    border-radius: 4px
    ul
      max-height: 460px
  .footer
    display: flex
    justify-content: space-between
    padding: 12px 12px 10px
    box-shadow: 0px -2px 5px 0px rgba(0, 0, 0, 0.14)
    .btns
      display: flex
      gap: 8px
