
.user
    padding: 3px 10px
    display: flex
    justify-content: space-between
    align-items: center
    gap: 10px
    background: var(--color-background-light)
    border: 1px solid var(--color-border-dark)
    border-radius: 6px
    font-family: "SF Pro Regular", sans-serif
    font-size: 13px
    line-height: 20px
    color: var(--color-text-grey-light)

    .name
        flex: 1
        text-align: left
        text-overflow: ellipsis
        overflow: hidden
        white-space: nowrap
    .check, .noCheck
        width: 19px
        height: 19px
    .noCheck
        border-radius: 50%
        border: 1px solid var(--color-border-dark)
    .mainMenuItems
        margin: 10px 0
.light
    border: none
    background: transparent
.short
    width: max-content
    flex: 0 0 auto
    background: var(--color-blue-light)
    padding: 3px 20px 3px 10px
    border-radius: 20px
    border: none
