
.wrap
  max-height: calc(100% - 100px)
  overflow-y: auto
.tasks
  padding: 10px
.text
  margin: 20px 0
  padding: 0 10px
  text-align: start
  font-size: 13px
  line-height: 18px
  color: var(--color-text-grey)
  font-family: 'SF Pro Text', sans-serif
  li
    font-weight: 700
    list-style: disc
  ul
    margin: 0
