
.addQuickTask
  display: flex
  flex-direction: column
  align-items: center
  width: 390px
  height: 520px
  min-height: max-content
  position: relative
  padding: 30px 20px
  .title
    font-size: 19px
    font-family: "SF Pro Medium", sans-serif
    margin-bottom: 20px
  .container
    width: 100%
    margin-bottom: 15px
    .label
      font-size: 12px
      margin: 0 0 13px
    .selectedProject
      margin: 0
      display: flex
      width: 90%
      div
        display: flex
        align-items: center
        width: 100%
        gap: 6px
        margin-left: 39px
        justify-content: flex-end
        position: relative
        .noLogo, .logo
          position: absolute
          left: -39px
        .name
          position: absolute
          left: 0
    .projectsList
      margin: 0
      display: flex
      flex-direction: column
      .project
        display: flex
        justify-content: space-between
        align-items: center
        gap: 6px
        padding: 4px 10px
        border-bottom: 1px solid var(--color-border-dark)
        margin-right: 10%
        &:hover
          background: var(--color-blue-light)
        .noLogo
          width: 39px
        .logo
          border-radius: 2px

        .name
          flex: 1
          font-size: 13px
          color: var(--color-text-grey)
          line-height: 30px
    .textarea
      outline: 0
      resize: none
      width: 100%
      font-size: 11px
      line-height: 16px
      font-family: "SF Pro Text", sans-serif
      letter-spacing: 0.023em
      padding: 16px 20px
      border: 1px solid var(--color-border-dark)
      border-radius: 4px
    .input
      width: 100%
      padding: 10px
      line-height: 17px
      border: 1px solid var(--color-border-dark)
      border-radius: 5px

      &::placeholder
        color: var(--color-grey)

.selectRow
  display: flex
  justify-content: center
  padding: 10px 0
  width: 100%
  gap: 20px
  font-size: 12px
  .select
    display: flex
    align-items: center
    cursor: pointer
.radioContact
  width: 10px
  height: 10px
  border: 1px solid black
  border-radius: 50%
  margin: 0 10px 0 0
  cursor: pointer
.radioContactChosen
  width: 10px
  height: 10px
  border: 1px solid rgb(64, 134, 241)
  border-radius: 50%
  margin: 0 10px 0 0
  background: rgb(64, 134, 241)
