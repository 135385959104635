.wrap
  overflow: auto
  height: calc(100% - 100px)
.content
  display: flex
  width: max-content
  position: relative
  height: 100%
  padding-right: 5px
.tasks
  position: sticky
  left: 0
  z-index: 2
  min-height: 100%
  height: max-content
  background-color: #fff
  box-shadow: 2px 0px 4px 0px rgba(0, 0, 0, 0.20), 1px 0px 10px 0px rgba(0, 0, 0, 0.12), 4px 0px 5px 0px rgba(0, 0, 0, 0.14)
  &.hidden
    .name, .author, .status, .table
      display: none
    .toggle
      right: -16px
      z-index: 2
      width: 18px
      transform: scaleX(-1)

.header
  position: sticky
  top: 0
  background: var(--color-d20)
  z-index: 1
  display: flex
  font-size: 14px
  line-height: 18px

  .name
    padding: 10px
    width: 220px
    border-right: 1px solid var(--color-d100)
  .author
    padding: 10px
    width: 60px
    border-right: 1px solid var(--color-d100)
  .status
    width: 160px
    padding: 10px

.toggle
  width: 16px
  height: 24px
  position: absolute
  top: 6px
  right: 0
  border-radius: 6px 0 0 6px
  background-color: var(--color-b600)
  display: flex
  align-items: center
  justify-content: center
  z-index: 2
.table
  padding-top: 20px
