.container
    display: flex
    height: 52px
    align-items: center
    padding: 0 12px
    border-radius: 4px
    transition: all 0.2s ease-in-out
    > div
        flex: 1
        font-weight: 400
        font-size: 12px
        line-height: 16px
        color: #274A42
    &:hover
        background: #E3F2FD

.date
    display: flex
    flex-direction: column
    justify-content: space-around

.help
    display: flex
    gap: 4px
    div
        padding: 2px 8px
        background: #CFD7D5
        border-radius: 4px
        font-weight: 500
        font-size: 12px
        line-height: 16px
        color: #274A42
        gap: 6px

.sizes
    display: flex
    justify-content: space-between
    align-items: center

.button
    span
        width: 18px !important
        height: 18px !important

.info
    width: 100%
    overflow: hidden
    padding-right: 6px
