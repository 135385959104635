.container
    min-height: 100vh
    width: 100vw
    height: 100%
    background: var(--color-b950)
    padding: 36px 64px
    display: flex
    align-items: center

.content
    width: 45%
    height: 100%
    padding: 0 30px 0 10px
    display: flex
    flex-direction: column

.wrapper
    height: 100%

.slider
    width: 55%
    height: 100%
    max-width: 1200px
    max-height: 1200px

.logo
    width: 133px

.info
    font-family: "SF Pro Text", sans-serif
    font-style: normal
    font-weight: 400
    font-size: 12px
    line-height: 20px
    text-align: center
    color: var(--color-white)
    margin-top: 15px
    a
        color: var(--color-c400)

@media (max-width: 991px)
    .container
        flex-direction: column
        align-items: center
        height: auto

    .content
        width: 100%

    .slider
        margin-top: 50px
        width: 100%

    .wrapper
        margin-top: 40px
    .info
        margin-top: 30px
@media (max-width: 768px)
    .container
        justify-content: center
        padding: 26px 16px
        height: 100%

    .content
        flex-grow: 1
        display: flex
        flex-direction: column
        padding: 0
    .slider
        display: none

    .wrapper
        margin-top: 0
