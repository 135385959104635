
.list
  &__label
    width: 100%
    display: inline-flex
    align-items: center
    gap: 2px
    padding: 6px 12px
    font-size: 12px
    line-height: 1.66
    color: var( --color-plantation)
    transition: background-color 0.2s ease-in-out
    cursor: pointer

    &:hover
      background: var(--color-blue-items)
  
.button
  flex: 0 0 100px
  width: 100px
  height: 28px
  font-family: 'SF Pro Text', sans-serif
  font-weight: 400
  font-size: 12px
  line-height: 14px
  display: flex
  column-gap: 8px
  justify-content: center
  align-items: center
  background-color: var(--color-white)
  color: var(--color-scorpion)
  border: 1px solid var(--color-blue-smooth)
  border-radius: 6px
  transition: background-color 0.2s ease-in-out
  cursor: pointer
  padding: 0
  &:hover
    background: var(--color-blue-items)
