
.wrap
  width: 100%
  display: flex
  padding: 10px 50px 5px
  border-right: 1px solid var(--color-border-dark)
  border-left: 1px solid var(--color-border-dark)

  .category
    flex-grow: 1
    border-right: 1px solid var(--color-border-dark)
    text-align: center
    min-width: 20px
    transition: width 500ms
    padding: 0 3px 5px

    &:last-child
      border-right: none
  .line
    height: 5px
    border-radius: 4px
