.container
    // padding: 12px 16px
    display: flex
    flex-direction: column
    gap: 8px
    height: calc( 100% - 98px )
    overflow: auto
    position: relative

.loader
    height: 20px
    position: relative
    margin-top: 15px
    display: flex
    justify-content: center
    align-items: center
