@import "src/theme/global"
.folderBox
  height: 50px
  display: flex
  align-items: center
  gap: 10px
  padding: 0 10px 0 15px
  background: transparent
  border: 1px solid transparent
  border-radius: 6px
  &.noBorder
    border-radius: 0
    border-right: 0
    border-left: 0
  &.chosenFolder
    background: var(--color-b100)
    border-color: var(--color-b800)
  &:hover
    background: var(--color-b50)
.innerFolderIcon
  flex-shrink: 0
.folderInfo
  flex-grow: 1
  display: flex
  align-items: center
  justify-content: space-between
  gap: 10px
  overflow: hidden
.nameWrapper
  flex-grow: 1
  display: flex
  flex-direction: column
  row-gap: 3px
  overflow: hidden
.folderText
  font-family: "Roboto", sans-serif
  font-weight: 400
  font-size: 13.5px
  line-height: 16px
  color: var(--color-text-grey)
  text-overflow: ellipsis
  white-space: nowrap
  overflow: hidden
.folderInfoWrapper
  flex: 1 1 auto
  display: flex
  align-items: center
  gap: 10px
  overflow: hidden
  transition: opacity 0.2s ease-in-out, visibility 0.2s ease-in-out
  &.collapsed
    opacity: 0
    pointer-events: none
.iconWrapper
  flex: 0 0 auto
  display: flex
  align-items: center
  justify-content: center
.rowStart
  display: flex
  align-items: center
  column-gap: 10px
.emo
  font-size: 14px
.buttonArrow
  display: flex
  align-items: center
  justify-content: center
  position: relative
  padding: 0
  margin: 0
  @include clickAreaEnlargement(200%, 200%)
.arrowIcon
  &:hover
    path
      fill: var(--color-blue-smooth)
  &.active
    transform: scaleY(-1)
    &
      path
        fill: var(--color-blue-smooth)
