.taskBox
  width: 96px
  flex-shrink: 0

  &:hover .btn
    display: block
  .preview
    overflow: hidden
    border-radius: 4px
    position: relative
    height: 72px
    display: flex
    align-items: center
    justify-content: center
    margin-bottom: 8px
    background: var(--color-d100)
  .image
    display: block
    width: 100%
    height: 100%
    object-fit: cover
  .btn
    position: absolute
    top: 10px
    right: 10px
    display: none
  .name
    color: var(--DarkGreen-D700, #3E5D56)
    font-size: 12px
    font-weight: 400
    line-height: 16px
    white-space: nowrap
    overflow: hidden
    text-overflow: ellipsis
