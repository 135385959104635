
.wrap
    width: 410px
    margin-top: 20px
    border-top: 1px solid var(--color-border-dark)
    padding-top: 20px
    text-align: left
    font-family: 'SF Pro Text', sans-serif
    font-size: 14px
    line-height: 16px
    color: var(--color-text-grey)
    .foldersWrap
        height: 400px
        overflow-y: scroll
        &::-webkit-scrollbar
            width: 4px
            background-color: var(--color-white)
        &::-webkit-scrollbar-thumb
            background-color: var(--color-blue-smooth)
        .folderBox
            padding-left: 20px
        .folder
            display: flex
            align-items: center
            gap: 15px
            padding: 10px 0
            img
                width: 20px
        .filesWrap
            max-height: 300px
            overflow-y: auto
            margin-bottom: 20px
            margin-right: 20px
            position: relative
            border-left: 1px solid var(--color-border-dark)
            &::-webkit-scrollbar
                width: 4px
                background-color: var(--color-white)
            &::-webkit-scrollbar-thumb
                background-color: var(--color-blue-smooth)
        .without
            text-align: center
            display: block
            color: var(--color-text-grey-light)
            font-size: 12px

    .btns
        display: flex
        justify-content: center
        gap: 10px
.additional
    padding: 30px 20px 20px
    margin: 0
    border: none
    .header
        text-align: center
        font-family: 'SF Pro Medium', sans-serif
        font-weight: 500
        font-size: 18px
        line-height: 21px
        color: var(--color-text-dark)
