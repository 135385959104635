
.roles
    flex: 1
    display: flex
    flex-direction: column
    flex-wrap: wrap
    gap: 0 10px
    align-content: flex-start
    overflow-x: scroll
    height: 40px
    padding: 0 10px

    .allRoles
        width: 80px
        padding: 6px 10px
        background: var(--color-background-light)
        border: 1px solid var(--color-border-dark)
        border-radius: 6px
        font-size: 13px
        font-family: "SF Pro Regular", sans-serif
        line-height: 16px
        color: var(--color-text-grey)-light
        text-align: center
        cursor: pointer
    .active
        background: var(--color-blue-light)
        border: 1px solid var(--color-primary)
