@import "src/theme/cutsStr"

.root
  display: flex
  align-self: stretch
  margin-left: -16px

.btn
  display: flex
  align-items: center
  width: 265px
  max-width: 100%
  padding: 16px
  gap: 4px
  background-color: var( --color-white-lilac)
  border-right: 1px solid var(--color-border-dark)
  box-shadow: 0px 0.75px 0px 0px #ECECEC inset, 0px -0.75px 0px 0px #ECECEC inset, -0.75px 0px 0px 0px #ECECEC inset

  &:disabled
    cursor: default

  &:not([disabled]):hover
    .icon
      fill: var(--color-plantation)

  &__label
    @include cutsStr(1)

.defaultBtnIcon
  display: flex
  align-items: center
  width: 18px
  height: 18px

.icon
  fill: var(--color-granny-smith)
  transition: fill 0.1s ease-in-out
.admin
  display: inline-block
  border-radius: 4px
  color: var(--color-white)
  background-color: var(--main-orange)
  padding: 0 8px
  font-size: 14px
