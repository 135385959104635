
.carousel
  max-width: 430px
  white-space: nowrap
  overflow: hidden
  padding: 5px 0
  .wrapper
    width: 100%
    position: relative
    padding-left: 5px
    .blocks
      display: flex
      transition: translate
      transition-property: transform
      transition-duration: 300ms
      transition-timing-function: ease-in-out
      .fileIcon
        width: 47px
        height: 50px
        position: relative
        display: inline-flex
        margin: 0 15px 0 0
        transition: all 0.3s ease
        cursor: pointer
        &:hover
          .delFile
            opacity: 1
            visibility: visible
        .delFile
          position: absolute
          cursor: pointer
          opacity: 0
          visibility: hidden
          right: -7px
          top: -5px
          z-index: 2
    .arrow
      position: absolute
      background: rgba(97, 97, 97, 0.3)
      backdrop-filter: blur(2.5px)
      border-radius: 4px
      display: flex
      justify-content: center
      align-items: center
      cursor: pointer
      width: 56px
      height: 52px
      transition: all 0.3s ease-out
      visibility: hidden
    .arrowPrev
      top: 0
      left: 0
      opacity: 0
    .arrowNext
      top: 0
      right: 0
    .isActive
      visibility: visible
      opacity: 1
  .menu
    position: absolute
    width: 100%
    height: 100%
    backdrop-filter: blur(2px)
    background: rgba(0, 0, 0, 0.5)
    border-radius: 4px
    top: 0
    display: flex
    flex-direction: column
    gap: 1px
    align-items: center
    justify-content: space-between
    & > div
      width: 100%
      text-align: center
    & > div:hover .icon path
      fill: var(--color-blue-smooth)
      stroke: var(--color-blue-smooth)
    .icon path
      fill: var(--color-white)
      stroke: var(--color-white)
    .icon
      width: 15px
      height: 15px
    &.noActionIcon
      justify-content: center
