.sliderFiles
    height: inherit

    :global
        .swiper-slide
            display: flex
            justify-content: center
            align-items: center

            > img
                object-fit: contain
