
.buttonLabel
    min-width: 112px
    min-height: 32px
    display: flex
    align-items: center
    justify-content: space-between
    column-gap: 4px
    background-color: var(--color-white)
    border: 1px solid var(--color-d100)
    border-radius: 4px
    color: var(--color-d400)
    font: 14px/1.33 "Roboto", sans-serif
    font-size: 14px
    line-height: 1.33
    padding: 6px 12px 6px 12px
    transition: background-color 0.2s ease-in-out, border-color 0.2s ease-in-out
    position: relative

    &:hover, &.open
        background-color: var(--color-blue-items)
        border-color: var(--color-blue-smooth)

    &::after
        content: ""
        width: 0
        height: 0
        border-left: 3.33px solid transparent
        border-right: 3.33px solid transparent
        border-top: 3.33px solid var(--color-d100)
        transition: transform 300ms, border-top-color 300ms

    &.open::after
        border-top-color: var(--color-blue-smooth)
        transform: rotate(180deg)

    &.disabled
        background-color: var(--color-d50)
