
.wrapper
    height: 100%
    margin-top: 70px
    h4, span
        font-family: "SF Pro Text", sans-serif
        font-style: normal
        font-weight: 590
        font-size: 32px
        line-height: 38px
        color: var(--color-white)

.answers
    display: flex
    gap: 15px
    align-items: center
    flex-wrap: wrap
    margin-top: 35px

.answer
    height: 100px
    width: 30%
    opacity: 0.8
    transition: all 0.2s ease-in-out

.answer.active
    transition: all 0.2s ease-in-out
    opacity: 1

@media (max-width: 991px)
    .wrapper
        margin-top: 40px
    .answers
        justify-content: space-between

@media (max-width: 768px)
    .answer
        width: 100%
        height: auto
        text-align: left

    .wrapper
        flex: 1
        h4, span
            font-size: 26px
            line-height: 32px
            display: inline
        span
            margin-right: 10px
