.wrap
    width: 560px
    padding-top: 30px
    .icon
        width: 50px
        height: 50px
        margin-bottom: 30px
    .text
        font-family: 'SF Pro Text', sans-serif
        font-size: 12px
        line-height: 21px
        text-align: center
        margin: 0
        &:last-child
            margin-bottom: 30px
