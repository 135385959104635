@import "src/theme/global"
.wrap
  width: 346px
  background: var(--color-white)
  border-radius: 10px
  position: fixed
  bottom: 70px
  right: 30px
  border: 1px solid var(--color-border-dark)
  overflow: hidden
  z-index: 10
  .header
    display: flex
    justify-content: flex-end
    align-items: center
    gap: 15px
    padding: 20px 15px 20px 20px
    font-size: 13px
    line-height: 15px
    background: var(--color-blue-light)
    color: var(--color-text-dark)
  .text
    flex: 1 1 auto
    display: block
  .btn
    flex: 0 0 auto
    padding: 0
    position: relative
    width: 12px
    height: 12px
    display: flex
    align-items: center
    @include clickAreaEnlargement(20px, 20px)
    *
      width: 100%
      fill: var(--color-text-dark)
    &:hover
      *
        fill: var(--color-blue-smooth)
  .fileList
    max-height: 300px
    overflow-y: auto
    overflow-x: hidden
    margin: 0
.collapsed
  bottom: 30px
  width: 128px
  .header
    padding: 16px 10px 16px 16px

  .progress
    width: 22px
    height: 22px
    border-radius: 50%
    position: relative
    transition: all .2s linear
    flex-shrink: 0
    .upload
      width: 70%
      height: 70%
      border-radius: 50%
      transform: translate(-50%, -50%)
      position: absolute
      top: 50%
      left: 50%
      background: var(--color-white)
      z-index: 1
      display: flex
      align-items: center
      justify-content: center
  .btn
    transform: scaleY(-1)
  .container
    display: flex
    align-items: center
    gap: 5px
    flex-grow: 1
  .text, .fileList
    display: none
