@import "src/theme/cutsStr"

.label
  background-color: var(--color-white)
  position: absolute
  top: 0
  left: 6px
  z-index: 1
  padding: 0 4px
  font-size: 12px
  line-height: 0.83
  color: var(--color-d700)

  &.required
    &::before
      content: "*"
      font-size: 12px
      display: inline-block
      color: var(--color-red)
      margin-right: 2px

  &Wrapp
    display: inline-block
    padding-top: 6px
    position: relative

.btnSelected
  flex-shrink: 0
  display: flex
  align-items: center
  justify-content: space-between
  column-gap: 4px
  background-color: var(--color-white)
  border: 1px solid var(--color-d100)
  border-radius: 4px
  color: var(--color-d400)
  font-size: 12px
  line-height: 1.33
  padding: 5px 4px 5px 10px
  transition: background-color 0.2s ease-in-out, border-color 0.2s ease-in-out
  position: relative
  z-index: 0
  cursor: pointer

  &.selected
    color: var(--color-d800)

  &::after
    content: ""
    width: 0
    height: 0
    border-left: 3.33px solid transparent
    border-right: 3.33px solid transparent
    border-top: 3.33px solid var(--color-d100)
    transition: transform 300ms, border-top-color 300ms

  &.open::after
    border-top-color: var(--color-blue-smooth)
    transform: rotate(180deg)

  &.medium
    height: 32px

  &.small
    height: 28px

  &:hover, &.open
    background-color: var(--color-blue-items)
    border-color: var(--color-blue-smooth)

.empty
  padding: 10px
