
.wrap
    padding: 10px
    height: calc(100% - 140px)
    width: 100%
    overflow-y: auto
    .stikyBox
        position: sticky
        top: 0
        display: flex
        gap: 10px
        margin-bottom: 10px
        padding: 5px
        background: var(--color-white)
        border-radius: 6px
        box-shadow: 0px 2px 8px 2px var(--color-shadow)
        width: max-content
        z-index: 1
