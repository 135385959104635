
.root
  flex-grow: 1
  display: flex
  flex-direction: column
  overflow-y: auto
  overflow-x: hidden
  padding-bottom: 20px
  position: relative

  &.marginTop
    margin-top: 10px
