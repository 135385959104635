@import "src/theme/cutsStr"

.list
  padding: 0
  margin: 0
  font-size: 12px
  color: var(--color-d800)
  font-family: "Roboto", sans-serif
  overflow-y: auto
  max-height: 250px

  &__item
    line-height: 1.66
    position: relative

    &:hover
      .btnEdit
        opacity: 1
        visibility: visible

  &__btn
    display: inline-flex
    align-items: center
    justify-content: space-between
    width: 100%
    padding: 6px 12px
    text-align: left
    line-height: 1.66

    &:hover
      background-color: var(--color-lb50)

    & span + span
      margin-left: 8px

    & > svg
      margin-right: 4px 

  .active
    background: var(--color-lb100)

    &:hover
      color: inherit
  
  &__label
    display: inline-block
    width: 100%
    background-color: var(--n100)
    padding: 0 12px
    font-weight: 500
    color: var(--color-plantation)
    
  &__btnName
    @include cutsStr(1)
  
  &__btnDate
    color: var(--color-d600)
    padding-right: 32px

.popoverBody
  border: 0

.btnEdit
  position: absolute
  top: 0
  right: 0
  z-index: 2
  opacity: 0
  visibility: hidden


