.container
    display: flex
    align-items: center
    gap: 8px
    cursor: pointer

.iconWrapper
    width: 35px
    min-width: 35px
    display: flex
    justify-content: flex-start

.text
    display: flex
    align-items: center
    overflow: hidden
    div
        font-weight: 500
        font-size: 14px
        line-height: 20px
        color: #274A42
        white-space: nowrap
        overflow: hidden
        text-overflow: ellipsis
        height: 40px

.noMargin > div, .noMargin
    margin: 0.1em 0
