
.wrapper
  display: flex
  align-items: center
  justify-content: center
  // min-height: 60px
  position: relative
  // width: 92%
  // margin: 0 auto
  .searchResults
    box-shadow: 0px 3px 14px 0px #0000001F
    border-radius: 4px
    position: absolute
    top: calc(100% + 1px)
    left: 0
    width: max-content
    min-width: 100%
    background: white
    z-index: 1
    max-height: 240px
    overflow-y: scroll

  .input
    width: 100%
    height: 32px
    border-radius: 4px
    padding: 0 33px 0 12px
    box-sizing: border-box
    background: var(--color-white)
    border: none
    font-size: 14px
    border-radius: 4px
    border: 1px solid var(--color-d100)

    &:focus
      outline: 1px solid var(--color-b600)

      &::placeholder
        color: var(--color-d800)

    &::placeholder
      color: var(--color-d400)

    &.primary
      height: 32px

    &.notOutline
      border: none
      &:focus
        outline: 0

    &.secondary
      height: 28px

  .icon
    position: absolute
    right: 8px
