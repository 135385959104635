
.searchWrapper
  padding: 8px 12px
  & > div > div
    padding: 8px 12px

  .programsSearchLoader
    height: 100px
  .searchItemWrapper
    padding: 8px 0
    &:hover .searchItem
      color: var(--color-b600)
  .searchItem
    font-size: 14px
    color: var(--color-d800)
    display: flex
    gap: 4px
    align-items: center
    img
      width: 24px
      height: 24px
