.paintField
  height: 100%
  width: calc(100% - 100px - 100px)
  .canvasWrap
    width: 100%
    height: 100%
    position: relative
    display: flex
    justify-content: center
    align-items: center
    .canvas
      //border: 1px solid red
    .verticalDivider
      position: absolute
      width: 16px
      height: calc(100% - 30px)
      left: calc(50% - 8px)
      background: #FFFFFF
    .horizontalDivider
      position: absolute
      width: calc(100% - 30px)
      height: 16px
      top: calc(50% - 8px)
      background: #FFFFFF
    .addField
      position: absolute
      bottom: 15px
      right: 15px
      background: #FFFFFF
      display: flex
      align-items: center
      justify-content: center
      .addFile
        display: flex
        flex-direction: column
        justify-content: center
        align-items: center
        width: 160px
        height: 160px
        position: relative
        background: #F2F2F2
        box-sizing: border-box
        cursor: pointer
        .addIcon
          margin: 0 0 23px 0
        .addIcon path
          transition: fill 0.2s ease-in-out
        span
          font-size: 10px
          text-align: center
          width: 120px
    .addFieldFull
      top: 15px
      left: 15px
      bottom: 0
      right: 0