
.previewFileToolbar
  display: flex
  align-items: center
  background: white
  height: 50px
  min-height: 50px
  margin: 0 0 10px 0
  justify-content: space-between
  .title
    font-family: "SF Pro Regular", sans-serif
    font-size: 13px
    color: #49494B
    margin: 0 40px 0 20px
  .leftPart
    display: flex
    align-items: center
    min-height: 100%
    .imgTitle
      box-sizing: border-box
      display: flex
      align-items: center
      height: max-content
      position: relative
      padding: 0 10px 0 0
      margin: 0 10px 0 5px
      &:after
        content: ''
        width: 1px
        height: 70%
        background: #CFCFCF
        position: absolute
        right: 0
      .name
        margin: 0 0 0 5px
        font-size: 13px
        color: #49494B
        font-family: "SF Pro Regular", sans-serif
  .rightPart
    display: flex
    align-items: center
    margin: 0 5px 0 0
    min-height: 100%
    height: 100%
    .manageButtons
      display: flex
      align-items: center
      position: relative
      min-height: 100%
      height: 100%
      box-sizing: border-box
      padding: 0 5px
      margin: 0 0 0 5px
      &:after
        content: ''
        width: 1px
        height: 70%
        background: #CFCFCF
        position: absolute
        left: 0
      .button
        display: flex
        justify-content: center
        align-items: center
        width: 130px
        height: 30px
        margin: 0 0 0 10px
        border-radius: 5px
        cursor: pointer
        font-size: 13px
        font-family: "SF Pro Regular", sans-serif
        box-sizing: border-box
        padding: 5px 10px
        -webkit-touch-callout: none
        -webkit-user-select: none
        -khtml-user-select: none
        -moz-user-select: none
        -ms-user-select: none
      .save
        color: white
        background: #4086F1
      .disabled
        color: #a4a4a4
        cursor: default
      .send
        color: #000000
        background: #F5F5F5
        border: 1px solid #A4A4A4
        &:hover
          background: #3b6dcb
      .cancel
        color: #A4A4A4
        background: #F5F5F5
        &:hover
          background: #fc7a7a
          color: white
    .btnsBox
      display: flex
      align-items: center
      & > span, & > div
        flex: 1 1 50%
        position: relative
      .saveSelect
        position: absolute
        top: calc(100% - 8px)
        background: inherit
        margin: 4px 0 0 0
        border-radius: 0 0 4px 4px
        width: 100%
        overflow: hidden
        & li
          padding: 5px 10px
          font-size: 10px
          &:hover
            color: var(--color-text-grey)
            background: var(--color-blue-light)
      .hidden
        display: none

  .customWrap
    position: relative
    margin: 0 5px 0 0
    .unread
      position: absolute
      width: 7px
      height: 7px
      top: -3px
      right: -3px
      background: #D72419
      border-radius: 50%
    .menuDots
      position: relative
      background: #3b6dcb
      width: 3px
      height: 3px
      border-radius: 1px
      &:before, &:after
        position: absolute
        content: ''
        background: #3b6dcb
        width: 3px
        height: 3px
        border-radius: 1px
      &:before
        transform: translateX(6px)
      &:after
        transform: translateX(-6px)
  .photoWrap
    width: calc(28px + 12px + 12px)
    height: 28px
    margin: 0 10px 0 0
    .photoUser
      position: absolute
.standardToolBarWrap
  display: flex
  align-items: center
  .customWrap
    margin: 0 0 0 5px
.mutualEditWrap
  .rightPart
    display: flex
    width: 50%
  .customWrap
    margin: 0 5px 0 0
  .menuDots
    position: relative
    background: #3b6dcb
    width: 3px
    height: 3px
    border-radius: 1px
    &:before, &:after
      position: absolute
      content: ''
      background: #3b6dcb
      width: 3px
      height: 3px
      border-radius: 1px
    &:before
      transform: translateX(6px)
    &:after
      transform: translateX(-6px)
  .photoWrap
    width: calc(28px + 12px + 12px)
    height: 28px
    margin: 0 40px 0 0
    .photoUser
      position: absolute

.bigSize
  .buttonWrap
    min-width: 32px
    min-height: 32px
.buttonWrap
  display: flex
  justify-content: center
  align-items: center
  min-width: 24px
  min-height: 24px
  box-shadow: 0 2px 5px #DEDEDE
  cursor: pointer
  position: relative
  .arrow
    color: #4086F1
  &.chosen
    background: #afc8ee
.inActive path,
.inActive clipPath,
.inActive circle,
.inActive
  fill: grey
  color: grey
  cursor: default
  .inactiveColor
    width: 17px
    height: 17px
    border: 1px solid grey
    background: grey
    border-radius: 50%
.buttonWrapInactive
  cursor: default
.compareWrap
  display: flex
  align-items: center
  justify-content: space-between
  box-sizing: border-box
  padding: 0 3px
  width: 45px
  height: 100%
  position: relative
  &:before
    content: ''
    position: absolute
    width: 1px
    height: 98%
    background: #4086F1
    left: calc(50% - 0.5px)
.compareWrapChosen
  background: #4086F1
  path
    fill: #FFFFFF !important
.additionalToolsWrap
  position: relative
  display: flex
  flex-direction: column
  align-items: center
  .additionalTools
    position: absolute
    width: 146px
    height: 120px
    top: 30px
    left: calc(50% - 82px)
    background: #FFFFFF
    z-index: 3
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.5)
    border-radius: 5px
    &:before
      content: ''
      width: 0
      height: 0
      position: absolute
      border: 8px solid transparent
      border-bottom: 8px solid #FFFFFF
      left: 50%
      top: -16px
    .line
      display: flex
      align-items: center
      width: 100%
      height: 40px
      box-sizing: border-box
      padding: 0 5px
      color: #454545
      font-family: "SF Pro Regular", sans-serif
      .iconTool
        margin: 0 10px 0 0
      &:hover
        background: #afc8ee
        border-radius: 5px
    .lineIcons
      border-top: 1px solid #CFCFCF
      height: 39px
      justify-content: space-around
      padding: 0
      &:hover
        background: #FFFFFF
      .toolWrap
        display: flex
        justify-content: center
        align-items: center
        height: 100%
        width: 29px
        &:hover
          background: #afc8ee
          border-radius: 5px
    .chosen
      background: #afc8ee
      border-radius: 5px
