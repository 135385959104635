
.passWrap
    padding: 16px
    position: relative
    min-width: 300px
    .btns
        display: flex
        gap: 10px
        justify-content: flex-end
        align-items: center
        margin-top: 30px

.list
    display: flex
    gap: 2px
    margin: 10px 0 0

.element
    width: 26px
    height: 26px
    display: flex
    align-items: center
    justify-content: center
    border-radius: 50%
    border: 2px solid transparent
    cursor: pointer

    &.active
        background-color: var(--color-b40)
        border-color: var(--color-b700)

    &:hover
        background-color: var(--color-b40)

    .color
        width: 18px
        height: 18px
        border-radius: 50%
