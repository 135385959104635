
.container
  position: relative

  .wrap
    position: absolute
    top: calc(100% + 10px)
    right: 0
    width: 220px
    box-shadow: 0px 2px 10px var(--color-shadow)
    background: var(--color-white)
    border: 5px solid var(--color-white)
    border-radius: 4px
    cursor: pointer
    z-index: 1
    padding: 5px
    &::before
      content: ""
      position: absolute
      width: 7px
      height: 7px
      background: var(--color-white)
      top: -8px
      right: 2px
      transform: rotate(45deg)
  .filters > div
    margin: 0 !important
    width: 100% !important
  .inputBox
    margin: 5px 0 5px 5px
    border-radius: 20px
    padding: 2px
    padding-left: 4px
    display: flex
    align-items: center
    gap: 5px
    border: 1px solid var(--color-border-dark)
    height: 22px
  .input
    border: none
    width: 80%
    font-size: 10px
    line-height: 12px
  .clear
    position: relative
    width: 16px
    height: 16px
    cursor: pointer
    padding: 0
    .span
      position: absolute
      top: 7px
      left: 2px
      width: 11px
      height: 2px
      background: var(--color-red)
      transform: rotate(45deg)
    .span:before
      content: ''
      position: absolute
      top: 0
      right: 0
      width: 11px
      height: 2px
      background: inherit
      transform: rotate(90deg)
