
.root
  flex: 0 0 60px
  height: 60px
  display: flex
  align-items: center
  position: relative
  padding-left: 10px
  padding-right: 10px
  border-bottom: 1px solid var(--color-border-dark)
.list
  height: 100%
  display: flex
  align-items: center
  column-gap: 12px
  overflow-x: auto
  overflow-y: hidden
  margin: 0
.item
  flex: 0 0 auto
