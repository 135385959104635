
.wrap
    width: 769px
    .list
        border-top: 1px solid var(--color-grey-secondary)
        border-bottom: 1px solid var(--color-grey-secondary)
        padding: 20px 36px
        height: 230px
        overflow-y: auto
        margin: 0 0 8px 0
        .comment
            background: var(--color-background-light)
            box-shadow: 0 2px 10px var(--color-gray-light)
            border-radius: 8px
            width: max-content
            max-width: 100%
            padding: 8px 10px
            margin: 0 0 20px 0
            font-size: 12px
            line-height: 16px

    .buttonWrap
        display: flex
        gap: 10px
        justify-content: center
        padding-top: 30px
        border-top: 1px solid var(--color-grey-secondary)
