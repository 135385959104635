
.task
  border: 1px solid var(--color-border-dark)
  border-radius: 6px
  padding: 9px 0 9px 10px
  margin-bottom: 10px
  display: flex
  gap: 10px
  align-items: center
  font-family: inherit
  font-size: 12px
  .taskName
    flex: 1
    white-space: nowrap
    overflow: hidden
    text-overflow: ellipsis
  .sprint, .tag
    font-size: 10px
    line-height: 12px
    padding: 3px 8px
    border-radius: 10px
  .sprint
    background: var(--color-background-light)
  .tag
    background: var(--color-blue-light)
    width: 70px
    text-align: center
    white-space: nowrap
    overflow: hidden
    text-overflow: ellipsis

  .clip
    width: 30px
    height: 24px
    display: flex
    flex-direction: column
    align-items: center
    justify-content: center
    border: 1px solid var(--color-border-dark)
    border-radius: 6px
    &  path
      fill: var(--color-grey)
  .statusBox
    display: flex
    gap: 5px
    align-items: center
    width: 100px
    padding: 6px 3px 6px 5px
    border-radius: 6px
    border: 1px solid var(--color-border-dark)
    font-size: 10px
    .circle
      width: 8px
      height: 8px
      border-radius: 50%
      flex-shrink: 0
    .status
      flex: 1
      white-space: nowrap
      overflow: hidden
      text-overflow: ellipsis
.task:hover, .active
  border-color: var(--color-blue-smooth)
  background: var(--color-blue-light)
  .sprint
    background: var(--color-white)
  .tag
    background: var(--color-white)
  .clip
    border-color: var(--color-blue-smooth)
    &  path
      fill: var(--color-blue-smooth)
