
.list
  padding: 0
  margin: 0
  font-size: 14px
  color: var(--color-text-grey)
  font-family: "Roboto", sans-serif

  &__btn
    display: inline-flex
    align-items: center
    width: 100%
    padding: 4px 12px
    text-align: left
    line-height: 1.66

    &:hover
      color: var(--color-blue-smooth)

    & > svg
      margin-right: 4px

  .active
    background: var(--color-b100)

    &:hover
      color: inherit

  &__label
    display: inline-block
    width: 100%
    background-color: var(--color-n100)
    padding: 0 12px
    font-weight: 500
    color: var(--color-plantation)

.popoverBody
  border: 0
