.wrapp
    padding: 16px 12px

.eventList
    &__item
        display: flex
        gap: 4px
        font-size: 14px
        line-height: 1.28

        &:not(:last-child)
            margin-bottom: 8px
    
    &__label
        width: 96px
        flex-shrink: 0
        color: var(--color-d500)

    &__value
        display: flex
        flex-direction: column
        gap: 4px
        font-weight: 500
        color: var(--color-d800)

        &_action
            background-color: var(--color-d700)
            border-radius: 2px
            padding: 0 4px
            color: var(--color-background-grey)

    &__itemColor
        display: inline-block
        width: 15px
        height: 15px
        border-radius: 50%

.btns
    margin-top: 26px
    display: flex
    justify-content: flex-end
    gap: 8px
