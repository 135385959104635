
.selectWrap.active
  border-radius: 5px 5px 0 0

.selectWrap
  width: 100%
  position: relative
  border-radius: 5px
  border: 1px solid var(--color-border-grey)
  cursor: pointer
  padding: 12.5px 10px

  .input
    width: 100%
    padding: 0 20px 0 0
    border: none
    color: var(--color-text-grey)
    cursor: pointer
    font-size: 14px
    &::placeholder
      color: var(--color-d800)

  .arrow.active
    border-top: 6px solid var(--color-blue-smooth)
    transform: scaleY(-1)

  .arrow
    position: absolute
    top: calc(100% / 2 - 3px)
    right: 10px
    border-top: 6px solid var(--color-border-dark)
    border-left: 5px solid transparent
    border-right: 5px solid transparent

    &:hover
      border-top: 6px solid var(--color-blue-smooth)

  .contentWrap.active
    max-height: 300px
    border: 1px solid var(--color-border-dark)

  .contentWrap
    position: absolute
    top: 100%
    left: -1px
    z-index: 9
    overflow: hidden
    overflow-y: auto
    width: calc(100% + 2px)
    max-height: 0
    background: var(--color-white)
    border-radius: 0 0 5px 5px

    .content
      max-height: 100%
      padding: 0
      margin: 0

      .option.active
        background: var(--color-white)

      .option
        font-size: 12px
        font-weight: 500
        display: block
        width: 100%
        cursor: pointer
        padding: 5px 12px
        &:hover
          background: var(--color-white)
  .clear
    position: absolute
    top: 10px
    right: 24px
    width: 16px
    height: 16px
    cursor: pointer
    padding: 0
  .clear:hover .span
    background: var(--color-secondary)
  .span
    position: absolute
    top: 7px
    left: 2px
    width: 14px
    height: 2px
    background: var(--color-border-dark)
    transform: rotate(45deg)
  .span:before
    content: ''
    position: absolute
    top: 0
    right: 0
    width: 14px
    height: 2px
    background: inherit
    transform: rotate(90deg)
.low
  padding: 9.5px 10px
.formField
  padding: 7px 10px
