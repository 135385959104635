
.root
  flex: 0 0 30px
  height: 30px
  display: flex
  align-items: center
  gap: 5px
  position: relative
  padding: 0 10px
  border-bottom: 1px solid var(--color-border-dark)
.list
  height: 100%
  display: flex
  align-items: center
  column-gap: 10px
  overflow-y: hidden
  overflow-x: auto
  margin: 0
.item
  display: flex
  align-items: center
  justify-content: center
.iconWrapper
  display: flex
  align-items: center
  justify-content: center
  svg
    &:last-child
      flex: 0 0 21px
      width: 21px
      height: 17px
.button
  display: flex
  align-items: center
  column-gap: 8px
  padding: 0
  font-family: 'Roboto', sans-serif
  font-size: 12px
.folderName
  font-weight: 400
  line-height: 14px
  color: var(--color-scorpion)
  white-space: nowrap
