
.slider
    height: 100%
    position: relative

.slide
    display: flex
    flex-direction: column
    height: 100%
    padding-top: 56px
    background: var(--color-b850)
    border-radius: 48px
    overflow: hidden
    h4
        font-family: "SF Pro Text", sans-serif
        font-style: normal
        font-weight: 590
        font-size: 32px
        line-height: 40px
        letter-spacing: 0.432px
        color: var(--color-white)
        width: 100%
        margin-left: 62px
        position: relative
        z-index: 1
    p
        font-family: "SF Pro Text", sans-serif
        font-style: normal
        font-weight: 400
        font-size: 18px
        line-height: 32px
        color: var(--color-white)
        margin: 10px 10px 10px 62px
        position: relative
        z-index: 1

.image
    width: 100%
    height: 100%
    min-height: 300px
    background-repeat: no-repeat
    position: relative
    z-index: 1

.pagination
    background: linear-gradient(112.48deg, #EFEFEF 6.31%, #ABABAB 98.51%)
    border-radius: 50px
    position: absolute
    z-index: 1
    width: fit-content !important
    left: 50%
    bottom: 5%
    transform: translateX(-50%)
    padding: 0 8px
    height: 24px
    div
        display: flex
        gap: 8px
        align-items: center
        height: 100%
        span
            background: var(--color-d300)
            width: 8px
            height: 8px
            margin: 0 !important

.frame
    position: absolute
    z-index: 0
    width: 100%
    height: 100%
    overflow: hidden
    left: 50%
    top: 50%
    transform: translate(-50%, -50%)
    border-radius: 48px
    &::before
        content: ""
        position: absolute
        z-index: -2
        left: -50%
        top: -50%
        width: 200%
        height: 200%
        background-repeat: no-repeat
        background-size: 50% 50%, 50% 50%
        background-position: 0 0, 100% 0, 100% 100%, 0 100%
        background-color: var(--color-b850)
        background-image: linear-gradient(var(--color-c400), var(--color-b850))
        -webkit-animation: rotate 4s linear infinite, opacityChange 3s ease-in-out infinite
        animation: rotate 4s linear infinite, opacityChange 3s ease-in-out infinite
    &::after
        content: ""
        position: absolute
        z-index: -1
        left: 3px
        top: 3px
        width: calc(100% - 6px)
        height: calc(100% - 6px)
        border-radius: 48px
        background-color: var(--color-b850)
        transition: all 0.5s ease-in-out

@keyframes opacityChange
    0%,
    100%
        opacity: 0

    25%
        opacity: 0

    50%
        opacity: 1

    75%
        opacity: 0

@keyframes rotate
    100%
        transform: rotate(1turn)
