.btnWrapp
  position: relative

  &.isSelected
    &::after
      content: ""
      background-color: var(--color-red)
      width: 10px
      height: 10px
      border-radius: 50%
      position: absolute
      right: -5px
      top: -5px
    
      



