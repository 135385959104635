.wrapper
    height: 102px
    width: 112px
    display: flex
    flex-direction: column
    gap: 8px
    cursor: pointer
    &:hover
        .menuButton
            display: flex
        .content
            background: var(--Blue-B100, #BBDEFB)

.content
    position: relative
    border-radius: 4px
    height: 78px
    background: var(--DarkGreen-D50, #E7EBEA)
    display: flex
    align-items: center
    justify-content: center
    transition: all 0.3s ease-in-out

.title
    color: var(--DarkGreen-D700, #3E5D56)
    font-size: 12px
    font-weight: 400
    line-height: 16px
    white-space: nowrap
    overflow: hidden
    text-overflow: ellipsis

.menuButton
    display: none
    position: absolute
    right: 4px
    top: 4px
