.wrap
  width: 1080px
  max-height: 510px
  .body
    display: flex
    gap: 8px
    padding: 12px 16px 0
.folders
  flex: 1 0 256px
  background: var(--color-d40)
  min-width: 256px
  & > ul
    max-height: 446px
.space
  flex: 1 0 784px

.container
  position: relative
  height: calc(100% - 28px)
  max-height: 418px
  overflow: auto

.header
  display: flex
  justify-content: space-between
  align-items: stretch
  & > div:first-child
    display: flex
    gap: 8px
    align-items: stretch

    &  button, span
      height: 32px
.files
  padding: 12px
  display: flex
  flex-wrap: wrap
  gap: 16px
.observer
  width: 100px
  height: 100px
  flex: 0 0 100px
.loaderBox
  width: 100px
  height: 100px
  flex: 0 0 100px
  margin: 0 auto
  position: relative
  align-self: flex-end
  margin-bottom: 10px

.btns
  display: flex
  justify-content: flex-end
  padding: 12px
  gap: 8px
  box-shadow: 0px -2px 5px 0px rgba(0, 0, 0, 0.14)

.file
  padding-top: 14px

.block
  position: relative
  height: 100%
  display: flex
  flex-direction: column
  width: 110px
  min-height: 87px
  padding: 5px
  border: 1px solid var(--color-gallery)
  border-radius: 4px
  background-color: var(--color-background-light)
  text-align: center
  cursor: pointer
  margin: 0 auto
  &:hover
    .checkbox
      visibility: visible

  &.checked
    background: var(--color-blue-items)
    border-color: var(--color-blue-smooth)
    .context
      background: var(--color-blue-smooth)
      &::after
        background: var(--color-blue-smooth)
      svg
        path
          fill: var(--color-white)
    .checkbox
      visibility: visible

.context
  background: var(--color-gallery)
  border-radius: 6px 6px 0 0
  cursor: pointer
  height: 20px
  left: -1px
  position: absolute
  top: -14px
  width: 50px
  z-index: -1
  &::after
    content: ""
    position: absolute
    top: 0
    right: -10px
    width: 20px
    height: 20px
    background: var(--color-gallery)
    border-radius: 0 3px 0 0
    transform: skew(30deg, 0deg)
  svg
    margin-top: -10px
.checkbox
  padding-bottom: 4px
  visibility: hidden
  height: 20px
.iconBox
  flex-grow: 1
  height: 44px
.name
  -webkit-line-clamp: 2
  -webkit-box-orient: vertical
  display: -webkit-box
  line-height: 1.33
  overflow: hidden
  text-align: left
  -webkit-user-select: none
  user-select: none
  word-break: break-word
  font-size: 10px
  max-height: 27px
  margin: 5px 0
  flex-grow: 1
.symbols
  width: calc(100% - 32px)
  position: absolute
  z-index: 1
  top: 5px
  right: 5px
  display: flex
  flex-direction: column
  align-items: flex-end
  row-gap: 4px
  overflow: hidden
.emoFigWrapper
  display: flex
  align-items: center
  column-gap: 4px
.bottom
  font-size: 6px
  line-height: 7px
  color: var(--color-d600)
  user-select: none
  display: flex
  justify-content: space-between
.emo
  font-size: 12px
