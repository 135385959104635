
.wrap
    position: relative
    width: 100%
    height: 177px
    border: 1px solid var(--color-border-dark)
    border-radius: 8px

    & > button
        position: absolute
        bottom: 0
        width: 45px
        height: 45px
.wrapEditor
    display: flex
    flex-direction: column
    height: 107px
    margin-top: 10px
    font-size: 12px !important
    line-height: 14px
.wrapEditor  > div:first-child
    padding-top: 8px
    width: 100%
    border: none !important
    border-radius: 0 0 8px 8px !important
    border-top: 1px solid var(--color-border-dark) !important
    position: absolute
    bottom: 1px

.wrapEditor > div:last-child
    flex: 1
    padding: 8px
    height: 50px !important
    position: static !important
    &  p
        margin: 0 0 6px !important
    & ul
        list-style-type: disc
        padding-left: 20px
