@import "src/theme/global"
.wrapper
  position: relative
  display: flex
  flex-direction: column
  width: 100%
  height: 35px

  &.isLabel
    padding-top: 6px

  &.extraLarge
    height: 32px

  &.medium
    height: 28px

  &.selected
    &::after
      content: ""
      width: 0
      height: 0
      border-left: 3.33px solid transparent
      border-right: 3.33px solid transparent
      border-top: 3.33px solid var(--color-d100)
      position: absolute
      top: 50%
      right: 10px
      transform: translateY(-50%)
      transition: transform 300ms, border-top-color 300ms

  &.open::after
    border-top-color: var(--color-blue-smooth)
    transform: rotate(180deg) translateY(50%)

  .rightText
    font-size: 14px
    font-weight: 500
    color: var(--color-d900)
    position: absolute
    top: 50%
    transform: translateY(-50%)
    right: 8px

    &.isLabel
      transform: translateY(calc(-50% + 3px))

  .inputBlock
    width: 100%
    height: 100%
    position: relative

.input
  width: 100%
  height: inherit
  font-family: 'Roboto', sans-serif
  font-size: 14px
  line-height: 16px
  color: var(--color-d800)
  border: 1px solid var(--color-d100)
  border-radius: 4px
  padding: 0 15px

  &.extraLarge
    width: 320px

  &.medium
    // width: 176px
    max-width: 100%
    font-size: 12px
    padding: 0 10px

    &::placeholder
      font-size: 12px

  &.error
    border-color: var(--color-error)
    &::placeholder
      color: var(--color-error)
  &.withIcon
    padding-right: 45px
  &.isNumberType
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button
      -webkit-appearance: none
      display: none
      margin: 0
  &::placeholder
    color: var(--color-d400)
    font-size: 14px
    font-weight: 400
    letter-spacing: normal !important

  &:focus
    &::placeholder
      color: var(--color-d800)

    &:not(.error)
      border-color: var(--color-b600)

  &.password
    font-family: '•', sans-serif
    letter-spacing: 3px
    -webkit-text-security: disc
    text-security: disc

.buttonIcon
  position: absolute
  top: calc(50% )
  right: 15px
  transform: translateY(-50%)
  display: flex
  align-items: center
  justify-content: center
  padding: 0
  @include clickAreaEnlargement(200%, 200%)
.label
  top: 0
  position: absolute
  left: 6px
  background: var(--color-white)
  padding: 0 4px
  color: var(--color-d700)
  font-size: 12px
  line-height: 12px
  border-radius: 2px
.require
  color: var(--color-red)
.errortext
  font-size: 10px
  color: var(--color-error)
  padding-left: 10px
