@import src/theme/global

.head
  display: flex
  gap: 2px
  align-items: center
  padding: 10px 12px
  background-color: #fff
  border-radius: 4px
  margin-bottom: 2px
  &.current
    background: var(--color-b100)
  &.open
    border-radius: 4px 4px 0 0
    .btn
      transform: rotate(90deg)
  &.hidden
    padding: 10px
    .name, button
      display: none

  .name
    color: var(--color-d800, #274A42)
    font-size: 14px
    font-weight: 500
    line-height: 18px
    flex-grow: 1
    min-width: 0
    padding: 0 8px
    @include textEllipsis
  .btn
    transform: rotate(-90deg)
.menu
  padding: 4px 0
  &.hidden
    .name
      display: none
  &:not(:last-child)
    border-bottom: 1px solid var(--color-d200, #B6C2BF)
  & li:not(:last-child)
    margin-bottom: 2px
  .link
    display: flex
    align-items: center
    gap: 6px
    padding: 8px 12px
    border-radius: 4px
    &:hover
      background-color: var(--color-b100)
    &.active
      background-color: var(--color-b400)
      .name
        color: var(--color-d50, #E7EBEA)
      svg path
        fill: var(--color-d50, #E7EBEA)
  .iconBox
    width: 24px
    height: 24px
    display: flex
    align-items: center
    justify-content: center
    flex-direction: column
  .name
    color: var(--color-d800, #274A42)
    font-size: 14px
    line-height: 20px
