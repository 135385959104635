
.wrap
    width: 410px
    padding-top: 30px

    .checkIcon
        width: 60px
        height: 60px
        margin: 0 auto 20px
        display: block
    .list
        display: grid
        grid-template-columns: 1fr auto
        grid-template-rows: auto
        grid-auto-columns: 1fr
        grid-auto-rows: 1fr
        gap: 10px 10px
        grid-auto-flow: row
        align-items: center
        grid-template-areas: ".."

    .text
        font-family: "SF Pro Regular", sans-serif
        font-size: 13px
        line-height: 16px
        color: var(--color-text-grey)
        margin: 0 auto 20px
        width: 80%
    .btns
        display: flex
        gap: 10px
        justify-content: center
        margin-top: 20px
