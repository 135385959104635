.textareaWrapper
  position: relative
  padding-top: 10px

  &.isError
    margin-bottom: 16px

.textarea
  display: block
  width: 100%
  height: 72px
  font-family: 'Roboto', sans-serif
  font-size: 13.5px
  line-height: 16px
  color: var(--color-d800)
  border: 1px solid var(--color-d100)
  border-radius: 6px
  padding: 10px 15px
  outline: none

  &.error
    border-color: var(--color-error)

    &::placeholder
      color: var(--color-error)

  &::placeholder
    color: var(--color-silver-chalice)
    font-size: 14px
    font-weight: 400
    letter-spacing: normal !important

  &:focus:not(.error)
    border-color: var(--color-blue-smooth)

.label
  top: 0
  position: absolute
  left: 6px
  background: var(--color-white)
  padding: 0 4px
  color: var(--color-d700)
  font-size: 12px

.require
  color: var(--color-red)

.errortext
  position: absolute
  top: 100%
  left: 4px
  font-size: 10px
  color: var(--color-error)
