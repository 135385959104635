$border: 1px solid #DFDFDF

.panel
  display: flex
  flex-direction: column
  align-items: center
  justify-content: flex-start
  width: 100px
  height: 100%
  min-height: 100%
  overflow-y: scroll
  overflow-x: hidden
  &:first-child
    border-right: $border
  &:last-child
    border-left: $border
  //TODO - Need to add styled scrollbar if there are more than 4 images
  &::-webkit-scrollbar
    width: 0
  .itemWrap
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center
    background: #F2F2F2
    width: 80px
    height: 80px
    min-height: 80px
    margin: 10px 0 0 0
    border-radius: 10px
    font-size: 11px
    font-family: "SF Pro Regular", sans-serif
    color: grey
    cursor: pointer
    position: relative
    &:last-child
      margin: 10px 0
    .addIcon
      width: 17px
      height: 17px
      margin: 0 0 6px 0
    .inputImage
      opacity: 0
      width: 100%
      height: 100%
      position: absolute
      z-index: 3
      cursor: pointer
    .image
      max-width: 80%
      max-height: 80%
  .itemChosen
    background: #4086F1
  .hoverDelete
    width: 100%
    height: 100%
    position: absolute
    z-index: 2
    border-radius: 10px
    display: flex
    align-items: center
    justify-content: center
    .deleteWrap
      display: none
      .deleteIco
        display: none
    &:hover
      background: rgba(0, 0, 0, 0.2)
      .deleteWrap
        display: flex
        justify-content: center
        align-items: center
        background: #FFFFFF
        width: 40px
        height: 25px
        border-radius: 3px
        .deleteIco
          display: flex
          path
            fill: red
