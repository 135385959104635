
.wrap
  width: 440px
  .body
    padding: 12px 16px
  .text
    padding: 8px 0
    margin: 0
    font-size: 14px
    line-height: 16px
    color: var(--color-d600)

    .value
      font-weight: 500
      color: var(--color-d800)
