@import "src/theme/theme"

.groupStart
  display: flex
  align-items: center
  gap: 10px

  &:not(:last-of-type)
    margin-right: 24px

  input
    font-size: 12px
    padding-top: 4px
    padding-bottom: 4px
    height: 28px

.input
  padding: 0
  font-size: 12px

  &Container
    width: auto
    min-width: 176px
    height: 28px

  &Icon
    width: 16px
    height: 16px
