.container
    text-align: center
    position: relative
    font-family: 'SF Pro Text', sans-serif
    letter-spacing: 0.5px
    width: 50px
    margin: 0 auto 5px
    .text
        position: absolute
        line-height: 50px
        width: 100%
        height: 100%
        font-size: 14px
