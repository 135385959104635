
.wrap
  width: 100%
  display: flex
  align-items: center
  gap: 10px
  padding: 6px
  border: 1px solid var(--color-border-dark)
  border-radius: 6px
  margin-bottom: 10px
  font-family: "SF Pro Text", sans-serif
  .img
    width: 32px
    height: 32px
    object-fit: cover
    border-radius: 6px
    flex-shrink: 0
  .name
    flex: 1 1 248px
    & span
      display: inline-block
      max-width: 248px
      text-overflow: ellipsis
      white-space: nowrap
      overflow: hidden
      font-family: "SF Pro Medium", sans-serif
      font-size: 12px
  .tag
    color: var(--color-blue-smooth)
    border: 1px solid var(--color-blue-smooth)
    border-radius: 10px
    background: var(--color-white)
    padding: 1px 8px 0
    font-size: 10px
    line-height: 14px
    width: max-content
    max-width: 80px
    white-space: nowrap
    text-overflow: ellipsis
    overflow: hidden
    margin: 0
    flex-shrink: 0
  .color
    width: 16px
    height: 16px
    border-radius: 50%
    flex-shrink: 0
  .link
    color: var(--color-blue-smooth)
    border-radius: 10px
    background: var(--color-blue-light)
    padding: 4px 20px
    font-size: 13px
    line-height: 14px
    width: max-content
    width: 210px
    white-space: nowrap
    text-overflow: ellipsis
    overflow: hidden
    text-decoration: underline
    flex-shrink: 0
    margin: 0 20px
    text-align: center
  .activeLink
    background: var(--color-white)
  .icon path
    fill: var(--color-red)-dark
.active
  background: var(--color-blue-light)
  border: 1px solid var(--color-blue-smooth)

.hiddenInput
  height: 0px
  margin: 0
  padding: 0
  border: none
  overflow: hidden
  position: absolute
