
.wrap
  width: 100%
  height: calc(100% - 140px)
  padding: 10px
  color: var(--color-text-grey)
  overflow-y: scroll
  font-family: 'SF Pro Text', sans-serif
  .text
    font-size: 13px
    line-height: 20px
    padding: 10px
    border-radius: 6px
    margin: 0 0 20px
    font-weight: 700
    font-family: 'SF Pro Medium', sans-serif
    text-align: center

    span
      font-weight: 500

  .row, .rowStart
    display: flex
    gap: 15px
    align-items: stretch
    button
      height: 20px
  .row
    justify-content: space-between
    margin-bottom: 10px

  .block
    background: var(--color-background-light)
    border: 1px solid var(--color-border-dark)
    border-radius: 10px
    padding: 10px
    margin-bottom: 10px
    flex-grow: 1

    .title,.textInfoBlue, .textInfoRed
      font-size: 11px
      line-height: 18px
      margin: 0
      text-align: center
      width: max-content
      color: var(--color-text-grey)
    .title
      padding: 0 20px
      background: var(--color-blue-light)
      border-radius: 10px
      border: 1px solid var(--color-blue-smooth)

    .textInfoBlue
      padding: 0 5px
      border-radius: 6px
      border: 1px solid var(--color-blue-smooth)
      span
        color: var(--color-blue-dark)
        font-family: 'SF Pro Medium', sans-serif
      .circle
        background: var(--color-blue-smooth)
        transform: translateY(1px)

    .textInfoRed
      padding: 0 5px
      border-radius: 6px
      border: 1px solid var(--color-red-dark)
      span
        color: var(--color-red)-dark
        font-family: 'SF Pro Medium', sans-serif
      .circle
        background: var(--color-red-dark)
        transform: translateY(1px)

  .half
    width: 50%
  .charts
    display: flex
    gap: 10px
    justify-content: space-between
    align-items: stretch
    max-width: 100%
    overflow: auto
    .chartItem
      flex: 1 0 auto
      text-align: center
      width: 88px
      font-size: 10px
      .legend
        margin: 0 auto 10px
        justify-content: center

  .legend
    display: flex
    gap: 5px
    font-size: 10px

  .blue
    border: 2px solid var(--color-blue-dark)
  .red
    border: 2px solid var(--color-red-dark)
  .green
    border: 2px solid var(--color-green-dark)
  .orange
    border: 2px solid var(--color-secondary-orange)

  .circle
    width: 10px
    height: 10px
    display: inline-block
    border-radius: 50%
    flex-shrink: 0

  .progressBlock
    width: calc(100% - 10px)
    height: 70px
    position: relative
    border-bottom: 3px solid var(--color-border-dark)
    margin: 0 auto
    .item
      position: absolute
      height: 100%
      &::before, &::after
        content: ""
        position: absolute
        left: -5px
        border: 2px solid
        width: 7px
        height: 7px
        border-radius: 50%
        background: var(--color-background-light)
      &::before
        top: -1px
      &::after
        bottom: -7px

    .start
      left: 0
      bottom: -2px
      border-left: 1px dashed var(--color-blue-dark)
      border-bottom: 2px solid var(--color-blue-dark)
      &::before, &::after
        border-color: var(--color-blue-dark)

    .current
      height: 80%
      bottom: 0
      border: 1px dashed var(--color-secondary-orange)

      &::before, &::after
        border-color: var(--color-secondary-orange)

    .end
      border: 1px dashed var(--color-green-dark)
      &::before, &::after
        border-color: var(--color-green-dark)
        z-index: 1

    .extend
      height: 80%
      bottom: -2px
      right: 0
      border-right: 1px dashed var(--color-red-dark)
      border-bottom: 2px solid var(--color-red-dark)
      &::before, &::after
        left: auto
        right: -6px
        border-color: var(--color-red-dark)

  .selectBox
    & > div
      padding: 4px
      width: 80px
      font-size: 12px
      ul
        margin: 5px
      li
        margin: 5px 0
  .column
    display: flex
    flex-direction: column
    span
      color: var(--color-text-grey-light)
