
.button
  flex: 0 0 40px
  width: 40px
  height: 28px
  display: flex
  align-items: center
  justify-content: center
  column-gap: 4px
  background-color: var(--color-background-light)
  border: 1px solid var(--color-gallery)
  border-radius: 6px
  margin-right: 4px
  padding: 0
  transition: background-color 0.2s ease-in-out, border-color 0.2s ease-in-out
  position: relative
  cursor: pointer
.button:hover, .open
  background-color: var(--color-blue-items)
  border-color: var(--color-blue-smooth)
  svg
    path,
    rect
      fill: var(--color-blue-smooth)

.menuIcon,
.arrowIcon
  path,
  rect
    fill: var(--color-fill-grey)

.list
  padding: 0 10px
  margin: 0
  font-size: 10px
  font-family: "SF Pro Text", sans-serif
  .item
    padding: 7px 4px
    line-height: 12px
    border: 1px solid transparent
    border-radius: 6px
    margin-bottom: 2px
    cursor: pointer
    &:hover
      color: var(--color-blue-smooth)
  .active
    border-color: var(--color-blue-smooth)
    background: var(--color-blue-items)
    &:hover
      color: inherit
