.wrap
  width: 640px
  max-width: 100%
  padding: 12px 16px

  &.loading
    &::after
      content: ""
      width: 100%
      height: 100%
      position: absolute
      top: 0
      left: 0
      z-index: 20
      background-color: var(--color-white-transparent-20)
      backdrop-filter: blur(2px)

.contentWrap
  display: flex
  flex-direction: column
  gap: 24px

.topWrap
  flex: 1 1 auto
  display: flex
  flex-direction: column
  align-items: flex-start
  row-gap: 12px

.buttonsWrap
  display: flex
  justify-content: flex-end
  gap: 10px
