
.selectWrap
  width: 100%
  height: 100%
  position: relative
  box-shadow: 0 0 5px rgba(151, 151, 151, 0.5)
  border-left: 1px solid
  border-color: inherit
  background: var(--color-white)

  .selected
    background: #F0F6FF

  .select
    cursor: pointer
    height: 100%

    .valueWrap
      height: 100%
      display: flex
      align-items: center

      span
        display: inline-block
        white-space: nowrap
        text-overflow: ellipsis
        overflow: hidden
        max-width: 85%
        height: auto

    .selectInput
      font-size: 14px
      line-height: 18px
      color: #000
      padding-left: 12px

  .arrow.active
    border-top: 6px solid #4086F1

  .arrow
    position: absolute
    top: calc(100% / 2 - 3px)
    right: 10px
    //border-top: 6px solid #4086F1
    border-top: 6px solid #666
    border-left: 5px solid transparent
    border-right: 5px solid transparent

    &:hover
      border-top: 6px solid #4086F1

  .contentWrap.active
    max-height: 300px

  .contentWrap
    position: absolute
    top: 100%
    z-index: 9
    overflow: hidden
    overflow-y: auto
    width: 100%
    max-height: 0
    background: inherit
    border-radius: 0 0 5px 5px
    box-shadow: 0 4px 5px rgba(151, 151, 151, 0.5)

    .content
      max-height: 100%
      padding: 0
      margin: 0

      .option.active
        background: #fff

      .option
        font-size: 12px
        font-weight: 500
        display: block
        width: 100%
        cursor: pointer
        padding: 5px 12px
        &:hover
          background: #F0F6FF

.darkTheme.selectWrap
  .contentWrap
    box-shadow: rgb(39, 39, 39,) 0 2px 5px
    .content
      .option.active,
      .option:hover
        background: rgba(196, 196, 196, 0.1)
