.content
    width: 768px
    margin: 0 auto
    height: 100%
    h2
        color: var(--DarkGreen-D800, #274A42)
        font-size: 20px
        font-weight: 500
        line-height: 28px
        margin-top: 20px
        margin-bottom: 8px

.itemsGrid
    display: flex
    flex-wrap: wrap
    gap: 8px

.empty
    display: flex
    align-items: center
    justify-content: center
    width: 100%
    height: 100%
