@import src/theme/global
.wrap
  display: flex
  gap: 40px
  font-size: 11px
  font-family: "SF Pro Text", sans-serif
  margin-right: 30px

.tabs
  display: flex
  .tabType
    border-top: 1px solid var(--color-border-dark)
    border-bottom: 1px solid var(--color-border-dark)
    position: relative
    padding: 1px 12px

    &:first-child
      border-radius: 6px 0 0 6px
      border-left: 1px solid var(--color-border-dark)

    &:last-child
      border-radius: 0 6px 6px 0
      border-right: 1px solid var(--color-border-dark)
      &::after
        display: none
    &::after
      content: ""
      width: 1px
      height: 16px
      background: var(--color-border-dark)
      display: block
      position: absolute
      right: 0
      top: 3px

  .activeTab
    border: 1px solid var(--color-blue-smooth)
    background: var(--color-blue-smooth)
    color: var(--color-white)
    &::after
      background: var(--color-blue-smooth)
    &:first-child,  &:last-child
      border-color: var(--color-blue-smooth)
.datePicker
  display: flex
  gap: 10px
  .arrowIcon
    width: 4px
    height: 7px
  .arrowRight
    transform: scaleX(-1)
.date
  width: 142px
  border: 1px solid var(--color-blue-smooth)
  border-radius: 6px
  background: var(--color-blue-light)
  cursor: pointer
  padding: 1px 10px
  gap: 5px
  color: var(--color-text-grey)
  line-height: 24px
  text-align: center
  @include textEllipsis
