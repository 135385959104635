.wrap
  width: 440px
  .body
    padding: 12px 16px
  .top
    display: flex
    gap: 8px
    margin-bottom: 8px
  .delimiter
    width: 2px
    background-color: var(--color-d100)
  .title
    color: var(--color-d600)
    font-size: 12px
    line-height: 20px
    margin-bottom: 8px
    display: inline-block
  .block
    background-color: var(--color-d20, #F5F7F7)
    padding: 7px 12px 12px
    margin-top: 8px
    border-radius: 4px

  .list
    display: flex
    flex-direction: column
    gap: 8px
    margin-bottom: 8px

  .item
    display: flex
    gap: 8px
    .name
      color: var(--color-d800)
      font-size: 12px
      font-weight: 500
      line-height: 20px

  .btns
    padding-top: 16px
    display: flex
    justify-content: flex-end
    gap: 8px
  .loaderWrap
    display: flex
    gap: 8px
    align-items: center
    padding: 8px
    .loaderBox
      width: 200px
      position: relative
