.container
    width: 58px
    height: 74px
    display: flex
    flex-direction: column
    align-items: center
    cursor: pointer
    border-radius: 6px
    padding: 2px
    transition: all 0.2s ease-in-out
    &:hover
        background: var(--color-b40, #EDF9FE)
    &_normal
        flex-direction: row
        height: 30px
        width: 100%
        gap: 5px
        justify-content: flex-start
        .title
            text-align: start
        .image
            width: 24px
            height: 24px

.emptyWrapper
    padding-right: 16px
    width: 100%
    height: 100%
    display: flex
    align-items: center
    justify-content: center

.title
    margin-top: 3px
    font-size: 12px
    line-height: 16px
    text-align: center
    color: var(--dark-green-d-800, #274A42)
    width: 100%
    white-space: nowrap
    overflow: hidden
    text-overflow: ellipsis

.image
    width: 54px
    height: 54px
    object-fit: contain
    border-radius: 4px
