.container
  padding: 18px
  width: 640px

.header
  display: flex
  justify-content: space-between

.info
  display: flex
  > :nth-child(2)
    margin-left: 10px
    display: flex
    flex-direction: column
    justify-content: space-between

.title
  color: var(--dark-green-d-800, #274A42)
  font-size: 12px
  font-weight: 700

.mail
  color: var(--dark-green-d-700, #3E5D56)
  font-size: 12px

.content
  color: var(--dark-green-d-700, #3E5D56)
  font-family: Roboto
  font-size: 14px
  margin-top: 10px
  max-height: 300px
  overflow: auto

.footer
  margin-top: 30px
  display: flex
  justify-content: space-between

.footerLeft
  display: flex
  gap: 8px
