@import "src/theme/cutsStr"
.card
  font: 12px/1.33 "Roboto", sans-serif
  padding: 8px
  cursor: pointer

  &:hover,
  &:active
    .card__btns
      opacity: 1
      visibility: visible

  &.isLinePreview
    display: flex
    align-items: center
    gap: 20px
    border-radius: 4px
    background-color: var(--color-d40)
    transition-duration: 500ms

    &:hover,
    &:active
      background-color: var(--color-d50)

  &__head
    height: 79px
    display: flex
    flex-wrap: wrap
    gap: 2px
    margin-bottom: 8px

    &.isLinePreview
      width: 61px
      height: 42px
      margin-bottom: 0

  &__imgBox
    width: 55px
    height: 38.5px
    flex: 1 1 auto
    background-color: var(--color-d50)
    border-radius: 4px
    overflow: hidden

    > img
      object-fit: contain
      height: inherit

    &.default
      > img
        width: clamp(22px, 49%, 54px)
        height: clamp(22px, 70%, 54px)

      &.isLinePreview
        > img
          width: clamp(19px, 49%, 29.5px)
          height: clamp(19px, 70%, 29.5px)

    &.isLinePreview
      width: 29.5px
      height: 20px

    &.heightInherit
      height: inherit
  
  &__body
    &.isLinePreview
      max-width: 233px
      width: 100%

  &__name
    font-size: 12px
    font-weight: 400
    color: var(--color-d700)
    @include cutsStr(2)

    &.isLinePreview
      color: var(--color-d800)
      font-size: 14px
      font-weight: 500
      line-height: 1.42
  
  &__date
    color: var(--color-d600)
    line-height: 1.5

  &__btns
    margin-left: auto
    display: flex
    gap: 8px
    opacity: 0
    visibility: hidden
    transition: 500ms opacity
