
.root
  height: 100%
  display: flex
  flex-direction: column
  row-gap: 20px
.infoWrapper
  min-height: 70px
  display: flex
  flex-direction: column
  row-gap: 8px
  padding-top: 10px
  border-top: 1px solid var(--color-grey-secondary)
  overflow-y: auto
.title
  font-family: 'SF Pro Text', sans-serif
  font-weight: 400
  font-size: 12px
  line-height: 17px
  color: var(--color-silver-chalice)
  span
    color: var(--color-text-dark)
.list
  display: flex
  flex-wrap: wrap
  align-items: stretch
  gap: 5px
  overflow-y: auto
  padding-right: 5px
  margin: 0
.item
  height: 34px
  display: flex
  align-items: center
  column-gap: 5px
  background: var(--color-blue-items)
  border-radius: 100px
  padding-right: 10px
  padding-left: 6px
.avatar
  flex: 0 0 24px
  width: 24px
  height: 24px
  border-radius: 50%
.email
  font-family: 'SF Pro Text', sans-serif
  font-weight: 400
  font-size: 12px
  line-height: 14px
  color: var(--color-text-dark)
  word-break: break-word
  margin: 0
.infoText
  font-family: 'SF Pro Text', sans-serif
  font-weight: 400
  font-size: 14px
  line-height: 16px
  color: var(--color-text-dark)
  margin: 0
