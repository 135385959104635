
.wrap
  padding: 10px
  border-bottom: 1px solid var(--color-border-dark)
  display: flex
  align-items: center
  justify-content: space-between
  .btns
    display: flex
    gap: 10px
