.container
    display: flex
    flex-direction: column

.content
    width: 400px
    height: 300px

.loader
    min-width: 400px
    min-height: 300px
    display: flex
    align-items: center
    justify-content: center

.buttons
    display: flex
    justify-content: flex-end
    align-items: center
    gap: 10px
    padding: 10px 20px
