.wrap
  height: 100%
  display: flex
  position: relative
.content
  flex: 1 1 auto
  max-height: 100%
  overflow-y: auto
  margin-right: 16px
.right
  flex-shrink: 0
  .editBtns
    display: flex
    gap: 8px
    justify-content: flex-end
    margin-top: 40px

.top
  display: flex
  margin-bottom: 8px
  justify-content: space-between
  div
    display: flex
    gap: 6px
.title
  color: var(--DarkGreen-D900, #0E352C)
  font-size: 24px
  font-style: normal
  font-weight: 500
  line-height: 32px
.info
  display: flex
  gap: 12px
  color: var(--DarkGreen-D700, #3E5D56)
  font-size: 12px
  line-height: 16px
  margin-bottom: 12px
  .num
    color: var(--color-b600)
  .author
    color: var(--Blue-B700, #1976D2)
    font-weight: 700
.prim
  a
    color: var(--Blue-B700, #1976D2)
    text-decoration: underline
  ul, li, ol
    list-style: disc
  ul, ol
    padding-left: 6px
  li
    margin-left: 10px
.editorWrapper
  border-radius: 4px
  position: relative
  margin-top: 12px
  .label
    top: -6px
    position: absolute
    left: 6px
    background: var(--color-white)
    padding: 0 4px
    color: var(--color-d700)
    font-size: 12px
    line-height: 12px
    border-radius: 2px
.fileList
  display: flex
  gap: 8px
  flex-wrap: nowrap
  overflow: auto
  max-width: 100%
  padding: 12px 0
  border-top: 1px solid var(--color-d100)

.loaderBox
  position: absolute
  width: 100%
  height: 100%
