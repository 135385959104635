
.container
    padding: 16px
    width: 300px

.check
    color: var(--DarkGreen-D800, #274A42)
    font-size: 12px
    font-weight: 500
    line-height: 20px
    display: flex
    align-items: center
    gap: 6px
    margin: 10px 0 20px

.buttons
    display: flex
    align-items: center
    gap: 6px
    justify-content: flex-end
