
.emptyText
  color: var(--color-text-grey)
  text-align: center
.loaderBox
  width: 100px
  height: 100px
  margin: 0 auto
  position: relative
.observer
  width: 100px
  height: 100px
