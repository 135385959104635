.wrap
  padding: 12px
  overflow: auto
  height: calc(100% - 100px)
  .row
    display: flex
    gap: 12px
  .block
    flex: 1 1 auto
    background: #F5F7F7
    border-radius: 4px
    padding: 8px 12px 12px
    margin-bottom: 12px
    min-width: 0
  .progressBlock
    flex: 0 0 auto
    background: #F5F7F7
    border-radius: 4px
    padding: 8px 12px 12px
    margin-bottom: 12px

  .header
    display: flex
    align-items: center
    justify-content: space-between
    margin-bottom: 6px
  .subTitle
    color: var(--DarkGreen-D600, #56716B)
    font-size: 14px
    font-weight: 500
    line-height: 20px
.allTasks
  display: flex
  gap: 10px
  justify-content: space-between
  overflow-x: auto
  min-width: 0
  height: 120px
.chartContainet
  height: 150px
.legend
  display: flex
  gap: 8px
  color: var(--DarkGreen-D600, #56716B)
  font-size: 10px
  line-height: 16px
  .bold
    color: var(--DarkGreen-D800, #274A42)
    font-size: 12px
    font-weight: 700
    line-height: 16px
  .icon
    width: 16px
    height: 16px
    display: flex
    align-items: center
.progress
  height: 120px
  width: 510px
.overdueTitle
  padding: 4px 8px
  border-radius: 4px
  background: var(--Main-red, #F00)
  color: var(--DarkGreen-D100, #CFD7D5)
  font-size: 12px
  line-height: 16px
  span
    color: var(--DarkGreen-D50, #E7EBEA)
    font-weight: 500
