
.wrapper
  overflow-x: auto
  overflow-y: hidden
  width: 100%
.list
  display: flex
  gap: 6px
  flex-wrap: nowrap
  min-width: 0
  li
    cursor: pointer
    border: 3px solid transparent
    border-radius: 5px
    margin-bottom: 1px
    &.active
      border: 3px solid var(--color-b700, #1976D2)
      border-radius: 6px
    &.activeWhite
      border: 3px solid var( --color-white, white)
      border-radius: 6px
