
@keyframes showPopup
  0%
    width: 10px
    height: 10px
    background: white
    opacity: 0
  99%
    width: 521px
    height: 418px
    opacity: 0
.wrap
  position: relative
  width: 480px
  font-family: 'SF Pro Text', sans-serif
  color: var(--color-text-grey)-medium
  font-size: 13px
  animation: showPopup 0.2s
  .header
    padding: 18px 40px
    position: relative
    .calendarTitle
      font-weight: 500
      font-size: 18px
      line-height: 21px
      letter-spacing: -0.086px
      color: var(--color-text-dark)
      margin: 0 auto 15px
      text-align: center
    .calendarSubtitle
      font-weight: 400
      font-size: 13px
      line-height: 16px
      letter-spacing: -0.08px
      color: var(--color-silver-chalice)
      margin-bottom: 15px
      text-align: center
    .calendarPicWrapper
      display: flex
      align-items: center
      justify-content: space-between
    .calendarPic
      display: flex
      min-width: max-content
      align-items: center
      min-height: 100%
      width: 80px
      height: 30px
      span
        font-size: 12px
        min-width: max-content
    .timeBlock
      display: flex
      align-items: center
      gap: 10px
    .inputTime
      border: 1px solid var(--color-border-dark)
      border-radius: 6px
      font-size: 13px
      width: 80px
      height: 30px
      padding: 6px 10px
      line-height: 16px
      color: inherit
      text-align: center
      &:hover, &:focus
        border-color: var(--color-blue-smooth)
    .inputTime::-webkit-inner-spin-button, .inputTime::-webkit-calendar-picker-indicator
      display: none
      -webkit-appearance: none
  .switchBlock
    display: flex
    align-items: center
    gap: 10px
    font-weight: 600
  .iconDecrease, .iconIncrease
    border: 1px solid var(--color-border-dark)
    border-radius: 6px
    width: 30px
    height: 30px
    display: flex
    flex-direction: column
    align-items: center
    justify-content: center
    & svg
      width: 6px
      height: 9px
      & path
        fill: var(--color-icon)
    &:hover
      border-color: var(--color-blue-smooth)
      *
        fill: var(--color-blue-smooth)
  .iconIncrease
    transform: scaleX(-1)

  .main
    border-top: 1px solid var(--color-border-dark)
    border-bottom: 1px solid var(--color-border-dark)
    padding: 18px 40px
    .month
      flex: 1
      text-align: center
    .switchBlock
      margin-bottom: 15px
    .week
      display: flex
      justify-content: space-between
      width: 100%
      margin-bottom: 10px
      .day
        height: 35px
        flex: 0 0 48px
        border: 1px solid var(--color-border-dark)
        border-radius: 6px
        line-height: 35px
        text-align: center

  .days
    display: flex
    flex-wrap: wrap
    gap: 3px 10px
    width: 100%
    .dayBox
      width: 48.5px
      cursor: pointer
      text-align: center
      line-height: 28px
      border: 1px solid transparent
      border-radius: 6px
      &:hover
        border: 1px solid var(--color-blue-smooth)
    .chosen
      background: var(--color-blue-light)
      border: 1px solid var(--color-blue-smooth)
      color: var(--color-text-grey-medium)
    .range
      background: var(--color-blue-light)
    .today
      background: var(--color-primary)
      color: var(--color-white)
    .current
      font-weight: 600

  .footer
    display: flex
    justify-content: center
    align-items: center
    padding: 12px 0
