.container
    width: 100%
    border-radius: 4px
    background: var(--dark-green-d-40, #F1F3F2)
    height: 100%
    padding: 0 16px
    display: flex
    flex-direction: column
    justify-content: center

.content
    display: flex
    width: 100%
    justify-content: space-between
    align-items: center

.icon
    margin-right: 6px

.mainContent
    display: flex
    gap: 8px
    align-items: center
    span
        color: var(--dark-green-d-800, #274A42)
        font-size: 14px
        font-weight: 500
        line-height: 20px

.desc
    color: var(--dark-green-d-600, #56716B)
    font-size: 14px
    line-height: 20px
    white-space: nowrap
    overflow: hidden
    text-overflow: ellipsis
    margin-left: 35px

.color
    border-radius: 50%
    width: 16px
    height: 16px

.editor
    display: flex
    gap: 2px
    align-items: center
    justify-content: flex-start
    width: 100px
    svg
        fill: var(--color-d800, #274A42)
