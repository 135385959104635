.container
    color: var(--DarkGreen-D600, #56716B)
    font-family: Roboto
    font-size: 12px
    font-style: normal
    font-weight: 400
    line-height: 16px
    padding: 4px 10px 4px 20px
    border-bottom: 1px solid #d5d7d6
    max-height: 300px
    overflow: auto
    cursor: pointer
    position: relative

.noMargin > div, .noMargin
    margin: 0.2em 0

figure.noMargin
    height: 20px
    position: relative
    &::before
        position: absolute
        top: 50%
        transform: translateY(-50%)
        content: " media"
        padding-left: 20px
        width: 100%
        height: 17px
        background-image: url('/assets/icons/playMedia.svg')
        background-size: contain
        background-repeat: no-repeat
        background-position: left center
        display: block

.icon
    position: absolute
    right: 5px
    top: 5px
    fill: #6E8680
    width: 16px
    height: 16px
