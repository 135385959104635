
.user
    display: flex
    border: 1px solid var(--color-border-dark)
    justify-content: space-between
    align-items: center
    border-radius: 6px
    cursor: pointer

    .mainMenuItems
        margin: 10px 0
    .icon
        width: 20px
        height: 20px
        margin-right: 5px
    .admin path
        fill: var(--color-green-dark)
    .info
        display: flex
        justify-content: space-between
        align-items: center

.select
    background: var(--color-blue-light)
    border: 1px solid var(--color-primary)
