
.button
    background: rgba(255, 255, 255, 0.2)
    border-radius: 15px
    padding: 15px
    position: relative
    font-family: "SF Pro Text", sans-serif
    font-style: normal
    font-weight: 400
    font-size: 16px
    line-height: 19px
    color: var(--color-white)
    gap: 16px
    transition: all 0.5s ease-in-out
    border: 1px solid transparent
    overflow: hidden
    & > div
        position: relative
        z-index: 1
    &:hover
        .circle
            transition: all 0.5s linear
            opacity: 0.5
            transform: scale(140)
    &:disabled
        opacity: 0.5
        .circle
            display: none
    &:focus
        outline: none

.circle
    background: var(--color-c400)
    position: absolute
    border-radius: 50%
    left: 10%
    top: 10%
    width: 2%
    padding-bottom: 2%
    opacity: 0
    transition: all 0.3s linear
    z-index: 0

.button.active
    border: 1px solid var(--color-c400)

.icon
    position: absolute
    right: 11px
    top: 8px
    z-index: 1
    opacity: 0
    animation: fadeIn 0.3s ease-in-out forwards

@keyframes fadeIn
    from
        opacity: 0
    to
        opacity: 1
