.wrap
  width: 440px
  .form
    padding: 16px 16px 0px 16px
  .inputBox
    margin-bottom: 8px
    &:last-child
      margin-bottom: 0
  .btns
    display: flex
    padding: 16px 16px 12px 16px
    justify-content: flex-end
    gap: 8px
