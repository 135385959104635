
.wrap
  width: 420px
  font-family: "SF Pro Text", sans-serif
  .block
    display: flex
    align-items: center
    gap: 10px
    margin-bottom: 20px
    margin-left: 7px
    font-size: 13px
    &:first-child
      margin-top: 20px
  .ml
    margin-left: 0
  .service
    display: flex
    align-items: center
    justify-content: flex-end
    padding-top: 10px
    margin-bottom: 20px
    border-top: 1px solid var(--color-border-dark)
    gap: 10px
  .icon
    width: 18px
    height: 18px
    cursor: pointer
    & path
      fill: var(--color-grey)
    &:hover path
      fill: var(--color-blue-smooth)
  .forward
    transform: scaleX(-1)
  .edit path
    fill: var(--color-blue-smooth)

  .btns
    display: flex
    align-items: center
    justify-content: center
    gap: 10px
  .avatar
    width: 36px
    height: 36px
    border-radius: 50%
    object-fit: cover

  .inputBox
    display: flex
    flex-direction: column
    align-items: flex-start
    transform: translateY(-7px)
  .label
    font-size: 12px
    line-height: 12px
    margin-bottom: 2px
    margin-left: 5px
  .hiddenLabel
    visibility: hidden
  .input
    border: 1px solid var(--color-border-dark)
    border-radius: 5px
    padding: 5px
  .name
    line-height: 28px
  .subTitle
    font-size: 11px
    margin: 12px auto 10px
    width: 330px
    line-height: 16px
