.block
  padding: 12px

  &:not(:last-child)
    border-bottom: 1px solid var(--color-d50)

.list
  display: flex
  flex-wrap: wrap
  gap: 2px

  .element
    width: 24px
    height: 24px
    display: flex
    align-items: center
    justify-content: center
    border-radius: 50%
    border: 2px solid transparent
    cursor: pointer

    &.active
      background-color: var(--color-b40)
      border-color: var(--color-b700)

    &:hover
      background-color: var(--color-b40)

    .color
      width: 16px
      height: 16px
      border-radius: 50%

    .emoji
      font-size: 16px
      line-height: 1

.tag
  padding: 2px 4px
  margin: 2px
  background: #CFD7D5
  border-radius: 4px
  font-weight: 500
  font-size: 12px
  line-height: 16px
  color: #274A42
  cursor: pointer
  display: flex
  align-items: center
  border: 2px solid #1565c000
  transition: all 0.3s ease-in-out
  &.active
    border: 2px solid #1565C0
