.container
    display: flex
    align-items: flex-start
    padding: 10px 0 0 8px
    flex-direction: column
    overflow: hidden
    border-radius: 8px
    height: 100%
    &_normal
        .list
            gap: 4px
.title
    color: var(--light-blue-b-700, #0288D1)
    font-family: Roboto
    font-size: 16px
    font-weight: 700
    line-height: 24px
    padding: 0 8px

.emptyWrapper
    padding-right: 16px
    width: 100%
    height: 100%
    display: flex
    align-items: center
    justify-content: center

.scrollWrapper
    width: 100%
    overflow: auto
    padding-right: 8px

.list
    margin-top: 4px
    width: 100%
    display: flex
    gap: 12px
    flex-wrap: wrap
