.wrapper
  border: 1px solid var(--color-d100)
  border-radius: 4px
  position: relative
.editor
  min-height: 200px
  max-height: calc(100vh - 550px)
  overflow: auto
  .rdw-suggestion-dropdown
    width: 200px
.toolbar
  border: none
  border-bottom: 1px solid var(--color-d100)
  position: sticky
  top: 0
  z-index: 10
  background: #fff
  margin-top: 6px
  & > button
    order: -1
    width: 24px
    height: 24px
    margin-right: 10px
    border-radius: 4px
    margin-bottom: 6px
    border: 1px solid #F1F1F1
    &:hover
      background: var(--color-d100)
    .clip
      display: block
      transform: rotate(45deg)
.item
  padding: 8px 12px
  color: var(--color-d800)
  font-size: 12px
  font-style: normal
  font-weight: 400
  line-height: 20px
  cursor: pointer
  &:hover
    color: var(--color-b800)
.hidden
  display: none
