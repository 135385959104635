@import src/theme/global

.block
  padding: 6px 0
  @include borderBottom
.link
  display: flex
  height: 40px
  padding: 8px 12px
  align-items: center
  gap: 4px
  align-self: stretch
  &:hover
    background-color: var(--color-b50)
  &.active
    background-color: var(--color-b100)
  .iconBox
    width: 24px
    height: 24px
    display: flex
    flex-direction: column
    align-items: center
    justify-content: center
  .name
    color: var(--color-d800)
    font-size: 14px
    line-height: 22px
  .admin
    display: inline-block
    border-radius: 4px
    color: var(--color-white)
    background-color: var(--main-orange)
    padding: 0 8px
    font-size: 14px
