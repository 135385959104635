@import "src/theme/colors"

.folderItem
  width: 100%
  cursor: pointer
.folderList
  display: flex
  flex-direction: column
  row-gap: 5px
  margin-top: 8px
  position: relative
