.tariffService
    margin-top: 50px
    h4
        margin: 16px 0
        font-family: 'Roboto'
        font-style: normal
        font-weight: 500
        font-size: 16px
        line-height: 24px
        text-align: center
        color: #3E5D56

.wrapper
    display: flex
    justify-content: center
    align-items: center
    gap: 16px

.item
    display: flex
    flex-direction: column
    align-items: flex-start
    justify-content: space-between
    padding: 0px
    height: 175px
    width: 200px
    background: #FFFFFF
    border-radius: 8px
    padding: 20px 16px
    transition: all 0.3s ease-in-out
    &:hover
        background: #E3F2FD

    h5
        font-family: 'Roboto'
        font-style: normal
        font-weight: 500
        font-size: 20px
        line-height: 20px
        display: flex
        align-items: center
        color: #274A42
        svg
            margin-right: 8px
            fill: #6E8680
            transform: scale(1.2)
        svg path
            fill: #6E8680
    span
        color: #274A42
        font-family: Roboto
        font-size: 12px
        font-style: normal
        font-weight: 400
        line-height: 16px

.price
    display: flex
    align-items: center
    justify-content: center
    color: var(--main-green-2, #33A333)
    font-family: Roboto
    font-size: 32px
    font-style: normal
    font-weight: 700
    line-height: 32px
    position: relative
    sup
        color: var(--main-green-2, #33A333)
        font-family: Roboto
        font-size: 16px
        font-style: normal
        font-weight: 700
        line-height: 24px
        top: -5px

.button
    width: 100%
    transition: all 0.3s ease-in-out
    span
        transition: all 0.3s ease-in-out
    &:hover
        background: #1565C0
        span
            color: white
