.item
    display: flex
    justify-content: space-between
    align-items: center
    padding: 0 12px
    border-radius: 6px
    background-color: var( --color-background-grey)
    height: 40px

    h6
        margin: 0
        width: 20%
        color: var(--color-plantation)
        font-size: 16px
        font-style: normal
        font-weight: 500
        line-height: 1.25

.buttons
    display: flex
    gap: 20px
    justify-content: flex-end

.button
    position: relative
    &:nth-child(2)
        padding: 4px
        span
            height: 16px
            width: auto

.date
    flex-grow: 1
    font-family: Roboto
    font-size: 12px
    font-style: normal
    font-weight: 400
    line-height: 16px

.warning
    border-radius: 14px
    background: #EED328
    padding: 2px 8px
    color: #274A42

.danger
    color: #F00

.success
    color: #33A333
