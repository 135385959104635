.head
  margin-bottom: 12px
.list
  margin-top: 12px
  height: 288px
  overflow-y: auto
  .item
    padding: 6px
    display: flex
    justify-content: space-between
    align-items: center
    &:hover
      background: var(--color-b50)
      .btn
        opacity: 1
    .info
      display: flex
      gap: 8px
      align-items: center
      flex-grow: 1
    .name
      color: var(--color-d800, #274A42)
      font-size: 12px
      font-weight: 700
      line-height: 14px
    .email
      color: var(--color-d700)
      font-size: 12px
      line-height: 16px
    .btn
      opacity: 0
.block
  width: 280px
  display: flex
  flex-direction: column
  gap: 16px
  align-items: flex-end
  padding: 8px 0 30px

.form
  background: var(--color-d50)
  padding: 12px
  display: flex
  flex-direction: column
  gap: 12px
