@import "src/theme/global"

.times
  position: absolute
  top: 12px
  right: 20px
  width: auto
  height: auto
  display: flex
  align-items: center
  justify-content: center
  padding: 0
  @include clickAreaEnlargement(28px, 28px)
  svg
    flex: 0 0 auto
    fill: var(--color-grey)
    transition: fill 0.1s ease-in-out
  &:hover
    svg
      fill: var(--color-primary)
