
.button
  flex: 1 1 82px
  width: 80px
  height: 24px
  border: 1px solid var(--color-border-dark)
  border-radius: 6px
  display: flex
  flex-direction: column
  align-items: center
  justify-content: center
  &:hover:not(:disabled)
    border-color: var(--color-blue-smooth)
    .default
      *
        fill: var(--color-blue-smooth)
.default
  width: 15px
  height: 15px
  & *
    fill: var(--color-icon)
.wrap
  width: 250px
  padding: 10px
  margin: 0
  position: relative
  display: flex
  flex-wrap: wrap
  gap: 1px
  .emojiItem
    width: 32px
    height: 32px
    border: 1px solid transparent
    border-radius: 50%
    padding: 5px
    display: flex
    &:hover
      border-color: var(--color-blue-smooth)

  .selected
    border-color: var(--color-blue-smooth)
    background: var(--color-blue-items)
  .marginRight
    margin-right: 32px
  .closeBtn
    display: flex
    padding: 5px
    position: absolute
    top: 15px
    right: 15px
    width: 20px
    height: 20px
    border-radius: 50%
    background: var(--color-blue-items)
    *
      width: 10px
      height: 10px
      fill: var(--color-icon)
    &:hover
      *
        fill: var(--color-blue-smooth)
