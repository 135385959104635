
.userAvatar, .userText
    width: 24px
    min-width: 24px
    height: 24px
    border-radius: 50%
.userAvatar
    overflow: hidden
    object-fit: cover
.userText
    font-size: 12px
    text-align: center
    line-height: 24px
    background: var(--color-white)
    border: 1px solid var(--color-border-dark)
