
.wrap
    width: 410px
    margin-top: 20px
    border-top: 1px solid var(--color-border-dark)
    text-align: left
    .avatarBox
        padding: 20px 0 10px
        display: flex
        justify-content: space-between
        align-items: center
        gap: 10px
        .avatar
            border: 1px solid var(--color-border-dark)
            border-radius: 6px
            width: 39px
            height: 39px
            display: flex
            align-items: center
            justify-content: center
        .image
            width: 100%
        .paceholder
            flex: 1
            margin: 0
            font-family: 'SF Pro Light', sans-serif
            font-weight: 400
            font-size: 14px
            line-height: 20px
            color: var(--color-grey)
    .fields
        display: flex
        flex-direction: column
        gap: 10px
        margin-bottom: 20px
    .btns
        display: flex
        justify-content: center
        gap: 10px
