.wrapper
    position: relative
    overflow: hidden
    display: flex
    .seekPanel
        position: absolute
        bottom: 20px
        left: 50%
        transform: translateX(-50%)
        height: 40px
        width: 90%
        background: rgba(255,255,255,.4)
        border-radius: 10px
        border: 1px solid rgba(64, 134, 241, .2)
        opacity: 0
        transition: opacity .2s ease
        text-align: left
        .time
            color: #fff
            position: absolute
            bottom: 50px
            font-size: 13px
            width: max-content
            text-shadow: #414141 0 0 1px
            &.curentTime
                left: 0
            &.durationTime
                right: 0
        .inputRange
            width: 100%
            height: 100%
            opacity: 0
            margin: 0
            padding: 0
        .seekHolder, .borderHolder
            width: 4px
            height: 50px
            background: rgb(64, 134, 241)
            position: absolute
            bottom: -5px
            border-radius: 10px
            transition: transform .2s linear
            z-index: 2
        .borderHolder
            background: #fff
            transition: none
            cursor: e-resize
            border: 1px solid rgba(64, 134, 241, 0.2)
        .framesWrapper
            position: absolute
            bottom: 0
            left: 50%
            transform: translateX(-50%)
            width: 100%
            height: 100%
            border-radius: 10px
            overflow: hidden
            z-index: -1
            white-space: nowrap
    .playButton
        position: absolute
        top: 50%
        left: 50%
        cursor: pointer
        width: 75px
        height: 57px
        transform: translate(-50%, -50%)
        background: #3D81E8
        border-radius: 7px
        display: flex
        align-items: center
        justify-content: center
        opacity: 0
        transition: opacity .2s ease
        svg
            //play icon
            transform: rotate(180deg)
            width: 22px
            height: 26px
            path
                fill: #fff
        .pauseIcon
            height: 22px
            width: 26px
            cursor: pointer
            position: relative
            &:before,
            &:after
                position: absolute
                top: 0
                background: #fff
                content: ""
                height: 22px
                width: 3px
            &:before
                left: 6px
            &:after
                right: 6px
        &.paused
            opacity: 1
    &:hover
        .playButton, .seekPanel
            opacity: 1
    .video
        width: 100%
        object-fit: contain
