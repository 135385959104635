.listWrap
  display: flex
  flex-direction: column
  justify-content: flex-start
  box-sizing: border-box
  width: 100%
  height: 100%
  font-family: "SF Pro Light", sans-serif
  letter-spacing: -0.06px
  .list
    overflow-x: hidden
    overflow-y: scroll
    scrollbar-width: thin
    scrollbar-color: #4086F1 #F5F5F5
    padding-bottom: 20px
    &::-webkit-scrollbar-track
      background: #0A3AAB
    &::-webkit-scrollbar-track
      -webkit-box-shadow: none
      background-color: #F5F5F5
    &::-webkit-scrollbar
      height: 0
      width: 3px
      background-color: #FFFFFF
    &::-webkit-scrollbar-thumb
      background-color: #4086F1
  .item
    display: flex
    align-items: center
    justify-content: space-between
    box-sizing: border-box
    padding: 20px 15px
    border-bottom: 1px solid #DFDFDF
    width: 100%
    &:hover, &.active
      background-color: #EBF1FD
  .addContact
    justify-content: flex-start
    cursor: pointer
    // color: #49494B
    position: relative
    .iconWrap
      font-size: 16.5px
      margin-right: 10px
      width: 30px
      text-align: center
      height: 22px

.listWrap.darkTheme
  .item
    border-color: #4E4E4E
    &:hover, &.active
      background-color: rgba(196, 196, 196, .1)
  .list
    scrollbar-color: #272727 #323232
    &::-webkit-scrollbar-track
      background: #272727
      &::-webkit-scrollbar-track
        background-color: #272727
        &::-webkit-scrollbar
          background-color: #323232
        &::-webkit-scrollbar-thumb
          background-color: #323232

@media (max-width: 1440px)
  .listWrap
    .item
      .groupName
        .info
          .name
            font-size: 14px
          .status
            font-size: 10px
    .addContact
      font-size: 14.5px
