
.addUserChat
    width: 640px
    height: max-content
    display: flex
    align-items: center
    position: relative
    flex-direction: column
    .addUserTitle
        font-size: 18px
        font-weight: bold
        margin: 30px 0
    .addUserSearch
        display: flex
        flex-direction: column
        width: 90%
        align-self: center
        margin-bottom: 10px
        label
            font-size: 13px
            margin-bottom: 10px
        input
            width: 100%
            border: 0
            background: var(--color-input-background)
            border-radius: 6px
            padding: 9px 20px
    .selectedUsers
        display: flex
        width: 90%
        max-width: 90%
        min-height: 56px
        overflow-y: hidden
        overflow-x: auto
        flex-wrap: nowrap
        gap: 10px
        align-items: center
        font-size: 13px
        .selectedUser
            display: flex
            align-items: center
            padding: 6px 20px 6px 9px
            gap: 6px
            background: var(--color-blue-items)
            border-radius: 100px
            min-width: max-content
            font-size: 13px
            height: 40px
    .userList
        display: flex
        flex-direction: column
        width: 90%
        min-width: 90%
        height: 240px
        min-height: 240px
        margin-bottom: 20px
        overflow-y: scroll
        gap: 10px
        .user
            display: flex
            align-items: center
            justify-content: space-between
            width: 100%
            background: var(--color-input-background)
            padding: 6px
            cursor: pointer
            min-height: max-content
            box-sizing: border-box
            height: 40px
            border-radius: 6px
            .userData
                display: flex
                align-items: center
                gap: 10px
                font-size: 13px

                img
                    width: 20px
                    height: 20px
                    border-radius: 50%
            .selected
                display: flex
                justify-content: center
                align-items: center
                border: 1px solid var(--color-grey_medium)
                width: 20px
                height: 20px
                border-radius: 50%
                color: transparent
            .isSelected
                border: 1px solid var(--color-green)
                background: var(--color-green)
                font-weight: bold
                color: var(--color-white)
    .info
        width: 70%
        font-size: 13px
        text-align: center
        margin-bottom: 6px
