.content
  ol
    margin: 0
    padding-left: 15px
    & > li:not(:last-child)
      margin-bottom: 10px
  ul
    list-style: inside
  p
    margin: 0 0 10px
    font-weight: 600
