
.root
  display: flex
  align-items: center
  justify-content: center
  position: relative
.fileIcon
  path
    &:first-child
      fill: currentColor
.extInfo
  width: 100%
  position: absolute
  top: calc(50% + 6px)
  transform: translateY(-50%)
  font-family: 'SF Pro Text', sans-serif
  font-weight: 700
  text-align: center
  color: var(--color-white)
  user-select: none
.lockedIcon
  position: absolute
  &.isFolder
    top: 50%
    left: 50%
    transform: translate(-50%, -50%)
  &.isFile
    top: -2px
    right: 1px

.folderIcon
  path
    &:first-child
      opacity: 0.5
      fill: currentColor

    &:nth-child(2)
      fill: currentColor
