
.wrap
    width: 250px
    height: 100%
    border-right: 1px solid var(--color-border-dark)
    flex-shrink: 0
    position: relative
    font-size: 13px
    font-family: "SF Pro Text", sans-serif
    color: var(--color-text-grey)
    .header
        padding: 15px
        display: flex
        align-items: center
        gap: 12px
        border-bottom: 1px solid var(--color-border-dark)
        margin-bottom: 10px
    .title
        flex: 1 1 auto
        line-height: 18px
        font-size: 14px
        font-weight: 700
        font-family: "SF Pro Bold", sans-serif
        white-space: nowrap
    .icon
        cursor: pointer
        width: 15px
        height: 15px
        flex-shrink: 0
        margin: 1px 0 2px
    .iconCollapse
        transform: scaleX(-1)
    .collapsed
        display: none
    .projectsList
        position: absolute
        top: 100%
        left: 0
        margin: 0
        display: flex
        flex-direction: column
        width: 100%
        background: var(--color-white)
        border: 1px solid var(--color-border-dark)
        border-radius: 6px

    .project
        display: flex
        justify-content: space-between
        align-items: center
        gap: 6px
        padding: 4px 10px
        border-bottom: 1px solid var(--color-border-dark)
        &:hover
            background: var(--color-blue-light)
        .noLogo
            width: 39px
        .logo
            border-radius: 2px

        .name
            flex: 1
            font-size: 13px
            color: var(--color-text-grey)
            line-height: 30px

    .createImg
        position: absolute
        top: 5px
        right: 20px
        width: max-content
        transform: translateX(100%)
        .arrow
            transform: rotate(-85deg)
        .text
            position: absolute
            bottom: 0
            width: max-content
            margin: 0
            color: var(--color-yellow)
            font-weight: 700
            font-size: 20px
    .selectBox
        position: relative
        display: flex
        align-items: center
        justify-content: space-between
        padding: 10px
        line-height: 19px
        background: var(--color-blue-smooth)
        border-radius: 6px
        margin: 0 10px
        color: var(--color-white)
        .text
            flex: 1 0 auto
            text-align: center
        .iconDown
            cursor: pointer
            margin: 6px 2px
            & path
                fill: var(--color-white)
    .menu
        height: 100%
        .button
            padding: 10px 10px 0
    .info
        display: flex
        justify-content: space-between
        align-items: center
        gap: 6px
        padding: 10px 4px 10px 10px
        .logo
            border-radius: 2px
        .noLogo
            width: 39px
        .name
            flex: 1
            font-size: 13px
            line-height: 15px
            color: var(--color-text-grey)
    .menuList
        padding: 10px
        height: calc( 100% - 205px )
        overflow: auto

.wrapCollapse
    width: 60px
    transition: width 0.2s ease
    .selectBox
        justify-content: center
    .projectsList
        width: 230px
    .addBtn
        height: 39px
        background: var(--color-green)
        border-radius: 6px
        display: flex
        flex-direction: column
        align-items: center
        justify-content: center
        cursor: pointer
        &:hover
            background: var(--color-green-dark)

.menuItem
    display: flex
    align-items: center
    gap: 15px
    padding: 12px 10px
    border: 1px solid transparent
    font-family: 'SF Pro Text', sans-serif
    font-size: 13px
    line-height: 16px
    color: var(--color-text-grey)
    border-radius: 6px

.menuIconBox
    border: 1px solid var(--color-border-dark)
    display: flex
    flex-direction: column
    align-items: center
    justify-content: center
    width: 30px
    height: 24px
    background: var(--color-background-light)
    border-radius: 6px
    padding: 2px
    & path
        fill: var(--color-grey)
.active
    color: var(--color-secondary)
    border: 1px solid var(--color-secondary)
    background: var(--color-blue-light)
    .menuIconBox
        border-color: var(--color-secondary)
        background: var(--color-white)
        &  path
            fill: var(--color-blue-smooth)
.menuCollapsed.menuItem
    padding: 13px 0
    border-color: transparent
    gap: 0
    background: transparent
    .name
        display: none
