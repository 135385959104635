.btns
    display: flex
    border-radius: 4px
    border: 1px solid var(--color-d50)
    flex-shrink: 0

.btn
    width: 27px
    height: 27px
    padding: 3px
    display: flex
    align-items: center
    justify-content: center

    &.active
        background-color: var(--color-d50)
