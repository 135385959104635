
.content
    padding: 22px 16px
    min-width: 600px
    min-height: 400px
    overflow: hidden

.header
    display: flex
    justify-content: space-between
    align-items: center
    color: var(--dark-green-d-700, #3E5D56)
    text-align: center
    font-size: 16px
    line-height: 24px

.list
    display: flex
    flex-direction: column
    gap: 8px
    margin-top: 12px
    overflow-x: hidden
    overflow-y: auto
    max-height: 360px

.item
    display: flex
    border-radius: 4px
    color: var(--dark-green-d-800, #274A42)
    font-size: 14px
    font-weight: 500
    line-height: 20px
    align-items: center
    min-height: 40px
    padding: 0 12px
    transition: all 0.3s ease-in-out
    justify-content: space-between
    background: var(--blue-b-100, #BBDEFB)
    .buttons
        display: flex
        gap: 10px
        margin-left: 24px
        span
            width: 24px
            height: 24px

.input
    width: 100%

.empty
    height: 250px
    width: 100%
    display: flex
    align-items: center
    justify-content: center
