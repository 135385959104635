.wrap
  display: flex
  flex-direction: column
  justify-content: space-between
  align-items: center
  height: 100%
  overflow: auto
  .info
    width: 440px
    margin: 0 auto
    padding-top: 16px
    .btns
      margin: 24px 0
      padding: 0
      background: transparent
  // .avatarBlock
  //   display: flex
  //   align-items: stretch
  //   gap: 8px
  //   margin-bottom: 20px
  //   & > .uploadBtns
  //     display: flex
  //     flex-direction: column
  //     align-items: stretch
  //     justify-content: space-between
  //     & > span
  //       flex: 0

  .btns
    display: flex
    width: 440px
    margin: 0 auto
    padding: 12px 16px
    justify-content: flex-end
    gap: 8px
    border-radius: 4px
    background: var(--color-d100, #CFD7D5)
// .period
//   padding: 8px 12px 24px
//   margin-top: 12px
//   background-color: var(--color-d100)
//   border-radius: 8px
//   .top
//     display: flex
//     justify-content: space-between
//     color: var(--DarkGreen-D700, #3E5D56)
//     font-size: 14px
//     line-height: 20px
//     margin-bottom: 10px
//   .dates
//     display: flex
//     justify-content: space-between
