.wrap
  width: 440px
  .body
    padding: 12px 16px
  .btns
    display: flex
    align-items: center
    justify-content: flex-end
    gap: 8px
    padding: 16px 16px 12px
