
.myTasksWrap
  height: 100%
  border-radius: 10px
  border: 1px solid var(--color-blue-smooth)
  font-family: "SF Pro Text", sans-serif
  overflow-y: auto
  .panel
    display: flex
    justify-content: space-between
    padding: 10px
    background: var(--color-background-light)
    border: 1px solid var(--color-border-dark)
    position: sticky
    top: 0
    z-index: 1
    .block
      display: flex
      align-items: center
      gap: 10px
      font-size: 13px
      line-height: 24px
    .expandIcon
      padding: 0
      display: flex
      & path
        fill: var(--color-blue-smooth)
