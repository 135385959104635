.wrap
  width: 440px
  .body
    padding: 12px 16px
  .restoreBox
    display: flex
    gap: 10px
    align-items: center
    & p
      font-size: 12px
      line-height: 16px
  .btns
    display: flex
    align-items: center
    justify-content: flex-end
    gap: 8px
  .inputs
    display: flex
    align-items: center
    gap: 8px
    margin-bottom: 16px
    margin-top: 8px
  .input
    display: flex
    width: 32px
    height: 40px
    padding: 10px 8px 10px 12px
    align-items: flex-start
    align-self: stretch
    border-radius: 4px
    border: 1px solid var(--color-d100)
    background: var(--color-white)
    color: var(--dark-green-d-800, #274A42)
    font-size: 14px
    line-height: 20px
    &.error
      border-color: var(--color-red)
  .errorText
    color: var(--color-red)
    font-size: 12px
    line-height: 12px
