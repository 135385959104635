
.wrap
  width: 420px
  position: relative
  padding: 20px 30px 15px
.title
  width: 100%
  max-width: 300px
  font-family: 'SF Pro Text', sans-serif
  font-weight: 500
  font-size: 18px
  line-height: 21px
  color: var(--color-text-dark)
  text-align: center
  margin: 0 auto 30px
.content
  display: flex
  flex-direction: column
  row-gap: 15px
.buttonsWrap
  margin-top: 30px
  display: flex
  justify-content: flex-end
  gap: 10px
