@import src/theme/colors

.title
    color: $color-d600
    font: 500 16px/1.33 "Roboto", sans-serif

    &.left
        margin-bottom: 8px

    &.center
        font-size: 12px
        text-align: center
        margin-bottom: 2px