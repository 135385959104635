
.wrap
    width: 100%
    height: 100%
    max-height: calc( 100% - 100px )
    padding: 10px
    display: flex
    font-family: "SF Pro Text", sans-serif
    color: var(--color-text-grey)

    .document
        min-height: 100%
        overflow-y: auto
        flex: 1 1 auto
        .sheet
            width: 750px
            min-height: 1000px
            height: auto
            overflow-y: auto
            border-radius: 6px
            padding: 10px 20px
        .docName
            font-size: 15px
            line-height: 18px
            margin: 10px 0 20px
            padding: 5px
            display: flex
            gap: 20px
            align-items: center
            justify-content: center
            .content
                text-align: left
                min-height: 34px
        .section
            margin-bottom: 20px
        .sectionHeader
            display: flex
            gap: 10px
            justify-content: space-between
            align-items: center
            margin-bottom: 5px
        .sectionName
            margin: 0
            font-size: 11px
            line-height: 24px
            font-weight: 600
        .btns
            display: flex
            gap: 5px
        .empty
            border: 1px solid var(--color-border-dark)
            border-radius: 6px
            height: 70px

    .sidePanel
        min-height: 100%
        overflow-y: auto
        width: 180px
        padding: 10px 20px
        border-left: 1px solid var(--color-border-dark)
        .sheet
            height: 200px
            width: 140px
            box-shadow: 0px 2px 10px var(--color-shadow)
            padding: 5px
            overflow: hidden
            cursor: pointer
        .docName
            font-size: 9px
            line-height: 11px
            margin: 0 0 10px
        .section
            margin-bottom: 5px
        .sectionName
            font-size: 7px
            line-height: 9px
            font-weight: 600
            margin: 0
            white-space: nowrap
            text-overflow: ellipsis
            overflow: hidden
        .sideContent
            font-size: 5px
            overflow: hidden
            margin-bottom: 5px
            th, td
                text-align: left
                border: 1px solid var(--color-border-dark)
                font-weight: 400
            table
                border-collapse: collapse
        .empty
            height: 20px
            border: 1px solid var(--color-border-dark)
            border-radius: 2px
        .sheetActive
            box-shadow: 0px 0px 6px var(--color-blue-smooth)
        .inputsBlock
            gap: 5px
            margin-bottom: 5px
            input
                width: 40%
                font-size: 5px
                padding: 3px
                border-radius: 1px
        .inputsBlock
            .item
                .value, .lable
                    font-size: 5px
        .sideContent
            figure
                margin: 0

    .sheet
        box-shadow: 0px 2px 10px var(--color-shadow)
        background: var(--color-white)
        margin: 10px auto
    .docName
        text-align: center
        font-weight: 600
    .inputFilled, .inputName
        display: block
        font-size: 15px
        font-weight: 600
        border-radius: 6px
        margin: 9px auto 19px
        padding: 5px
        text-align: center
        font-family: "SF Pro Text", sans-serif
        color: var(--color-text-grey)
    .inputName
        border: 1px dashedvar(--color-border-dark)
        width: 80%
    .inputFilled
        border-color: transparent
    .editorBox
        min-height: 70px
        border: 1px dashedvar(--color-border-dark)
        padding: 10px
        border-radius: 6px
        font-size: 11px
        & p
            margin: 0 0 10px
    .content
        font-size: 11px
        min-height: 34px
        & p
            margin: 0 0 10px
    .editorBoxTable
        border: none
        padding: 0
    .editorBoxTable, .content
        th, td
            text-align: left
            border: 1px solid var(--color-border-dark) !important
            font-weight: 500 !important
            font-family: "SF Pro Text", sans-serif !important
            height: 22px
            padding: 4px !important
            background: var(--color-white) !important
            line-height: 13px
            span, p
                font-size: 11px !important
                color: var(--color-text-grey) !important
                font-family: "SF Pro Text", sans-serif
                font-weight: 500
                letter-spacing: normal
                margin: 0
                line-height: 13px
                display: block
        table
            width: 100%
            border-collapse: collapse
            border: 1px solid var(--color-border-dark) !important
        figure
            width: 100%
            margin: 0 !important
            p, div
                display: none
            table p
                display: block
    .numeredList
        padding: 0
        margin: 0
        list-style: none
        .li
            margin-bottom: 20px
    .nestedList
        padding-left: 14px

    .inputSection
        border: none
        font-size: 11px
        font-weight: 600
        line-height: 24px
        padding: 0
        margin: 0 0 5px
        font-family: inherit

    .editorWrap
        height: calc(100% - 100px)
        width: 100%
        font-size: 11px
    .stickyBtn
        position: sticky
        bottom: 20px
        text-align: center
    .add
        display: flex
        flex-direction: column
        align-items: center
        justify-content: center
        width: 30px
        height: 30px
        padding: 5px
        border-radius: 6px
        background: var(--color-green)
        color: var(--color-white)
        margin-top: 5px
        &:hover
            background: var(--color-green-dark)

        p
            margin: 0
            font-weight: 600
            font-size: 14px
            line-height: 24px

        .trashIcon path
            fill: var(--color-red-dark)

    .inputsBlock
        display: flex
        align-items: center
        justify-content: space-between
        gap: 10px
        flex-wrap: wrap
        margin-bottom: 20px
        input
            flex: 1 1 45%
            border: 1px dashedvar(--color-border-dark)
            font-size: 12px
            padding: 10px
            border-radius: 6px
        .item
            flex: 1 1 45%
            .lable
                margin: 0 0 5px
                font-size: 11px
            .value
                margin: 0
                font-size: 13px
                line-height: 16px
                font-weight: 600
                min-height: 16px
    .footer
        margin-top: 40px
        .text
            font-size: 11px
            font-weight: 600
            margin: 0 0 20px
        .signatures
            display: flex
            align-items: center
            justify-content: space-between
            p
                font-size: 9px
                color: var(--color-text-grey-light)
                width: 40%
                margin: 30px 0 40px
                text-align: center
                position: relative
                &::before
                    content: ""
                    position: absolute
                    top: -10px
                    left: 0
                    width: 100%
                    height: 1px
                    border-top: 1px solid var(--color-border-dark)
    .tooltip
        ul
            list-style: disc
            padding-left: 20px
