
.open
    width: 10px
    height: 10px
    border-bottom: 3px solid var(--color-border-dark)
    border-right: 3px solid var(--color-border-dark)
    margin: 0 auto
    transform: scaleY(1) rotate(45deg) translateY(-50%)
    cursor: pointer
    transition: transform 0.2s
    &:hover
        border-color: var(--color-blue-smooth)
.isOpen
    transform: scaleY(-1) rotate(45deg) translateY(-50%)
    border-color: var(--color-blue-smooth)
