@import "src/theme/global"
.wrap
  padding: 13px 16px
  margin: 0 auto
  background: var(--color-b800)
  border-radius: 10px 10px 4px 4px
  display: flex
  align-items: center
  justify-content: space-between
  .title
    font-size: 16px
    line-height: 18px
    color: var(--color-white)
  .btn
    width: auto
    height: auto
    display: flex
    align-items: center
    justify-content: center
    position: relative
    padding: 2px
    @include clickAreaEnlargement(28px, 28px)
    &:hover *
      fill: var(--color-b100)

.btns
  display: flex
  align-items: center
  gap: 20px
