.container
    padding: 30px
    display: flex
    flex-direction: column
    border-radius: 48px
    height: 90vh
    background: var(--color-b850)
    gap: 20px
    overflow: scroll

.item
    border-radius: 30px
    padding: 30px 25px
    position: relative

.title
    font-family: "SF Pro Text", sans-serif
    font-style: normal
    font-weight: 590
    font-size: 32px
    line-height: 40px
    letter-spacing: 0.432px
    color: #FFFFFF
    display: flex
    align-items: center

.text
    font-family: "SF Pro Text", sans-serif
    font-style: normal
    font-weight: 400
    font-size: 18px
    line-height: 32px
    color: #FFFFFF

.icon
    width: 32px
    height: 32px
    margin-right: 10px

.rightIcon
    position: absolute
    right: 25px
    top: 40px
    width: 24px
    height: 24px
    path
        stroke: var(--color-white)
