
.imgContainer
  flex: 1 0
  height: 1px
  margin: 0 auto
  padding: 10px
  &.line
    height: 100%
    flex: 0 1 auto
.image
  width: 100%
  max-height: 100%
  object-fit: contain
  border-radius: 10px
.wrapMedia
  flex: 1 0
  display: flex
  align-self: center
  align-items: center
  width: 70%
  margin: 5px auto
  padding: 10px
  height: 1px
  &.line
    height: 100%
.pdfContainer
  flex: 1 0
  display: flex
  align-self: center
  align-items: center
  width: 100%
  height: 100%
  .embed
    width: 100%
    height: 100%

.container
  display: flex
  flex-direction: column
  justify-content: center
  height: 100%
  flex: 1
.folderBox
  width: 385px
  padding: 15px
  margin: 0 auto
  border: 1px solid var(--color-blue-smooth)
  border-radius: 10px
  display: flex
  gap: 4px
  flex-direction: column
  align-items: center
  background: var(--color-background-light)
  .options
    width: 100%
    display: flex
    gap: 10px
    align-items: center
    min-height: 22px
    justify-content: flex-end

  .text
    font-size: 14px
    color: var(--color-text-grey-medium)
    margin: 0
    text-align: center
.emptyBox
  display: flex
  flex-direction: column
  justify-content: center
  align-items: center
  flex-grow: 1

.emo
  font-size: 20px

.tags
  display: flex
  gap: 6px
  flex-wrap: wrap
  flex-grow: 1

.tag
  border-radius: 4px
  display: inline
  padding: 2px 6px
  font-size: 12px
  font-style: normal
  font-weight: 500
  line-height: 16px
  background: var(--DarkGreen-D900, #0E352C)
  color: var(--DarkGreen-D50, #E7EBEA)
