
.tools
  &Left
    display: flex
    align-items: center
    column-gap: 10px
    min-height: 60px

  &Right
    display: flex
    align-items: center
    gap: 12px