.list
  display: flex
  height: 100%

  &.column
    flex-direction: column

  &.row
    width: calc(100% + 32px)
    flex-direction: row
    gap: 12px
    position: relative
    left: -16px
    overflow-x: auto
    padding: 0 16px

  &__item
    display: flex
    justify-content: space-between
    align-items: center
    gap: 5px
    flex-shrink: 0
    font-size: 12px
    line-height: 1.33
    color: var(--color-d800)
    transition: background-color 500ms

    &.row
      flex-direction: column

    &.column
      flex-direction: row
      padding: 8px 12px

      &:hover,
      &:active
          background-color: var(--color-lb50)

  &__itemInfo
    display: flex
    align-items: center
    gap: 5px

  &__itemSize
    font-weight: 700


