.tools
  display: flex
  gap: 10px
  width: 100%
  align-items: flex-start
  justify-content: space-between

.wrapper
  display: flex
  flex-direction: column
  gap: 10px
  padding: 14px 0
  width: 100%
  height: 100%
  align-items: flex-start

.btns
  display: flex
  align-items: center
  gap: 8px
  margin: 0 6px

.delimetr
  width: 1px
  height: 28px
  background-color: var(--color-d100)

.backlogBtn
  margin-left: -20px

.addBtn
  margin-left: 10px
