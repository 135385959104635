.wrap
  width: 100%
  height: 100%
  background-color: var(--color-white)
  .container
    width: 440px
    margin: 60px auto 0
    border-radius: 10px
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.20), 0px 1px 10px 0px rgba(0, 0, 0, 0.12), 0px 4px 5px 0px rgba(0, 0, 0, 0.14)
  .successBlock
    display: flex
    align-items: flex-start
    gap: 8px
    padding: 12px 16px
    h5
      color: var(--color-d800)
      font-size: 16px
      font-weight: 700
      line-height: 20px
      margin: 0
    p
      color: var(--color-d700)
      font-size: 12px
      font-weight: 400
      line-height: 16px
      margin-bottom: 6px
    .link
      border-radius: 4px
      background: var(--color-d800)
      color: var(--color-d50)
      padding: 2px 12px
      font-size: 12px
      line-height: 20px
      display: inline-block
      &:hover
        background: var(--color-d700)

  .form
    padding: 12px 16px
    max-height: calc(100vh - 280px)
    overflow-y: auto
  .footer
    padding: 12px 16px
    display: flex
    justify-content: flex-end
.input
  height: 32px
  padding: 6px 8px 6px 12px
  width: 244px
