.wrapper
    position: relative
    .videoWrapper
        // width: 300px
        // height: 300px
        overflow: hidden
        border-radius: 50%
        position: relative
        display: flex
        align-items: center
        justify-content: center
        .progressCircle
            stroke-width: 8
            stroke: #4086F1
            transform-origin: center
            transform: rotate(-90deg)
            transition: stroke-dashoffset linear .3s
        .video
            width: 90%
            height: 90%
            object-fit: cover
            position: absolute
            border-radius: 50%
        .muteBtn
            opacity: 0
            transition: opacity .3s ease
            position: absolute
            top: 25px
            width: 42px
            height: 42px
            background: rgba(0, 0, 0, 0.5)
            display: flex
            padding: 0 0 0 9px
            align-items: center
            border-radius: 50%
            cursor: pointer
            .muteValue
                width: 10px
                height: 10px
                margin-left: 2px
                position: relative
                &::after,
                &::before
                    top: calc(50%)
                    position: absolute
                    content: ""
                    width: 100%
                    height: 1px
                    background: #fff
                &::after
                    transform: rotate(-45deg)
                &::before
                    transform: rotate(45deg)
        &:hover
            .muteBtn
                opacity: 1
.duration
    font-size: 10px
    color: #858585
    text-align: right
    margin: 7px 4px
    position: absolute
    bottom: -26px
    left: 0

.darkTheme.wrapper
    .duration
        color: #fff
