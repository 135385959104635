@import src/theme/cutsStr

.switcher
  width: 64px
  height: 24px
  display: flex
  align-items: center
  background-color: var(--color-d50)
  border-radius: 4px
  padding: 2px
  cursor: pointer
  position: relative
  font-size: 12px
  &_checkbox
    transform: translateX(0)
    transition: transform 500ms ease
    z-index: 2
  &_label
    width: 40px
    color: var(--color-d800)
    transform: translateX(0)
    transition: transform 500ms ease
    @include cutsStr(1)
    text-align: center
  &.checked
    .switcher_label
      transform: translateX(calc((100% - 20px) * -1))
    .switcher_checkbox
      transform: translateX(calc(100% + 20px))
