
.root
  width: 460px
  display: flex
  flex-direction: column
  padding: 30px 20px 15px
  position: relative
.content
  height: 100%
  display: flex
  flex-direction: column
  text-align: center
.titleWrapper
  margin-bottom: 15px
.title
  width: 100%
  max-width: 293px
  font-family: 'SF Pro Text', sans-serif
  font-weight: 500
  font-size: 18px
  line-height: 21px
  letter-spacing: -0.08px
  color: var(--color-text-dark)
  text-align: center
  margin: 0 auto
.iconWrapper
  margin-bottom: 15px
.icon
  display: inline-block
  width: 70px
  height: 70px
.text
  width: 100%
  max-width: 305px
  font-family: 'SF Pro Text', sans-serif
  font-weight: 400
  font-size: 11px
  line-height: 16px
  text-align: center
  letter-spacing: -0.05px
  color: var(--color-text-dark)
  margin: 0 auto
.buttonsWrapper
  display: flex
  justify-content: center
  column-gap: 10px
  margin-top: 25px
