
@keyframes showPopup
  0%
    width: 10px
    background: white
    opacity: 0
  99%
    width: 420px
    opacity: 0

.root
  width: 420px
  position: relative
  padding: 20px 30px 15px
  animation: showPopup 0.2s ease-in-out
.title
  width: 100%
  max-width: 300px
  font-family: 'SF Pro Text', sans-serif
  font-weight: 500
  font-size: 18px
  line-height: 21px
  color: var(--color-text-dark)
  text-align: center
  margin: 0 auto 30px
.content
  display: flex
  flex-direction: column
  row-gap: 15px
.buttonsWrap
  margin-top: 30px
  display: flex
  justify-content: center
  gap: 10px
