
.container
  display: flex
  align-items: flex-start
  gap: 10px
  margin-bottom: 10px
  background: var(--color-white)
  border-radius: 4px
  &:last-child
    margin: 0
  &.isOver
    background: var(--color-b50)

.time
  width: 40px
  flex-shrink: 0
  font-size: 13px
.tasks
  border-radius: 6px
  width: 100%
  flex: 1 1 auto
  max-height: 160px
  overflow-y: auto
  min-height: 40px
.emptyTasks
  border: 1px solid var(--color-border-dark)
.stiky
  position: sticky
  top: 0
  z-index: 1
.addBtn
  display: block
  padding: 0
  height: 38px
  width: 38px
  path
    fill: var(--color-icon)
  &:hover path
    fill: var(--color-blue-smooth)
.infin
  path
    fill: var(--color-icon)
