@import "src/theme/theme"

.root
  width: 660px
  position: relative

.mainContent
  padding: 12px 16px

.tabsBox
  width: 100%
  overflow-x: auto
  padding-bottom: 16px
  @include scrollbar(0, 0, "none", "none")
