
.container
  width: 420px
  margin-top: 20px
  text-align: start
  .input
    width: 100%
    padding: 10px
    line-height: 17px
    border: 1px solid var(--color-border-dark)
    border-radius: 5px
    margin-bottom: 10px

    &::placeholder
      color: var(--color-grey)
  .tagWrap
    margin-bottom: 20px
    & > div
      padding: 7.5px 10px
  .tags
    & > p
      margin: 0
      padding: 2px 10px
      font-size: 13px
      &:hover
        background: var(--color-blue-light)

  .btns
    display: flex
    gap: 10px
    justify-content: center
