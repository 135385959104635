.previewFiles
  margin-top: 8px
  display: flex
  flex-wrap: wrap
  gap: 8px
  max-height: 270px
  overflow-y: auto

.previewFile
  position: relative

.btnRemove
  position: absolute
  top: 0
  right: 0
  padding: 0