.wrap
  width: 960px
  position: relative
.body
  padding: 16px
  display: flex
  gap: 16px
  max-height: calc(100% - 94px)

  .main
    width: 618px
  .fileList
    display: flex
    gap: 8px
    flex-wrap: nowrap
    overflow: auto
    max-width: 100%
    padding: 12px 0

.btns
  padding: 12px 16px 10px
  display: flex
  width: 100%
  justify-content: flex-end
  gap: 8px
.editorWrapper
  border-radius: 4px
  position: relative
  margin-top: 12px

  .label
    top: -6px
    position: absolute
    left: 6px
    background: var(--color-white)
    padding: 0 4px
    color: var(--color-d700)
    font-size: 12px
    line-height: 12px
    border-radius: 2px
.loader
  width: 100%
  height: 100%
  position: absolute
  backdrop-filter: blur(2px)
  top: 0
  left: 0
  z-index: 10
