@import  "src/theme/cutsStr"
    
.wrapper
    width: 100%
    height: 56px
    display: flex
    padding: 8px 12px 8px 8px
    gap: 8px
    cursor: pointer
    position: relative
    border-radius: 4px
    background-color: var(--color-d40)
    transition: all 0.3s ease-in-out
    overflow: hidden

    &:hover
        background: var(--color-d50)

        .menuButton
            right: 12px

.content
    display: flex
    gap: 8px

.title
    color: var(--color-d800)
    font-size: 16px
    font-weight: 400
    line-height: 1.5
    @include cutsStr(1)

.size
  font-size: 12px
  line-height: 1.33
  color: var(--color-d600)
  user-select: none

.menuButton
    position: absolute
    right: -100%
    top: 50%
    transform: translateY(-50%)
    transition: right 300ms ease-in-out
