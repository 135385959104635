.container
    margin: 0 auto
    border-radius: 8px
    max-width: 950px
    overflow: hidden

.header
    display: flex
    justify-content: space-between
    align-items: center
    border-bottom: 1px solid #E7EBEA
    height: 52px
    background: #FFF

.headerCell
    width: 18%
    height: 100%
    display: flex
    align-items: center
    justify-content: center
    border-left: 1px solid #E7EBEA
    color: var(--dark-green-d-800, #274A42)
    font-family: Roboto
    font-size: 16px
    font-style: normal
    font-weight: 500
    line-height: 20px
    &:first-child
        width: 28%

.title
    height: 84px
    display: flex
    border-bottom: 1px solid #E7EBEA
    background: #FFF

.titleCell
    display: flex
    border-left: 1px solid #E7EBEA
    color: var(--dark-green-d-800, #274A42)
    width: 18%
    height: 100%
    display: flex
    align-items: center
    justify-content: center
    color: var(--main-green-2, #33A333)
    font-family: Roboto
    font-size: 32px
    font-style: normal
    font-weight: 700
    line-height: 32px
    flex-direction: column
    sup
        color: var(--main-green-2, #33A333)
        font-family: Roboto
        font-size: 16px
        font-style: normal
        font-weight: 700
        line-height: 24px
        top: -10px
    &:first-child
        width: 28%
        justify-content: start
        color: var(--dark-green-d-600, #56716B)
        font-family: Roboto
        font-size: 12px
        font-style: normal
        font-weight: 400
        line-height: 16px
        flex-direction: row
        padding: 16px
        svg
            margin-right: 8px
    &:nth-child(2)
        color: var(--dark-green-d-700, #3E5D56)
        font-family: Roboto
        font-size: 18px
        font-style: normal
        font-weight: 300
        line-height: 32px

.row
    width: 100%
    display: flex
    height: 40px
    background: #FFF

.cell
    width: 18%
    height: 100%
    display: flex
    align-items: center
    justify-content: center
    color: var(--dark-green-d-700, #3E5D56)
    font-family: Roboto
    font-size: 14px
    font-style: normal
    font-weight: 500
    line-height: 20px
    border-left: 1px solid #E7EBEA
    &:first-child
        width: 28%
        justify-content: start
        color: var(--dark-green-d-600, #56716B)
        font-family: Roboto
        font-size: 12px
        font-style: normal
        font-weight: 400
        line-height: 16px
        flex-direction: row
        padding: 16px
        svg
            margin-right: 8px
            min-width: 20px
            height: auto
            path
                fill: #56716B

.cellFirst
    height: 56px !important
    padding-top: 16px !important

.footer
    display: flex
    .cell
        height: 50px
        background: #FFF
        padding: 0
        border-top: 1px solid #E7EBEA
        &:first-child
            height: 16px
            padding: 0
            border-top: none

.button
    margin: 16px
    width: 100%
    color: var(--dark-green-d-800, #274A42)
    text-align: center
    font-family: Roboto
    font-size: 12px
    font-style: normal
    font-weight: 400
    line-height: 20px
    transition: all 0.3s ease-in-out
    span
        transition: all 0.3s ease-in-out
    &:hover
        background: #1565C0
        span
            color: white
