@import "src/theme/scrollbar"
@import "src/theme/global"
.container
  display: flex
  gap: 5px
  margin-bottom: 10px
  max-width: 100%
  overflow-x: auto
  @include scrollbar(0, 0, "#fff", "#fff")
  &:last-child
    margin-bottom: 0

.additions
  display: flex
  flex-direction: column
  gap: 5px
  & > button
    flex-grow: 1
.fullContainer > .additions
  flex-direction: row
  & button
    height: 45px

.mailBox
  padding: 10px
  position: relative
  border: 1px solid var(--color-border-dark)
  border-radius: 6px
  color: var(--color-text-dark)
  font-size: 12px
  font-family: "SF Prop Text", sans-serif
  min-width: 100%
  .top
    display: flex
    justify-content: space-between
  .time
    flex-grow: 1
  .color
    width: 14px
    height: 14px
    border-radius: 50%
    flex-shrink: 0
  .emo
    font-size: 14px
  .block
    display: flex
    align-items: center
    gap: 5px
  .subtitle
    display: flex
    margin: 0 0 5px
    white-space: nowrap
  .email
    flex-grow: 1
    color: var(--color-blue-smooth)
    @include textEllipsis
  .text
    font-size: 10px
    display: -webkit-box
    max-width: 400px
    -webkit-line-clamp: 2
    -webkit-box-orient: vertical
    overflow: hidden
    text-overflow: ellipsis
    p
      margin: 0
.iconStatus
  position: absolute
  top: 0
  left: 1px
  width: 8px
  height: 8px
  display: flex
.full
  display: flex
  align-items: center
  gap: 10px
  padding: 10px
  .context
    position: static
  .text
    flex: 1 1 auto
    max-width: 100%
    -webkit-line-clamp: 1
    font-size: 12px
  .subtitle, .email
    margin: 0
  .email
    margin-right: 20px
  .tag
    max-width: 110px
    min-width: max-content
    padding: 0 10px
    background: var(--color-blue-light)
    text-align: center
    font-size: 10px
    line-height: 18px
    border-radius: 9px
    @include textEllipsis
