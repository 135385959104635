@import src/theme/global
.block
  padding-bottom: 16px
  @include borderBottom
  .title
    color: var(--color-d600)
    font-size: 12px
    line-height: 20px
    margin-bottom: 8px
    display: inline-block
  .list
    display: flex
    flex-direction: column
    gap: 8px
  .item
    display: flex
    gap: 8px
    .name
      color: var(--color-d800)
      font-size: 12px
      font-weight: 500
      line-height: 20px
