
.passWrap
  width: 420px
  .btns
    display: flex
    flex-direction: column
    gap: 10px
    justify-content: center
    align-items: flex-end
    div
      flex-direction: row
      margin: 0
