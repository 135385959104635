.wrapper
    .buttonsWrapper
        height: 70px
        padding: 10px 20px
        display: flex
        justify-content: space-between
        align-items: center
        border-top: 1px solid #DFDFDF
        user-select: none
        -moz-user-select: none
        -webkit-user-select: none
        position: relative
        .leftContainer, .rightContainer
            display: flex
            align-items: center
            width: 120px
        .leftContainer
            justify-content: flex-start
        .rightContainer
            justify-content: flex-end
        .centerContainer
            display: flex
            align-items: center
            .actionButton
                position: absolute
                top: -85px
                left: 50%
                transform: translateX(-50%)
                .square
                    width: 14px
                    height: 14px
                    background: #fff
                    border-radius: 2px
    .optionsWrapper
        border-top: 1px solid #DFDFDF
        &.textWrapper
            display: flex
            justify-content: center
            padding: 10px 0
            svg
                display: none
            text-area
                width: 100%

.wrapper.darkTheme
    .buttonsWrapper
        border-color: #4E4E4E
    .optionsWrapper
        border-color: #4E4E4E
    .textWrapper
        background-color: #323232
