
.wrap
    width: 620px
    max-height: 600px
    padding-top: 10px
    .text
        font-family: "SF Pro Regular", sans-serif
        font-size: 13px
        line-height: 16px
        color: var(--color-text-grey)
        margin: 0 auto 20px
        width: 80%
    .userList
        margin-bottom: 20px
    .scroll
        max-height: 400px
        overflow-y: auto
    .userBlock
        display: flex
        gap: 10px
        margin-bottom: 10px
        & > div:first-child
            flex: 1

    .roles, .rolesList
        width: 200px
        background: var(--color-background-light)
        border-radius: 6px
        font-family: "SF Pro Text", sans-serif

    .rolesList
        padding: 10px 0
        .item
            border-bottom: 1px solid var(--color-border-dark)
            font-size: 13px
            line-height: 30px
            text-align: left
            padding: 0 10px
            color: var(--color-text-grey)
            &:last-child
                border: none

    .btns
        display: flex
        gap: 10px
        justify-content: center
