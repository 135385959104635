@import src/theme/global
.row
  display: flex
  align-items: center
  &.active
    background-color: rgba(100, 181, 246, 0.2)

.name
  width: 220px
  font-size: 14px
  line-height: 18px
  color: var(--color-d800)
  padding: 6px
  cursor: pointer
  @include textEllipsis
  .num
    font-weight: 500
    color: var(--color-b800)

.executor
  width: 60px
  display: flex
  align-items: center
  justify-content: center

.status
  width: 160px
  display: flex
  align-items: center
  font-size: 14px
  & > div > div
    display: flex
    align-items: center
    gap: 8px
    max-width: 154px
    width: max-content
    padding: 4px 12px
    cursor: pointer
    border-radius: 4px
