
.input
  height: 28px
  border-radius: 4px
  border: 1px solid var(--color-d100)
  background: white
  padding: 6px 6px 6px 30px
  background-image: url("../../../assets/icons/calendar_2.svg")
  background-repeat: no-repeat
  background-position: left 6px center
  color: var(--color-d800)
  font-size: 13px
  font-family: "Roboto", sans-serif

  &:focus
    border-color: var(--color-b600)
