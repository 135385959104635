
.wrap
  border-bottom: 1px solid var(--color-border-dark)
  padding: 10px
  margin-bottom: 10px

.block
  display: flex
  align-items: center
  gap: 10px
  margin-bottom: 10px
.name
  display: flex
  justify-content: space-between
  align-items: center
  gap: 10px
  border-radius: 6px
  background: var(--color-blue-light)
  min-width: 130px
  font-size: 13px
  line-height: 15px
  padding: 8px 10px 7px
  font-family: "SF Pro Text", sans-serif
.add
  display: flex
  flex-direction: column
  align-items: center
  justify-content: center
  width: 30px
  height: 30px
  padding: 5px
  border-radius: 6px
  background: var(--color-green)
  color: var(--color-white)
  &:hover
    background: var(--color-green-dark)
