@keyframes show
  from
    transform: scale(0.1)
    opacity: (0.1)
  80%
    transform: scale(1.2)

  to
    transform: scale(1)
    opacity: 1

.overlay
  display: flex
  justify-content: center
  align-items: center
  position: fixed
  top: 0
  left: 0
  width: 100%
  height: 100%
  z-index: 11
  background: rgba(1, 1, 1, 0.5)
  &.rightBottom
    width: max-content
    height: max-content
    top: auto
    left: auto
    bottom: 10px
    right: 5px
    background: none
  &.rightTop
    width: max-content
    height: max-content
    top: 10px
    left: auto
    bottom: auto
    right: 5px
    background: none
  &.top
    align-items: flex-start
    padding-top: 150px
  .content
    width: max-content
    border-radius: 10px
    position: relative
    z-index: 12
    background: white
    box-shadow: 0px 16px 24px rgba(0, 0, 0, 0.14), 0px 6px 30px rgba(0, 0, 0, 0.12), 0px 8px 10px rgba(0, 0, 0, 0.2)
    animation: show .3s
