.wrapper
  height: 100%
  display: flex
  align-items: center
  justify-content: center
  letter-spacing: -0.06px
  color: #2B353E
  font-family: 'SF Pro Text'
  border-radius: 7px

.contentWrapper
  width: 458px
  padding: 30px
  // background: #FFFFFF
  background: inherit
  border-radius: 7px
  position: relative

  .close
    cursor: pointer
    position: absolute
    top: 10px
    right: 10px

  .header
    display: flex
    justify-content: center

    .title
      margin: 0
      font-size: 18px
      line-height: 21px
      font-family: 'SF Pro Text Medium'

  .content
    display: flex
    flex-direction: column
    align-items: center

    .successBlock
      margin: 28px 0 17px 0
      img
        width: 60px
        height: 60px
        border-radius: 50%

    .text
      width: 60%
      font-size: 12px
      line-height: 21px
      text-align: center
      color: #4F555B
      margin: 0 0 3em 0

    .actionBlock
      display: flex
      justify-content: center

      .completeBtn
        font-size: 12px
        line-height: 14px
        color: #FFFFFF
        transition: background .2s ease
        width: 145px
        height: 38px
        border: none
        background: #4086F1
        border-radius: 3.75px
        &:hover
          background: #3b6dcb
