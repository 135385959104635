
.wrap
  width: 440px
  padding: 16px 20px 20px
  display: flex
  gap: 10px
  .icon
    flex-shrink: 0
  .title
    font-size: 18px
    line-height: 20px
    color: var(--color-d800)
    font-weight: 500
    margin: 0 0 6px
  .text
    font-size: 12px
    line-height: 16px
    color: var(--color-d700)
    margin: 0 0 4px
  .counter p
    font-size: 24px
    line-height: 28px
    color: var(--color-d700)
