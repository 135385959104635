.wrap
    width: 440px
    max-height: 100%
.body
    padding: 12px 16px
    display: flex
    flex-direction: column
    gap: 3px

.inputWrapper
    display: flex
    gap: 8px
    align-items: center

.input
    height: 30px
    width: 370px
    margin-bottom: 6px

.btns
    padding-top: 16px
    display: flex
    justify-content: flex-end
    gap: 8px
