.block
  padding: 8px 12px 24px
  margin-top: 12px
  background-color: var(--color-d50)
  border-radius: 8px
  .top
    display: flex
    justify-content: space-between
    color: var(--DarkGreen-D700, #3E5D56)
    font-size: 14px
    line-height: 20px
    margin-bottom: 10px
  .dates
    display: flex
    justify-content: space-between
.avatarBlock
  display: flex
  align-items: stretch
  gap: 8px
  margin-bottom: 20px
  & > .uploadBtns
    display: flex
    flex-direction: column
    align-items: stretch
    justify-content: space-between
    & > span
      flex: 0
.days
  display: flex
  gap: 6px
.hoursBlock
  position: relative
.hours
  border-radius: 4px
  border: 1px solid var(--DarkGreen-D100, #CFD7D5)
  background: var(--Main-white, #FFF)
  // height: 28px
  padding: 4px
  display: flex
  gap: 4px
  align-items: center
  overflow: hidden

.hoursSelect
  position: absolute
  top: 110%
  bottom: -2px
  width: 100%
  height: max-content
  display: flex
  flex-wrap: wrap
  justify-content: space-between
  gap: 2px
  padding: 2px
  border-radius: 4px
  background: #FFF
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.20), 0px 1px 10px 0px rgba(0, 0, 0, 0.12), 0px 4px 5px 0px rgba(0, 0, 0, 0.14)
  z-index: 2
.hour
  display: block
  width: 101px
  padding: 4px 8px
  flex-shrink: 0
  font-size: 14px
  font-weight: 500
  line-height: 20px
  text-align: center
  border-radius: 4px
  cursor: pointer
  background: var(--Blue-B800, #1565C0)
  color: var(--DarkGreen-D20, #F5F7F7)
  &.inactive
    background: var(--DarkGreen-D300, #9FAEAB)
    color: var(--DarkGreen-D800, #274A42)
.label
  position: absolute
  color: var(--DarkGreen-D700, #3E5D56)
  font-size: 12px
  line-height: 12px
  top: -6px
  left: 6px
  background: #fff
  border-radius: 2px
  padding: 0 4px

.timezoneSelect
  &.disabled
    cursor: none
    opacity: 0.7

.autoTimezone
  border-radius: 4px
  background: var(--DarkGreen-D100, #CFD7D5)
  padding: 6px 8px
  width: 100%
  margin-top: 6px
  display: flex
  align-items: center
  justify-content: space-between
  > div
    color: var(--DarkGreen-D800, #274A42)
    font-family: Roboto
    font-size: 12px
    font-style: normal
    font-weight: 400
    line-height: 19px
    display: flex
    align-items: center
    gap: 8px
