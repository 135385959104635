
.statusBtn
  width: 39px
  display: flex
  flex-direction: column
  justify-content: center
  align-items: center
  border: 1px solid var(--color-border-dark)
  border-radius: 6px
  padding: 0
  cursor: pointer
  *
    width: 18px
    height: 18px
.statusBtn:hover, .activeStatus
  background: var(--color-blue-light)
  border-color: var(--color-blue-smooth)
