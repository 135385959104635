
.wrap
  width: 100%
  position: relative
  .select
    display: flex
    gap: 6px
    justify-content: space-between
    align-items: stretch
    font-size: 14px
    color: var(--color-d800)
    padding: 4px 4px 4px 0
  .lable
    color: var(--color-d500)
  .placeholder
    color: var(--color-d500)
  .name
    flex: 1
  .icon
    margin: 0 6px
    &.isOpen
      transform: scaleY(-1)
  .list
    position: absolute
    top: calc(100% + 2px)
    left: 0
    z-index: 1
    background: var(--color-white)
    border-radius: 4px
    box-shadow: 0px 3px 14px 0px #00000024
    max-height: 126px
    width: 100%
    padding: 6px 0
    overflow-y: scroll
    .item
      font-size: 14px
      color: var(--color-d800)
      line-height: 20px
      padding: 4px 16px
      cursor: pointer
      &:hover
        color: var(--color-b400)
