
.item
  width: 100%
.button
  width: 100%
  height: 45px
  display: flex
  align-items: center
  justify-content: space-between
  column-gap: 15px
  background-color: var(--color-white)
  border: 1px solid var(--color-grey-secondary)
  border-radius: 6px
  padding: 0 10px
  &.selected
    background-color: var(--color-blue-items)
    border-color: var(--color-blue-smooth)
  &.isMediumSize,
  &.isBigSize
    height: 60px
.leftSide
  flex: 1 1 auto
  display: flex
  align-items: center
  column-gap: 20px
  overflow-x: auto
  overflow-y: hidden
.itemIconWrapper
  display: flex
  align-items: center
  justify-content: center
.itemInfoWrapper
  display: flex
  flex-direction: column
  row-gap: 4px
  overflow-x: auto
  overflow-y: hidden
.itemName
  display: block
  font-family: 'Roboto', sans-serif
  font-weight: 400
  font-size: 13px
  line-height: 16px
  color: var(--color-text-grey)
  white-space: nowrap
  overflow: hidden
  text-overflow: ellipsis
  user-select: none
  text-align: left
.itemSubInfoWrapper
  display: flex
  align-items: center
  column-gap: 10px
.itemSize,
.itemDateCreate
  font-family: 'Roboto', sans-serif
  font-weight: 400
  font-size: 8px
  line-height: 10px
  color: var(--color-border-dark-grey)
.itemDescriptionGroup
  display: flex
  align-items: center
  column-gap: 10px
.emo
  font-size: 16px
