
.wrapper
  padding: 12px 16px
  background-color: var(--color-d50)
  height: 100%
  display: flex
  align-items: center
  column-gap: 50px
  button
    min-width: 100px
    height: 24px
    display: flex
    justify-content: center
    align-items: center
    text-align: center
    font-family: 'Roboto', sans-serif
    font-weight: 400
    font-size: 12px
    line-height: 16px
    color: var(--color-scorpion)
    background-color: var(--color-background-light)
    border: 1px solid var(--color-gallery)
    border-radius: 6px
    padding: 0 10px
    cursor: pointer
    transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out
    &:not(.cancel, .disabled):hover
      background-color: var(--color-blue-items)
      border-color: var(--color-blue-smooth)
  .cancel
    &:hover
      background-color: var(--color-background-orange)
      color: white
  .disabled
    background-color: var(--color-border-dark)
    cursor: not-allowed
  .deleteFileFromCart
    width: max-content
.leftSide
  display: flex
  align-items: center
  column-gap: 4px
.checkboxWrapper
  display: flex
  align-items: center
  justify-content: center
  margin-right: 7px
  cursor: pointer
.checkboxButton
  padding: 0
  min-width: 0
.selectedText
  font-family: 'Roboto', sans-serif
  font-weight: 400
  font-size: 14px
  line-height: 18px
  color: var(--color-plantation)
.selectedLengthWrapper
  flex: 1 0 26px
  min-width: 26px
  height: 24px
  display: flex
  align-items: center
  justify-content: center
  background: var(--color-b800)
  border-radius: 4px
  padding: 0 5px
.selectedLength
  font-family: 'Roboto', sans-serif
  font-weight: 400
  font-size: 12px
  line-height: 20px
  color: var(--color-white)
.buttonsWrapper
  display: flex
  align-items: center
  column-gap: 8px
