
.wrap
  width: 420px
  text-align: start
  .title
    font-size: 18px
    font-weight: 500
    color: var(--color-d900)
    margin-bottom: 31px
  .note
    border: 1px solid var(--color-border-dark)
    border-radius: 4px
    margin-bottom: 20px
    padding: 10px
    font-size: 14px
    min-height: 130px
  .btns
    display: flex
    justify-content: center
    width: 100%
    padding-top: 20px
