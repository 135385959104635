
.wrap
  flex-grow: 1
  display: flex
  flex-direction: column
  justify-content: flex-end
  overflow: hidden
.caruselContainer
  flex-shrink: 0
  transform: scaleY(-1)
  overflow-x: auto
  border-bottom: 1px solid var(--color-border-dark)
  .carusel
    transform: scaleY(-1)
    display: flex
    align-items: stretch
