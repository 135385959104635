// @-webkit-keyframes pulse
// 	0%
//     -webkit-box-shadow: 0 0 0 0 rgba(64, 134,241,.4)

// 	70% 
//     -webkit-box-shadow: 0 0 0 10px rgba(64,134,241,.4)

// 	100% 
//     -webkit-box-shadow: 0 0 0 0 rgba(64,134,241,.4)

@keyframes pulse
  0%
    -moz-box-shadow: 0 0 0 0 rgba(64,134,241,.4)
    box-shadow: 0 0 0 0 rgba(64,134,241,.4)

  70%
    -moz-box-shadow: 0 0 0 10px rgba(64,134,241,.4)
    box-shadow: 0 0 0 10px rgba(64,134,241,.4)

  100%
    -moz-box-shadow: 0 0 0 0 rgba(64,134,241,.4)
    box-shadow: 0 0 0 0 rgba(64,134,241,.4)

.chatBoardWrap
  display: flex
  flex-direction: column
  height: 100%
  width: 100%
  justify-content: space-between
  position: relative

  .chatBoardMessageList
    display: flex
    width: 100%
    flex-grow: 1
    overflow-y: hidden
    .chatAreaWrapper
      display: flex
      flex-direction: column
      .chatArea
        overflow-y: scroll
        overflow-x: hidden
        position: relative
        height: 100%
        .bottomLine
          width: 100%
          height: 100px
          position: relative
        .bottomLineHidden
          width: 1px
          transition: height 1s ease-in-out, width 1s ease-in-out
        .dateGroup
          .date
            text-align: center
            position: sticky
            top: 10px
            z-index: 4
            .text
              color: #AEAEAE
              font-size: 14px
              background: #fff
              border-radius: 12px
              padding: 2px 6px
        .addFirstContactIcon
          margin: 45px 0 0 8px
        .collapsedMenu
          margin-top: -20px
      .attachedFiles
        margin-left: 65px
        background: #ECF3FE
        width: calc(100% - 200px - 65px)
        max-width: calc(100% - 65px)
        padding: 14px 18px 8px 13px
        border-radius: 10px 10px 0 0
        position: relative
        display: grid
        grid-gap: 10px
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr
        .close
          width: 10px
          height: 10px
          position: absolute
          cursor: pointer
          right: 10px
          top: 10px
          &::before, &::after
            top: 5px
            position: absolute
            content: ""
            width: 10px
            height: 1px
          &::before
            transform: rotate(45deg)
          &::after
            transform: rotate(-45deg)
        &:hover
          .close
            &::before, &::after
              background-color: #a7a4a4
        .close:hover
          &::before, &::after
            background-color: #4086F1

        .attachedFileWrap
          position: relative
          display: flex
          align-items: center
          justify-content: center
          margin: 0
          .remove
            width: 0
            height: 0
            position: absolute
            top: 5px
            right: 15px
            z-index: 5
            &::after, &::before
              content: ''
              display: block
              width: 100%
              height: 1px
              background: red
              top: 5px
              position: absolute
            &::after
              transform: rotate(-45deg)
            &::before
              transform: rotate(45deg)
          &:hover
            .remove
              width: 10px
              height: 10px

      .editingMessage
        margin-left: 65px
        background: #ECF3FE
        width: calc(100% - 200px - 65px)
        max-width: calc(100% - 65px)
        padding: 14px 18px 8px 13px
        border-radius: 10px 10px 0 0
        display: flex
        justify-content: space-between
        white-space: break-spaces
        .line
          border-left: 1px solid #4086F1
          margin: 0 12px 0 0
        .text
          color: #5D5D5D
          font-size: 13px
          margin: 0
          text-align: left
          flex-grow: 1
          max-height: 150px
          max-width: 95%
          overflow: hidden
        .close
          width: 10px
          height: 10px
          transform: translate(10px, -6px)
          position: relative
          cursor: pointer
          &::before, &::after
            top: 5px
            position: absolute
            content: ""
            width: 10px
            height: 1px
          &::before
            transform: rotate(45deg)
          &::after
            transform: rotate(-45deg)
        &:hover
          .close
            &::before, &::after
              background-color: #a7a4a4
        .close:hover
          &::before, &::after
            background-color: #4086F1
      &.center
        align-self: center
  .chatBoardFooter
    display: flex
    align-items: flex-end
    justify-content: space-between
    width: 100%
    border-top: 1px solid #DFDFDF
    box-sizing: border-box
    padding: 15px 0 35px 15px
    .leftContainer
      display: flex
      align-self: center
      .recordIcon
        width: 18px
        height: 18px
        background-color: #E1562A
        border-radius: 50%
        align-self: center
        margin: 0 10px
    .recordHint, .duration
      color: #49494B
      font-size: 13px
      align-self: center
    .downloadOptions
      display: flex
      align-items: flex-end
      align-self: stretch
      position: relative
      padding: 7px 10px
      justify-content: center
      &:after
        content: ""
        position: absolute
        width: 1px
        height: 44px
        bottom: -5px
        right: 0
        background: #DFDFDF
      svg
        width: 20px
        min-height: 20px
        margin: 0 10px 0 0
        cursor: pointer
        &:hover
          opacity: .8

    .sendOptions
      min-width: 185px
      flex-basis: 185px
      display: flex
      align-items: flex-end
      // justify-content: flex-end
      justify-content: center
      position: relative
      &:before
        content: ""
        position: absolute
        width: 1px
        height: 44px
        top: 0
        left: 0
        background: #DFDFDF
      .loaderWrapper
        position: relative
        width: 98px
        display: flex
        align-items: center
        justify-content: center
        align-self: stretch
      .button
        display: flex
        justify-content: center
        align-items: center
        width: 34px
        height: 34px
        border-radius: 50%
        border: 1px solid #4086F1
        // background: #fff
        cursor: pointer
        transition: background .3s ease
        &:not(:last-child)
          margin: 0 15px 0 0
        .triangle
          transform: rotate(180deg)
          width: 14px
          padding-right: 2px
        svg
          path
            fill: #C4C4C4
        &:hover
          background: #4086F1
          svg
            path
              fill: #fff
        &:active.pressed
          animation: pulse 2s infinite
          background: #4086F1
          svg
            path
              fill: #fff
      &.secretChat
        min-width: 235px

  &.recoring
    *
      -webkit-touch-callout: none
      -webkit-user-select: none
      -moz-user-select: none
      -ms-user-select: none
      user-select: none

.chatBoardWrap.darkTheme
  .chatBoardMessageList
    .chatAreaWrapper
      .editingMessage
        background: rgba(196, 196, 196, 0.1)
        .text
          color: #aeaeae
        .date
          .text
            color: #AEAEAE
            background: #323232
      .chatArea
        .dateGroup
          .date
            .text
              color: #AEAEAE
              background: #323232
  .chatBoardFooter
    border-color: #4E4E4E
    .recordHint, .duration
      color: #fff
    .downloadOptions::after,
    .sendOptions::before
      background: #4E4E4E
    .sendOptions
      svg
        path
          fill: #fff
