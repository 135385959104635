@import "src/theme/global"
.item
  display: flex
  justify-content: space-between
  align-items: center
  gap: 10px
  padding: 10px 15px 10px 20px
  border-bottom: 1px solid var(--color-border-dark)
  &:last-child
    border: none
  .name
    flex: 1 1 auto
    font-size: 13px
    @include textEllipsis
  .progress
    width: 22px
    height: 22px
    border-radius: 50%
    position: relative
    transition: all .2s linear
    flex-shrink: 0
    &::after
      content: ""
      width: 70%
      height: 70%
      border-radius: 50%
      transform: translate(-50%, -50%)
      position: absolute
      top: 50%
      left: 50%
      background: var(--color-white)
      z-index: 1
  .checkIcon
    flex-shrink: 0
