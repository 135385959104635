.toolsPanel
  display: flex
  align-items: center
  justify-content: space-between
  padding: 0 16px
  font-family: "Roboto", sans-serif
  background: white
  position: relative
  border-bottom: 1px solid #E3E3E3
.large
  height: 60px
  min-height: 60px
.small
  height: 48px

.extraLarge
  height: 100px
  min-height: 100px
