.container
    display: flex
    align-items: flex-start
    padding: 10px 0 0 16px
    flex-direction: column
    overflow: hidden
    border-radius: 8px
    height: 100%
    .list
        margin-top: 12px
        width: 100%
        display: flex
        gap: 10px
        flex-wrap: wrap
    &_normal
        .list
            flex-direction: column
            gap: 4px

.emptyWrapper
    padding-right: 16px
    width: 100%
    height: 100%
    display: flex
    align-items: center
    justify-content: center

.title
    color: var(--light-blue-b-700, #0288D1)
    font-family: Roboto
    font-size: 16px
    font-weight: 700
    line-height: 24px

.scrollWrapper
    width: 100%
    overflow: auto
    padding-right: 8px
