@import "src/theme/cutsStr"
.card
  background-color: var(--color-grey-transparent-40)
  padding: 6px 8px
  border-radius: 4px
  font: 12px/1.33 "Roboto", sans-serif
  overflow: hidden
  position: relative
  height: 97px

  &.bars,
  &.preview
    width: 120px
    height: 97pz

  &.lines
    border: 1px solid var(--color-grey-transparent-40)
    height: 44px

  &__wrapp
    display: flex
    position: relative

    &.bars,
    &.preview
      flex-direction: column

    &.lines
      gap: 6px
      align-items: center
      padding-right: 36px

  &__head
    display: flex
    align-items: center

    &.bars,
    &.view
      margin-bottom: 8px

    &.lines
      width: 160px

    & > svg
      flex-shrink: 0
      margin-right: 8px

  &__name
    font-weight: 500
    color: var(--color-d800)
    @include cutsStr(2)

  &__body
    &.lines
      display: flex
      flex-wrap: wrap

  &__date,
  &__time
    display: block

  &__date
    color: var(--color-d700)
    font-weight: 500
    text-transform: capitalize

    &.lines
      margin-right: 4px

  &__time
    color: var(--color-d700)

  &__info
    color: var(--color-d600)
    @include cutsStr(3)

    &.lines
      width: 100%
