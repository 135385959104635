.button
    flex-grow: 1
    text-align: center
    border-radius: 100px
    padding: 5px 0
    box-shadow: 0 2px 4px #DEDEDE
    cursor: pointer
    transition: background ease .3s
    user-select: none
    &:not(:last-child)
        margin-right: 4px

    &:hover, &.active
        background: #4086F1
        color: #fff

.darkTheme.button
    box-shadow: 0 2px 4px #292929
    color: #fff
