
.block
  display: flex
  gap: 10px
  align-items: center
.icon
  width: 7px !important
  height: 4px !important
  margin-right: 5px
  transition: transform 0.3s ease
  &.open
    transform: scaleY(-1)
    transition: transform 0.3s ease
.popoverBody
  width: 160px
  padding: 0
.createItem
  cursor: pointer
  button
    padding: 8px 12px
    display: flex
    align-items: center
    gap: 10px
    color: var(--color-d800)
    font-size: 12px
    line-height: 16px
    width: 100%
  .iconItem
    flex-shrink: 0
    width: 16px
    height: 16px
    display: flex
    align-items: center

  &:hover span
    color: var(--color-b900)
