
.wrap
  position: fixed
  top: 0
  left: 0
  width: 100vw
  height: 100vh
  z-index: 10
  .content
    position: relative
    transform: translateX(-260px)
    width: 300px
    border: 7px solid var(--color-white)
    background: var(--color-white)
    box-shadow: 0px 4px 10px var(--color-shadow)
    border-radius: 6px
    padding: 10px 0
  .down::after, .up::after
    position: absolute
    content: ""
    width: 10px
    height: 10px
    transform: rotate(45deg)
    right: 27px
    background: var(--color-white)
    border-radius: 1px 0 0 0
  .up::after
    bottom: -11px
  .down::after
    top: -11px
  .tasks
    max-height: 450px
    padding: 0 7px
    overflow: auto
  .childrenTask
    position: relative
    margin-bottom: 20px
    &:last-child
      margin: 0

  .title
    font-size: 13px
    margin-bottom: 16px
  .task
    border: 1px solid
    padding: 5px
    border-radius: 4px
    .top
      display: flex
      align-items: center
      gap: 10px
      margin-bottom: 10px
      & > div:first-child
        flex-shrink: 0
    .name
      display: block
      text-overflow: ellipsis
      white-space: nowrap
      overflow: hidden
      font-family: "SF Pro Medium", sans-serif
      font-size: 12px
    .type
      font-size: 10px
      color: var(--color-blue-smooth)
      margin-bottom: 10px
    .bottom
      display: flex
      align-items: center
      justify-content: space-between
      gap: 6px
    .executors
      width: 50%
    .role
      color: var(--color-blue-smooth)
      border: 1px solid var(--color-blue-smooth)
      border-radius: 10px
      background: var(--color-white)
      padding: 1px 8px 0
      font-size: 10px
      line-height: 14px
      width: max-content
      max-width: 80px
      white-space: nowrap
      text-overflow: ellipsis
      overflow: hidden
      margin: 0
    .statusBox
      display: flex
      gap: 6px
      align-items: center
      flex-grow: 1
      margin-left: auto
    .circle
      width: 12px
      height: 12px
      border-radius: 50%
      margin-left: 5px
      flex-shrink: 0
    .status
      font-size: 12px
      flex-grow: 1
      line-height: 12px
      max-width: 85px
      text-overflow: ellipsis
      white-space: nowrap
      overflow: hidden
  .margin
    margin-bottom: 20px
  .svgBox
    position: absolute
    top: -14px
    left: -7px
    height: calc(100% + 34px)
    width: calc(100% + 14px)
