.wrap
  width: 360px
  padding: 12px
.selectDate
  margin: 12px 0
  position: relative
  input
    width: 330px
    height: 35px
  .label
    position: absolute
    color: var(--DarkGreen-D700, #3E5D56)
    font-size: 12px
    line-height: 12px
    top: -6px
    left: 6px
    background: #fff
    border-radius: 2px
    padding: 0 4px

.btns
  display: flex
  gap: 8px
  justify-content: flex-end
