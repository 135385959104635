
.form
    position: relative
    padding: 30px 20px 20px
    text-align: center
    font-family: 'SF Pro Medium', sans-serif
    font-weight: 500
    font-size: 18px
    line-height: 21px
    color: var(--color-text-dark)
.noPadding
    padding: 0
