
.file
  width: 128px
  height: 48px
  display: flex
  align-items: center
  column-gap: 8px
  background: var(--color-d40)
  border-radius: 6px
  padding-right: 10px
  padding-left: 5px
  text-align: left
  position: relative
  &.hover
    background: var(--color-b50)
    .contextBtn
      display: flex
  .contextBtn
    display: none
    position: absolute
    transform: translateY(-50%)
    right: 10px
    top: 50%
    width: 24px
    height: 24px
    border-radius: 4px
    background: var(--color-d100)
    padding: 0
    align-items: center
    justify-content: center
    &.visible
      display: flex
    & *
      fill: var(--color-d900)
.popoverBody
  padding: 10px

.name
  font-weight: 500
  font-size: 10px
  line-height: 12px
  color: var(--color-d800)
  overflow: hidden
  display: -webkit-box
  -webkit-box-orient: vertical
  -webkit-line-clamp: 2
  white-space: pre-wrap
  word-break: break-word
.image
  width: 32px
  height: 32px
  object-fit: contain
  border-radius: 4px
.popoverButton
  display: flex
  gap: 10px
  align-items: center
  p
    margin: 0
    color: var(--color-d800)
.popoverItem:hover p
  color: var(--color-b800)
