.container
    display: flex
    align-items: center
    gap: 8px
    cursor: pointer

.iconWrapper
    width: 35px
    min-width: 35px
    display: flex
    justify-content: flex-start
    padding: 2px 2px 0 0

.text
    display: flex
    flex-direction: column
    overflow: hidden
    align-items: flex-start

    div:nth-child(1)
        font-weight: 500
        font-size: 14px
        line-height: 20px
        color: #274A42
        white-space: nowrap
        overflow: hidden
        text-overflow: ellipsis

    div:nth-child(2)
        font-weight: 400
        font-size: 12px
        line-height: 16px
        color: #56716B
        white-space: nowrap
        overflow: hidden
        text-overflow: ellipsis
.tooltip
    padding: 0 !important

.tooltipContainer
    padding: 6px
    background: var(--color-d40)
    display: flex
    border-radius: 4px
    width: 100%

.info
    display: flex
    flex-direction: column
    justify-content: space-between
    margin-left: 10px

.title
    font-weight: 700
    font-size: 16px
    color: var(--dark-green-d-800, #274A42)
    white-space: nowrap

.buttons
    display: flex
    gap: 8px

.button
    background: rgba(39, 74, 66, 0.16)
    padding: 1px
    span
        width: 16px !important
        height: 16px !important
