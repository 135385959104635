
.container
    height: 100%
    display: flex
    width: 100%
    max-width: 450px
    margin: 0 auto
    flex-direction: column
    justify-content: center
    h4
        font-family: "SF Pro Text", sans-serif
        font-style: normal
        font-weight: 590
        font-size: 36px
        line-height: 43px
        color: var(--color-white)
        margin-bottom: 0
    p
        font-family: "SF Pro Text", sans-serif
        font-style: normal
        font-weight: 400
        font-size: 16px
        line-height: 19px
        color: var(--color-white)
        margin: 15px 0

.buttons_wrapper
    display: flex
    items: center
    gap: 9px
    width: 100%
    button
        width: 100%
        &:hover
            svg
                path
                    fill: var(--color-c400)
                    transition: fill 0.3s ease-in-out

.or
    font-family: "SF Pro Text", sans-serif
    font-style: normal
    font-weight: 400
    font-size: 16px
    line-height: 19px
    color: var(--color-white)
    margin: 15px 0
    text-align: center

.inputs_wrapper
    display: flex
    flex-direction: column
    gap: 15px
    position: relative

.confirm_button
    width: 100%
    background: var(--color-c400)
    color: var(--color-black)
    font-family: "SF Pro Text", sans-serif
    font-style: normal
    font-weight: 650
    font-size: 16px
    line-height: 19px
    letter-spacing: -0.0865063px
    text-align: center
    margin-top: 45px

.error
    position: absolute
    left: 0
    bottom: -25px
    font-family: "SF Pro Text", sans-serif
    font-style: normal
    font-weight: 510
    font-size: 14px
    line-height: 100%
    color: var(--color-red)

.info
    font-family: "SF Pro Text", sans-serif
    font-style: normal
    font-weight: 400
    font-size: 12px
    line-height: 20px
    color: var(--color-white)
    display: block
    margin-top: 25px
    text-align: center
    a
        color: var(--color-c400)

.animation
    background: var(--color-blue-dark)

.checkboxWrap
    font-family: "SF Pro Text", sans-serif
    font-style: normal
    font-weight: 400
    font-size: 12px
    line-height: 20px
    color: var(--color-white)
    display: flex
    align-items: center
    cursor: pointer

.checkbox
    margin-right: 10px
    svg
        fill: transperent

@media (max-width: 768px)
    .wrapper
        flex-grow: 1
        margin-top: 70px
