@keyframes showPopup
  0%
    width: 10px
    height: 10px
    background: white
    opacity: 0
  99%
    width: 420px
    height: 350px
    opacity: 0

.wrap
  display: flex
  flex-direction: column
  justify-content: space-between
  align-items: center
  position: relative
  animation: showPopup 0.2s ease-in-out
  min-width: 409px
  //height: 352px
  box-sizing: border-box
  padding: 0 0 30px 0
  font-family: "SF Pro Regular"
  letter-spacing: -0.08px
  .cross
    position: absolute
    top: 21px
    right: 16px
    width: 16px
    height: 2px
    background: #a7a4a4
    transform: rotate(45deg)
    cursor: pointer
  .cross:before
    content: ''
    position: absolute
    top: 0
    right: 0
    width: 16px
    height: 2px
    background: #a7a4a4
    transform: rotate(90deg)
  .title
    font-size: 19px
    margin: 40px 0 40px 0
    font-family: "SF Pro Medium"
    // color: #2B353E
    color: inherit
  .buttonsWrap
    display: flex
    width: 290px
    justify-content: space-between
    div
      display: flex
      justify-content: center
      align-items: center
      width: 140px
      height: 39px
      border-radius: 5px
      cursor: pointer
      font-size: 14px
    .cancel
      background: #e3e3e3
      // border: 1px solid #e9e9e9
      color: grey
    .cancel:hover
      background: #fc7a7a
      color: white
    .action
      background: #477DE1
      border: 1px solid #477DE1
      color: white
    .action:hover
      background: #3b6dcb
    .action.disableBtn
      background: #e9e9e9
      border: none
      color: grey
      cursor: default
      opacity: .7
      &:hover
        background: #e9e9e9

  .children
    margin: 0 0 28px 0
  .text
    display: block
    overflow: hidden
    text-overflow: ellipsis
    width: 60%
    max-width: 400px
    font-size: 12px
    line-height: 20px
    letter-spacing: 0.2px
    text-align: center
    margin: 0 0 30px 0

@media (max-width: 1420px)
  .wrap
    .buttonsWrap
      width: 250px
      div
        width: 120px
        height: 32px
        font-size: 12px
