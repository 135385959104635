.wrapper
    background: #fff
    border-radius: 10px
    float: left
    margin: 0 7px 0 0
    &.severalFiles
        float: none
        background: transparent
        margin: 0
    .fileBar
        display: flex
        flex-direction: column
        align-items: center
        justify-content: center
        width: 150px
        min-width: 155px
        height: 174px
        position: relative
        z-index: 3
        cursor: default
        font-family: "SF Pro Light", sans-serif
        cursor: pointer
        .file
            width: 37%
            height: 40%
            position: relative
            margin: 0 0 10px 0
        .fname
            display: block
            max-width: 85%
            overflow: hidden
            white-space: nowrap
            text-overflow: ellipsis
            margin: 0 0 15px 0
            color: #5D5D5D
    .fileBar:hover
        background: rgba(64, 134, 241, .1)
        box-shadow: none
    .fileBar:hover
        .file:after
            background: linear-gradient(45deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 41%, rgba(64, 134, 241, .1) 41%)

.wrapper
    .fileBar.small
        width: 54px
        min-width: 54px
        height: 63px
        .file
            width: 74%
            height: 70%
            margin: 0
            &:after
                width: 16px
                height: 16px
        .fname
            max-width: 85%
            margin: 0
            font-size: 10px
    .fileBar:hover
        background: none
        box-shadow: none
    .fileBar:hover
        .file:after
            background: linear-gradient(45deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 50%, white 50%)
