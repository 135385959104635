.item
    display: flex
    justify-content: space-between
    align-items: center
    padding: 0 12px
    border-radius: 6px
    background: #F5F7F7
    height: 52px

.number
    display: flex
    flex-direction: column
    width: 15%
    padding: auto 0 10px
    height: 35px
    justify-content: space-between
    span
        color: #274A42
        font-family: Roboto
        font-size: 16px
        font-style: normal
        font-weight: 500
        line-height: 20px
        &:nth-child(2)
            color: #56716B
            font-family: Roboto
            font-size: 12px
            font-style: normal
            font-weight: 400
            line-height: 16px

.invoice
    padding: 0 20px
    flex-grow: 1
    display: flex
    flex-direction: column
    justify-content: space-evenly
    height: 35px
    justify-content: space-between
    span
        color: #274A42
        font-family: Roboto
        font-size: 12px
        font-style: normal
        font-weight: 400
        line-height: 16px
    a
        color: #1976D2
        font-family: Roboto
        font-size: 12px
        font-style: normal
        font-weight: 400
        line-height: 16px
        svg
            path
                fill: #1976D2
                transition: all 0.3s ease-in-out
        &:hover
            svg
                path
                    fill: #274A42
.price
    color: #274A42
    font-family: Roboto
    font-size: 12px
    font-style: normal
    font-weight: 500
    line-height: 16px
    width: 20%

.button
    min-width: 95px
    span
        width: auto !important
        height: auto !important
        svg
            transform: scale(0.8)

.payedButton
    color: #F5F7F7
    text-align: center
    font-family: Roboto
    font-size: 12px
    font-style: normal
    font-weight: 400
    line-height: 20px
    border-radius: 4px
    background: #2FB292
    padding: 2px 10px
    svg
        margin-right: 4px
