.category
    display: flex
    flex-direction: column
    padding: 10px 16px 0 16px
    border-radius: 8px
    height: 320px
    background: #cfd7d54d
    position: relative
    transition: all 0.3s ease-in-out
    &.size_1
        flex: 0 0 calc( 33.33% - 12px )
    &.size_2
        flex: 0 0 calc( 66.66% - 12px )
    &.size_3
        flex: 0 0 calc(100% - 12px)
    h6
        color: var(--DarkGreen-D700, #3E5D56)
        font-family: Roboto
        font-size: 14px
        font-style: normal
        font-weight: 500
        line-height: 18px
        display: flex
        align-items: center
        height: 24px
        gap: 4px

.header
    display: flex
    justify-content: space-between

.notesList
    display: flex
    width: 100%
    flex-wrap: wrap
    gap: 12px
    overflow: auto
    padding-bottom: 10px
    margin-top: 5px
.empty
    display: flex
    width: 100%
    height: 250px
    align-items: center
    justify-content: center
.deleteButton
    position: absolute
    right: 8px
    top: -8px
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.20), 0px 1px 10px 0px rgba(0, 0, 0, 0.12), 0px 4px 5px 0px rgba(0, 0, 0, 0.14)

.bottomBar
    padding: 6px 8px
    border-radius: 8px
    background: rgba(207, 215, 213, 0.30)
    backdrop-filter: blur(9px)
    display: flex
    justify-content: space-between
    position: absolute
    left: 0
    right: 0
    bottom: 0
    z-index: 2

.colorList
    display: flex
    flex-wrap: wrap
    align-items: center
    gap: 1px
    width: 200px

.element
    width: 22px
    height: 22px
    display: flex
    align-items: center
    justify-content: center
    border-radius: 50%
    border: 2px solid transparent
    cursor: pointer

    &.active
        background-color: var(--color-b40)
        border-color: var(--color-b700)

    &:hover
        background-color: var(--color-b40)

    .color
        width: 15px
        height: 15px
        border-radius: 50%

.sizes
    display: flex
    gap: 5px
    align-items: center
    color: var(--DarkGreen-D700, #3E5D56)
    font-size: 14px
    font-weight: 400
    line-height: normal
    letter-spacing: 0.15px

.sizeButtons
    display: flex
    div
        height: 32px
        padding: 5px
        border: 1px solid var(--DarkGreen-D200, #B6C2BF)
        cursor: pointer
        span
            display: block
            width: 100%
            height: 100%
            background: #B6C2BF
            &.active
                background: #1E88E5
        &:nth-child(1)
            border-radius: 4px 0px 0px 4px
            width: 32px
        &:nth-child(2)
            width: 46px
        &:nth-child(3)
            border-radius: 0px 4px 4px 0px
            width: 60px
