
.root
  width: 440px

.mainContent
  display: flex
  flex-direction: column
  row-gap: 22px
  padding: 16px

.users
  min-height: 200px
  max-height: 324px
  overflow-y: auto
  overflow-x: hidden
  position: relative

.buttonsWrapper
  justify-content: space-between
  display: flex
  flex: 0 0 100%
  gap: 8px
  & > button
    width: 50%

.divider
  text-align: center
  position: relative
  span
    position: relative
    background: var(--color-white)
    color: var(--color-d800)
    font-size: 14px
    padding: 0 2px
  &::before
    content: ""
    display: block
    width: 100%
    height: 1px
    position: absolute
    background: var(--color-d100)
    top: 56%
    transform: translateY(-56%)

.google
  color: var(--color-d600)
  font-size: 14px
  display: flex
  justify-content: center
  align-items: center
  gap: 6px
