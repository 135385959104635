.preview
  display: flex
  flex-direction: column
  justify-content: center
  align-items: center
  width: 100vw
  height: 100vh
  overflow: hidden
  position: relative
  .imagePreviewWrap
    position: relative
    max-width: 85%
    min-width: 85%
    width: 85%
    max-height: 90%
    height: 90%
    display: flex
    flex-direction: column
    justify-content: flex-start
    align-items: center
    background: rgba(0, 0, 0, 0.36)
    .edit
      display: flex
      justify-content: center
      align-items: center
      align-self: flex-end
      margin: 10px 10px 10px 0
      color: white
      box-sizing: border-box
      padding: 5px 10px
      background: #4086F1
      border-radius: 5px
      cursor: pointer
      -webkit-touch-callout: none
      -webkit-user-select: none
      -khtml-user-select: none
      -moz-user-select: none
      -ms-user-select: none
    .canvasWrap
      position: relative
      width: 100%
      height: 100%
      display: flex
      justify-content: center
      align-items: center
  .filePreviewWrapWrap
    display: flex
    justify-content: center
    align-items: center
    border-radius: 10px
    width: 20vw
    height: 20vw
    background: white
    .filePreviewWrap
      position: relative
      width: 120px
      height: 140px