
@keyframes showPopup
  0%
    width: 10px
    background: white
    opacity: 0
  99%
    width: 420px
    opacity: 0

.root
  width: 420px
  position: relative
  padding: 20px 30px 15px
  animation: showPopup 0.2s ease-in-out
.title
  width: 100%
  max-width: 300px
  font-family: 'Roboto', sans-serif
  font-weight: 500
  font-size: 24px
  line-height: 28px
  color: var(--color-text-dark)
  margin-bottom: 15px
.contentWrap
  display: flex
  flex-direction: column
  row-gap: 15px
.buttonsWrap
  display: flex
  justify-content: flex-end
  gap: 8px
  margin-top: 30px
  button
    min-width: 0
    width: max-content
    padding: 8px 22px
