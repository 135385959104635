
.container
    position: relative

.tooltip
    padding: 0 !important

.tooltipContainer
    padding: 6px
    background: var(--color-d40)
    display: flex
    border-radius: 4px
    width: 100%

.info
    display: flex
    flex-direction: column
    justify-content: space-between
    margin-left: 10px

.title
    font-weight: 700
    font-size: 16px
    color: var(--dark-green-d-800, #274A42)
    white-space: nowrap

.buttons
    display: flex
    gap: 8px

.button
    background: rgba(39, 74, 66, 0.16)
    padding: 1px
    span
        width: 16px !important
        height: 16px !important
