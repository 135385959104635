.usersWrap
    padding: 10px 10px 0
    display: flex
    gap: 10px

    .users
        width: 100%
        display: flex
        flex-direction: column
        flex-wrap: wrap
        gap: 10px
        align-content: flex-start
        overflow-x: scroll
        height: 40px
        padding-right: 10px
