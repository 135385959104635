.wrap
  width: 440px
  .body
    padding: 12px 16px
  .footer
    display: flex
    align-items: center
    justify-content: flex-end
    padding-top: 24px
    gap: 8px
