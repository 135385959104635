
.roleName
  display: flex
  align-items: center
  gap: 5px
  justify-content: space-between
  border: 1px solid var(--color-border-dark)
  border-radius: 6px
  font-size: 10px
  text-align: center
  padding: 6px
  margin-bottom: 10px
  background: var(--color-white)
  font-family: "SF Pro Text", sans-serif

  span
    flex: 1
  div
    margin-right: 3px
.active
  border-color: var(--color-blue-smooth)
  background: var(--color-blue-light)
