@import "src/theme/cutsStr"

.box
    padding: 12px
    font-family: "Roboto", sans-serif

    &__head
        display: flex
        align-items: center
        gap: 10px
        color: var(--color-d800)
        font-size: 12px

    &__switcher
        width: 64px
        height: 24px
        display: flex
        align-items: center
        background-color: var(--color-d50)
        border-radius: 4px
        padding: 2px
        cursor: pointer
        position: relative

        &Check
            transform: translateX(0)
            transition: transform 500ms ease
            z-index: 2

            &.checked
                transform: translateX(calc(100% + 20px))

        &Label
            width: 40px
            text-align: center
            transform: translateX(0)
            transition: transform 500ms ease
            @include cutsStr(1)

            &.checked
                transform: translateX(calc((100% - 20px) * -1))

    &__title
        font-weight: 500
        line-height: 1.66
        color: var(--color-d800)

    &__body
        margin-top: 8px
        display: flex
        flex-direction: column
        align-items: flex-start
        gap: 6px

    &__desc
        font-size: 14px
        line-height: 1.28
        color: var(--color-d700)
