
.taskWrap
    display: flex
    justify-content: space-between
    gap: 20px
    padding: 6px 12px
    max-width: 100%

    .left
        flex: 1 1 80%
        display: flex
        flex-direction: column
        gap: 10px
    .right
        display: flex
        gap: 10px
        &>div:last-child
            transform: translateX(10px)

    .top
        display: flex
        justify-content: space-between
        gap: 10px
        align-items: flex-start

    .header
        display: flex
        align-items: flex-start
        gap: 10px
        .name
            margin: 0
            font-weight: 500
            font-size: 12px
            line-height: 14px
            color: var(--color-text-dark)
            font-family: "SF Pro SemiBold", sans-serif
            max-width: 400px
        .role
            color: var(--color-blue-smooth)
            border: 1px solid var(--color-blue-smooth)
            border-radius: 10px
            background: var(--color-white)
            padding: 1px 8px 0
            font-size: 10px
            line-height: 14px
            width: max-content
            max-width: 80px
            white-space: nowrap
            text-overflow: ellipsis
            overflow: hidden
            margin: 0
        .icons
            flex-shrink: 0
            display: flex
            gap: 6px
            align-items: center
            .clip
                font-size: 10px
                line-height: 12px
    .block
        display: flex
        gap: 6px
        align-items: center
        & div
            padding: 0
    .subTitle, .text
        margin: 0
        font-size: 10px
        line-height: 14px
    .text
        max-width: 500px
        height: 14px
        overflow: hidden

    .subTitle
        color: var(--color-blue-smooth)
        flex-shrink: 0
    .statusBox
        display: flex
        gap: 6px
        align-items: center
        flex-grow: 1
    .circle
        width: 12px
        height: 12px
        border-radius: 50%
        margin-left: 5px
        flex-shrink: 0
    .status
        font-size: 12px
        flex-grow: 1
        max-width: 100px
        text-overflow: ellipsis
        white-space: nowrap
        overflow: hidden
    .cursor
        cursor: pointer
