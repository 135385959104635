
.container
  flex: 1 0 auto
  width: 30%
  height: calc(100% - 12px)
  background: var(--color-background-light)
  border-radius: 6px
  border: 1px solid var(--color-border-dark)
  position: relative

  .title
    display: flex
    align-items: center
    gap: 10px
    border-bottom: 1px solid var(--color-border-dark)
    padding: 8px 10px
    .color
      width: 14px
      height: 14px
      border-radius: 50%
    .name
      font-size: 12px
      line-height: 14px
  .body
    height: calc(100% - 30px)
    overflow-y: auto
  .taskBox
    margin: 0 10px
  .isDraggable
    opacity: 0.2
.isOverStatus
  border-color: var(--color-blue-smooth)
