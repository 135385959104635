@import "src/theme/cutsStr"
.card
  font-size: 12px
  line-height: 1.33
  padding: 8px
  display: flex
  align-items: center
  gap: 20px
  border-radius: 4px
  background-color: var(--color-d40)
  transition-duration: 500ms

  &.notSystem
    cursor: pointer

  &:hover,
  &:active
    background-color: var(--color-d50)

    .card__btns
      opacity: 1
      visibility: visible

  &__imgBox
    width: 61px
    height: 42px
    background-color: var(--color-d50)
    border-radius: 4px
    overflow: hidden

    > img
      width: 100%
      height: inherit
      object-fit: contain
  
  &__body
    max-width: 233px
    width: 100%

  &__name
    font-size: 14px
    font-weight: 500
    line-height: 1.42
    color: var(--color-d800)
    @include cutsStr(2)
  
  &__date
    color: var(--color-d600)
    line-height: 1.5

  &__btns
    margin-left: auto
    display: flex
    gap: 8px
    opacity: 0
    visibility: hidden
    transition: 500ms opacity
      
  
