
.body
    width: 440px
    max-width: 100%
    background-color: var(--color-white)
    padding: 14px 16px 20px
    font-size: 14px
    font-family: "Roboto", sans-serif
    border-bottom-left-radius: 4px
    border-bottom-right-radius: 4px

.info
    display: flex
    column-gap: 4px

    &:not(:last-of-type)
        margin-bottom: 12px

    &__name
        line-height: 1.28
        color: var(--color-d500)
        max-width: 120px
        width: 100%
        flex-shrink: 0

    &__text
        max-width: 284px
        width: 100%
        flex-shrink: 0
        color: var(--color-d800)

        & > p
            &:not(:last-child)
                margin-bottom: 12px
.btns
    margin-top: 28px
    display: flex
    justify-content: flex-end
    gap: 8px
