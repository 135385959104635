
.root
  display: flex
  margin-right: 15px
  &.isLinesViewSelected
    .button
      &:first-child
        &::after
          background-color: transparent
  &.isBarsPreviewViewSelected
    .button
      &:nth-child(2)
        &::after
          background-color: transparent
  &.isLinesPreviewViewSelected
    .button
      &:nth-child(3)
        &::after
          background-color: transparent
.button
  flex: 0 0 40px
  width: 40px
  height: 28px
  display: flex
  align-items: center
  justify-content: center
  background-color: var(--color-background-light)
  border: 1px solid var(--color-gallery)
  transition: background-color 0.2s ease-in-out, border-color 0.2s ease-in-out
  position: relative
  padding: 0
  svg
    path,
    rect
      fill: var(--color-fill-grey)
      transition: fill 0.2s ease-in-out
  &:first-child
    border-radius: 6px 0 0 6px
    border-right: 0
  &:nth-child(2),
  &:nth-child(3)
    border-right: 0
    border-left: 0
  &:last-child
    border-radius: 0 6px 6px 0
    border-left: 0
  &:not(:last-child)
    &::after
      content: ""
      position: absolute
      top: 50%
      transform: translateY(-50%)
      right: 1px
      height: calc(100% - 6px)
      width: 1px
      background-color: var(--color-icon)
      transition: background-color 0.1s ease-in-out
  &.selected
    background-color: var(--color-blue-smooth)
    border-color: var(--color-blue-smooth)
    svg
      path,
      rect
        fill: var(--color-white)
    &::after
      background-color: var(--color-blue-smooth)
  &:hover
    background-color: var(--color-blue-smooth)
    border-color: var(--color-blue-smooth)
    &::after
      background-color: var(--color-blue-smooth)
    svg
      path,
      rect
        fill: var(--color-white)
