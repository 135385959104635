
.wrap
  display: flex
  justify-content: space-between
  align-items: center
  padding: 8px 12px
  background: var(--color-d50)
.tools
  display: flex
  align-items: center
  gap: 6px
  .toolBtn
    padding: 0
    height: 32px
    width: 32px
    display: flex
    flex-direction: column
    align-items: center
    justify-content: center
    background: var(--color-white)
    border-radius: 4px
    position: relative
    border: 1px solid transparent
    &.active
      border-color: var(--color-blue-smooth)
  .sizes
    position: absolute
    top: 100%
    left: 0
    background: var(--color-white)
    width: 100%
    display: flex
    flex-direction: column-reverse
    align-items: center
    border-radius: 4px
  .sizeBtn
    width: 32px
    height: 32px
    padding: 0
    display: flex
    align-items: center
    justify-content: center
    border: 1px solid transparent
    &:hover
      border-color: var(--color-blue-smooth)
    span
      display: block
      border-radius: 50%
  .pallete
    width: 18px
    height: 18px
.manageButtons
  display: flex
  gap: 10px
  .btn
    padding: 6px 11px
    line-height: 20px
    font-size: 14px
.actionBtn
  min-width: 68px
  height: 26px
  padding: 6px
  font-size: 12px
