@import "colors"

@mixin flex-center
  display: flex
  justify-content: center
  align-items: center

//SidePanel colors
.menuItemChosen-blue
  background: var(--color-blue-smooth)
  border: 1px solid var(--color-blue-deleted-side-menu) !important

.menuItem-blue
  &:hover
    background: var(--color-blue-smooth)
    border: 1px solid var(--color-blue-deleted-side-menu)!important

.menuItemChosen-orange
  background: var(--color-orange-smooth)
  border: 1px solid var(--color-orange-border) !important

.menuItem-orange
  &:hover
    background: var(--color-orange-smooth)
    border: 1px solid var(--color-orange-border) !important

.menuItemChosen-turquoise
  background: var(--color-turquoise-smooth)
  border: 1px solid var(--color-turquoise-border) !important

.menuItem-turquoise
  &:hover
    background: var(--color-turquoise-smooth)
    border: 1px solid var(--color-turquoise-border) !important

.menuItemChosen-dark
  background: var(--color-dark-smooth)
  border: 1px solid var(--color-dark-border) !important

.menuItem-dark
  &:hover
    background: var(--color-dark-smooth)
    border: 1px solid var(--color-dark-border) !important

//Colors on hover element
.interaction-border-blue
  &:hover
    border: 1px solid var(--color-primary) !important

.interaction-border-orange
  &:hover
    border: 1px solid var(--color-primary) !important

.interaction-border-turquoise
  &:hover
    border: 1px solid var(--color-primary) !important

.interaction-border-dark
  &:hover
    border: 1px solid var(--color-primary) !important

.interaction-background-blue
  &:hover
    background: var(--color-blue-smooth) !important

.interaction-background-orange
  &:hover
    background: var(--color-blue-smooth) !important

.interaction-background-turquoise
  &:hover
    background: var(--color-blue-smooth) !important

.interaction-background-dark
  &:hover
    background: var(--color-blue-smooth) !important

//Border colors
.border-blue
  border: 1px solid var(--color-blue-deleted) !important
.border-side-menu-blue
  border: 1px solid var(--color-blue-deleted-side-menu) !important
.border-inner-side-menu-blue
  border: 1px solid var(--color-scrollbar) !important

.border-orange
  border: 1px solid var(--color-orange-border-secondary)!important

.border-turquoise
  border: 1px solid var(--color-turquoise-border) !important

.border-dark
  border: 1px solid var(--color-dark-border) !important

//Backgorund colors
.background-blue
  background: var(--color-blue-background) !important

.background-orange
  background: var(--color-orange-smooth) !important

.background-turquoise
  background: var(--color-turquoise-smooth) !important

.background-dark
  background: var(--color-dark-background) !important

.d-none
  display: none !important

// Text colors
.text-blue-smooth
  color: var(--color-blue-smooth)

.text-danger
  color: var(--color-background-danger)
