
.listWrap
  height: calc(100% - 100px)
.workSpace
  display: flex
  align-items: stretch
  height: 100%
.path
  padding: 4px 10px
  border-bottom: 1px solid var(--color-border-dark)
  font-family: "SF Pro Text", sans-serif
  font-size: 12px
  display: flex
  align-items: center

  & span:first-child
    font-size: 16px
  .pathItem
    display: flex
    align-items: center
  .pathInfo
    display: flex
    gap: 5px
    align-items: center
    border-radius: 6px
    cursor: pointer
    padding: 2px 10px
    &:hover
      background: var(--color-blue-light)
.foldersList
  display: flex
  flex-direction: column
  row-gap: 5px
  margin: 10px
