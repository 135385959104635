
.wrap
    width: 410px
    margin-top: 20px
    border-top: 1px solid var(--color-border-dark)
    text-align: center

    .btns
        display: flex
        justify-content: center
        gap: 10px
