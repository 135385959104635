.container
    width: 100%
    display: flex
    height: 100%
    background: var(--dark-green-d-20, #F5F7F7)

.content
    width: 100%
    height: 100%
    padding: 20px
    overflow: auto

.sidebar
    width: 240px
    height: 100%
    background: var(--color-white)
    overflow: auto

.text
    max-width: 768px
    margin: 0 auto
    color: var(--dark-green-d-800, #274A42)

    h1, h2, h3, h4, h5, h6
        margin-top: 25px
    p, table
        font-size: 14px
        line-height: 20px
        margin: 10px 0
    table
        table-layout: fixed
        width: 100%
        th, tr
            vertical-align: top
        td
            padding: 0 20px 0 0

.sidebar
    .title
        color: var(--primary-n-800, #425D5A)
        font-size: 12px
        font-weight: 500
        line-height: 16px
        padding: 8px 12px
    button
        color: var(--primary-n-800, #425D5A)
        font-size: 12px
        line-height: 20px
        padding: 8px 12px
.navigate
    display: flex
    gap: 20px
    flex-wrap: wrap

.item
    width: 300px
    min-height: 52px
    border-radius: 12px
    color: var(--color-d700, #3E5D56)
    font-size: 20px
    font-weight: 500
    background: var(--color-white, #F1F3F2)
    padding: 12px 16px
    transition: all 0.3s ease-in-out
    &:hover
        background: var(--dark-green-d-100, #CFD7D5)
