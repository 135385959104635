
.sidebar
    min-width: 300px
    background: var(--color-b500, #2196F3)
    height: calc( 100% - 100px)
    padding: 8px
    position: relative
    transition: all 0.3s linear
    margin-left: -300px
    &.sidebarMyTasksPage
        height: calc( 100% - 48px)
    &.showSidebar
        margin-left: 0

.emptySidebar
    height: 350px
    color: var(--DarkGreen-D20, #F5F7F7)
    font-size: 16px
    font-weight: 400
    line-height: 20px
    width: 100%
    height: 100%
    display: flex
    align-items: center
    justify-content: center
    text-align: center
    svg
        margin-right: 10px
        fill: var(--DarkGreen-D20, #F5F7F7)

.sidebarScroll
    height: 100%
    overflow-y: auto
    overflow-x: hidden
    padding-bottom: 110px

.teamTabs
    margin: 10px 0
    max-width: 284px

.sidebarItem
    margin: 0 0 20px
    border-radius: 4px
    width: 284px
