
.list
  width: 640px
  height: 100%
  overflow-y: auto
  margin: 0 auto
  .roleItem
    background-color: var(--color-d50)
    margin-bottom: 8px
    border-radius: 4px
    padding: 6px 12px
    position: relative
    &:hover .btns
      display: flex

    .title
      color: var(--color-d800, #274A42)
      font-size: 14px
      font-weight: 500
      line-height: 20px
    .prim
      color: #425D5A
      font-size: 12px
      font-weight: 400
      line-height: 16px
    .btns
      display: none
      align-items: center
      position: absolute
      top: 6px
      right: 12px
