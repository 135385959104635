
.container
    display: flex
    justify-content: space-between
    align-items: flex-end

.title
    color: var(--dark-green-d-700, #3E5D56)
    font-size: 14px
    line-height: 20px
    margin-bottom: 4px

.list
    display: flex
    gap: 8px
