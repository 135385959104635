.devices
  max-height: calc(100% - 60px)
  overflow-y: auto
  .list
    width: 600px
    margin: 16px auto 0
.item
  border-radius: 4px
  padding: 8px 12px
  background: var(--color-d40)
  display: flex
  align-items: center
  justify-content: space-between
  margin-bottom: 8px
  &.start
    align-items: flex-start
  .info
    flex: 1 1 auto
    font-size: 14px
    line-height: 20px
    .browser
      color: var(--color-d800)
      font-weight: 500
      margin: 0
    .ip
      color: var(--color-d600)
      font-weight: 400
      margin: 0
  .btns
    display: flex
    gap: 4px
    .crossIcon
      width: 14px
      height: 14px
  .text
    color: var(--color-d700)
    font-size: 12px
    font-weight: 400
    line-height: 16px
.selectBox
  width: 200px
