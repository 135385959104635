
body
    .tooltip
        z-index: 3
        padding: 0
        border-radius: 6px
        background: var(--color-o500)
        &_grey
            background: var(--color-fill-grey)

body
    .arrow
        background: transparent
        background-size: contain
        transform: scale(3.5) !important
        background-repeat: no-repeat
        background-image: url('/assets/icons/tooltip_arrow.svg')
        &_grey
            background-image: url('/assets/icons/tooltip_arrow_grey.svg')

.wrapper
    padding: 10px 15px
    max-width: 200px

.title
    font-weight: 600
    font-size: 18px
    line-height: 16px
    margin: 2px 0
    text-align: left

.text
    font-weight: 400
    font-size: 12px
    line-height: 16px
    margin: 0 !important
    padding: 5px 0
    text-align: left
