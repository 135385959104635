
.cross
  position: absolute
  top: 10px
  right: 11px
  width: 18px
  height: 18px
  cursor: pointer
  &:before, &:after
    content: ''
    position: absolute
    top: 6px
    width: 18px
    height: 2px
    background: var(--color-grey_dark)
  &:before
    transform: rotate(45deg)
  &:after
    transform: rotate(-45deg)
