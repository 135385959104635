.container
    width: 360px
    padding: 12px
    &.full
        width: 940px
        max-width: 98vw
.header
    display: flex
    justify-content: flex-end
    align-items: center
    width: 100%
    span
        width: 16px !important
        height: 16px !important

.cloudIcon
    margin-right: 10px
    opacity: 0.5
    animation: fadeInOut 1.5s infinite

@keyframes fadeInOut
    0%
        opacity: 0.5

    50%
        opacity: 1

    100%
        opacity: 0.5
