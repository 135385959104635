@keyframes minimizeImage
  0%
    width: calc(100vw - 200px)
    height: calc(100vh - 200px)
    left: calc(50vw - (100vw - 200px) / 2)
    bottom: calc(50vh - (100vh - 200px) / 2)
  70%
    width: calc(100vw - 200px)
    height: calc(100vh - 200px)
    left: calc(50vw - (100vw - 200px) / 2)
    bottom: calc(50vh - (100vh - 200px) / 2)
  100%
    width: 200px
    height: 110px
    left: 50px
    bottom: 50px
.screenImage
  position: fixed
  left: 50px
  bottom: 50px
  cursor: pointer
  z-index: 1000
.minimize
  animation: minimizeImage 1.5s ease-in-out