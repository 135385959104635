.container
    display: flex
    flex-direction: column
    align-items: center
    justify-content: center
    gap: 8px

.title
    color: var(--DarkGreen-D700, #3E5D56)
    font-size: 12px
    font-weight: 400
    line-height: 16px
    display: flex
    white-space: nowrap
    span
        width: 16px
        height: 16px
        display: block
        margin-right: 6px
        border-radius: 50%

.chartWrapper

    position: relative
    background: #fff
    border-radius: 50%

.percentage
    position: absolute
    text-align: center
    font-size: 14px
    font-weight: 400
    line-height: 20px
    top: 50%
    left: 50%
    transform: translate(-50%, -50%)

.subtitle
    color: var(--DarkGreen-D700, #3E5D56)
    text-align: center
    font-size: 14px
    line-height: 16px
