@import "src/theme/global"
.show
  opacity: 1 !important
.popoverList
  display: flex
  flex-direction: column
  margin: 0
  &:not(:last-child)
    @include borderBottom
.popoverItem
  width: 100%
  padding: 6px 0
  position: relative

.popoverButton
  width: 100%
  display: flex
  align-items: center
  column-gap: 10px
  padding: 0 10px
  &:hover
    .popoverButtonName
      color: var(--color-b800)

.popoverButtonName
  font-family: "Roboto", sans-serif
  font-weight: 400
  font-size: 14px
  line-height: 24px
  letter-spacing: -0.06px
  color: var(--color-d800)
  margin: 0
  flex: 1 1 auto
  text-align: left
  @include textEllipsis
.buttonIcon
  display: flex
  align-items: center
  justify-content: center
  position: relative
  padding: 0
  margin: 0
  &:hover,
  &:focus
    *
      fill: var(--color-blue-smooth)
  &:not(.unzoomClickArea)
    @include clickAreaEnlargement(150%, 150%)

.buttonIconHidden
  opacity: 0
  visibility: hidden
  position: absolute
  right: 0
  top: 0
  width: 32px
  height: 32px
  border-radius: 4px
  background: var(--color-d800)
  padding: 0
  display: flex
  align-items: center
  justify-content: center
  z-index: 10

  &.visible
    opacity: 1
    visibility: visible

.icon
  *
    fill: var(--color-alto-darker)
.arrowIcon
  transform: scaleX(-1)
  & path
    fill: var(--color-d800)
.nextList
  display: none
  position: absolute
  top: 0
  right: 100%
  width: 250px
  background: var(--color-white)
  @include boxShadow

.nextItem
  &:hover
    background: var(--color-b100)
    .nextList
      display: block

.popoverRoot
  &:global(.bs-popover-top)
    width: 100%
    max-width: 250px
    @include boxShadow
    \:global(.popover-arrow)
      &::after, &::before
        border-top-color: var(--color-blue-items)
        border: none

  &:global(.bs-popover-bottom)
    width: 100%
    max-width: 250px
    @include boxShadow

    \:global(.popover-arrow)
      &::after, &::before
        border-bottom-color: var(--color-blue-items)
        border: none

  &:global(.bs-popover-start)
    width: 100%
    max-width: 250px
    @include boxShadow

    \:global(.popover-arrow)
      &::after, &::before
        border-left-color: var(--color-blue-items)
        border: none

  &:global(.bs-popover-end)
    width: 100%
    max-width: 250px
    @include boxShadow
    \:global(.popover-arrow)
      &::after, &::before
        border-right-color: var(--color-blue-items)
        border: none
.popoverHeader
  &:global(.popover-header)
    font-weight: 600
    font-size: 11px
    line-height: 13px
    letter-spacing: -0.06px
    color: var(--color-text-grey)
    background-color: var(--color-blue-items)
    border-bottom: 1px solid var(--color-gallery)
    padding: 10px 10px 8px
.popoverBody
  &:global(.popover-body)
    padding: 4px 0
