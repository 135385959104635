@import "src/theme/styles"

.button
  @include flex-center
  position: relative
  padding: 10px
  border-radius: 5px
  cursor: pointer
  font-size: 12px
  color: var(--color-white) !important
  min-height: 40px
  transition: all .3s ease
.buttonLight
  border-radius: 0
  color: var(--color-text-grey)
  background: var(--color-white)
  height: 40px
  min-width: 190px
.small
  min-height: 30px
  min-width: 90px
.large
  min-width: 140px
.fullWidth
  width: 100%
.grey
  min-width: 140px
  background: var(--color-background-grey)
  color: var(--color-text-grey)
.light-long
  display: flex
  align-items: center
  justify-content: space-between !important
  gap: 10px
  cursor: pointer
  box-shadow: 0 2px 4px var(--color-shadow)
  font-size: 12px
  padding: 0 12px 0 10px
.icon, .icon-disabled, .icon-chosen-disabled
  display: flex
  align-items: center
  justify-content: center
.icon
  cursor: pointer
  svg
    fill: var(--color-icon)
    transition: all .2s ease
    &:hover
      fill: var(--color-primary)
      rect
        fill: var(--color-primary)
.icon-disabled
  pointer-events: none
  svg
    fill: var(--color-background-gray_dark)
    opacity: 0.2
    rect
      fill: var(--color-background-gray_dark)
.icon-chosen-disabled
  pointer-events: none
  svg
    fill: var(--color-primary)
    rect
      fill: var(--color-primary)
.round-grey
  cursor: pointer
  background: var(--color-background-gray)
  border: none
  border-radius: 50%
  width: 50px
  height: 50px
  box-shadow: 0 2px 4px rgba(140, 140, 140, 0.5)
  &:hover
    background: var(--color-shadow)
.round-white
  cursor: pointer
  background: var(--color-shadow)
  border: none
  border-radius: 50%
  width: 50px
  height: 50px
  box-shadow: 0 2px 4px rgba(140, 140, 140, 0.5)
  &:hover
    background: var(--color-shadow)
.disabled
  cursor: default
  background: var(--color-grey)
  &:hover
    background: var(--color-grey)
.round-red
  cursor: pointer
  background: var(--color-red)
  border: none
  border-radius: 50%
  width: 50px
  height: 50px
  &:hover
    background: var(--color-red)
.round-green
  cursor: pointer
  background: var(--color-green)
  border: none
  border-radius: 50%
  width: 50px
  height: 50px
  &:hover
    background: var(--color-green-light)
.active
  background: var(--color-blue-light)
  border-color: var(--color-blue-smooth)
.green
  background-color: var(--color-green)
  border-color: var(--color-green)
  &:hover
    background: var(--color-green-dark)
    border-color: var(--color-green-dark)
.red
  cursor: pointer
  svg
    fill: var(--color-red)
    transition: all .2s ease
    &:hover
      fill: var(--color-red-dark)
.transparent
  cursor: pointer
  background: transparent
  border: none
  border-radius: 50%
  width: 50px
  height: 50px
  box-shadow: 0 2px 4px rgba(140, 140, 140, 0.5)
  &:hover
    background: var(--color-shadow)
