
.wrap
  height: 100%
  width: 910px
  padding: 10px 10px 30px
  overflow-y: auto
  display: flex
  justify-content: center
  flex-wrap: wrap
  gap: 16px
  font-family: "SF Prop Text", sans-serif
  font-size: 11px
  color: var(--color-text-grey)
  .month
    width: 286px
    border: 1px solid var(--color-border-dark)
    border-radius: 6px
    overflow: hidden
  .monthTitle
    padding: 6px 10px
    margin-bottom: 10px
    line-height: 18px
    border-bottom: 1px solid var(--color-border-dark)
    background: var(--color-background-light)
    cursor: pointer
    &:hover
      color: var(--color-blue-smooth)
  .daysBlock
    padding: 10px
  .weekRow
    display: flex
    gap: 6px
    justify-content: center
  .weekLine
    border-bottom: 1px solid var(--color-gallery)
    &:last-child
      border: none
  .weekDay, .dayBox
    width: 32px
    text-align: center
    line-height: 30px
    border-radius: 6px
    position: relative
  .weekDay
    border: 1px solid var(--color-border-dark)
  .hidden
    visibility: hidden
  .light
    color: var(--color-text-grey-light)

  .today, .numDay
    width: 22px
    line-height: 20px
    border: 1px solid transparent
    border-radius: 50%
    text-align: center
    margin: 4px auto
    cursor: pointer
  .today
    color: var(--color-blue-smooth)
    background: var(--color-blue-light)
  .numDay:hover
    border-color: var(--color-blue-smooth)
  .currentMonth
    border: 1px solid var(--color-blue-smooth)
    .monthTitle
      background: var(--color-blue-light)
      border-bottom: 1px solid var(--color-blue-smooth)
    .currentWeekDay
      background: var(--color-blue-light)
      border: 1px solid var(--color-blue-smooth)

  .tasksColor
    display: flex
    justify-content: center
    position: absolute
    bottom: 0
    width: 100%
    padding-bottom: 1px
  .color
    width: 6px
    height: 6px
    border: 1px solid var(--color-white)
    border-radius: 50%
  .task
    background: var(--color-blue-smooth)
  .meeting
    background: var(--color-purple)
  .call
    background: var(--color-green)
  .mail
    background: var(--color-yellow)
  .reminder
    background: var(--color-aqua)
