
.wrap
  width: 100%
  display: flex
  padding: 6px 40px
  border-bottom: 1px solid var(--color-border-dark)

  .category
    flex-grow: 1
    text-align: center
    min-width: 20px
    transition: width 500ms
    padding: 0 3px

    p
      font-size: 10px
      line-height: 12px
      margin: 0 0 2px
  .line
    height: 5px
    border-radius: 4px
