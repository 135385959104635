
.wrap
  border-radius: 6px
  border: 1px solid var(--color-border-dark)
  background: var(--color-background-light)
  padding: 12px
  display: flex
  align-items: center
  justify-content: space-between
  gap: 6px
  margin-bottom: 10px
  .name
    flex: 1 1 100%
    font-size: 13px
  .date
    font-size: 12px
    border-radius: 6px
    border: 1px solid var(--color-border-dark)
    background: var(--color-white)
    padding: 5px 20px
    color: var(--color-grey_medium)
  .icon path
    fill: var(--color-red-dark)
  .roleSelectBlock
    position: relative
    .roleSelect
      position: absolute
      top: 110%
      right: 0
      width: max-content
      background: var(--color-white)
      padding: 5px
      z-index: 1
      & > div
        margin-bottom: 5px

.select
  border-color: var(--color-blue-smooth)
  background: var(--color-blue-light)
.hiddenInput
  height: 0px
  margin: 0
  padding: 0
  border: none
  overflow: hidden
  position: absolute
.text
  margin: 20px 0
  padding: 0 10px
  text-align: center
  font-size: 13px
  line-height: 18px
  color: var(--color-text-grey)
  font-family: 'SF Pro Text', sans-serif
