.container
    width: 100%
    padding: 10px 20px
    background: linear-gradient(91deg, #2196F3 -0.5%, #64B5F6 108.2%)
    display: flex
    flex-wrap: wrap
    justify-content: space-between
    align-items: center

.content
    display: flex
    justify-content: space-between
    align-items: center
    width: 100%

.logo
    display: flex
    align-items: center
    gap: 4px

.title
    color: var(--color-white)
    font-size: 26px
    margin: 2px 0 0 8px

.changeLang
    color: var(--dark-green-d-20, #F5F7F7)

.search
    padding: 30px 64px 64px
    width: 100%
    display: flex
    flex-direction: column
    align-items: center
    justify-content: center
    h2
        color: var(--color-white)
        text-align: center
        font-size: 40px
        font-weight: 700
        line-height: 48px
        letter-spacing: 1.2px

.input
    margin-top: 20px
    width: 440px
    position: relative
    svg
        fill: var(--dark-green-d-400, #869A95)

.details
    flex-wrap: nowrap
    gap: 20px
    .search
        width: auto
        padding: 0
    .input
        margin-top: 0

.searchResult
    width: 100%
    position: absolute
    border-radius: 4px
    background: var(--color-white)
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.20), 0px 1px 10px 0px rgba(0, 0, 0, 0.12), 0px 4px 5px 0px rgba(0, 0, 0, 0.14)
    display: flex
    flex-direction: column
    max-height: 440px
    overflow: auto
    a
        padding: 4px 12px 6px 12px
        display: flex
        flex-direction: column
.empty
    padding: 4px 12px 6px 12px
