.container
    width: 560px
    height: 450px
    position: relative

.header
    display: flex
    flex-direction: column
    gap: 8px
    padding: 10px 8px
    border-bottom: 1px solid var(--DarkGreen-D100, #CFD7D5)

.content
    overflow: auto
    height: 315px
    padding: 0 8px
    position: relative

.wrapper
    display: flex
    align-items: center
    justify-content: center
    height: 100%

.item
    display: flex
    justify-content: space-between
    align-items: center
    padding: 4px 12px
    transition: all 0.3s ease-in-out
    height: 32px
    overflow: hidden
    position: relative
    &:hover
        background: var(--LightBlue-B50, #E1F5FE)
        button
            right: 20px
    > div
        display: flex
        align-items: center
        gap: 4px
        margin-right: 100px
        overflow: hidden
        .num
            color: var(--Blue-B800, #1565C0)
            font-size: 12px
            font-weight: 700
            line-height: 16px
        .name
            color: var(--DarkGreen-D700, #3E5D56)
            font-size: 12px
            font-weight: 500
            line-height: 16px
            width: 100%
            white-space: nowrap
            overflow: hidden
            text-overflow: ellipsis
    button
        position: absolute
        right: -100%

.loaderBox
    position: absolute
    top: 0
    left: 0
    width: 100%
    height: 100%
    backdrop-filter: blur(2px)
