
.wrap
    width: 430px
    padding-top: 20px
    .text
        padding: 0 10px
        text-align: center
        font-size: 13px
        line-height: 21px
        color: var(--color-text-grey)
        margin: 25px auto
    .icon
        width: 50px
        height: 50px
