.wrap
    height: 100%
    display: flex
    align-items: center
    justify-content: center
    flex-direction: column
    font-family: 'SF Pro Light', sans-serif
    letter-spacing: -0.06px
    .title
        color: #49494B
        font-size: 16px
        margin: 0 0 21px 0
    .list
        margin: 0
        .text
            color: #797979
            font-size: 14px
            margin-bottom: 5px

@media (min-width: 1440px)
    .wrap
        .title
            font-size: 18px
        .list
            .text
                font-size: 16px
                margin-bottom: 6px