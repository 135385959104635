.content
    margin-top: 5px
    border-radius: 4px
    border: 1px solid var(--DarkGreen-D100, #CFD7D5)
    background: var(--Main-white, #FFF)
    padding: 8px 8px 0
    min-height: 300px
    max-height: 600px
    overflow: auto
    display: flex
    flex-direction: column
    justify-content: space-between

.audio
    max-height: 50px
    width: 300px

.noMargin > div, .noMargin
    margin: 0.2em 0

.buttons
    display: flex
    justify-content: space-between
    align-items: center
    margin-bottom: 5px
    .left, .right
        display: flex
        align-items: center
        gap: 4px
    button
        > span
            width: 18px !important
            height: 18px !important

.editorIcon
    height: 28px
    width: 28px
    display: flex
    align-items: center
    justify-content: center
    border-radius: 4px
    transition: all 0.3s ease-in-out
    &:hover
        background: var(--color-d200)

.middle
    display: flex
    align-items: center
    gap: 2px
    button
        span
            width: 20px !important
            height: 20px !important
    .active
        background: #274A4229
