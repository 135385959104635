@import "src/theme/colors"

.root
  flex: 0 0 30px
  width: 30px
  height: 24px
  display: inline-flex
  align-items: center
  justify-content: center

  & svg
    width: 24px
    height: 18px
