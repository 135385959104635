.root
  flex-grow: 1
  display: flex
  flex-direction: column
  gap: 20px
  overflow-y: auto
  overflow-x: hidden
  padding: 16px 20px
  position: relative
  height: 100%
  
  &.marginTop
    margin-top: 10px
  
  &.bgnd
    background: var(--color-blue-light)
