@import src/theme/global

.letterItem
  background: var(--color-b100)
  margin-bottom: 8px
  padding: 6px
  border-radius: 4px
  color: var(--color-d700)
  &:hover
    .time
      display: none
    .actions
      display: flex

.messageHeader
  display: flex
  justify-content: space-between
  align-items: center
  margin-bottom: 8px

  .time
    font-size: 14px
    color: var(--color-d600)
    line-height: 20px
    flex: 0 0
    display: flex
    align-items: center
    gap: 4px
    span
      white-space: nowrap
    &.show
      display: none
  .messageHeaderLeft
    display: flex
    gap: 8px
    flex: 1 1
    overflow: hidden
    padding: 2px 0
    .messageName
      display: flex
      flex-direction: column
      justify-content: space-between
    .name
      line-height: 1
      font-size: 12px
      color: var(--color-d800)
      font-weight: 700
      @include textEllipsis
    .email
      line-height: 1
      font-size: 12px
      @include textEllipsis

.actions
  display: none
  gap: 8px
  position: relative
  flex: 0
  &.show
    display: flex

.scheduleMessageModal
  width: 400px
  padding: 12px 20px 20px
  box-shadow: 0px 8px 10px 0px rgba(0, 0, 0, 0.20), 0px 6px 30px 0px rgba(0, 0, 0, 0.12), 0px 16px 24px 0px rgba(0, 0, 0, 0.14)
  border-radius: 8px
  position: absolute
  top: 100%
  right: 40px
  z-index: 2
  background: var(--color-white)
  .scheduleMessageHeader
    display: flex
    justify-content: space-between
    align-items: center
    gap: 12px
    margin-bottom: 6px
    .scheduleMessageTitle
      color: var(--color-d900)
      font-size: 24px
      font-style: normal
      font-weight: 500
      line-height: 28px
    .crossBtn
      padding: 6px
      display: flex
      &:hover *
        fill: var(--color-b800)
  .scheduleMessageContent
    width: 168px
    margin-bottom: 18px
  .scheduleActions
    display: flex
    gap: 8px
    justify-content: flex-end
.form
  @include boxShadow
