.file
  width: 86%
  margin-left: 14%
  height: 100%
  background-color: #E2E5E7
  border-radius: 5%
  font-family: "SF Pro Medium", sans-serif
  .label
    position: absolute
    top: 46%
    left: 0
    display: flex
    justify-content: center
    align-items: center
    width: 86%
    height: 37%
    border-radius: 10%
    color: white
    .labelZip
      margin: 0 0 0 25%
  .zip
    position: absolute
    left: 0
    margin: 0 30% 0 0
    width: 30%
    img
      width: 45%
.corner
  position: absolute
  top: 0
  right: 0
  width: 29%
  height: 24%
  border-bottom-left-radius: 30%
.shadow
  position: absolute
  top: 24%
  right: 0
  width: 22%
  height: 20%
  background: linear-gradient(45deg, rgb(226, 229, 231) 0%, rgb(226, 229, 231) 50%, rgba(202,209,216,1) 50%)
.png, .jpeg, .jpg, .svg, .doc, .docx
  background: linear-gradient(45deg, #477DE1 0%, #477DE1 50%, white 51%)
.sketch
  background: linear-gradient(45deg, #F9AD50 0%, #F9AD50 50%, white 51%)
.ai
  background: linear-gradient(45deg, #FF9304 0%, #FF9304 50%, white 51%)
.psd, .mp4
  background: linear-gradient(45deg, rgb(52, 174, 196) 0%, rgb(52, 174, 196) 50%, white 51%)
.mov, .avi, .xls, .xlsx, .pptx
  background: linear-gradient(45deg, #34C6A2 0%, #34C6A2 50%, white 51%)
.others
  background: linear-gradient(45deg, grey 0%, grey 50%, white 51%)
.othersBig
  background: grey
.pngBig, .jpegBig, .jpgBig, .svgBig, .docBig, .docxBig
  background: #477DE1
.sketchBig
  background: #F9AD50
.aiBig
  background: #FF9304
.psdBig, .mp4Big
  background: rgb(52, 174, 196)
.movBig, .aviBig, .xlsBig, .xlsxBig, .pptxBig
  background: #34C6A2
@media (max-width: 1420px)
  .file
    .label
      font-size: 10px