@import "src/theme/theme"

.previewsWrap
  display: flex
  width: 100%
  height: 100%
  align-self: center
  justify-self: center
  align-items: center
  justify-content: center
video
  max-width: 100%
  max-height: 100%
iframe
  width: 100%
  height: 100%
  border-radius: 10px
.audioWrap
  display: flex
  flex-direction: column
  justify-content: center
  align-items: center
  background: #FFFFFF
  width: 430px
  height: 450px
  box-sizing: border-box
  padding: 15px
  border-radius: 10px
.audioPicWrap
  display: flex
  justify-content: center
  align-items: center
  width: 100%
  height: 100%
  position: relative
  .audioPic
    max-width: 50%
    max-height: 90%
    height: 90%
  .audioSwitchPlay
    position: absolute
    width: 3%
    transform: rotateZ(180deg)
    cursor: pointer
  .audioSwitch
    position: absolute
    width: 5%
    cursor: pointer

.filePreviewWrapWrap
  position: relative
  width: 230px
  height: 270px
  background: #FFFFFF
  display: flex
  justify-content: center
  align-items: center
  .filePreviewWrap
    position: relative
    width: 220px
    height: 260px

.canvasWrap
  display: flex
  align-self: flex-start
  width: 98%
  height: 92%
  max-height: 92%
  overflow: auto
