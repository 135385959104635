.wrap
  margin: 0 auto
  width: 440px
  padding: 16px
  overflow: auto
  .pass
    display: flex
    padding: 10px 12px
    justify-content: space-between
    gap: 4px
    align-self: stretch
    background-color: var(--color-d50)
    border-radius: 4px
    margin-top: 12px

  .footer
    display: flex
    justify-content: flex-end
    padding-top: 16px
