
.wrap
  background-color: var(--color-d20, #F5F7F7)
  padding: 12px
  border-radius: 4px
  .inputBlock
    display: flex
    gap: 8px
  .inputField
    height: 32px
.switcher
  margin-top: 8px
  height: 28px
  display: flex
  align-items: center
  background-color: var(--color-d50)
  border-radius: 4px
  padding: 2px
  cursor: pointer
  position: relative
  font-size: 12px
  flex: 0 0 52px
  &_checkbox
    transform: translateX(0)
    transition: transform 500ms ease
    z-index: 2
  &_label
    width: 40px
    color: var(--color-d800)
    text-align: center
    transform: translateX(0)
    transition: transform 500ms ease
  &.checked
    .switcher_checkbox
      transform: translateX(100%)
