@import "src/theme/global"
.workAreaWrap
  display: flex
  width: 100%
  min-width: 100%
  height: 100%
  background-color: var(--color-white)
  .folderListWrap
    height: 100%
    overflow-x: hidden
    overflow-y: auto
    position: relative
  .foldersList
    display: flex
    flex-direction: column
    row-gap: 5px
    overflow-x: hidden
    overflow-y: auto
    margin: 5px 10px 30px
  .workSpaceWrap
    height: 100%
    display: flex
    flex-direction: column
    flex-grow: 1
    background-color: var(--color-white)
    overflow: hidden
    position: relative
.listItem
  width: 100%
  cursor: pointer
.link
  width: 100%
  display: flex
  align-items: center
  justify-content: space-between
  gap: 10px
  padding: 14px 22px 15px 10px
  background: transparent
  border: 1px solid transparent
  border-radius: 6px
  margin-bottom: 5px
  &:last-child
    margin-bottom: 0
  &:hover
    background: var(--color-blue-lightest)
    .arrowIcon path
      fill: var(--color-blue-smooth)
  .name
    flex: 1
    text-align: left
    font-size: 13.5px
    @include textEllipsis
    transition: opacity 0.2s ease-in-out, visibility 0.2s ease-in-out
    &.collapsed
      opacity: 0
      visibility: hidden
      pointer-events: none
  &.active
    background: var(--color-blue-lightest)
    border-color: var(--color-blue-smooth)
.sharedIcon
  flex: 0 0 auto
