.list
  display: flex
  gap: 6px
  flex-wrap: nowrap
  min-width: 0
  overflow-x: auto
  flex-grow: 1
  .team
    background: #e9edec
    border-radius: 4px
    &.active
      border-color: blue
      button
        font-weight: 500
  .disabledBtn
    background: rgba(39, 74, 66, 0.10)
    color: var(--color-d400)
    & path
      fill: var(--color-d400)
