h2,
h3,
p
    margin: 0

button
    padding: 0
    background-color: transparent
    font-family: inherit
    border: 0
    transition-duration: 500ms

    &:disabled
        cursor: not-allowed

a
    color: inherit
    text-decoration: none

    &:hover
        color: inherit
// reset input number default styls
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button
    -webkit-appearance: none
    margin: 0

input[type=number]
    -moz-appearance: textfield
ul, li
    list-style: none
    margin: 0
    padding: 0
