.container
    display: flex
    align-items: flex-start
    padding: 10px 0 0 16px
    flex-direction: column
    overflow: hidden
    border-radius: 8px
    height: 100%
    .listWrapper
        overflow-x: hidden
        overflow-y: auto
        width: 100%
        margin-top: 8px
        padding-right: 8px
    .list
        display: flex
        gap: 8px
        align-items: center
    &_long
        .listWrapper
            overflow-x: auto
            overflow-y: hidden
            height: 60px

    &_normal
        padding: 10px 0 10px 8px
        .list
            flex-direction: column
            .item
                height: 24px
                width: 100%
                & > div
                    width: 100%
                    height: 24px
                    > div > div
                        text-shadow: 0px 0px 6px rgba(0, 0, 0, 0.9)
                svg
                    height: 20px
                img
                    max-height: 22px
.title
    color: var(--light-blue-b-700, #0288D1)
    font-family: Roboto
    font-size: 16px
    font-weight: 700
    line-height: 24px

.emptyWrapper
    padding-right: 16px
    width: 100%
    height: 100%
    display: flex
    align-items: center
    justify-content: center
