
.wrap
    display: flex
    gap: 10px
    justify-content: space-between
    align-items: center
    padding: 10px
    border-top: 1px solid var(--color-border-dark)
    border-bottom: 1px solid var(--color-border-dark)
    font-family: "SF Pro Text", sans-serif
    .selectBtn
        border: 1px solid var(--color-border-dark)
        border-radius: 6px
        background: var(--color-background-light)
        cursor: pointer
        font-size: 12px
        line-height: 14px
        padding: 4px 10px
        display: flex
        align-items: center
        gap: 10px
    .active, .selectBtn:hover
        border: 1px solid var(--color-blue-smooth)
        background: var(--color-blue-light)
    .btns
        display: flex
        align-items: center
        gap: 10px
    .disabled
        cursor: not-allowed
