
.users
    width: 100%
    display: flex
    flex-direction: column
    flex-wrap: wrap
    gap: 10px
    align-content: flex-start
    overflow-x: scroll
    height: 50px
    padding: 9px 10px 6px

    .user
        display: flex
        border: 1px solid var(--color-border-dark)
        justify-content: space-between
        align-items: center
        border-radius: 6px
        cursor: pointer

    .icon
        width: 20px
        height: 20px
        margin-right: 5px
    .admin path
        fill: var(--color-green-dark)
    .active
        border: 1px solid var(--color-blue-smooth)
        background: var(--color-blue-light)
