
.avatarBlock
  display: flex
  align-items: stretch
  gap: 8px
  margin-bottom: 16px
  & > .uploadBtns
    display: flex
    flex-direction: column
    align-items: stretch
    justify-content: space-between
    & > span
      flex: 0

.phoneBlock
  display: flex
  gap: 8px
  align-items: flex-start
  &:not(:last-child)
    margin-bottom: 8px
  button
    margin-top: 6px
.contactBox
  padding: 12px 12px 12px 0
.inputs
  display: flex
  flex-direction: column
  gap: 8px

.input
  height: 32px
  padding: 6px 8px 6px 12px
  width: 244px

.emailBox
  padding: 10px 12px
  background-color: var(--dark-green-d-530, #E7EBEA)
  border-radius: 4px
  .input
    width: 236px
.subtitleBlock
  color: var(--color-d700)
  font-size: 14px
  line-height: 20px
  display: flex
  justify-content: space-between
  align-items: center
  margin-bottom: 8px
.divFlex
  display: flex
  gap: 8px
  align-items: center
  &:not(:last-child)
    margin-bottom: 8px
  button
    margin-top: 6px

.timezone
  padding: 10px 12px
  background-color: var(--dark-green-d-530, #E7EBEA)
  border-radius: 4px
  margin-bottom: 8px

.autoTimezone
  border-radius: 4px
  background: var(--DarkGreen-D100, #CFD7D5)
  padding: 6px 8px
  width: 100%
  margin-top: 6px
  display: flex
  align-items: center
  justify-content: space-between
  > div
    color: var(--DarkGreen-D800, #274A42)
    font-family: Roboto
    font-size: 12px
    font-style: normal
    font-weight: 400
    line-height: 19px
    display: flex
    align-items: center
    gap: 8px
