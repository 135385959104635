.container
    padding: 16px 0 16px 6px

.list
    margin-top: 16px
    width: 608px
    max-height: 370px
    overflow-y: auto
    overflow-x: hidden

.content
    width: 100%
    height: 100%
    padding-right: 6px

.addButton
    margin-left: 10px

.buttons
    width: 100%
    justify-content: flex-end
    display: flex
    gap: 8px
    margin-top: 10px
    padding: 0 10px
