.list
  max-height: 300px
  width: 100%
  overflow: auto
  margin: 0
  &.noBorderRadius
    border-bottom-left-radius: 6px
    border-bottom-right-radius: 6px
.listItem > div
  padding-right: 10px
  margin: 1px 0
