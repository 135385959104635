.container
    width: 56px
    height: 72px
    display: flex
    flex-direction: column
    align-items: center
    cursor: pointer
    position: relative
    transition: all 0.2s ease-in-out
    padding: 2px
    border-radius: 6px
    .title
        margin-top: 3px
        font-size: 12px
        line-height: 16px
        text-align: center
        color: var(--dark-green-d-800, #274A42)
        width: 100%
        white-space: nowrap
        overflow: hidden
        text-overflow: ellipsis

    &:hover
        background: var(--color-b40, #EDF9FE)
    &_normal
        flex-direction: row
        height: 30px
        width: 100%
        gap: 5px
        justify-content: flex-start
        align-items: center
        .title
            margin-top: 0
            text-align: start

.share
    position: absolute
    width: 18px
    height: 18px
    padding: 2px
    display: flex
    justify-content: center
    align-items: center
    border-radius: 4px
    background: var(--green-g-800, #34C6A2)
    right: 0px
    top: 0px
    svg
        width: 11px
        height: 11px
