@font-face
  font-family: "SF Pro Light"
  src: url("fonts/SF-Pro/SF-Pro-Text-Light.otf") format("opentype")
  src: url("fonts/SF-Pro/sf-pro-text-light-webfont.woff2") format("woff2")
  src: url("fonts/SF-Pro/sf-pro-text-light-webfont.woff") format("woff")
  font-style: normal
  font-weight: normal

@font-face
  font-family: "SF Pro Regular"
  src: url("./fonts/SF-Pro/SF-Pro-Text-Regular.otf") format("opentype")
  font-style: normal
  font-weight: normal

@font-face
  font-family: "SF Pro Medium"
  src: url("./fonts/SF-Pro/SF-Pro-Text-Medium.otf") format("opentype")
  src: url("./fonts/SF-Pro/sf-pro-text-medium-webfont.woff2") format("woff2")
  src: url("./fonts/SF-Pro/sf-pro-text-medium-webfont.woff") format("woff")
  font-style: normal
  font-weight: normal

@font-face
  font-family: "SF Pro Bold"
  src: url("./fonts/SF-Pro/SF-Pro-Text-Bold.otf") format("opentype")
  src: url("./fonts/SF-Pro/sf-pro-text-bold-webfont.woff2") format("woff2")
  src: url("./fonts/SF-Pro/sf-pro-text-bold-webfont.woff") format("woff")
  font-style: normal
  font-weight: normal

@font-face
  font-family: "SF Pro RegularItalic"
  src: url("./fonts/SF-Pro/SF-Pro-Text-RegularItalic.otf") format("opentype")
  font-style: normal
  font-weight: normal

@font-face
  font-family: "SF Pro SemiBold"
  src: url("./fonts/SF-Pro/SF-Pro-Text-Semibold.otf") format("opentype")
  font-style: normal
  font-weight: normal

@font-face
  font-family: "SF Pro SemiBoldItalic"
  src: url("./fonts/SF-Pro/SF-Pro-Text-SemiboldItalic.otf") format("opentype")
  font-style: normal
  font-weight: normal
