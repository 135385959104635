.wrapper
    width: 290px
    height: 64px
    display: flex
    align-items: center
    padding: 8px 10px
    .playButton
        width: 46px
        height: 46px
        cursor: pointer
        background: #fff
        border-radius: 50%
        display: flex
        align-items: center
        justify-content: center
        margin-right: 14px
        .pauseIcon
            height: 18px
            width: 20px
            cursor: pointer
            position: relative
            &:before,
            &:after
                position: absolute
                top: 0
                background: #4086F1
                content: ""
                height: 18px
                width: 3px
            &:before
                left: 4px
            &:after
                right: 4px
        svg
            transform: rotate(180deg) translateX(-2px)
            width: 20px
            height: 20px
            path
                fill: #4086F1
        &.inboxMessage
            background: #4086F1
            .pauseIcon
                &:before,
                &:after
                    background: #fff
            svg
                path
                    fill: #fff
    .rightContainer
        align-self: center
        display: flex
        flex-direction: column
        justify-content: flex-start
        height: 70%
        margin-top: 2px
        .audioHistogram
            flex-grow: 1
            display: flex
            align-items: flex-end
            justify-content: center
            overflow: hidden
            margin-bottom: 2px
            position: relative
            .seekBar
                position: absolute
                top: 0
                bottom: 0
                height: 100%
                width: 100%
                cursor: pointer
                opacity: 0
                &[type=range]
                    -webkit-appearance: none !important
                &[type=range]::thumb
                    width: 0 !important
                    height: 0 !important
                    visibility: hidden
                &[type=range]::-webkit-slider-thumb
                    width: 0 !important
                    height: 0 !important
                    visibility: hidden
                &[type=range]::-moz-range-thumb
                    width: 0
                    height: 0
                    display: none
            .column
                width: 2px
                background: #fff
                margin-right: 2px
                min-height: 2px
                border-radius: 2px
            .lisened
                transition: background .2s linear
                background: #00388b
        .duration
            color: #fff
            font-size: 11px
            align-self: flex-start
            text-align: center
        &.inboxMessage
            .audioHistogram
                .column
                    background: #4086F1
                .lisened
                    background: #00388b
            .duration
                color: #4086F1
.wrapper.small
    width: 182px
    height: 33px
    padding: 4px 5px
    .playButton
        min-width: 25px
        min-height: 25px
        height: 25px
        width: 25px
        margin: 0
        padding: 0
        position: relative
        .pauseIcon
            height: 7px
            width: 7px
            &:before,
            &:after
                height: 7px
                width: 1px
            &:before
                left: 1px
            &:after
                right: 1px
        svg
            width: 6px
            height: 9px
            position: absolute
            left: 50%
            transform: translateX(-50%) rotate(180deg)
    .rightContainer
        flex-direction: row
        justify-content: space-between
        flex-grow: 1
        padding: 0 7px 0 4px
        height: 50%
        margin-top: 0
        .audioHistogram
            margin-bottom: 0
            .seekBar
                margin: 0
            .column
                width: 1px
                margin-right: 1px
                min-height: 1px
        .duration
            font-size: 10px
