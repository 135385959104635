.btnSelected
  flex-shrink: 0
  display: flex
  align-items: center
  justify-content: space-between
  column-gap: 4px
  background-color: var(--color-white)
  border: 1px solid var(--color-d100)
  border-radius: 4px
  color: var(--color-d400)
  font-size: 12px
  line-height: 1.33
  padding: 5px 4px 5px 10px
  transition: background-color 0.2s ease-in-out, border-color 0.2s ease-in-out
  position: relative
  z-index: 0
  cursor: pointer
  height: 28px
  width: 128px

  &.selected
    color: var(--color-d800)

  &::after
    content: ""
    width: 0
    height: 0
    border-left: 3.33px solid transparent
    border-right: 3.33px solid transparent
    border-top: 3.33px solid var(--color-d100)
    transition: transform 300ms, border-top-color 300ms

  &.open::after
    border-top-color: var(--color-blue-smooth)
    transform: rotate(180deg)

  &:hover, &.open
    background-color: var(--color-blue-items)
    border-color: var(--color-blue-smooth)

.content
  &__header
    border-bottom: 1px solid var(--color-d100)

  &__body
    padding: 20px 0

  &__footer
    background-color: var(--color-d40)
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.20), 0px 0px 10px 0px rgba(0, 0, 0, 0.12), 0px -2px 5px 0px rgba(0, 0, 0, 0.14)
    padding: 8px 12px
