
.miniCallRoom
  position: fixed
  width: 360px
  height: 220px
  z-index: 12
  right: 20px
  bottom: 100px
  border-radius: 6px
  background: black

  .innerMiniCallRoom
    display: flex
    align-items: center
    justify-content: center
    width: 100%
    height: 100%
    position: relative
    border-radius: 6px
    box-shadow: 0 0 7px var(--color-shadow)

    &:hover
      background: rgba(0, 0, 0, 0.49)

      .buttons
        position: absolute
        color: var(--color-white)
        width: 90%
        display: flex
        align-items: center
        justify-content: space-around
        z-index: 1
        .cameraIcon
          path, polygon
            fill: var(--color-white)
        .slash
          position: absolute
          width: 80%
          height: 2px
          background: var(--color-white)
          transform: rotateZ(-45deg)

    video
      width: 100%
      height: 100%
      object-fit: cover
      border-radius: 6px

    .hiddenButtons
      display: none

    .avatarImage
      position: absolute
      width: 100%
      height: 100%
      object-fit: fill
