.box
    max-width: 736px
    width: 100%
    height: calc(100% - 92px)
    margin: 0 auto
    display: grid
    grid-template: calc(100% - 89px) / 0.345fr 0.655fr
    gap: 12px
    padding: 16px 10px
    box-sizing: content-box

    > div
        &:first-child
            height: 77px
            grid-row: -3
            grid-column: 1/3