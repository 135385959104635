@import "src/theme/global"
.box
  height: 340px
  margin: 0
  position: relative
  overflow-y: auto
  .user
    display: flex
    justify-content: space-between
    align-items: center
    gap: 10px
    padding: 8px 12px
    background: var(--color-d40)
    border-radius: 4px
    margin-bottom: 4px
  .name
    color: var(--color-d800)
    font-size: 12px
    line-height: 20px
    flex: 1 1
    margin: 0
    @include textEllipsis
