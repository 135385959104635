
.wrap
  width: 100%
  margin-bottom: 10px
  font-family: "SF Pro Text", sans-serif

  .name
    display: flex
    justify-content: space-between
    align-items: center
    gap: 10px
    border-radius: 6px
    background: var(--color-blue-light)
    min-width: 130px
    width: max-content
    font-size: 13px
    line-height: 15px
    padding: 8px 10px 7px
    margin-bottom: 10px
