.grey path
  fill: #efeeee
.grey path:first-child
  fill: #cacaca
.grey polygon
  fill: #efeeee

.wheat path
  fill: #fedcb8
.wheat path:first-child
  fill: #c4aa8f
.wheat polygon
  fill: #FEDCB8

.blue path
  fill: #4086f2
.blue path:first-child
  fill: #326bc1
.blue polygon
  fill: #4086F1

.green path
  fill: #34c6a2
.green path:first-child
  fill: #27967a
.green polygon
  fill: #34C6A2

.orange path
  fill: #F9AD50
.orange path:first-child
  fill: #cb8d40
.orange polygon
  fill: #F9AD50

.pink path
  fill: #F4707D
.pink path:first-child
  fill: #c15862
.pink polygon
  fill: #F4707D

.purple path
  fill: #A234C6
.purple path:first-child
  fill: #792794
.purple polygon
  fill: #A234C6

.red path
  fill: #C63534
.red path:first-child
  fill: #992928
.red polygon
  fill: #C63534

.aqua path
  fill: #34aec4
.aqua path:first-child
  fill: #267d8d
.aqua polygon
  fill: #34aec4

.brown path
  fill: #a1627d
.brown path:first-child
  fill: #c47697
.brown polygon
  fill: #a1627d

.crimson path
  fill: #C81E98
.crimson path:first-child
  fill: #981E76
.crimson polygon
  fill: #C81E98

.darkblue path
  fill: #5134C6
.darkblue path:first-child
  fill: #40289b
.darkblue polygon
  fill: #5134C6

.darkbrown path
  fill: #C67734
.darkbrown path:first-child
  fill: #A46128
.darkbrown polygon
  fill: #C67734

.darkgreen path
  fill: #83BF20
.darkgreen path:first-child
  fill: #6A9E14
.darkgreen polygon
  fill: #83BF20
