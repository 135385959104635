.item
    padding: 8px 12px
    border-radius: 4px
    background: var(--DarkGreen-D40, #F1F3F2)
    position: relative
    width: 100%
    display: flex
    flex-direction: column
    justify-content: space-between
    gap: 8px
    overflow: hidden
    transition: all 0.3s ease-in-out
    min-height: 70px
    &:hover,  &:hover .hoverIcons
        background: var(--DarkGreen-D50, #E7EBEA)
    &:hover .hoverIcons, &.active .hoverIcons
        right: 12px
    &:hover .number
        background: var(--Green-G900, #2FB292)

.number
    border-radius: 4px 0px
    background: var(--DarkGreen-D300, #9FAEAB)
    position: absolute
    left: 0
    top: 0
    padding: 1px 6px
    color: var(--DarkGreen-D20, #F5F7F7)
    font-size: 12px
    font-weight: 700
    line-height: 16px
    transition: all 0.3s ease-in-out

.descriptions
    font-size: 14px
    line-height: 1.43
    position: relative

    &.margin
        // margin-top: 10px
        margin-left: 20px

    .num
        color: var(--Blue-B800, #1565C0)
        font-weight: 700
        margin-right: 5px

    .text
        color: var(--DarkGreen-D700, #3E5D56)
        font-weight: 500

.hoverIcons
    padding: 2px
    position: absolute
    display: flex
    right: -100%
    top: 8px
    background: var(--DarkGreen-D40, #F1F3F2)
    transition: all 0.3s ease-in-out

.priority
    color: var(--DarkGreen-D600, #56716B)
    font-size: 12px
    font-weight: 500
    line-height: 16px
    &.urgent
        border-radius: 4px
        background: #F45151
        padding: 2px 8px 2px 6px
        gap: 4px
        display: flex
        align-items: center
        justify-content: center
        color: var(--DarkGreen-D20, #F5F7F7)

.information
    display: flex
    align-items: center
    justify-content: space-between
    height: 20px

.leftBlock
    display: flex
    gap: 8px
    align-items: center

.icons
    display: flex
    gap: 10px
    color: var(--DarkGreen-D600, #56716B)
    font-size: 12px
    font-weight: 400

    div
        cursor: pointer
        display: flex
        align-items: center

.comments
    svg
        margin-right: 3px
.openTask
    width: 28px
    height: 28px
    border-radius: 4px
    display: flex
    align-items: center
    justify-content: center
    padding: 4px
    svg
        width: 14px
        height: 14px
    &:hover
        background: var(--color-d200)

.tags
    margin: 4px 0
    display: flex
    gap: 6px
    align-items: center

.color
    border-radius: 50%
    width: 16px
    height: 16px
