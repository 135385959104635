.storageWrap
    display: flex
    background-color: var(--color-d100)
    border-radius: 4px
    overflow: hidden
    height: 31px

.chapter
    position: relative
    z-index: 1
    height: 31px