
.root
  display: flex
  align-items: stretch
  column-gap: 5px
  margin-right: 15px
.navigationButton
  padding: 0
  &:hover
    .icon
      fill: var(--color-plantation)
.defaultButtonIcon
  width: 40px
  height: 28px
.icon
  fill: var(--color-granny-smith)
  transition: fill 0.1s ease-in-out
