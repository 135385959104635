
@keyframes showinput
  0%
    width: 0
    padding: 5px 0
  100%
    width: 200px
    padding: 5px 10px

.btn
  width: 30px
  height: 24px
  display: flex
  align-items: center
  justify-content: center
  border: 1px solid var(--color-border-dark)
  border-radius: 6px
  background: var(--color-background-light)
  cursor: pointer
.btnVisible
  border-radius: 0 6px 6px 0
.input
  width: 0
  border: 1px solid var(--color-border-dark)
  border-right: none
  background: var(--color-background-light)
  border-radius: 6px 0 0 6px
  font-size: 11px
  line-height: 12px
  color: var(--color-text-dark)
  margin-right: -16px

.visible
  width: 200px
  padding: 5px 10px
  animation: showinput 0.3s ease

.hidden
  width: 0
  padding: 5px 0
  transition: all 0.2s
