
.wrap
    width: 410px
    padding-top: 10px
    font-family: "SF Pro Regular", sans-serif
    .subTitle
        display: block
        font-size: 13px
        line-height: 16px
        color: var(--color-text-grey)
        margin-bottom: 8px
        text-align-last: left
    .input
        background: var(--color-background-light)
        border: 1px solid var(--color-border-dark)
        border-radius: 6px
        padding: 9px 20px
        width: 100%
        font-family: "SF Pro Regular", sans-serif
        font-size: 13px
        line-height: 16px
        color: var(--color-text-grey)
        margin-bottom: 20px
    .text
        margin: 0 0 27px
        padding: 0 10px
        text-align: center
        font-size: 13px
        line-height: 16px
        color: var(--color-text-grey)
    .error
        color: var(--color-error)
