
.wrap
    padding: 10px
    display: flex
    flex-direction: column
    flex-wrap: wrap
    align-content: flex-start
    gap: 10px
    height: calc(100% - 140px)
    width: 100%
    overflow-x: auto

    .container
        height: 100%
    .opacity
        opacity: 0.1
    .start
        position: relative
        &::after
            position: absolute
            top: 0
            left: 0
            content: ""
            width: 100%
            height: 100%
    .dragEnter
        padding-right: 210px
        position: relative
        transition: all 0.2s ease
        &::before
            content: ""
            position: absolute
            top: 0
            right: 0
            box-shadow: inset 0 0 10px 5px var(--color-shadow)
            width: 200px
            height: 100%
            border-radius: 6px
    .stikyBox
        position: sticky
        right: 0
        display: flex
        flex-direction: column
        gap: 10px
        padding: 5px
        background: var(--color-white)
        border-radius: 6px
        box-shadow: -2px 0px 8px 2px var(--color-shadow)
