
.wrapper
  overflow-x: auto
  overflow-y: hidden
  flex-grow: 1
  transform: scaleY(-1)
  display: flex
.listPath
  display: flex
  margin: 0
  transform: scaleY(-1)
  height: 100%
  flex: 0 0 auto
.pathItem
  flex: 0 0 290px
  width: 290px
  display: flex
  flex-direction: column
  height: 100%
  overflow-y: auto
  border-right: 1px solid var(--color-border-dark)
  padding: 10px
  position: relative
.preview
  transform: scaleY(-1)
  width: 100%
  min-width: 400px
  height: 100%
  display: flex
