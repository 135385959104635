
.timeBlock
  font-size: 11px
  color: var(--color-text-grey-light)
  display: flex
  align-items: center
  justify-content: space-between
  min-height: 30px
  padding: 0 20px
  border-bottom: 1px solid var(--color-border-dark)
  .cancelBtn
    position: relative
    color: var(--color-blue-smooth)
