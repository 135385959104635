
.passWrap
    display: flex
    flex-direction: column
    align-items: center
    width: 420px
    padding: 35px 30px 30px
    font-family: 'SF Pro Text', sans-serif
    font-weight: 500
    font-size: 12px
    line-height: 14px
    color: var(--color-text-dark)

    .text
        margin: 0 0 25px
        text-align: center
    .formItem
        align-self: stretch
        .text
            text-align: start
            margin-bottom: 10px

    .inputWrap
        height: 38px
        width: 100%
        margin: 8px 0 20px
