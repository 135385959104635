.addNoteWrap
  width: 380px
  text-align: left
  display: flex
  flex-direction: column
  gap: 15px
.btns
  display: flex
  gap: 10px
  justify-content: center
  padding-top: 15px
.editorWrap
  position: relative
  .btnPass
    position: absolute
    bottom: 10px
    right: 10px
