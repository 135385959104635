
.editFile
  width: 90vw
  height: 90vh
  position: relative

.pdfContainer
  width: 980px
  height: 600px
.image
  display: block
  max-width: 100%
  max-height: 80vh
.audiopopup
  width: 100vw
  height: 100vh
  position: fixed
  top: 0
  z-index: 999
  background: rgba(0,0,0, 0.5)
  display: flex
  align-items: center
  justify-content: center
  &.collapse
    width: max-content
    height: max-content
    top: auto
    bottom: 32px
    right: 32px
    background: none
    .wrap
      width: 360px
  .wrap
    width: 984px
    border-radius: 10px
    overflow: hidden

.header
  background: var(--color-b800)
  color: var(--color-white)
  font-size: 14px
  line-height: 18px
  position: relative
  padding: 11px 12px
  display: flex
  justify-content: space-between
  .btns
    display: flex
    gap: 20px
  .close
    padding: 0
    display: flex
    & path
      fill: var(--color-white)
.videopopup
  width: 100vw
  height: 100vh
  position: fixed
  top: 0
  z-index: 999
  background: rgba(0,0,0, 0.5)
  display: flex
  align-items: center
  justify-content: center
  .wrap
    max-width: 984px
    min-width: 600px
    background: rgba(0,0,0, 0.5)
    border-radius: 10px
    overflow: hidden
  &.collapse
    width: max-content
    height: max-content
    top: auto
    bottom: 32px
    right: 32px
    background: none
    .wrap
      max-width: 400px
      min-width: 400px
.canvas
  display: block
  margin: 0 auto
.noformat
  width: 80vw
  height: 80vh
  background: var(--color-white)
  display: flex
  align-items: center
  justify-content: center
  gap: 30px
  flex-direction: column
  .text
    font-size: 16px
    font-family: "Roboto", sans-serif
    font-weight: 600
    color: var(--color-d900)
