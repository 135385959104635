
.wrap
  width: 440px
  .body
    padding: 12px 16px
  .btns
    display: flex
    justify-content: space-between
    padding: 16px
    &>div
      display: flex
      gap: 10px
  .list
    padding: 10px
    font-size: 12px
    .item
      padding: 5px 0
      &:hover
        color: var(--color-b800)
